// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import EQuery from "../../../../utils/EQuery";
import { ADMIN_QUESTIONNAIRE, GET_LAYER } from "../../_GraphQL/queries";

// Semantic ui
import { Breadcrumb, Header } from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";
import QuestionnaireFieldInfos from "../../components/Questionnaire/QuestionnaireFieldInfos";
import QuestionnaireFieldLabels from "../../components/Questionnaire/QuestionnaireFieldLabels";

// Constants
import { QUESTIONNAIRE_FIELD_TYPES } from "../../../../utils/Constants";

class QuestionnaireField extends React.Component {
  state = {
    editMode: false,
  };

  toReadMode = () => this.setState({ editMode: false });

  toEditMode = () => this.setState({ editMode: true });

  render() {
    const { questionnaire, questionnaireField, fieldTypeSet, layer } =
      this.props;
    let breadcrumbItems = [
      <Breadcrumb.Section key="breadcrumb-home">
        <Link to="/manager">Manager</Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key="bc-divider-questionnaires" icon="right angle" />,
    ];

    if (layer) {
      breadcrumbItems.push([
        <Breadcrumb.Section key="breadcrumb-sites">
          <Link to="/manager/sites">Sites</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-site" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-site">
          <Link to={`/manager/site/${layer.task.project.site.id}`}>
            Site : {layer.task.project.site.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-project" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-project">
          <Link to={`/manager/projet/${layer.task.project.id}`}>
            Projet : {layer.task.project.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-module" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-module">
          <Link to={`/manager/carte/?projectId=${layer.task.project.id}`}>
            Module : Carte participative
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-task" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-task">
          <Link to={`/manager/tache/${layer.task.id}`}>
            Tâche : {layer.task.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-layer" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-layer">
          <Link to={`/manager/couche/${layer.id}`}>Couche : {layer.name}</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-field" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-questionnaire">
          <Link
            to={`/manager/couche/${layer.id}/questionnaire/${questionnaire.id}`}
          >
            Questionnaire : {questionnaire.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-reation-questionnaire"
          icon="right angle"
        />,
      ]);
    } else {
      breadcrumbItems.push([
        <Breadcrumb.Section key="breadcrumb-questionnaires">
          <Link to="/manager/questionnaires">Questionnaires</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-questionnaire"
          icon="right angle"
        />,
        <Breadcrumb.Section key="breadcrumb-questionnaire">
          <Link to={`/manager/questionnaire/${questionnaire.id}`}>
            Questionnaire : {questionnaire.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-field" icon="right angle" />,
      ]);
    }

    breadcrumbItems.push([
      <Breadcrumb.Section key="breadcrumb-field">
        Champ : {questionnaireField.text}
      </Breadcrumb.Section>,
    ]);

    return (
      <ManagerLayout breadcrumbItems={breadcrumbItems}>
        <Header as="h5" block>
          Champ : {questionnaireField.text}
        </Header>
        <QuestionnaireFieldInfos
          questionnaire={questionnaire}
          questionnaireField={questionnaireField}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
          fieldTypeSet={fieldTypeSet}
          create={false}
        />

        {questionnaireField.fieldType.code ===
          QUESTIONNAIRE_FIELD_TYPES.RADIO ||
        questionnaireField.fieldType.code ===
          QUESTIONNAIRE_FIELD_TYPES.SELECT ? (
          <QuestionnaireFieldLabels
            questionnaire={questionnaire}
            questionnaireField={questionnaireField}
            editMode={this.state.editMode}
            toEditMode={this.toEditMode}
            toReadMode={this.toReadMode}
            layer={layer}
          />
        ) : null}
      </ManagerLayout>
    );
  }
}

const QuestionnaireFieldQuery = ({ match, layer }) => (
  <EQuery
    query={ADMIN_QUESTIONNAIRE}
    variables={{ questionnaireId: match.params.questionnaireId }}
  >
    {(data) => {
      const questionnaireField =
        data.adminQuestionnaire.questionnairefieldSet.find(
          (field) => field.id === match.params.questionnaireFieldId
        );
      const fieldTypeSet = data.adminFieldTypeSet.map((type) => ({
        key: type.id,
        value: type.code,
        text: type.text,
      }));
      return (
        <QuestionnaireField
          questionnaireField={questionnaireField}
          questionnaire={data.adminQuestionnaire}
          fieldTypeSet={fieldTypeSet}
          layer={layer}
        />
      );
    }}
  </EQuery>
);

export { QuestionnaireFieldQuery };

const QuestionnaireFieldQueryEntry = ({ match }) => {
  if (match.params.layerId) {
    return (
      <EQuery query={GET_LAYER} variables={{ layerId: match.params.layerId }}>
        {(data) => <QuestionnaireFieldQuery match={match} layer={data.layer} />}
      </EQuery>
    );
  }

  return <QuestionnaireFieldQuery match={match} />;
};

export default QuestionnaireFieldQueryEntry;
