// GraphQL
import { gql } from "@apollo/client";
import {
  DRAWING_GROUP_PERMISSION_FRAG,
  DEFAULT_DRAWING_GROUP_PERMISSION_FRAG,
  LAYER_FRAG,
  QUESTIONNAIRE_FRAG,
  TASK_FRAG,
  OPEN_SIGN_IN_FRAG,
} from "./fragments";
import {
  DRAWING_FRAG,
  PROJECT_FRAG,
  DEFAULT_FOLDER_GROUP_PERMISSION_FRAG,
  DEFAULT_DOCUMENT_GROUP_PERMISSION_FRAG,
  USER_FRAG,
  GROUP_PERMISSION_FRAG,
  QUESTIONNAIRE_FIELD_LABEL_FRAG,
  QUESTIONNAIRE_FIELD_FRAG,
  STYLE_FRAG,
} from "../../../Common/_GraphQL/fragments";

export const ADMIN_CREATE_DRAWING = gql`
  mutation AdminCreateDrawing(
    $projectId: ID!
    $name: String!
    $background: Upload!
  ) {
    adminCreateDrawing(
      projectId: $projectId
      name: $name
      background: $background
    ) {
      drawing {
        ...DrawingFrag
      }
    }
  }
  ${DRAWING_FRAG}
`;

export const ADMIN_ADD_REMOVE_DEFAULT_DRAWING_GROUP_PERMISSION = gql`
  mutation adminAddRemoveDefaultDrawingGroupPermission(
    $projectId: ID!
    $groupPermissionToAddSet: [GroupPermissionType]!
    $groupPermissionToRemoveSet: [GroupPermissionType]!
  ) {
    adminAddRemoveDefaultDrawingGroupPermission(
      projectId: $projectId
      groupPermissionToAddSet: $groupPermissionToAddSet
      groupPermissionToRemoveSet: $groupPermissionToRemoveSet
    ) {
      defaultDrawingGroupPermissionSet {
        ...DefaultDrawingGroupPermissionFrag
      }
    }
  }
  ${DEFAULT_DRAWING_GROUP_PERMISSION_FRAG}
`;

export const ADMIN_ADD_REMOVE_DRAWING_GROUP_PERMISSION = gql`
  mutation adminAddRemoveDrawingGroupPermission(
    $drawingId: ID!
    $groupPermissionToAddSet: [GroupPermissionType]!
    $groupPermissionToRemoveSet: [GroupPermissionType]!
  ) {
    adminAddRemoveDrawingGroupPermission(
      drawingId: $drawingId
      groupPermissionToAddSet: $groupPermissionToAddSet
      groupPermissionToRemoveSet: $groupPermissionToRemoveSet
    ) {
      drawingGroupPermissionSet {
        ...DrawingGroupPermissionFrag
      }
    }
  }
  ${DRAWING_GROUP_PERMISSION_FRAG}
`;

export const UPDATE_PROJECT_ROOT_FOLDER = gql`
  mutation AdminUpdateProjectRootFolder(
    $projectId: ID!
    $rootFolderName: String!
    $groupPermissionToAddSet: [GroupPermissionType]!
    $groupPermissionToRemoveSet: [GroupPermissionType]!
  ) {
    adminUpdateProjectRootFolder(
      projectId: $projectId
      rootFolderName: $rootFolderName
      groupPermissionToAddSet: $groupPermissionToAddSet
      groupPermissionToRemoveSet: $groupPermissionToRemoveSet
    ) {
      project {
        ...ProjectFrag
      }
      folderGroupPermissionSet {
        ...GroupPermissionFrag
      }
    }
  }
  ${PROJECT_FRAG}
  ${GROUP_PERMISSION_FRAG}
`;

export const ASSIGN_UNASSIGN_GROUP_TO_TASK = gql`
  mutation AdminAssignUnassignGroupToTask(
    $taskId: ID!
    $groupsToAssign: [ID]!
    $groupsToUnassign: [ID]!
  ) {
    adminAssignUnassignGroupToTask(
      taskId: $taskId
      groupsToAssign: $groupsToAssign
      groupsToUnassign: $groupsToUnassign
    ) {
      adminAssignedGroupSet {
        id
        name
      }
      adminAvailableGroupSet {
        id
        name
      }
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateTask(
    $name: String!
    $projectId: ID!
    $tileLayerId: ID!
    $illustration: Upload!
  ) {
    createTask(
      name: $name
      projectId: $projectId
      tileLayerId: $tileLayerId
      illustration: $illustration
    ) {
      task {
        id
        name
        illustration
        illustrationPath
      }
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask(
    $taskId: ID!
    $order: Int
    $name: String!
    $subtitle: String
    $excerpt: String
    $useApicarto: Boolean!
    $municipalities: String
    $file: Upload
    $slug: String
    $description: String
    $active: Boolean!
    $taskTypeId: ID!
    $showHeart: Boolean!
    $geocodingActive: Boolean!
  ) {
    updateTask(
      taskId: $taskId
      name: $name
      subtitle: $subtitle
      excerpt: $excerpt
      useApicarto: $useApicarto
      municipalities: $municipalities
      file: $file
      slug: $slug
      description: $description
      active: $active
      order: $order
      taskTypeId: $taskTypeId
      showHeart: $showHeart
      geocodingActive: $geocodingActive
    ) {
      task {
        ...TaskFrag
      }
    }
  }
  ${TASK_FRAG}
`;

export const UPDATE_TASK_ILLUSTRATION = gql`
  mutation UpdateTaskIllustration($taskId: ID!, $illustration: Upload!) {
    updateTaskIllustration(taskId: $taskId, illustration: $illustration) {
      task {
        id
        name
        illustration
        illustrationPath
      }
    }
  }
`;

export const DELETE_TASK = gql`
  mutation AdminDeleteTask($taskId: ID!) {
    adminDeleteTask(taskId: $taskId) {
      result
    }
  }
`;

export const CREATE_TILE_LAYER = gql`
  mutation CreateTileLayer(
    $name: String!
    $description: String!
    $mapId: String!
    $latitude: Float!
    $longitude: Float!
    $zoomInit: Float!
    $zoomMin: Float!
    $zoomMax: Float!
  ) {
    createTileLayer(
      description: $description
      latitude: $latitude
      longitude: $longitude
      mapId: $mapId
      name: $name
      zoomInit: $zoomInit
      zoomMax: $zoomMax
      zoomMin: $zoomMin
    ) {
      tileLayer {
        id
        name
        description
        mapId
        latitude
        longitude
        zoomInit
        zoomMin
        zoomMax
      }
    }
  }
`;

export const CREATE_TILE_LAYER_FOR_PROJECT = gql`
  mutation CreateTileLayer(
    $projectId: ID!
    $mapId: String!
    $latitude: Float!
    $longitude: Float!
    $zoomInit: Float!
  ) {
    adminCreateTileLayerForProject(
      projectId: $projectId
      latitude: $latitude
      longitude: $longitude
      mapId: $mapId
      zoomInit: $zoomInit
    ) {
      tileLayer {
        id
        name
        description
        mapId
        latitude
        longitude
        zoomInit
        zoomMin
        zoomMax
      }
    }
  }
`;

export const UPDATE_TILE_LAYER = gql`
  mutation UpdateTileLayer(
    $tileLayerId: ID!
    $name: String!
    $description: String!
    $mapId: String!
    $latitude: Float!
    $longitude: Float!
    $zoomInit: Float!
    $zoomMin: Float!
    $zoomMax: Float!
  ) {
    updateTileLayer(
      tileLayerId: $tileLayerId
      description: $description
      latitude: $latitude
      longitude: $longitude
      mapId: $mapId
      name: $name
      zoomInit: $zoomInit
      zoomMax: $zoomMax
      zoomMin: $zoomMin
    ) {
      tileLayer {
        id
        name
        description
        mapId
        latitude
        longitude
        zoomInit
        zoomMin
        zoomMax
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation AdminCreateProject($name: String!, $siteId: ID!) {
    adminCreateProject(name: $name, siteId: $siteId) {
      project {
        ...ProjectFrag
      }
    }
  }
  ${PROJECT_FRAG}
`;

export const UPDATE_PROJECT = gql`
  mutation adminUpdateProject(
    $projectId: ID!
    $name: String!
    $description: String!
    $slug: String
    $notificationsActive: Boolean!
    $notificationsSenderGroups: [ID]
  ) {
    adminUpdateProject(
      projectId: $projectId
      name: $name
      description: $description
      slug: $slug
      notificationsActive: $notificationsActive
      notificationsSenderGroups: $notificationsSenderGroups
    ) {
      project {
        ...ProjectFrag
      }
    }
  }
  ${PROJECT_FRAG}
`;

export const UPDATE_PROJECT_ILLUSTRATION = gql`
  mutation AdminUpdateProjectIllustration(
    $projectId: ID!
    $illustration: Upload!
  ) {
    adminUpdateProjectIllustration(
      projectId: $projectId
      illustration: $illustration
    ) {
      project {
        ...ProjectFrag
      }
    }
  }
  ${PROJECT_FRAG}
`;

export const UPDATE_PROJECT_PLANNING = gql`
  mutation AdminUpdateProjectPlanning($projectId: ID!, $planning: Upload!) {
    adminUpdateProjectPlanning(projectId: $projectId, planning: $planning) {
      project {
        ...ProjectFrag
      }
    }
  }
  ${PROJECT_FRAG}
`;

export const DELETE_PROJECT = gql`
  mutation AdminDeleteProject($projectId: ID!) {
    adminDeleteProject(projectId: $projectId) {
      project {
        ...ProjectFrag
      }
    }
  }
  ${PROJECT_FRAG}
`;

export const ADMIN_CREATE_USER = gql`
  mutation AdminCreateUser(
    $email: String!
    $password: String!
    $nature: String!
    $firstName: String
    $lastName: String
    $name: String
  ) {
    adminCreateUser(
      email: $email
      password: $password
      nature: $nature
      firstName: $firstName
      lastName: $lastName
      name: $name
    ) {
      user {
        ...UserFrag
      }
    }
  }
  ${USER_FRAG}
`;

export const ADMIN_UPDATE_USER = gql`
  mutation AdminUpdateUser(
    $userId: ID!
    $email: String!
    $nature: String!
    $firstName: String
    $lastName: String
    $name: String
  ) {
    adminUpdateUser(
      userId: $userId
      email: $email
      nature: $nature
      firstName: $firstName
      lastName: $lastName
      name: $name
    ) {
      user {
        ...UserFrag
      }
    }
  }
  ${USER_FRAG}
`;

export const ADMIN_TOGGLE_USER_ACTIVITY = gql`
  mutation AdminToggleUserActivity($userId: ID!) {
    adminToggleUserActivity(userId: $userId) {
      user {
        ...UserFrag
      }
    }
  }
  ${USER_FRAG}
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdatePassword($password: String!, $userId: ID!) {
    updatePassword(password: $password, userId: $userId) {
      passwordUpdated
    }
  }
`;

export const ASSIGN_UNASSIGN_PROJECT_TO_USER = gql`
  mutation AdminAssignUnassignProjectToUser(
    $userId: ID!
    $siteId: ID!
    $projectsToAssign: [ID]!
    $projectsToUnassign: [ID]!
  ) {
    adminAssignUnassignProjectToUser(
      userId: $userId
      siteId: $siteId
      projectsToAssign: $projectsToAssign
      projectsToUnassign: $projectsToUnassign
    ) {
      adminAvailableProjectSet {
        id
        name
      }
      adminAssignedProjectSet {
        id
        name
      }
    }
  }
`;

export const ASSIGN_UNASSIGN_SITE_TO_USER = gql`
  mutation AdminAssignUnassignSiteToUser(
    $userId: ID!
    $sitesToAssign: [ID]!
    $sitesToUnassign: [ID]!
  ) {
    adminAssignUnassignSiteToUser(
      userId: $userId
      sitesToAssign: $sitesToAssign
      sitesToUnassign: $sitesToUnassign
    ) {
      adminAvailableSiteSet {
        id
        name
      }
      adminAssignedSiteSet {
        id
        name
      }
    }
  }
`;

export const ASSIGN_UNASSIGN_GROUP_TO_USER = gql`
  mutation AdminAssignUnassignGroupToUser(
    $userId: ID!
    $groupsToAssign: [ID]!
    $groupsToUnassign: [ID]!
  ) {
    adminAssignUnassignGroupToUser(
      userId: $userId
      groupsToAssign: $groupsToAssign
      groupsToUnassign: $groupsToUnassign
    ) {
      adminAvailableGroupSet {
        id
        name
      }
      adminAssignedGroupSet {
        id
        name
      }
    }
  }
`;

export const ADMIN_ADD_REMOVE_DEFAULT_FOLDER_GROUP_PERMISSION = gql`
  mutation AdminAddRemoveDefaultFolderGroupPermission(
    $projectId: ID!
    $groupPermissionToAddSet: [GroupPermissionType]!
    $groupPermissionToRemoveSet: [GroupPermissionType]!
  ) {
    adminAddRemoveDefaultFolderGroupPermission(
      projectId: $projectId
      groupPermissionToAddSet: $groupPermissionToAddSet
      groupPermissionToRemoveSet: $groupPermissionToRemoveSet
    ) {
      defaultFolderGroupPermissionSet {
        ...DefaultFolderGroupPermissionFrag
      }
    }
  }
  ${DEFAULT_FOLDER_GROUP_PERMISSION_FRAG}
`;

export const ADMIN_ADD_REMOVE_DEFAULT_DOCUMENT_GROUP_PERMISSION = gql`
  mutation AdminAddRemoveDefaultDocumentGroupPermission(
    $projectId: ID!
    $groupPermissionToAddSet: [GroupPermissionType]!
    $groupPermissionToRemoveSet: [GroupPermissionType]!
  ) {
    adminAddRemoveDefaultDocumentGroupPermission(
      projectId: $projectId
      groupPermissionToAddSet: $groupPermissionToAddSet
      groupPermissionToRemoveSet: $groupPermissionToRemoveSet
    ) {
      defaultDocumentGroupPermissionSet {
        ...DefaultDocumentGroupPermissionFrag
      }
    }
  }
  ${DEFAULT_DOCUMENT_GROUP_PERMISSION_FRAG}
`;

export const CREATE_LAYER = gql`
  mutation CreateLayer($taskId: ID!, $name: String!, $editable: Boolean!) {
    createLayer(taskId: $taskId, name: $name, editable: $editable) {
      layer {
        ...LayerFrag
      }
    }
  }
  ${LAYER_FRAG}
`;

export const UPDATE_LAYER = gql`
  mutation UpdateLayer(
    $layerId: ID!
    $name: String!
    $visibleByDefault: Boolean!
    $creatorVisible: Boolean!
    $restrictionsBetweenUsers: Boolean!
    $multipleFilled: Boolean!
    $marker: String
    $markerColor: String
    $lineColor: String
    $lineStyleId: ID
    $polyColor: String
    $polyStyleId: ID
    $position: Int!
  ) {
    updateLayer(
      layerId: $layerId
      name: $name
      visibleByDefault: $visibleByDefault
      creatorVisible: $creatorVisible
      restrictionsBetweenUsers: $restrictionsBetweenUsers
      multipleFilled: $multipleFilled
      marker: $marker
      markerColor: $markerColor
      lineColor: $lineColor
      lineStyleId: $lineStyleId
      polyColor: $polyColor
      polyStyleId: $polyStyleId
      position: $position
    ) {
      layer {
        ...LayerFrag
      }
    }
  }
  ${LAYER_FRAG}
`;

export const DELETE_LAYER = gql`
  mutation AdminDeleteLayer($layerId: ID!) {
    adminDeleteLayer(layerId: $layerId) {
      result
    }
  }
`;

export const UPDATE_LAYER_QUESTIONNAIRE = gql`
  mutation UpdateLayerQuestionnaire($layerId: ID!, $questionnaireId: ID) {
    updateLayerQuestionnaire(
      layerId: $layerId
      questionnaireId: $questionnaireId
    ) {
      layer {
        ...LayerFrag
      }
    }
  }
  ${LAYER_FRAG}
`;

export const ADD_REMOVE_LAYER_GEOMETRY = gql`
  mutation AddRemoveLayerGeometry(
    $layerId: ID!
    $geometryToRemoveSet: [String]!
    $geometryToAddSet: [LayerGeometryInputType]!
  ) {
    addRemoveLayerGeometry(
      layerId: $layerId
      geometryToRemoveSet: $geometryToRemoveSet
      geometryToAddSet: $geometryToAddSet
    ) {
      layer {
        ...LayerFrag
      }
    }
  }
  ${LAYER_FRAG}
`;

export const ASSIGN_UNASSIGN_GROUP_TO_LAYER = gql`
  mutation AdminAssignUnassignGroupToLayer(
    $layerId: ID!
    $groupsToAssign: [ID]!
    $groupsToUnassign: [ID]!
  ) {
    adminAssignUnassignGroupToLayer(
      layerId: $layerId
      groupsToAssign: $groupsToAssign
      groupsToUnassign: $groupsToUnassign
    ) {
      adminAvailableGroupSet {
        id
        name
      }
      adminAssignedGroupSet {
        id
        name
      }
    }
  }
`;

export const ASSIGN_UNASSIGN_USER_TO_LAYER = gql`
  mutation AdminAssignUnassignUserToLayer(
    $projectId: ID!
    $layerId: ID!
    $usersToAssign: [ID]!
    $usersToUnassign: [ID]!
  ) {
    adminAssignUnassignUserToLayer(
      projectId: $projectId
      layerId: $layerId
      usersToAssign: $usersToAssign
      usersToUnassign: $usersToUnassign
    ) {
      adminAvailableUserSet {
        id
        firstName
        lastName
        email
      }
      adminAssignedUserSet {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const ASSIGN_UNASSIGN_TOOL_PERMISSION_TO_GROUP = gql`
  mutation AdminAssignUnassignToolPermissionToGroup(
    $layerId: ID!
    $groupToAssignUnassignSet: [GroupPermissionToAssignUnAssignType]!
  ) {
    adminAssignUnassignToolPermissionToGroup(
      layerId: $layerId
      groupToAssignUnassignSet: $groupToAssignUnassignSet
    ) {
      adminAssignedToolGroupSet {
        id
        name
        assignedToolPermissionSet(layerId: $layerId) {
          id
          codename
        }
      }
    }
  }
`;

export const ASSIGN_UNASSIGN_TOOL_PERMISSION_TO_USER = gql`
  mutation AdminAssignUnassignToolPermissionToUser(
    $layerId: ID!
    $userToAssignUnassignSet: [UserPermissionToAssignUnAssignType]!
  ) {
    adminAssignUnassignToolPermissionToUser(
      layerId: $layerId
      userToAssignUnassignSet: $userToAssignUnassignSet
    ) {
      adminUserWithAssignedToolPermissionSetSet {
        id
        firstName
        lastName
        email
        assignedToolPermissionSet(layerId: $layerId) {
          id
          codename
        }
      }
    }
  }
`;

export const ASSIGN_UNASSIGN_RESTR_BTW_USERS_PERM_TO_GROUP = gql`
  mutation AdminAssignUnassignRestrBtwUsersPermToGroup(
    $layerId: ID!
    $groupToAssignUnassignSet: [GroupPermissionToAssignUnAssignType]!
  ) {
    adminAssignUnassignRestrBtwUsersPermToGroup(
      layerId: $layerId
      groupToAssignUnassignSet: $groupToAssignUnassignSet
    ) {
      groupWithAssignedRestrBtwUsersPermSetSet {
        id
        name
        assignedRestrBtwUsersPermSet(layerId: $layerId) {
          id
          codename
        }
      }
    }
  }
`;

export const ASSIGN_UNASSIGN_RESTR_BTW_USERS_PERM_TO_USER = gql`
  mutation AdminAssignUnassignRestrBtwUsersPermToUser(
    $layerId: ID!
    $userToAssignUnassignSet: [UserPermissionToAssignUnAssignType]!
  ) {
    adminAssignUnassignRestrBtwUsersPermToUser(
      layerId: $layerId
      userToAssignUnassignSet: $userToAssignUnassignSet
    ) {
      userWithAssignedRestrBtwUsersPermSetSet {
        id
        firstName
        lastName
        email
        assignedRestrBtwUsersPermSet(layerId: $layerId) {
          id
          codename
        }
      }
    }
  }
`;

export const IMPORT_GEOJSON = gql`
  mutation ImportGeojson($layerId: ID!, $properties: [String], $data: Upload!) {
    importGeojson(layerId: $layerId, properties: $properties, data: $data) {
      layer {
        ...LayerFrag
      }
    }
  }
  ${LAYER_FRAG}
`;

export const IMPORT_TASK_GEOJSON = gql`
  mutation ImportTaskGeojson($taskId: ID!, $data: Upload!) {
    importTaskGeojson(taskId: $taskId, data: $data) {
      task {
        ...TaskFrag
      }
    }
  }
  ${TASK_FRAG}
`;

export const ADMIN_CREATE_QUESTIONNAIRE = gql`
  mutation AdminCreateQuestionnaire($name: String!, $layerId: ID) {
    adminCreateQuestionnaire(name: $name, layerId: $layerId) {
      questionnaire {
        ...QuestionnaireFrag
      }
      layer {
        ...LayerFrag
      }
    }
  }
  ${LAYER_FRAG}
  ${QUESTIONNAIRE_FRAG}
`;

export const ADMIN_UPDATE_QUESTIONNAIRE = gql`
  mutation AdminUpdateQuestionnaire($questionnaireId: ID!, $name: String!) {
    adminUpdateQuestionnaire(questionnaireId: $questionnaireId, name: $name) {
      questionnaire {
        ...QuestionnaireFrag
      }
    }
  }
  ${QUESTIONNAIRE_FRAG}
`;

export const ADMIN_SORT_QUESTIONNAIRE = gql`
  mutation AdminSortQuestionnaire(
    $questionnaireId: ID!
    $sortList: [CustomSortQuestionnaireFieldType]
  ) {
    adminSortQuestionnaire(
      questionnaireId: $questionnaireId
      sortList: $sortList
    ) {
      questionnaire {
        ...QuestionnaireFrag
      }
    }
  }
  ${QUESTIONNAIRE_FRAG}
`;

export const ADMIN_DELETE_QUESTIONNAIRE = gql`
  mutation AdminDeleteQuestionnaire($questionnaireId: ID!) {
    adminDeleteQuestionnaire(questionnaireId: $questionnaireId) {
      questionnaireSet {
        ...QuestionnaireFrag
      }
    }
  }
  ${QUESTIONNAIRE_FRAG}
`;

export const ADMIN_CREATE_QUESTIONNAIRE_FIELD = gql`
  mutation AdminCreateQuestionnaireField(
    $questionnaireId: ID!
    $text: String!
    $fieldTypeId: ID!
    $shpField: String
    $placeHolder: String
    $required: Boolean!
    $readOnly: Boolean!
    $helptext: String
    $minimum: Int
    $maximum: Int
    $multiline: Boolean!
    $multiselect: Boolean
    $decimal: Boolean!
    $decimalDigits: Float
    $parentQuestionnaireFieldId: ID
    $parentQuestionnaireFieldAnswer: Boolean
    $parentQuestionnaireFieldLabelId: ID
  ) {
    adminCreateQuestionnaireField(
      questionnaireId: $questionnaireId
      text: $text
      fieldTypeId: $fieldTypeId
      shpField: $shpField
      placeHolder: $placeHolder
      required: $required
      readOnly: $readOnly
      helptext: $helptext
      minimum: $minimum
      maximum: $maximum
      multiline: $multiline
      multiselect: $multiselect
      decimal: $decimal
      decimalDigits: $decimalDigits
      parentQuestionnaireFieldId: $parentQuestionnaireFieldId
      parentQuestionnaireFieldAnswer: $parentQuestionnaireFieldAnswer
      parentQuestionnaireFieldLabelId: $parentQuestionnaireFieldLabelId
    ) {
      questionnaireField {
        ...QuestionnaireFieldFrag
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
`;

export const CREATE_QUESTIONNAIRE_FIELD_WITH_LABEL_SET = gql`
  mutation CreateQuestionnaireFieldWithLabelSet(
    $questionnaireId: ID!
    $text: String!
    $fieldTypeId: ID!
    $shpField: String
    $labelSet: [String]
  ) {
    createQuestionnaireFieldWithLabelSet(
      questionnaireId: $questionnaireId
      text: $text
      fieldTypeId: $fieldTypeId
      shpField: $shpField
      labelSet: $labelSet
    ) {
      questionnaireField {
        ...QuestionnaireFieldFrag
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
`;

export const DELETE_QUESTIONNAIRE_FIELD_WITH_LABEL_SET = gql`
  mutation DeleteQuestionnaireFieldWithLabelSet($questionnaireFieldId: ID!) {
    deleteQuestionnaireFieldWithLabelSet(
      questionnaireFieldId: $questionnaireFieldId
    ) {
      questionnaireFieldId
    }
  }
`;

export const ADMIN_UPDATE_QUESTIONNAIRE_FIELD = gql`
  mutation AdminUpdateQuestionnaireField(
    $questionnaireId: ID!
    $questionnaireFieldId: ID!
    $text: String!
    $fieldTypeId: ID!
    $shpField: String
    $placeHolder: String
    $required: Boolean!
    $readOnly: Boolean!
    $helptext: String
    $minimum: Int
    $maximum: Int
    $multiline: Boolean!
    $multiselect: Boolean
    $decimal: Boolean!
    $decimalDigits: Float
    $parentQuestionnaireFieldId: ID
    $parentQuestionnaireFieldAnswer: Boolean
    $parentQuestionnaireFieldLabelId: ID
  ) {
    adminUpdateQuestionnaireField(
      questionnaireId: $questionnaireId
      questionnaireFieldId: $questionnaireFieldId
      text: $text
      fieldTypeId: $fieldTypeId
      shpField: $shpField
      placeHolder: $placeHolder
      required: $required
      readOnly: $readOnly
      helptext: $helptext
      minimum: $minimum
      maximum: $maximum
      multiline: $multiline
      multiselect: $multiselect
      decimal: $decimal
      decimalDigits: $decimalDigits
      parentQuestionnaireFieldId: $parentQuestionnaireFieldId
      parentQuestionnaireFieldAnswer: $parentQuestionnaireFieldAnswer
      parentQuestionnaireFieldLabelId: $parentQuestionnaireFieldLabelId
    ) {
      questionnaire {
        ...QuestionnaireFrag
      }
    }
  }
  ${QUESTIONNAIRE_FRAG}
`;

export const ADMIN_DELETE_QUESTIONNAIRE_FIELD = gql`
  mutation AdminDeleteQuestionnaireField(
    $questionnaireId: ID!
    $questionnaireFieldId: ID!
  ) {
    adminDeleteQuestionnaireField(
      questionnaireId: $questionnaireId
      questionnaireFieldId: $questionnaireFieldId
    ) {
      questionnaireFieldSet {
        ...QuestionnaireFieldFrag
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
`;

export const ADMIN_CREATE_QUESTIONNAIRE_FIELD_LABEL = gql`
  mutation AdminCreateQuestionnaireFieldLabel(
    $questionnaireFieldId: ID!
    $text: String!
    $marker: String
    $markerColor: String
    $lineColor: String
    $lineStyleId: ID
    $polyColor: String
    $polyStyleId: ID
  ) {
    adminCreateQuestionnaireFieldLabel(
      questionnaireFieldId: $questionnaireFieldId
      text: $text
      marker: $marker
      markerColor: $markerColor
      lineColor: $lineColor
      lineStyleId: $lineStyleId
      polyColor: $polyColor
      polyStyleId: $polyStyleId
    ) {
      questionnaireFieldLabel {
        ...QuestionnaireFieldLabelFrag
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_LABEL_FRAG}
`;

export const ADMIN_UPDATE_QUESTIONNAIRE_FIELD_LABEL = gql`
  mutation AdminUpdateQuestionnaireFieldLabel(
    $questionnaireId: ID!
    $questionnaireFieldLabelId: ID!
    $text: String!
    $marker: String
    $markerColor: String
    $lineColor: String
    $lineStyleId: ID
    $polyColor: String
    $polyStyleId: ID
  ) {
    adminUpdateQuestionnaireFieldLabel(
      questionnaireId: $questionnaireId
      questionnaireFieldLabelId: $questionnaireFieldLabelId
      text: $text
      marker: $marker
      markerColor: $markerColor
      lineColor: $lineColor
      lineStyleId: $lineStyleId
      polyColor: $polyColor
      polyStyleId: $polyStyleId
    ) {
      questionnaireFieldLabel {
        ...QuestionnaireFieldLabelFrag
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_LABEL_FRAG}
`;

export const ADMIN_DELETE_QUESTIONNAIRE_FIELD_LABEL = gql`
  mutation AdminDeleteQuestionnaireFieldLabel(
    $questionnaireFieldId: ID!
    $questionnaireFieldLabelId: ID!
  ) {
    adminDeleteQuestionnaireFieldLabel(
      questionnaireFieldId: $questionnaireFieldId
      questionnaireFieldLabelId: $questionnaireFieldLabelId
    ) {
      questionnaireFieldLabelSet {
        ...QuestionnaireFieldLabelFrag
      }
    }
  }
  ${QUESTIONNAIRE_FIELD_LABEL_FRAG}
`;

export const ADMIN_ADD_REMOVE_GROUP_SITE = gql`
  mutation AdminAddRemoveGroupSite(
    $siteId: ID!
    $groupToAssignSet: [ID]!
    $groupToUnassignSet: [ID]!
  ) {
    adminAddRemoveGroupSite(
      siteId: $siteId
      groupToAssignSet: $groupToAssignSet
      groupToUnassignSet: $groupToUnassignSet
    ) {
      assignedGroupSet {
        id
        name
      }
      availableGroupSet {
        id
        name
      }
    }
  }
`;

export const ADMIN_ASSIGN_UNASSIGN_MODULE_SITE = gql`
  mutation AdminAssignUnassignModuleToSite(
    $siteId: ID!
    $moduleToAssignSet: [ID]!
    $moduleToUnassignSet: [ID]!
  ) {
    adminAssignUnassignModuleToSite(
      siteId: $siteId
      moduleToAssignSet: $moduleToAssignSet
      moduleToUnassignSet: $moduleToUnassignSet
    ) {
      assignedModuleSet {
        id
        name
      }
      availableModuleSet {
        id
        name
      }
    }
  }
`;

export const IMPORT_USER_SET = gql`
  mutation ImportUserSet(
    $projectId: ID!
    $file: Upload!
    $sendEmail: Boolean!
  ) {
    importUserSet(projectId: $projectId, file: $file, sendEmail: $sendEmail) {
      projectUserSet {
        id
        email
        firstName
        lastName
        profile {
          avatar
          avatarPath
          name
          nature {
            id
            code
          }
        }
      }
    }
  }
`;

export const ADMIN_PWD_RESET_AND_SEND = gql`
  mutation AdminPwdResetAndSend($userId: ID!) {
    adminPwdResetAndSend(userId: $userId) {
      success
    }
  }
`;

export const ADMIN_CREATE_OPEN_SIGN_IN = gql`
  mutation AdminCreateOpenSignIn(
    $projectId: ID!
    $slug: String!
    $groupId: ID!
  ) {
    adminCreateOpenSignIn(
      projectId: $projectId
      slug: $slug
      groupId: $groupId
    ) {
      openSignIn {
        ...OpenSignInFrag
      }
    }
  }
  ${OPEN_SIGN_IN_FRAG}
`;

export const ADMIN_UPDATE_OPEN_SIGN_IN = gql`
  mutation AdminUpdateOpenSignIn(
    $openSignInId: ID!
    $slug: String!
    $groupId: ID!
    $notice: Upload
    $welcome: String
  ) {
    adminUpdateOpenSignIn(
      openSignInId: $openSignInId
      slug: $slug
      groupId: $groupId
      notice: $notice
      welcome: $welcome
    ) {
      openSignIn {
        ...OpenSignInFrag
      }
    }
  }
  ${OPEN_SIGN_IN_FRAG}
`;

export const ADMIN_DELETE_OPEN_SIGN_IN = gql`
  mutation AdminDeleteOpenSignIn($openSignInId: ID!) {
    adminDeleteOpenSignIn(openSignInId: $openSignInId) {
      openSignInId
    }
  }
`;

export const CREATE_STYLE = gql`
  mutation CreateStyle(
    $pathOptions: String!
    $geoTypeCode: String!
    $usePattern: Boolean!
    $patternTypeCode: String
    $patternOptions: String
    $shapeOptions: String
  ) {
    createStyle(
      pathOptions: $pathOptions
      geoTypeCode: $geoTypeCode
      usePattern: $usePattern
      patternTypeCode: $patternTypeCode
      patternOptions: $patternOptions
      shapeOptions: $shapeOptions
    ) {
      style {
        ...StyleFrag
      }
    }
  }
  ${STYLE_FRAG}
`;

export const UPDATE_STYLE = gql`
  mutation UpdateStyle(
    $styleId: ID!
    $pathOptions: String!
    $usePattern: Boolean!
    $patternTypeCode: String
    $patternOptions: String
    $shapeOptions: String
  ) {
    updateStyle(
      styleId: $styleId
      pathOptions: $pathOptions
      usePattern: $usePattern
      patternTypeCode: $patternTypeCode
      patternOptions: $patternOptions
      shapeOptions: $shapeOptions
    ) {
      style {
        ...StyleFrag
      }
    }
  }
  ${STYLE_FRAG}
`;

export const DELETE_STYLE = gql`
  mutation DeleteStyle($styleId: ID!) {
    deleteStyle(styleId: $styleId) {
      styleId
    }
  }
`;

export const ADMIN_REMOVE_USERS_FROM_PROJECT = gql`
  mutation AdminRemoveUsersFromProject(
    $projectId: ID!
    $userToRemoveSet: [ID]!
  ) {
    adminRemoveUsersFromProject(
      projectId: $projectId
      userToRemoveSet: $userToRemoveSet
    ) {
      projectUserSet {
        id
        email
        firstName
        lastName
        profile {
          avatar
          avatarPath
          name
          nature {
            id
            code
          }
        }
        groups {
          name
        }
      }
    }
  }
`;

export const CHANGE_PROJECT_SITE = gql`
  mutation ChangeProjectSite($projectId: ID!, $siteId: ID!) {
    changeProjectSite(projectId: $projectId, siteId: $siteId) {
      project {
        ...ProjectFrag
      }
    }
  }
  ${PROJECT_FRAG}
`;
