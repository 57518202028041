/******************************* */
/*           COMMON              */
/******************************* */
export const SITE_NAMES = {
  ICI: "ICI",
  CITTANOVA: "Cittànova",
  SINOPIA: "Sinopia",
};

// Messages
export const MESSAGE_TYPE = {
  POSITIVE: "MESSAGE_TYPE_POSITIVE",
  WARNING: "MESSAGE_TYPE_WARNING",
  NEGATIVE: "MESSAGE_TYPE_NEGATIVE",
};

// Components
export const COMPONENTS = {
  COMMON: {
    INDEX: "COMPONENT_COMMON_INDEX",
    PROFILE: "COMPONENT_COMMON_PROFILE",
  },
  COLLABORATIVE_MAP: {
    CONTROLLER: "COMPONENT_COLLABORATIVE_MAP_CONTROLLER",
  },
  MANAGER: {
    MANAGER: "COMPONENT_MANAGER_MANAGER",
    SITE: "COMPONENT_MANAGER_SITE",
    SITES: "COMPONENT_MANAGER_SITES",
    PWD: "COMPONENT_MANAGER_PWD",
    CREATE_USER: "COMPONENT_MANAGER_CREATE_USER",
    DELETE_QUESTIONNAIRE: "COMPONENT_MANAGER_DELETE_QUESTIONNAIRE",
    DELETE_QUESTIONNAIRE_FIELD: "COMPONENT_MANAGER_DELETE_QUESTIONNAIRE_FIELD",
    DELETE_QUESTIONNAIRE_FIELD_LABEL:
      "COMPONENT_MANAGER_DELETE_QUESTIONNAIRE_FIELD_LABEL",
  },
  FILEMANAGER: {
    MANAGE_PERMISSIONS: "COMPONENT_FILEMANAGER_MANAGE_PERMISSIONS",
  },
  FORUM: {
    ADD_POST: "COMPONENT_FORUM_ADD_POST",
  },
  SITE: {
    FIGEAC: {
      LOGIN: "COMPONENT_SITE_FIGEAC_LOGIN",
    },
  },
};

/******************************* */
/*      COLLABORATIVE MAP        */
/******************************* */
export const COLLABORATIVE_MAP_GEOMETRY_TYPES = {
  POINT: "COLLABORATIVE_MAP_GEOMETRY_TYPE_POINT",
  LINE: "COLLABORATIVE_MAP_GEOMETRY_TYPE_LINE",
  POLYGON: "COLLABORATIVE_MAP_GEOMETRY_TYPE_POLYGON",
};

export const COLLABORATIVE_MAP_TOOLS = {
  NONE: "COLLABORATIVE_MAP_TOOL_NONE",
  ADD_POINT: "COLLABORATIVE_MAP_TOOL_ADD_POINT",
  ADD_LINE: "COLLABORATIVE_MAP_TOOL_ADD_LINE",
  ADD_POLYGON: "COLLABORATIVE_MAP_TOOL_ADD_POLYGON",
  EDIT_GEOMETRY: "COLLABORATIVE_MAP_TOOL_EDIT_GEOMETRY",
  EDIT_QUESTIONNAIRE: "COLLABORATIVE_MAP_TOOL_EDIT_QUESTIONNAIRE",
  DELETE_FEATURE: "COLLABORATIVE_MAP_TOOL_DELETE_FEATURE",
  EXPORT_SHP: "COLLABORATIVE_MAP_TOOL_EXPORT_SHP",
};

export const COLLABORATIVE_MAP_TOOL_TO_GEOMETRY_TYPE_CODE = {
  [COLLABORATIVE_MAP_TOOLS.ADD_POINT]: COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT,
  [COLLABORATIVE_MAP_TOOLS.ADD_LINE]: COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE,
  [COLLABORATIVE_MAP_TOOLS.ADD_POLYGON]:
    COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON,
};

// Collaborative Map Permissions
export const COLLABORATIVE_MAP_PERMISSIONS = {
  ADD_POINT_TO_LAYER: "add_point_to_layer_",
  ADD_LINE_TO_LAYER: "add_line_to_layer_",
  ADD_POLYGON_TO_LAYER: "add_polygon_to_layer_",
  EDIT_GEOMETRY_ON_LAYER: "edit_geometry_on_layer_",
  EDIT_QUESTIONNAIRE_ON_LAYER: "edit_questionnaire_on_layer_",
  DELETE_GEOMETRY_ON_LAYER: "delete_geometry_on_layer_",
  EXPORT_SHP_FROM_LAYER: "export_shp_from_layer_",
  VIEW_OTHERS_FEATURES: "view_others_features_on_layer_",
  VIEW_OTHERS_INFOS: "view_others_infos_on_layer_",
  EDIT_OTHERS_INFOS: "edit_others_infos_on_layer_",
  EDIT_OTHERS_GEOMETRIES: "edit_others_geometries_on_layer_",
  DELETE_OTHERS_GEOMETRIES: "delete_others_geometries_on_layer_",
};

export const PATTERN_TYPES = {
  STRIPES: "PATTERN_TYPE_STRIPES",
  RECTANGLE: "PATTERN_TYPE_RECTANGLE",
  CIRCLE: "PATTERN_TYPE_CIRCLE",
  PATH: "PATTERN_TYPE_PATH",
};

export const TASK_TYPES = {
  INVENTORY: "TASK_TYPE_INVENTORY",
  IDEAS: "TASK_TYPE_IDEAS",
};

/******************************* */
/*        QUESTIONNAIRE          */
/******************************* */
export const QUESTIONNAIRE_FIELD_TYPES = {
  TEXT: "QUESTIONNAIRE_FIELD_TYPE_TEXT",
  RADIO: "QUESTIONNAIRE_FIELD_TYPE_RADIO",
  SELECT: "QUESTIONNAIRE_FIELD_TYPE_SELECT",
  CHECKBOX: "QUESTIONNAIRE_FIELD_TYPE_CHECKBOX",
  DECIMAL: "QUESTIONNAIRE_FIELD_TYPE_DECIMAL",
  DATE: "QUESTIONNAIRE_FIELD_TYPE_DATE",
  IMAGE: "QUESTIONNAIRE_FIELD_TYPE_IMAGE",
  DOCUMENT: "QUESTIONNAIRE_FIELD_TYPE_DOCUMENT",
};

export const QUESTIONNAIRE_FILTER = {
  ORPHELIN: "QUESTIONNAIRE_FILTER_ORPHELIN",
  NON_ORPHELIN: "QUESTIONNAIRE_FILTER_NON_ORPHELIN",
  ALL: "QUESTIONNAIRE_FILTER_ALL",
};

/******************************* */
/*        FILEMANAGER            */
/******************************* */
export const FILEMANAGER_PERMISSIONS = {
  view_folder_: "canViewFolder",
  change_folder_: "canChangeFolder",
  move_folder_: "canMoveFolder",
  delete_folder_: "canDeleteFolder",
  add_folder_to_folder_: "canAddFolder",
  add_document_to_folder_: "canAddDocument",
  view_document_: "canViewDocument",
  move_document_: "canMoveDocument",
  delete_document_: "canDeleteDocument",
};

export const FILEMANAGER_PERMISSIONS_REVERSE = {
  canViewFolder: "view_folder_",
  canChangeFolder: "change_folder_",
  canMoveFolder: "move_folder_",
  canDeleteFolder: "delete_folder_",
  canAddFolder: "add_folder_to_folder_",
  canAddDocument: "add_document_to_folder_",
  canViewDocument: "view_document_",
  canMoveDocument: "move_document_",
  canDeleteDocument: "delete_document_",
};

/******************************* */
/*            USERS              */
/******************************* */
export const USER_TYPES = {
  NATURAL_PERSON: "USER_TYPE_NATURAL_PERSON",
  LEGAL_PERSON: "USER_TYPE_LEGAL_PERSON",
};

export const MODES = {
  READ: "READ",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

/******************************* */
/*            STYLES             */
/******************************* */
export const GEOMETRY_CODE_OPTIONS = [
  {
    key: COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE,
    value: COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE,
    text: "Ligne",
  },
  {
    key: COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON,
    value: COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON,
    text: "Polygone",
  },
];
