import React, { useState, useRef, useEffect } from "react";
import { Table, Icon } from "semantic-ui-react";
import { DeleteQuestionnaireField } from "./DeleteQuestionnaireField";

const sourceInit = {
  dragging: false,
  level: 0,
  index: 0,
  parentId: null,
};

export function QuestionnaireDnD({
  qFieldRowSet,
  handleRowClic,
  questionnaireId,
  sortQuestionnaire,
}) {
  const [rowSet, setRowSet] = useState([...qFieldRowSet]);

  useEffect(() => {
    setRowSet([...qFieldRowSet]);
  }, [qFieldRowSet]);

  const dragNode = useRef();

  const [source, setSource] = useState(sourceInit);

  const handleDragStart = (e, row, index) => {
    setSource({
      level: row.level,
      totalChildren: row.totalChildren,
      index: index,
      dragging: true,
      parentId: row.field.parentQuestionnaireField
        ? row.field.parentQuestionnaireField.id
        : null,
    });

    dragNode.current = e.target;
    e.target.addEventListener("dragend", handleDragEnd);
    e.dataTransfer.setData("source-level", row.level);

    if (row.field.parentQuestionnaireField) {
      e.dataTransfer.setData(
        "parent-id",
        row.field.parentQuestionnaireField.id
      );
    }
  };

  const handleDragEnter = (e, row, index) => {
    e.preventDefault();
    if (!source.dragging) return;
    if (source.index === index) return;
    if (source.level !== row.level) return;

    if (source.index < index) {
      rowSet.splice(
        index + row.totalChildren - source.totalChildren,
        0,
        ...rowSet.splice(source.index, 1 + source.totalChildren)
      );
      setRowSet(rowSet);
      setSource({
        ...source,
        index: index + row.totalChildren - source.totalChildren,
      });
    } else {
      rowSet.splice(
        index,
        0,
        ...rowSet.splice(source.index, 1 + source.totalChildren)
      );
      setRowSet(rowSet);
      setSource({
        ...source,
        index: index,
      });
    }
  };

  const handleDragEnd = (e) => {
    var sourceLevel = parseInt(e.dataTransfer.getData("source-level"), 10);
    var parentId = e.dataTransfer.getData("parent-id");
    let changedRowSet = [];

    if (sourceLevel === 0) {
      changedRowSet = rowSet.filter((row) => row.level === sourceLevel);
    } else {
      changedRowSet = rowSet.filter(
        (row) =>
          row.level === sourceLevel &&
          row.field.parentQuestionnaireField.id === parentId
      );
    }

    const sortList = changedRowSet.map((row, index) => ({
      questionnaireFieldId: row.field.id,
      newOrder: index + 1,
    }));

    sortQuestionnaire({
      variables: {
        questionnaireId: questionnaireId,
        sortList: sortList,
      },
    });

    dragNode.current.removeEventListener("dragend", handleDragEnd);
    dragNode.current = null;

    setSource(sourceInit);
  };

  return (
    <Table.Body className=" white DragnDrop">
      {rowSet.map((row, index) => {
        const { field, level } = row;
        let indent = "";
        if (level === 1) {
          indent = "↳\u00a0\u00a0";
        } else if (level > 1) {
          indent =
            "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0".repeat(level - 1) +
            "↳\u00a0\u00a0";
        }

        let rowClassName = "dnd-item";

        if (source.dragging && source.index === index) {
          rowClassName += " active";
        }

        return (
          <Table.Row
            disabled={
              source.dragging &&
              (level !== source.level ||
                (level !== 0 &&
                  level === source.level &&
                  field.parentQuestionnaireField.id !== source.parentId))
            }
            className={rowClassName}
            draggable
            key={`champ-row-${field.id}`}
            onDragStart={(e) => handleDragStart(e, row, index)}
            onDragEnter={(e) => handleDragEnter(e, row, index)}
            style={
              source.dragging
                ? { cursor: "grabbing !important" }
                : { backgroundColor: "white" }
            }
          >
            <Table.Cell textAlign="center" style={{ cursor: "grab" }}>
              <Icon name={"move"} className="Draggable" />
            </Table.Cell>

            <Table.Cell
              className="clickable"
              onClick={() => handleRowClic(field)}
            >
              {indent + field.text}
            </Table.Cell>
            <Table.Cell
              className="clickable"
              onClick={() => handleRowClic(field)}
            >
              {field.fieldType.text}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <DeleteQuestionnaireField
                questionnaireId={questionnaireId}
                questionnaireFieldId={field.id}
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );
}
