import React, { useState } from "react";
// React Router DOM
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Header,
  Segment,
  Message,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { CREATE_TOKEN_FOR_PWD_RESET_REQUEST } from "../_GraphQL/mutations";
import { useQuery, useMutation } from "@apollo/client";
import { ErrorMessage } from "../../../Common/components/ErrorMessage";
import { IS_CONNECTED } from "../../../_GraphQL/queries";

export function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const [result, setResult] = useState({
    formSubmitted: false,
    success: false,
    error: false,
  });
  const [createToken, { loading, error }] = useMutation(
    CREATE_TOKEN_FOR_PWD_RESET_REQUEST,
    {
      onCompleted() {
        setResult({
          formSubmitted: true,
          success: true,
          error: false,
        });
      },
      onError() {
        setResult({
          formSubmitted: true,
          success: false,
          error: true,
        });
      },
    }
  );

  const {
    loading: isConnectedLoading,
    error: errorIsConnected,
    data,
  } = useQuery(IS_CONNECTED);

  if (isConnectedLoading) return null;

  if (errorIsConnected) return null;

  if (!data) return null;

  if (data.isConnected) {
    history.push("/");
  }
  return (
    <Container>
      <Segment>
        <Header as="h1">Mot de passe oublié</Header>
        <Form
          error={result.formSubmitted && result.error}
          success={result.formSubmitted && result.success}
          onSubmit={(e) => {
            e.preventDefault();
            createToken({ variables: { email: email } });
          }}
        >
          {loading ? (
            <Dimmer active>
              <Loader />
            </Dimmer>
          ) : null}
          {result.formSubmitted && result.error && error ? (
            <ErrorMessage error={error} />
          ) : null}
          {result.formSubmitted && result.success ? (
            <Message success>
              Un mot de passe vous a été envoyé. Pour réinitialiser votre mot de
              passe veuillez consulter votre boite mail.
            </Message>
          ) : null}
          {result.formSubmitted && result.success ? null : (
            <>
              <Form.Field>
                <label>
                  Veuillez renseigner votre adresse email afin
                  d&apos;initialiser la procédure de réinitialisation de mot de
                  passe.
                </label>
                <input
                  placeholder="Email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Field>
              <Button type="submit">Envoyer</Button>
            </>
          )}
        </Form>
        <Link to="/">Retour</Link>
      </Segment>
    </Container>
  );
}
