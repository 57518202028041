// GraphQL
import { gql } from "@apollo/client";
import { PROJECT_FRAG, ROOT_FOLDER_FRAG, USER_FRAG } from "./fragments";

export const GET_PROFILE = gql`
  query GetCurrentUser {
    currUser {
      ...UserFrag
    }
  }
  ${USER_FRAG}
`;

export const GET_PROJECT = gql`
  query project($projectId: ID!) {
    project(projectId: $projectId) {
      ...ProjectFrag
    }
  }
  ${PROJECT_FRAG}
`;

export const GET_PROJECT_USER_SET = gql`
  query GetProjectUserSet($projectId: ID!) {
    projectUserSet(projectId: $projectId) {
      id
      email
      firstName
      lastName
      profile {
        avatar
        avatarPath
        name
        nature {
          id
          code
        }
      }
      groups {
        name
      }
    }
  }
`;

export const PROJECTS_ASSIGNED_TO_CURRENT_USER_FOR_CURRENT_SITE = gql`
  query currUserCurrSiteAssignedProjectSet {
    currUserCurrSiteAssignedProjectSet {
      id
      name
      rootFolder {
        ...RootFolderFrag
      }
      illustrationPath
      description
    }
  }
  ${ROOT_FOLDER_FRAG}
`;

export const PUBLIC_CURR_WEBSITE = gql`
  query GetPublicCurrentWebsite {
    publicCurrWebsite {
      id
      homeFolder
      mainModule {
        id
        name
        menu
        private
      }
      site {
        id
        domain
        name
        moduleSet {
          id
          name
          menu
          private
        }
        projectSet {
          id
          name
        }
      }
    }
  }
`;
