import React, { useState, useEffect, useRef } from "react";
import { Segment } from "semantic-ui-react";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "leaflet.pattern";
import { nantesIslandLatLngs, nantesLine } from "./nantes";
import {
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  PATTERN_TYPES,
} from "../../../../utils/Constants";

function createMap(id) {
  const mapView = L.map(id).setView([47.205212, -1.563407], 13);
  L.tileLayer(
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
    {
      maxZoom: 18,
      id: "mapbox/streets-v11",
      tileSize: 512,
      zoomOffset: -1,
      accessToken:
        "pk.eyJ1IjoieXZhaW5tIiwiYSI6ImNrMnZyZzdyODA3aWIzaHFsNDJ3eDlpMzMifQ.bSF9_23BfhATwmQFfGryTw",
    }
  ).addTo(mapView);
  return mapView;
}

function BaseViewer({ pathOptions, type }) {
  const [leafMap, setLeafMap] = useState();
  const [geo, setGeo] = useState();

  useEffect(() => {
    if (!leafMap) {
      setLeafMap(createMap("BaseView"));
    }
  }, [leafMap]);

  useEffect(() => {
    if (leafMap) {
      if (!geo) {
        let geometry;
        if (type === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON) {
          geometry = L.polygon(nantesIslandLatLngs);
        } else {
          geometry = L.polyline(nantesLine);
        }
        geometry.addTo(leafMap);
        setGeo(geometry);
      }
    }
  }, [leafMap, geo, setGeo, type]);

  useEffect(() => {
    if (leafMap && geo) {
      pathOptions.dashArray = pathOptions.dashArray ?? null;
      geo.setStyle(pathOptions);
    }
  }, [leafMap, geo, pathOptions]);

  return (
    <Segment>
      <div id="BaseView" className="mapView"></div>
    </Segment>
  );
}

function StripesViewer({ pathOptions, patternOptions }) {
  const leafMapRef = useRef(null);
  const stripesRef = useRef(null);

  useEffect(() => {
    if (leafMapRef.current) {
      const oldLeafMap = leafMapRef.current;
      oldLeafMap.remove();
    }

    const leafMap = createMap("StripesView");
    leafMapRef.current = leafMap;

    const newStripes = new L.StripePattern(patternOptions);
    stripesRef.current = newStripes;
    newStripes.addTo(leafMap);
    const poly = L.polygon(nantesIslandLatLngs, {
      ...pathOptions,
      ...{ fillPattern: newStripes },
    });
    poly.addTo(leafMap);
  }, [leafMapRef, patternOptions, pathOptions]);

  return (
    <Segment>
      <div id="StripesView" className="mapView"></div>
    </Segment>
  );
}

function ShapeViewer({
  pathOptions,
  patternOptions,
  patternType,
  shapeOptions,
}) {
  const leafMapRef = useRef(null);

  useEffect(() => {
    if (leafMapRef.current) {
      const oldLeafMap = leafMapRef.current;
      oldLeafMap.remove();
    }

    const leafMap = createMap("ShapeView");
    leafMapRef.current = leafMap;

    let shape;

    if (patternType === PATTERN_TYPES.RECTANGLE) {
      shape = new L.PatternRect(shapeOptions);
    } else if (patternType === PATTERN_TYPES.CIRCLE) {
      shape = new L.PatternCircle(shapeOptions);
    } else {
      shape = new L.PatternPath(shapeOptions);
    }

    const pattern = new L.Pattern(patternOptions);
    pattern.addShape(shape);

    pattern.addTo(leafMap);
    const poly = L.polygon(nantesIslandLatLngs, {
      ...pathOptions,
      ...{ fillPattern: pattern },
    });
    poly.addTo(leafMap);
  }, [leafMapRef, patternOptions, pathOptions, patternType, shapeOptions]);

  return (
    <Segment>
      <div id="ShapeView" className="mapView"></div>
    </Segment>
  );
}

function PolyViewer({
  pathOptions,
  usePattern,
  patternType,
  patternOptions,
  shapeOptions,
}) {
  if (usePattern) {
    if (patternType === PATTERN_TYPES.STRIPES) {
      return (
        <StripesViewer
          pathOptions={pathOptions}
          patternOptions={patternOptions}
        />
      );
    } else {
      return (
        <ShapeViewer
          pathOptions={pathOptions}
          patternOptions={patternOptions}
          patternType={patternType}
          shapeOptions={shapeOptions}
        />
      );
    }
  }

  return (
    <BaseViewer
      pathOptions={pathOptions}
      type={COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON}
    />
  );
}

export function Viewer({
  geoTypeCode,
  pathOptions,
  usePattern,
  patternType,
  patternOptions,
  shapeOptions,
}) {
  if (geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON) {
    return (
      <PolyViewer
        pathOptions={pathOptions}
        usePattern={usePattern}
        patternType={patternType}
        patternOptions={patternOptions}
        shapeOptions={shapeOptions}
      />
    );
  }
  return (
    <BaseViewer
      pathOptions={pathOptions}
      type={COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE}
    />
  );
}
