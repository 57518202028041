// React
import React from "react";

// Semantic ui
import { Button, Form, Grid } from "semantic-ui-react";

// Components
import RootFolderGroupPermissionsForm from "./RootFolderGroupPermissionsForm";

class RootFolderForm extends React.Component {
  render() {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          // create toAdd and toRemove
          this.props.handleSubmit();
        }}
      >
        {this.props.project.rootFolder || this.props.modificationsEnabled ? (
          <>
            <Form.Field>
              <label>Nom du dossier racine</label>
              <input
                type="text"
                required
                value={this.props.rootFolderName}
                onChange={this.props.handlerootFolderNameChange}
                disabled={!this.props.modificationsEnabled}
              />
            </Form.Field>
            <RootFolderGroupPermissionsForm
              project={this.props.project}
              groupPermissionsRows={this.props.groupPermissionsRows}
              handleCheckChange={this.props.handleCheckChange}
              disabled={!this.props.modificationsEnabled}
            />
          </>
        ) : (
          <p>Ce site ne possède pas de dossier racine.</p>
        )}
        {this.props.modificationsEnabled ? (
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button positive type="submit">
                  Enregistrer
                </Button>
                <Button
                  negative
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.cancel();
                  }}
                >
                  Annuler
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
      </Form>
    );
  }
}

export default RootFolderForm;
