// React
import React from "react";

// Semantic ui
import { Grid, Header, Segment, List, Popup } from "semantic-ui-react";

// GraphQL
import { gql } from "@apollo/client";
import { ADMIN_ADD_REMOVE_GROUP_SITE } from "../../_GraphQL/mutations";
import { ADMIN_GROUP_SITE } from "../../_GraphQL/queries";

// Components
import EQuery from "../../../../utils/EQuery";
import { EditSegment } from "../../../../Common/components/EditSegment";

// Types
import { Scalars, GroupType } from "../../../../_GraphQL/typesDefinition";
import { AdminAddRemoveGroupSite } from "../../../../_GraphQL/mutationsDefinition";
import { ListItemType } from "../../../../Common/types/commonTypes";
import { listItemSorter } from "../../../../utils/sorter";
import { ListItemState } from "../../../../utils/enums";

// Message
import { addSuccessToastMessage } from "../../../../_GraphQL/message";

type FieldsProps = {
  enabled: boolean;
  groupIn: ListItemType[];
  groupOut: ListItemType[];
  initFields: () => void;
  addGroup: (group: ListItemType) => void;
  removeGroup: (group: ListItemType) => void;
};

class Fields extends React.Component<FieldsProps, {}> {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <Grid columns={2}>
        {this.props.enabled ? (
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Groupes non autorisés
              </Header>
              <Segment attached>
                <List selection>
                  {this.props.groupOut.sort(listItemSorter).map((g) => (
                    <Popup
                      key={`popup-${g.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.props.addGroup(g)}
                        >
                          {g.name}
                        </List.Item>
                      }
                      content="Ajouter"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" attached="top">
                Groupes autorisés
              </Header>
              <Segment attached>
                <List selection>
                  {this.props.groupIn.sort(listItemSorter).map((g) => (
                    <Popup
                      key={`popup-${g.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.props.removeGroup(g)}
                        >
                          {g.name}
                        </List.Item>
                      }
                      content="Supprimer"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Groupes autorisés
              </Header>
              <Segment attached>
                <List>
                  {this.props.groupIn.sort(listItemSorter).map((g) => (
                    <List.Item key={`grp-${g.id}`}>{g.name}</List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

type SiteGroupsProps = {
  toEditMode: () => void;
  toReadMode: () => void;
  data: Data;
  siteId: Scalars["ID"];
  editMode: boolean;
};

type SiteGroupsState = {
  groupIn: ListItemType[];
  groupOut: ListItemType[];
};

class SiteGroups extends React.Component<SiteGroupsProps, SiteGroupsState> {
  constructor(props: SiteGroupsProps) {
    super(props);
    this.state = {
      groupIn: [],
      groupOut: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    this.setState({
      groupIn: this.props.data.adminAssignedGroupForSiteSet.map((g) => ({
        id: g.id,
        name: g.name,
        itemState: ListItemState.NoChange,
      })),
      groupOut: this.props.data.adminAvailableGroupForSiteSet.map((g) => ({
        id: g.id,
        name: g.name,
        itemState: ListItemState.NoChange,
      })),
    });
  };

  addGroup = (group: ListItemType) => {
    if (group.itemState === ListItemState.NoChange) {
      group.itemState = ListItemState.Add;
    }
    if (group.itemState === ListItemState.Remove) {
      group.itemState = ListItemState.NoChange;
    }
    this.setState({
      groupIn: this.state.groupIn.concat([group]),
      groupOut: this.state.groupOut.filter((g) => g.id !== group.id),
    });
  };

  removeGroup = (group: ListItemType) => {
    if (group.itemState === ListItemState.NoChange) {
      group.itemState = ListItemState.Remove;
    }
    if (group.itemState === ListItemState.Add) {
      group.itemState = ListItemState.NoChange;
    }
    this.setState({
      groupOut: this.state.groupOut.concat([group]),
      groupIn: this.state.groupIn.filter((g) => g.id !== group.id),
    });
  };

  handleSubmit = (mutate: any) => {
    mutate({
      variables: {
        siteId: this.props.siteId,
        groupToAssignSet: this.state.groupIn
          .filter((g) => g.itemState === ListItemState.Add)
          .map((g) => g.id),
        groupToUnassignSet: this.state.groupOut
          .filter((g) => g.itemState === ListItemState.Remove)
          .map((g) => g.id),
      },
    });
  };

  update = (
    cache: any,
    {
      data: { adminAddRemoveGroupSite },
    }: { data: { adminAddRemoveGroupSite: AdminAddRemoveGroupSite } }
  ) => {
    cache.writeQuery({
      query: ADMIN_GROUP_SITE,
      variables: { siteId: this.props.siteId },
      data: {
        adminAvailableGroupForSiteSet:
          adminAddRemoveGroupSite.availableGroupSet,
        adminAssignedGroupForSiteSet: adminAddRemoveGroupSite.assignedGroupSet,
      },
    });
    cache.writeQuery({
      query: gql`
        query AdminGroupSet($siteId: ID!) {
          adminGroupSet(siteId: $siteId) {
            id
            name
          }
        }
      `,
      variables: { siteId: this.props.siteId },
      data: {
        adminGroupSet: adminAddRemoveGroupSite.assignedGroupSet,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Le modifications ont été enregistrées.",
    });
    this.toReadMode();
  };

  handleCancel = () => {
    this.props.toReadMode();
    this.initFields();
  };

  render() {
    return (
      <EditSegment
        title="Autorisation des Groupes"
        buttonText="Modifier"
        mutation={ADMIN_ADD_REMOVE_GROUP_SITE}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        toEditMode={this.props.toEditMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            groupIn={this.state.groupIn}
            groupOut={this.state.groupOut}
            enabled={enabled}
            initFields={this.initFields}
            addGroup={this.addGroup}
            removeGroup={this.removeGroup}
          />
        )}
      </EditSegment>
    );
  }
}

type SiteGroupsQueryProps = {
  siteId: Scalars["ID"];
  editMode: boolean;
  toEditMode: () => void;
  toReadMode: () => void;
};

type Data = {
  adminAvailableGroupForSiteSet: [GroupType];
  adminAssignedGroupForSiteSet: [GroupType];
};

const SiteGroupsQuery = (props: SiteGroupsQueryProps) => {
  return (
    <EQuery query={ADMIN_GROUP_SITE} variables={{ siteId: props.siteId }}>
      {(data: Data) => (
        <SiteGroups
          data={data}
          siteId={props.siteId}
          editMode={props.editMode}
          toEditMode={props.toEditMode}
          toReadMode={props.toReadMode}
        />
      )}
    </EQuery>
  );
};

export default SiteGroupsQuery;
