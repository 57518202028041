// React
import React from "react";

// Semantic ui
import { Grid, Header, Segment, List, Popup } from "semantic-ui-react";

// GraphQL
import { ASSIGN_UNASSIGN_USER_TO_LAYER } from "../../../_GraphQL/mutations";
import { GET_ASSIGNED_AND_AVAILABLE_USERS_FOR_LAYER } from "../../../_GraphQL/queries";

// Components
import EQuery from "../../../../../utils/EQuery";
import { EditSegment } from "../../../../../Common/components/EditSegment";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

const userStates = {
  noChange: 0,
  add: 1,
  remove: 2,
};

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <Grid columns={2}>
        {this.props.enabled ? (
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Utilisateurs non autorisés explicitement
              </Header>
              <Segment attached>
                <List selection>
                  {this.props.userOut.map((g) => (
                    <Popup
                      key={`popup-${g.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.props.addUser(g)}
                        >{`${g.firstName} ${g.lastName} (${g.email})`}</List.Item>
                      }
                      content="Ajouter"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" attached="top">
                Utilisateurs autorisés explicitement
              </Header>
              <Segment attached>
                <List selection>
                  {this.props.userIn.map((g) => (
                    <Popup
                      key={`popup-${g.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.props.removeUser(g)}
                        >{`${g.firstName} ${g.lastName} (${g.email})`}</List.Item>
                      }
                      content="Supprimer"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Utilisateurs autorisés explicitement
              </Header>
              <Segment attached>
                <List>
                  {this.props.userIn.map((g) => (
                    <List.Item
                      key={`grp-${g.id}`}
                    >{`${g.firstName} ${g.lastName} (${g.email})`}</List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

class LayerUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userIn: [],
      userOut: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    this.setState({
      userIn: this.props.data.adminAssignedUserForLayerSet.map((g) => ({
        id: g.id,
        firstName: g.firstName,
        lastName: g.lastName,
        email: g.email,
        userState: userStates.noChange,
      })),
      userOut: this.props.data.adminAvailableUserForLayerSet.map((g) => ({
        id: g.id,
        firstName: g.firstName,
        lastName: g.lastName,
        email: g.email,
        userState: userStates.noChange,
      })),
    });
  };

  addUser = (user) => {
    if (user.userState === userStates.noChange) {
      user.userState = userStates.add;
    }
    if (user.userState === userStates.remove) {
      user.userState = userStates.noChange;
    }
    this.setState({
      userIn: this.state.userIn.concat([user]),
      userOut: this.state.userOut.filter((g) => g.id !== user.id),
    });
  };

  removeUser = (user) => {
    if (user.userState === userStates.noChange) {
      user.userState = userStates.remove;
    }
    if (user.userState === userStates.add) {
      user.userState = userStates.noChange;
    }
    this.setState({
      userOut: this.state.userOut.concat([user]),
      userIn: this.state.userIn.filter((g) => g.id !== user.id),
    });
  };

  handleSubmit = (mutate) => {
    let usersToUnassign = this.state.userOut
      .filter((g) => g.userState === userStates.remove)
      .map((g) => g.id);
    mutate({
      variables: {
        projectId: this.props.projectId,
        layerId: this.props.layer.id,
        usersToAssign: this.state.userIn
          .filter((g) => g.userState === userStates.add)
          .map((g) => g.id),
        usersToUnassign: usersToUnassign,
      },
    });
  };

  update = (cache, { data: { adminAssignUnassignUserToLayer } }) => {
    cache.writeQuery({
      query: GET_ASSIGNED_AND_AVAILABLE_USERS_FOR_LAYER,
      variables: {
        projectId: this.props.projectId,
        layerId: this.props.layer.id,
      },
      data: {
        adminAssignedUserForLayerSet:
          adminAssignUnassignUserToLayer.adminAssignedUserSet,
        adminAvailableUserForLayerSet:
          adminAssignUnassignUserToLayer.adminAvailableUserSet,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Le modifications ont été enregistrées.",
    });
    this.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="Autorisation des utilisateurs (Si une personne fait partie d'un groupe autorisé, il n'est pas nécessaire de l'autoriser explicitement)"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_USER_TO_LAYER}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            userIn={this.state.userIn}
            userOut={this.state.userOut}
            enabled={enabled}
            initFields={this.initFields}
            onChangeName={this.onChangeName}
            addUser={this.addUser}
            removeUser={this.removeUser}
          />
        )}
      </EditSegment>
    );
  }
}

const LayerUsersQuery = (props) => {
  return (
    <EQuery
      query={GET_ASSIGNED_AND_AVAILABLE_USERS_FOR_LAYER}
      variables={{
        projectId: props.projectId,
        layerId: props.layer.id,
      }}
    >
      {(data) => (
        <LayerUsers
          data={data}
          layer={props.layer}
          projectId={props.projectId}
          editMode={props.editMode}
          toEditMode={props.toEditMode}
          toReadMode={props.toReadMode}
        />
      )}
    </EQuery>
  );
};

export default LayerUsersQuery;
