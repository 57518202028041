import React from "react";
import { Message, Segment, Table } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import { ADMIN_QUESTIONNAIRE_USAGE } from "../_GraphQL/queries";
import { Link } from "react-router-dom";

export function QuestionnaireUsage({ questionnaireId, asSegment }) {
  const { loading, error, data } = useQuery(ADMIN_QUESTIONNAIRE_USAGE, {
    variables: { questionnaireId: questionnaireId },
  });

  if (loading) return null;
  if (error) return null;
  if (!data) return null;
  if (data.adminQuestionnaireUsage.length < 2) return null;

  // Ce composant n'affiche quelque chose que si
  // le questionnaire est utilisé par plus d'une couche
  const comp = (
    <>
      <Message warning>
        Ce questionnaire est utilisé par plus d&apos;une couche.
        <br />
        Les modifications appliquées à ce questionnaire le seront également pour
        les couches suivantes :
      </Message>
      <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tâche</Table.HeaderCell>
              <Table.HeaderCell>Couche</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.adminQuestionnaireUsage.map((layer) => (
              <Table.Row key={layer.id}>
                <Table.Cell>{layer.task.name}</Table.Cell>
                <Table.Cell>
                  <Link to={`/manager/couche/${layer.id}`}>{layer.name}</Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  );

  if (asSegment) {
    return <Segment color="orange">{comp}</Segment>;
  }
  return comp;
}
