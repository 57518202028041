// React
import React, { useState } from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import { IS_CONNECTED } from "../../../_GraphQL/queries";

// React Router DOM
import { useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Container,
  Segment,
  Header,
  Message,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { getCookie } from "../../../utils/utils";

export function PasswordReset() {
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const history = useHistory();
  const [result, setResult] = useState({
    formSubmitted: false,
    success: false,
    error: false,
  });
  const [counter, setCounter] = useState(5);

  const { loading, error, data } = useQuery(IS_CONNECTED);

  if (loading) return null;

  if (error) return null;

  if (!data) return null;

  if (data.isConnected) {
    history.push("/");
  }
  const csrftoken = getCookie("csrftoken");

  const urlParams = new URLSearchParams(window.location.search);
  const requestToken = urlParams.get("rt");
  return (
    <Container>
      <Segment>
        <Header as="h1">Réinitialisation de votre mot de passe</Header>
        <Form
          error={result.formSubmitted && result.error}
          success={result.formSubmitted && result.success}
          onSubmit={(e) => {
            e.preventDefault();
            if (pwd !== pwdConfirm) {
              setResult({
                formSubmitted: true,
                success: false,
                error: true,
                errorMessage: "Les deux mots de passe ne correspondent pas.",
              });
            } else {
              setFetchLoading(true);
              //const url = `${domain}/compte/reset-pwd/?rt=${requestToken}`;

              let formData = new FormData();
              formData.append("pwd", pwd);

              let headers = new Headers();
              headers.append("X-CSRFToken", csrftoken);

              fetch(`/compte/reset-pwd/?rt=${requestToken}`, {
                method: "POST",
                headers: headers,
                body: formData,
              })
                .then(function (response) {
                  // Response.ok est une propriété de la réponse de fetch
                  // Elle doit être à "true" si tout va bien
                  if (!response.ok) {
                    throw Error(response.statusText);
                  }
                  const contentType = response.headers.get("content-type");
                  if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                  ) {
                    return response.json().then((data) => {
                      if (data.status === "error") {
                        setResult({
                          formSubmitted: true,
                          success: false,
                          error: true,
                          errorMessage: data.message,
                        });
                      } else {
                        // Si ok
                        setResult({
                          formSubmitted: true,
                          success: true,
                          error: false,
                        });

                        let ms = 5000;
                        const interval = 1000;
                        const countdown = setInterval(() => {
                          ms = ms - interval;
                          setCounter(ms / 1000);

                          if (ms <= 0) {
                            clearInterval(countdown);
                            history.push("/");
                          }
                        }, interval);
                      }
                      // start timer and redirect.
                      setFetchLoading(false);
                    });
                  }
                })
                .catch((err) => {
                  setResult({
                    formSubmitted: true,
                    success: false,
                    error: true,
                    errorMessage: err.toString(),
                  });
                  setFetchLoading(false);
                });
            }
          }}
        >
          {fetchLoading ? (
            <Dimmer active>
              <Loader />
            </Dimmer>
          ) : null}
          {result.formSubmitted && result.error ? (
            <Message error>{result.errorMessage}</Message>
          ) : null}
          {result.formSubmitted && result.success ? (
            <>
              <Message success>
                Votre mot de passe à bien été réinitialisé. Vous allez être
                redirigé.e vers la page d&apos;accueil. Nous vous invitons à
                vous y reconnecter avec votre nouveau mot de passe.
              </Message>
              <div>Redirection dans {counter} secondes</div>
            </>
          ) : null}
          {result.formSubmitted && result.success ? null : (
            <>
              <Form.Field>
                <label>Nouveau mot de passe</label>
                <input
                  placeholder="Nouveau mot de passe"
                  type="password"
                  required
                  pattern=".{8,}"
                  title="Minimum 8 caractères"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label>Confirmation nouveau mot de passe</label>
                <input
                  placeholder="Confirmation nouveau mot de passe"
                  type="password"
                  required
                  pattern=".{8,}"
                  title="Minimum 8 caractères"
                  value={pwdConfirm}
                  onChange={(e) => setPwdConfirm(e.target.value)}
                />
              </Form.Field>
              <Button type="submit">Réinitialiser</Button>
            </>
          )}
        </Form>
      </Segment>
    </Container>
  );
}
