// React
import React, { useState } from "react";

// React Router
import { withRouter } from "react-router-dom";

// GraphQL
import { useMutation } from "@apollo/client";
import { ADMIN_SORT_QUESTIONNAIRE } from "../../_GraphQL/mutations";

// Semantic ui
import {
  Table,
  Segment,
  Header,
  Grid,
  Button,
  Divider,
  Dimmer,
  Loader,
} from "semantic-ui-react";

// Component
import { QuestionnaireDnD } from "./QuestionnaireDnD";
import { ErrorMessage } from "../../../../Common/components/ErrorMessage";
import { buildTreeAndGetRoots } from "../../../../utils/utils";

const QuestionnaireFields = (props) => {
  const [error, setError] = useState(null);

  const [sortQuestionnaire, { loading }] = useMutation(
    ADMIN_SORT_QUESTIONNAIRE,
    {
      onError(error) {
        setError(error);
      },
      onCompleted() {
        setError(null);
      },
    }
  );

  const flaternTree = (tree, sortedFlat, level = 0) => {
    tree.sort((nodeA, nodeB) => {
      let orderA = parseInt(nodeA.associatedObject.order, 10);
      let orderB = parseInt(nodeB.associatedObject.order, 10);
      if (orderA < orderB) return -1;
      if (orderA === orderB) return 0;
      return 1;
    });

    var countChildren = function (node) {
      var count = 0;

      if (!node.children) {
        return count;
      } else {
        node.children.forEach((c) => {
          count += countChildren(c);
        });
        return node.children.length + count;
      }
    };

    tree.forEach((node) => {
      let totalChildren = countChildren(node);

      sortedFlat.push({
        field: node.associatedObject,
        level,
        totalChildren: totalChildren,
      });
      if (node.children && node.children.length > 0) {
        flaternTree(node.children, sortedFlat, level + 1);
      }
    });
  };

  const handleRowClic = (field) => {
    if (props.layer) {
      props.history.push(
        `/manager/couche/${props.layer.id}/questionnaire/${props.questionnaire.id}/champ/${field.id}`
      );
    } else {
      props.history.push(
        `/manager/questionnaire/${props.questionnaire.id}/champ/${field.id}`
      );
    }
  };

  let sortedFlat = [];
  flaternTree(
    buildTreeAndGetRoots(props.questionnaire.questionnairefieldSet),
    sortedFlat
  );
  return (
    <Segment secondary>
      <Header as="h5">
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">Liste des champs</Grid.Column>
            <Grid.Column textAlign="right">
              {!props.editMode ? (
                <Button
                  primary
                  floated="right"
                  onClick={() => {
                    if (props.layer) {
                      props.history.push(
                        `/manager/couche/${props.layer.id}/questionnaire/${props.questionnaire.id}/champ/nouveau`
                      );
                    } else {
                      props.history.push(
                        `/manager/questionnaire/${props.questionnaire.id}/champ/nouveau`
                      );
                    }
                  }}
                >
                  Ajouter un champ
                </Button>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Header>
      <Divider />
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : null}
      {error ? <ErrorMessage error={error} /> : null}
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Intitulé</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <QuestionnaireDnD
          qFieldRowSet={sortedFlat}
          handleRowClic={handleRowClic}
          questionnaireId={props.questionnaire.id}
          sortQuestionnaire={sortQuestionnaire}
        />
      </Table>
    </Segment>
  );
};

export default withRouter(QuestionnaireFields);
