// React
import React, { useState } from "react";

// GraphQL
import { useMutation, useQuery } from "@apollo/client";
import { ADMIN_LAYER_HAS_FEATURES } from "../../../_GraphQL/queries";
import { DELETE_LAYER } from "../../../_GraphQL/mutations";

// Semantic ui
import { Button, Modal, Message } from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../../Common/components/ErrorMessage";
import LoaderDisplayer from "../../../../../Common/components/LoaderDisplayer";

const DeleteLayerMutation = ({ layer, closeDeleteLayer, taskId }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useQuery(ADMIN_LAYER_HAS_FEATURES, { variables: { layerId: layer.id } });
  const [deleteLayer, { loading, error }] = useMutation(DELETE_LAYER, {
    update(cache) {
      cache.modify({
        id: `TaskType:${taskId}`,
        fields: {
          layerSet(existingLayerRefs = [], { readField }) {
            return existingLayerRefs.filter(
              (layerRef) => readField("id", layerRef) !== layer.id
            );
          },
        },
      });
    },
    onCompleted() {
      closeDeleteLayer();
    },
  });

  if (loading || queryLoading) {
    return (
      <Modal.Content>
        <LoaderDisplayer />
      </Modal.Content>
    );
  }

  if (error) {
    return (
      <Modal.Content>
        <ErrorMessage error={error} />
        <Button content="Annuler" onClick={closeDeleteLayer} />
      </Modal.Content>
    );
  }

  if (queryError) {
    return <ErrorMessage error={queryError} />;
  }

  return (
    <Modal.Content>
      {confirmDelete ? (
        <Message warning>
          <p>
            Cette couche contient des données. La suppression est irréversible
            et supprime les données liées (géométries et réponses aux
            questionnaires).
          </p>
          <p>
            Veuillez confirmer à nouveau pour supprimer la couche
            définitivement.
          </p>
        </Message>
      ) : (
        <p>Confirmez-vous la suppression de la couche {layer.name} ?</p>
      )}
      <Button content="Annuler" onClick={closeDeleteLayer} />
      <Button
        positive
        content="Supprimer"
        onClick={() => {
          if (data.adminLayerHasFeatures && !confirmDelete) {
            setConfirmDelete(true);
          } else {
            deleteLayer({ variables: { layerId: layer.id } }).catch((e) => {
              /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
            });
          }
        }}
      />
    </Modal.Content>
  );
};

const DeleteLayerModal = ({ layer, modalOpen, closeDeleteLayer, taskId }) => (
  <Modal open={modalOpen} onCancel={closeDeleteLayer}>
    <DeleteLayerMutation
      closeDeleteLayer={closeDeleteLayer}
      layer={layer}
      taskId={taskId}
    />
  </Modal>
);

export { DeleteLayerModal as DeleteLayer };
