import React from "react";
import { useMutation } from "@apollo/client";
import { Dimmer, Loader } from "semantic-ui-react";
import { handleError } from "./ErrorSender";

function EMutation({ mutation, update, onCompleted, location, children }) {
  const [mut, { loading, error }] = useMutation(mutation, {
    update,
    onCompleted,
    onError: (error) => {
      handleError(error, location);
    },
  });

  return (
    <>
      {loading ? (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      ) : null}
      {children(mut, error)}
    </>
  );
}

export default EMutation;
