// React
import React from "react";

// React Router
import { withRouter, RouteComponentProps } from "react-router-dom";

// Semantic ui
import { Form } from "semantic-ui-react";

// GraphQL
import {
  ADMIN_CREATE_QUESTIONNAIRE_FIELD_LABEL,
  ADMIN_UPDATE_QUESTIONNAIRE_FIELD_LABEL,
} from "../../_GraphQL/mutations";
import { QUESTIONNAIRE_FIELD_LABEL_FRAG } from "../../../../Common/_GraphQL/fragments";

// Components
import { EditSegment } from "../../../../Common/components/EditSegment";
import {
  IconField,
  ColorField,
  StyleField,
} from "../CollaborativeMap/Layer/LayerInfos";

// Types
import {
  Maybe,
  Scalars,
  QuestionnaireType,
  QuestionnaireFieldType,
  QuestionnaireFieldLabelType,
} from "../../../../_GraphQL/typesDefinition";
import { QuestionnaireFieldLabelVariables } from "../../../../_GraphQL/mutationsDefinition";
import { ColorResult } from "react-color";

// Message
import { addSuccessToastMessage } from "../../../../_GraphQL/message";
import { COLLABORATIVE_MAP_GEOMETRY_TYPES } from "../../../../utils/Constants";

type FieldsProps = {
  text?: Scalars["String"];
  marker?: Maybe<Scalars["String"]>;
  markerColor?: Maybe<Scalars["String"]>;
  lineColor?: Maybe<Scalars["String"]>;
  lineStyle?: any;
  polyColor?: Maybe<Scalars["String"]>;
  polyStyle?: any;
  initFields: () => void;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  enabled: boolean;
  resetIcon: any; // todo: change any to reset function signature, see IconField in typescript
  pickIcon: any; // idem
  resetMarkerColor: any; // idem
  onMarkerColorChange: any; // idem
  resetLineColor: any; // idem
  onLineColorChange: any; // idem
  resetPolyColor: any; // idem
  onPolyColorChange: any; // idem
  setLineStyle: any;
  setPolyStyle: any;
};

class Fields extends React.Component<FieldsProps, {}> {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <>
        <Form.Field width={6}>
          <label>Texte</label>
          <input
            placeholder="Texte"
            required
            value={this.props.text}
            onChange={this.props.onTextChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <IconField
          label="Icone du marqueur"
          icon={this.props.marker}
          enabled={this.props.enabled}
          reset={this.props.resetIcon}
          pickIcon={this.props.pickIcon}
        />
        <ColorField
          label="Couleur des marqueurs"
          color={this.props.markerColor}
          enabled={this.props.enabled}
          reset={this.props.resetMarkerColor}
          onChange={this.props.onMarkerColorChange}
        />
        <ColorField
          label="Couleur des lignes"
          color={this.props.lineColor}
          enabled={this.props.enabled}
          reset={this.props.resetLineColor}
          onChange={this.props.onLineColorChange}
        />
        <StyleField
          label="Style des lignes"
          style={this.props.lineStyle}
          enabled={this.props.enabled}
          setStyle={this.props.setLineStyle}
          geoTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE}
        />
        <ColorField
          label="Couleur des polygones"
          color={this.props.polyColor}
          enabled={this.props.enabled}
          reset={this.props.resetPolyColor}
          onChange={this.props.onPolyColorChange}
        />
        <StyleField
          label="Style des polygones"
          style={this.props.polyStyle}
          enabled={this.props.enabled}
          setStyle={this.props.setPolyStyle}
          geoTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON}
        />
      </>
    );
  }
}

interface QuestionnaireFieldLabelInfosProps extends RouteComponentProps {
  createNewLabel: boolean;
  questionnaire: QuestionnaireType;
  questionnaireField: QuestionnaireFieldType;
  questionnaireFieldLabel?: QuestionnaireFieldLabelType;
  layerId?: Scalars["ID"];
  toEditMode: () => void;
  toReadMode: () => void;
  editMode: boolean;
}

type QuestionnaireFieldLabelInfosState = {
  text: Scalars["String"];
  marker?: Maybe<Scalars["String"]>;
  markerColor?: Maybe<Scalars["String"]>;
  lineColor?: Maybe<Scalars["String"]>;
  lineStyle?: any;
  polyColor?: Maybe<Scalars["String"]>;
  polyStyle?: any;
};

class QuestionnaireFieldLabelInfos extends React.Component<
  QuestionnaireFieldLabelInfosProps,
  QuestionnaireFieldLabelInfosState
> {
  constructor(props: QuestionnaireFieldLabelInfosProps) {
    super(props);
    this.state = {
      text: "",
      marker: "",
      markerColor: "",
      lineColor: "",
      lineStyle: null,
      polyColor: "",
      polyStyle: null,
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    if (!this.props.createNewLabel && this.props.questionnaireFieldLabel) {
      const { questionnaireFieldLabel } = this.props;

      this.setState({
        text: questionnaireFieldLabel.text,
        marker: questionnaireFieldLabel.marker,
        markerColor: questionnaireFieldLabel.markerColor || "",
        lineColor: questionnaireFieldLabel.lineColor || "",
        lineStyle: questionnaireFieldLabel.lineStyle,
        polyColor: questionnaireFieldLabel.polyColor || "",
        polyStyle: questionnaireFieldLabel.polyStyle,
      });
    }
  };

  onTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ text: e.target.value });

  handleSubmit = (mutate: any) => {
    let variables: QuestionnaireFieldLabelVariables = {
      text: this.state.text,
    };

    if (this.props.createNewLabel) {
      variables.questionnaireFieldId = this.props.questionnaireField.id;
    } else if (this.props.questionnaireFieldLabel) {
      variables.questionnaireId = this.props.questionnaire.id;
      variables.questionnaireFieldLabelId =
        this.props.questionnaireFieldLabel.id;
    }

    if (this.state.marker !== "") {
      variables.marker = this.state.marker;
    }
    if (this.state.markerColor !== "") {
      variables.markerColor = this.state.markerColor;
    }
    if (this.state.lineColor !== "") {
      variables.lineColor = this.state.lineColor;
    }
    if (this.state.lineStyle) {
      variables.lineStyleId = this.state.lineStyle.id;
    }
    if (this.state.polyColor !== "") {
      variables.polyColor = this.state.polyColor;
    }
    if (this.state.polyStyle) {
      variables.polyStyleId = this.state.polyStyle.id;
    }

    mutate({
      variables: variables,
    });
  };

  update = (
    cache: any,
    {
      data: {
        adminUpdateQuestionnaireFieldLabel,
        adminCreateQuestionnaireFieldLabel,
      },
    }: {
      data: {
        adminUpdateQuestionnaireFieldLabel?: {
          questionnaireFieldLabel: QuestionnaireFieldLabelType;
        };
        adminCreateQuestionnaireFieldLabel?: {
          questionnaireFieldLabel: QuestionnaireFieldLabelType;
        };
      };
    }
  ) => {
    if (this.props.createNewLabel && adminCreateQuestionnaireFieldLabel) {
      cache.modify({
        id: `QuestionnaireFieldType:${this.props.questionnaireField.id}`,
        fields: {
          questionnairefieldlabelSet(existingQuestionnairefieldlabelRefs = []) {
            const newQuestionnairefieldlabelRef = cache.writeFragment({
              data: adminCreateQuestionnaireFieldLabel.questionnaireFieldLabel,
              fragment: QUESTIONNAIRE_FIELD_LABEL_FRAG,
            });

            return [
              ...existingQuestionnairefieldlabelRefs,
              newQuestionnairefieldlabelRef,
            ];
          },
        },
      });
    } else if (adminUpdateQuestionnaireFieldLabel) {
      cache.writeFragment({
        fragment: QUESTIONNAIRE_FIELD_LABEL_FRAG,
        data: adminUpdateQuestionnaireFieldLabel.questionnaireFieldLabel,
      });
    }
  };

  onCompleted = () => {
    if (this.props.createNewLabel) {
      if (this.props.layerId) {
        this.props.history.push(
          `/manager/couche/${this.props.layerId}/questionnaire/${this.props.questionnaire.id}/champ/${this.props.questionnaireField.id}`
        );
      } else {
        this.props.history.push(
          `/manager/questionnaire/${this.props.questionnaire.id}/champ/${this.props.questionnaireField.id}`
        );
      }
    } else {
      addSuccessToastMessage({
        header: "Confirmation enregistrement",
        content: "Les informations ont été mises à jour.",
      });
      this.toReadMode();
    }
  };

  pickIcon = (icon: string) => this.setState({ marker: icon });

  resetIcon = () => this.setState({ marker: "" });

  onMarkerColorChange = (color: ColorResult) =>
    this.setState({ markerColor: color.hex });

  resetMarkerColor = () => this.setState({ markerColor: "" });

  onLineColorChange = (color: ColorResult) =>
    this.setState({ lineColor: color.hex });

  resetLineColor = () => this.setState({ lineColor: "" });

  onPolyColorChange = (color: ColorResult) =>
    this.setState({ polyColor: color.hex });

  resetPolyColor = () => this.setState({ polyColor: "" });

  setLineStyle = (style: any) => this.setState({ lineStyle: style });

  setPolyStyle = (style: any) => this.setState({ polyStyle: style });

  render() {
    const mutate = this.props.createNewLabel
      ? ADMIN_CREATE_QUESTIONNAIRE_FIELD_LABEL
      : ADMIN_UPDATE_QUESTIONNAIRE_FIELD_LABEL;
    return (
      <EditSegment
        title="Informations"
        buttonText="Modifier"
        mutation={mutate}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
        openInEditMode={this.props.createNewLabel}
      >
        {(enabled) => (
          <Fields
            text={this.state.text}
            enabled={enabled}
            initFields={this.initFields}
            onTextChange={this.onTextChange}
            marker={this.state.marker}
            pickIcon={this.pickIcon}
            resetIcon={this.resetIcon}
            markerColor={this.state.markerColor}
            onMarkerColorChange={this.onMarkerColorChange}
            resetMarkerColor={this.resetMarkerColor}
            lineColor={this.state.lineColor}
            onLineColorChange={this.onLineColorChange}
            resetLineColor={this.resetLineColor}
            polyColor={this.state.polyColor}
            onPolyColorChange={this.onPolyColorChange}
            resetPolyColor={this.resetPolyColor}
            setLineStyle={this.setLineStyle}
            lineStyle={this.state.lineStyle}
            setPolyStyle={this.setPolyStyle}
            polyStyle={this.state.polyStyle}
          />
        )}
      </EditSegment>
    );
  }
}

export default withRouter(QuestionnaireFieldLabelInfos);
