// React
import React from "react";

// Semantic ui
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";

// Components
import RootFolderQuery from "./RootFolderQuery";

class RootFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modificationsEnabled: false,
    };
  }

  toReadMode = () => {
    this.setState({ modificationsEnabled: false }, () => {
      this.props.toggleMode();
    });
  };

  render() {
    return (
      <Segment secondary>
        <Header as="h5">
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column verticalAlign="middle">Dossier racine</Grid.Column>
              <Grid.Column textAlign="right">
                {!this.props.editMode && !this.state.modificationsEnabled ? (
                  <Button
                    primary
                    floated="right"
                    onClick={() => {
                      this.setState({ modificationsEnabled: true }, () =>
                        this.props.toggleMode()
                      );
                    }}
                  >
                    {this.props.project.rootFolder ? "Modifier" : "Créer"}
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <Divider />
        <RootFolderQuery
          modificationsEnabled={this.state.modificationsEnabled}
          project={this.props.project}
          toReadMode={this.toReadMode}
        />
      </Segment>
    );
  }
}

export default RootFolder;
