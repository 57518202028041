// GraphQL
import { gql } from "@apollo/client";

import {
  QUESTIONNAIRE_FIELD_FRAG,
  STYLE_FRAG,
} from "../../../Common/_GraphQL/fragments";

export const DRAWING_FRAG_WITH_PARENTS = gql`
  fragment DrawingWithParentsFrag on DrawingType {
    id
    name
    background
    backgroundPath
    paperjsProject
    currentUserCanEdit
    project {
      id
      name
      site {
        id
        domain
        name
      }
    }
  }
`;

export const DRAWING_GROUP_PERMISSION_FRAG = gql`
  fragment DrawingGroupPermissionFrag on DrawingGroupPermissionType {
    drawing {
      id
      name
    }
    group {
      id
      name
    }
    permission {
      id
      codename
      name
    }
  }
`;

export const DEFAULT_DRAWING_GROUP_PERMISSION_FRAG = gql`
  fragment DefaultDrawingGroupPermissionFrag on DefaultDrawingGroupPermissionType {
    project {
      id
      name
    }
    group {
      id
      name
    }
    permission {
      id
      codename
      name
    }
  }
`;

export const WEBSITE_FRAG = gql`
  fragment WebsiteFrag on WebSiteType {
    id
    homeFolder
    mainModule {
      id
      name
      menu
      private
    }
    site {
      id
      domain
      name
      moduleSet {
        id
        name
        menu
        private
      }
    }
  }
`;

export const TASK_FRAG = gql`
  fragment TaskFrag on TaskType {
    id
    name
    subtitle
    excerpt
    slug
    description
    useApicarto
    municipalities
    order
    active
    showHeart
    geocodingActive
    taskType {
      id
      text
      code
    }
    layerSet {
      id
      name
      editable
      position
    }
    project {
      id
      name
      site {
        id
        name
      }
    }
    illustrationPath
    tileLayer {
      id
      name
      description
      mapId
      latitude
      longitude
      zoomInit
      zoomMin
      zoomMax
    }
  }
`;

export const LAYER_FRAG = gql`
  fragment LayerFrag on LayerType {
    id
    name
    editable
    marker
    markerColor
    lineColor
    lineStyle {
      ...StyleFrag
    }
    polyColor
    polyStyle {
      ...StyleFrag
    }
    visibleByDefault
    creatorVisible
    restrictionsBetweenUsers
    multipleFilled
    position
    task {
      id
      name
      project {
        id
        name
        site {
          id
          name
        }
      }
    }
    questionnaire {
      id
      name
      questionnairefieldSet {
        id
        text
        shpField
        fieldType {
          id
          code
          text
        }
        multiselect
      }
    }
    layergeometrySet {
      geometryType {
        id
        text
        code
      }
      decorator {
        id
        fieldType {
          id
          text
          code
        }
      }
      icon
      text
    }
    hasFeature
  }
  ${STYLE_FRAG}
`;

export const QUESTIONNAIRE_FRAG = gql`
  fragment QuestionnaireFrag on QuestionnaireType {
    id
    name
    questionnairefieldSet {
      ...QuestionnaireFieldFrag
    }
  }
  ${QUESTIONNAIRE_FIELD_FRAG}
`;

export const OPEN_SIGN_IN_FRAG = gql`
  fragment OpenSignInFrag on OpenSignInType {
    id
    project {
      id
      name
      slug
      site {
        id
        name
        domain
      }
    }
    group {
      id
      name
    }
    slug
    welcome
  }
`;
