// React
import React from "react";

// React Router
import { Link, withRouter } from "react-router-dom";

// GraphQL
import { CREATE_LAYER } from "../../_GraphQL/mutations";
import { ADMIN_TASK } from "../../_GraphQL/queries";
import { LAYER_FRAG } from "../../_GraphQL/fragments";

// Semantic ui
import {
  Breadcrumb,
  Segment,
  Header,
  Divider,
  Form,
  Grid,
  Button,
  Checkbox,
} from "semantic-ui-react";

// Components
import ManagerLayout from "../../components/ManagerLayout";
import EMutation from "../../../../utils/EMutation";
import EQuery from "../../../../utils/EQuery";

class CreateLayerLayout extends React.Component {
  state = { name: "", editable: true };

  render() {
    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-sites" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-sites">
            <Link to="/manager/sites">Sites</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-site" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-site">
            <Link to={`/manager/site/${this.props.task.project.site.id}`}>
              Site : {this.props.task.project.site.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-project" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-project">
            <Link to={`/manager/projet/${this.props.task.project.id}`}>
              Projet : {this.props.task.project.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-module" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-module">
            <Link
              to={`/manager/carte/?projectId=${this.props.task.project.id}`}
            >
              Module : Carte participative
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-task" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-task">
            <Link to={`/manager/tache/${this.props.task.id}`}>
              Tâche : {this.props.task.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-layer" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-layer">
            Création couche
          </Breadcrumb.Section>,
        ]}
      >
        <Segment secondary>
          <Header as="h5">
            Création d&quot;une couche pour la tâche {this.props.task.name}
          </Header>
          <Divider />
          <EMutation
            mutation={CREATE_LAYER}
            update={(cache, { data: { createLayer } }) => {
              cache.modify({
                id: `TaskType:${this.props.task.id}`,
                fields: {
                  layerSet(existingLayerRefs = []) {
                    const newLayerRef = cache.writeFragment({
                      data: createLayer.layer,
                      fragment: LAYER_FRAG,
                      fragmentName: "LayerFrag",
                    });
                    return [...existingLayerRefs, newLayerRef];
                  },
                },
              });
            }}
            onCompleted={(mutationResult) => {
              this.props.history.push(
                `/manager/couche/${mutationResult.createLayer.layer.id}`
              );
            }}
          >
            {(mutate) => (
              <Form
                onSubmit={() =>
                  mutate({
                    variables: {
                      taskId: this.props.task.id,
                      name: this.state.name,
                      editable: this.state.editable,
                    },
                  })
                }
              >
                <Form.Field>
                  <label>Nom</label>
                  <input
                    type="text"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="Couche participative"
                    checked={this.state.editable}
                    onChange={(e, { checked }) =>
                      this.setState({ editable: checked })
                    }
                  />
                </Form.Field>
                <Grid columns={1}>
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      <Button positive type="submit">
                        Enregistrer
                      </Button>
                      <Button
                        negative
                        onClick={() =>
                          this.props.history.push(
                            `/manager/tache/${this.props.task.id}`
                          )
                        }
                      >
                        Annuler
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </EMutation>
        </Segment>
      </ManagerLayout>
    );
  }
}

const CreateLayerLayoutWithRouter = withRouter(CreateLayerLayout);

export const CreateLayer = ({ match }) => (
  <EQuery query={ADMIN_TASK} variables={{ taskId: match.params.taskId }}>
    {(data) => <CreateLayerLayoutWithRouter task={data.adminTask} />}
  </EQuery>
);
