// React
import React from "react";

// GraphQL
import EMutation from "../../../../../utils/EMutation";
import { ADMIN_TILE_LAYERS } from "../../../_GraphQL/queries";
import { GET_PROJECT } from "../../../../../Common/_GraphQL/queries";

// Semantic ui
import {
  Grid,
  Header,
  Form,
  Segment,
  Button,
  Icon,
  Dropdown,
} from "semantic-ui-react";

import { typeOptions } from "../Task/TileLayerInfos";

export const TARGET = {
  TARGET_TASK: "TARGET_TASK",
  TARGET_PROJECT: "TARGET_PROJECT",
};

class CreateTileLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTileLayerId: -1,
      name: "",
      description: "",
      mapId: "satellite-streets-v12",
      latitude: 0,
      longitude: 0,
      zoomInit: 13,
      zoomMin: 0,
      zoomMax: 16,
    };
  }

  handleSubmit = (mutate) => {
    let variables = {
      mapId: this.state.mapId,
      latitude: parseFloat(this.state.latitude),
      longitude: parseFloat(this.state.longitude),
      zoomInit: parseFloat(this.state.zoomInit),
    };

    if (this.props.target === TARGET.TARGET_TASK) {
      variables.name = this.state.name;
      variables.description = this.state.description;
      variables.zoomMin = parseFloat(this.state.zoomMin);
      variables.zoomMax = parseFloat(this.state.zoomMax);
    } else {
      variables.projectId = this.props.projectId;
    }

    mutate.call(this, { variables });
  };

  onMapIdChange = (e, data) => this.setState({ mapId: data.value });

  render() {
    const forProject = this.props.target === TARGET.TARGET_PROJECT;

    const updateTileLayerSet = (cache, { data: { createTileLayer } }) => {
      const adminTileLayersData = cache.readQuery({
        query: ADMIN_TILE_LAYERS,
      });
      if (adminTileLayersData) {
        const { adminTileLayers } = adminTileLayersData;
        cache.writeQuery({
          query: ADMIN_TILE_LAYERS,
          data: {
            adminTileLayers: adminTileLayers.concat({
              id: createTileLayer.tileLayer.id,
              name: createTileLayer.tileLayer.name,
              __typename: "TileLayerType",
            }),
          },
        });
      }
    };

    const updateProject = (
      cache,
      { data: { adminCreateTileLayerForProject } }
    ) => {
      const { project } = cache.readQuery({
        query: GET_PROJECT,
        variables: {
          projectId: this.props.projectId,
        },
      });
      cache.writeQuery({
        query: GET_PROJECT,
        variables: {
          projectId: this.props.projectId,
        },
        data: {
          project: {
            ...project,
            tileLayer: adminCreateTileLayerForProject.tileLayer,
          },
        },
      });
    };

    const update = forProject ? updateProject : updateTileLayerSet;

    return (
      <Segment attached>
        <Header as="h5">Création d&apos;un fond de carte</Header>

        <EMutation
          mutation={this.props.mutation}
          update={update}
          onCompleted={(data) => {
            this.props.handleCompleted(data.createTileLayer.tileLayer.id);
          }}
        >
          {(mutate) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleSubmit(mutate);
              }}
            >
              {forProject ? null : (
                <>
                  <Form.Field width={6} required>
                    <label>Nom</label>
                    <input
                      placeholder="Nom"
                      required
                      value={this.state.name}
                      onChange={(event) =>
                        this.setState({ name: event.target.value })
                      }
                    />
                  </Form.Field>
                  <Form.Field width={6} required>
                    <label>Description</label>
                    <input
                      placeholder="Description"
                      value={this.state.description}
                      onChange={(event) =>
                        this.setState({ description: event.target.value })
                      }
                      required
                    />
                  </Form.Field>
                </>
              )}
              <Form.Field width={6} required>
                <label>Style de carte</label>
                <Dropdown
                  placeholder="Style de carte"
                  required
                  selection
                  options={typeOptions}
                  value={this.state.mapId}
                  onChange={this.onMapIdChange}
                />
              </Form.Field>
              <Form.Field width={4} required>
                <label>Latitude</label>
                <input
                  type="number"
                  step="0.000001"
                  placeholder="Latitude"
                  min="-90"
                  max="90"
                  required
                  value={this.state.latitude}
                  onChange={(event) =>
                    this.setState({ latitude: event.target.value })
                  }
                />
              </Form.Field>
              <Form.Field width={4} required>
                <label>Longitude</label>
                <input
                  type="number"
                  step="0.000001"
                  placeholder="Longitude"
                  min="-180"
                  max="180"
                  required
                  value={this.state.longitude}
                  onChange={(event) =>
                    this.setState({ longitude: event.target.value })
                  }
                />
              </Form.Field>
              <Form.Field width={4} required>
                <label>Zoom initial</label>
                <input
                  type="number"
                  step="0.1"
                  placeholder="Zoom initial"
                  min="0"
                  max="16"
                  required
                  value={this.state.zoomInit}
                  onChange={(event) =>
                    this.setState({ zoomInit: event.target.value })
                  }
                />
              </Form.Field>
              {forProject ? null : (
                <>
                  <Form.Field width={4} required>
                    <label>Zoom minimal</label>
                    <input
                      type="number"
                      step="0.1"
                      placeholder="Zoom minimal"
                      min="0"
                      max="16"
                      required
                      value={this.state.zoomMin}
                      onChange={(event) =>
                        this.setState({ zoomMin: event.target.value })
                      }
                    />
                  </Form.Field>
                  <Form.Field width={4} required>
                    <label>Zoom maximal</label>
                    <input
                      type="number"
                      step="0.1"
                      placeholder="Zoom maximal"
                      min="0"
                      max="16"
                      required
                      value={this.state.zoomMax}
                      onChange={(event) =>
                        this.setState({ zoomMax: event.target.value })
                      }
                    />
                  </Form.Field>
                </>
              )}
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Button negative onClick={this.props.handleCancel}>
                      <Icon name="ban" /> Annuler
                    </Button>
                    <Button positive>
                      <Icon name="checkmark" /> Enregistrer
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </EMutation>
      </Segment>
    );
  }
}

export { CreateTileLayer };
