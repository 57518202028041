// React
import React from "react";

// Semantic ui
import {
  Checkbox,
  Form,
  Header,
  Select,
  Input,
  GridColumn,
  Grid,
} from "semantic-ui-react";

// Components
import { ItemFilter } from "../Questionnaire/QuestionnaireFilter";

function UsersFilter({
  active,
  toggleActive,
  listForFilters,
  groupId,
  handleGroupChange,
  siteId,
  handleSiteChange,
  projectId,
  projectSet,
  handleProjectChange,
  handleSearchChange,
  search,
}) {
  let sites = [{ key: null, value: null, text: "Tous les sites" }];
  sites = sites.concat(
    listForFilters.siteSet.map((s) => ({
      key: s.id,
      value: s.id,
      text: s.name,
    }))
  );

  let groups = [{ key: null, value: null, text: "Tous les groupes" }];
  groups = groups.concat(
    listForFilters.groupSet.map((g) => ({
      key: g.id,
      value: g.id,
      text: g.name,
    }))
  );

  return (
    <Form>
      <Header as="h4">Filtres</Header>
      <Form.Field>
        <Checkbox
          label="Utilisateurs actifs"
          checked={active}
          onChange={toggleActive}
        />
      </Form.Field>
      <Grid>
        <GridColumn>
          <Form.Group>
            <Form.Field
              control={Select}
              label="Groupe"
              options={groups}
              placeholder="Tous les groupes"
              value={groupId}
              onChange={handleGroupChange}
              width={4}
            />
            <Form.Field
              control={Select}
              label="Site"
              options={sites}
              placeholder="Tous les sites"
              value={siteId}
              onChange={handleSiteChange}
              width={4}
            />
            {siteId ? (
              <ItemFilter
                itemId={projectId}
                itemSet={projectSet}
                handleItemChange={handleProjectChange}
                label="Projets"
                placeholder="Tous les projets"
                noItem="Aucun projet pour ce site"
              />
            ) : (
              <Form.Field width={4} />
            )}
            <Form.Field
              control={Input}
              label="Rechercher"
              placeholder="Nom, prénom, email, raison sociale"
              value={search}
              onChange={handleSearchChange}
              icon="search"
              width={4}
            />
          </Form.Group>
        </GridColumn>
      </Grid>
    </Form>
  );
}

export { UsersFilter };
