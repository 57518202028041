import React, { useEffect, useState } from "react";
import { Form, Checkbox, Segment } from "semantic-ui-react";

function BaseShapeForm({ setShapeBaseOptions, initShapeOptions }) {
  const [shapeBaseOptStroke, setShapeBaseOptStroke] = useState(true); //: {boolean} (default: true) - Whether to draw along the path or not.

  const [shapeBaseOptColor, setShapeBaseOptColor] = useState("#3388ff"); //: {color} (default: 3388ff) - Color of the stroke.
  const [shapeBaseOptWeight, setShapeBaseOptWeight] = useState(1); //: {number} (default: 3) - Width of the stroke.
  const [shapeBaseOptOpacity, setShapeBaseOptOpacity] = useState(1.0); //: {0.0 - 1.0} (default: 1.0) - Opacity of the stroke.
  const [shapeBaseOptDashArray, setShapeBaseOptDashArray] = useState(""); //: {dashArray} (default: null) - Defines the strokes dash pattern. ex: '5, 5'

  const [shapeBaseOptFill, setShapeBaseOptFill] = useState(false); //: {boolean} (default: false) - Should the shape be filled.
  const [shapeBaseOptFillColor, setShapeBaseOptFillColor] = useState("#3388ff"); //: {color} (default: same as color) - Color of the fill.
  const [shapeBaseOptFillOpacity, setShapeBaseOptFillOpacity] = useState(0.2); //: {0.0 - 1.0} (default: 0.2) - Opacity of the fill.

  // Initialisation
  useEffect(() => {
    if (initShapeOptions) {
      if (initShapeOptions.hasOwnProperty("stroke")) {
        setShapeBaseOptStroke(initShapeOptions.stroke);
      }

      if (initShapeOptions.hasOwnProperty("color")) {
        setShapeBaseOptColor(initShapeOptions.color);
      }

      if (initShapeOptions.hasOwnProperty("weight")) {
        setShapeBaseOptWeight(initShapeOptions.weight);
      }

      if (initShapeOptions.hasOwnProperty("opacity")) {
        setShapeBaseOptOpacity(initShapeOptions.opacity);
      }

      if (initShapeOptions.hasOwnProperty("dashArray")) {
        setShapeBaseOptDashArray(initShapeOptions.dashArray);
      }

      if (initShapeOptions.hasOwnProperty("fill")) {
        setShapeBaseOptFill(initShapeOptions.fill);
      }

      if (initShapeOptions.hasOwnProperty("fillColor")) {
        setShapeBaseOptFillColor(initShapeOptions.fillColor);
      }

      if (initShapeOptions.hasOwnProperty("fillOpacity")) {
        setShapeBaseOptFillOpacity(initShapeOptions.fillOpacity);
      }
    }
  }, [initShapeOptions]);

  useEffect(() => {
    setShapeBaseOptions({
      stroke: shapeBaseOptStroke,
      color: shapeBaseOptColor,
      weight: shapeBaseOptWeight,
      opacity: shapeBaseOptOpacity,
      dashArray: shapeBaseOptDashArray,
      fill: shapeBaseOptFill,
      fillColor: shapeBaseOptFillColor,
      fillOpacity: shapeBaseOptFillOpacity,
    });
  }, [
    shapeBaseOptStroke,
    shapeBaseOptColor,
    shapeBaseOptWeight,
    shapeBaseOptOpacity,
    shapeBaseOptDashArray,
    shapeBaseOptFill,
    shapeBaseOptFillColor,
    shapeBaseOptFillOpacity,
    setShapeBaseOptions,
  ]);

  return (
    <Segment secondary attached>
      <Form.Field>
        <Checkbox
          label="Afficher le contour"
          checked={shapeBaseOptStroke}
          onChange={() => setShapeBaseOptStroke(!shapeBaseOptStroke)}
        />
      </Form.Field>
      {shapeBaseOptStroke ? (
        <>
          <Form.Field>
            <label htmlFor="shape-base-opt-color">Couleur du contour</label>
            <input
              id="shape-base-opt-color"
              type="color"
              value={shapeBaseOptColor}
              onChange={(e) => setShapeBaseOptColor(e.target.value)}
            />
          </Form.Field>

          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="shape-base-opt-weight">
                Épaisseur : {shapeBaseOptWeight}
              </label>
              <input
                id="shape-base-opt-weight"
                type="range"
                min="1"
                max="20"
                step="1"
                value={shapeBaseOptWeight}
                onChange={(e) =>
                  setShapeBaseOptWeight(parseInt(e.target.value, 10))
                }
              />
            </Form.Field>

            <Form.Field>
              <label htmlFor="shape-base-opt-opacity">
                Opacité du contour : {shapeBaseOptOpacity}
              </label>
              <input
                id="shape-base-opt-opacity"
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={shapeBaseOptOpacity}
                onChange={(e) =>
                  setShapeBaseOptOpacity(parseFloat(e.target.value))
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label htmlFor="shape-base-opt-dash-array">Pointillés</label>
            <input
              id="shape-base-opt-dash-array"
              type="text"
              value={shapeBaseOptDashArray}
              onChange={(e) => setShapeBaseOptDashArray(e.target.value)}
            />
          </Form.Field>
        </>
      ) : null}

      <Form.Field>
        <Checkbox
          label="Remplir"
          checked={shapeBaseOptFill}
          onChange={() => setShapeBaseOptFill(!shapeBaseOptFill)}
        />
      </Form.Field>
      {shapeBaseOptFill ? (
        <>
          <Form.Field>
            <label htmlFor="shape-base-opt-fill-color">
              Couleur de remplissage
            </label>
            <input
              id="shape-base-opt-fill-color"
              type="color"
              value={shapeBaseOptFillColor}
              onChange={(e) => setShapeBaseOptFillColor(e.target.value)}
            />
          </Form.Field>

          <Form.Field>
            <label htmlFor="shape-base-opt-fill-opacity">
              Opacité du remplissage : {shapeBaseOptFillOpacity}
            </label>
            <input
              id="shape-base-opt-fill-opacity"
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={shapeBaseOptFillOpacity}
              onChange={(e) =>
                setShapeBaseOptFillOpacity(parseFloat(e.target.value))
              }
            />
          </Form.Field>
        </>
      ) : null}
    </Segment>
  );
}

export { BaseShapeForm };
