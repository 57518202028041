// React
import React from "react";

// GraphQL
import EQuery from "../../../../../utils/EQuery";
import { ADMIN_TILE_LAYERS } from "../../../_GraphQL/queries";

// Semantic ui
import {
  Grid,
  Form,
  Segment,
  Button,
  Select,
  Divider,
  Icon,
} from "semantic-ui-react";

// Message
import { addErrorToastMessage } from "../../../../../_GraphQL/message";

class SelectOrCreateTileLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTileLayerId: -1,
    };
  }

  changeSelectedLayer = (selectedTileLayerId) => {
    this.setState({ selectedTileLayerId: selectedTileLayerId });
  };

  render() {
    return (
      <Segment attached>
        <Grid
          columns={2}
          relaxed="very"
          stackable
          verticalAlign="middle"
          textAlign="center"
        >
          <Grid.Column>
            <EQuery query={ADMIN_TILE_LAYERS}>
              {(data) => {
                if (data.adminTileLayers.length === 0) return null;
                let options = data.adminTileLayers.map((tl) => ({
                  key: tl.id,
                  value: tl.id,
                  text: tl.name,
                }));
                return (
                  <Form>
                    <Form.Field>
                      <Select
                        placeholder="Fond de carte"
                        options={options}
                        value={this.props.selectedTileLayerId}
                        onChange={(e, { value }) =>
                          this.changeSelectedLayer(value)
                        }
                      />
                    </Form.Field>
                    <Button
                      positive
                      onClick={() => {
                        if (this.state.selectedTileLayerId === -1) {
                          addErrorToastMessage({
                            header: "Aucun fond de carte sélectionné",
                            content: `Vous devez sélectionner un fond de carte 
                                                            existant ou créer un nouveau fond de carte pour 
                                                            passer à l'étape suivante.`,
                            autoClose: true,
                            closeTime: 10000,
                          });
                          return;
                        }
                        this.props.goToTask(this.state.selectedTileLayerId);
                      }}
                    >
                      <Icon name="checkmark" />
                      Sélectionner ce fond de carte
                    </Button>
                  </Form>
                );
              }}
            </EQuery>
          </Grid.Column>
          <Grid.Column>
            <Button positive onClick={() => this.props.goToAddTileLayer()}>
              <Icon name="edit" />
              Créer un fond de carte
            </Button>
          </Grid.Column>
        </Grid>
        <Divider vertical>Ou</Divider>
      </Segment>
    );
  }
}

export { SelectOrCreateTileLayer };
