// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import { GET_LAYER } from "../../_GraphQL/queries";
import EQuery from "../../../../utils/EQuery";
import { ADMIN_QUESTIONNAIRE } from "../../_GraphQL/queries";

// Semantic ui
import { Breadcrumb, Header } from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";
import QuestionnaireInfos from "../../components/Questionnaire/QuestionnaireInfos";
import QuestionnaireFields from "../../components/Questionnaire/QuestionnaireFields";
import { QuestionnaireUsage } from "../../components/QuestionnaireUsage";

class Questionnaire extends React.Component {
  state = {
    editMode: false,
  };

  toReadMode = () => this.setState({ editMode: false });

  toEditMode = () => this.setState({ editMode: true });

  render() {
    const { questionnaire, layer } = this.props;
    if (layer) {
      return (
        <ManagerLayout
          breadcrumbItems={[
            <Breadcrumb.Section key="breadcrumb-home">
              <Link to="/manager">Manager</Link>
            </Breadcrumb.Section>,
            <Breadcrumb.Divider key="bc-divider-sites" icon="right angle" />,
            <Breadcrumb.Section key="breadcrumb-sites">
              <Link to="/manager/sites">Sites</Link>
            </Breadcrumb.Section>,
            <Breadcrumb.Divider key="bc-divider-site" icon="right angle" />,
            <Breadcrumb.Section key="breadcrumb-site">
              <Link to={`/manager/site/${layer.task.project.site.id}`}>
                Site : {layer.task.project.site.name}
              </Link>
            </Breadcrumb.Section>,
            <Breadcrumb.Divider key="bc-divider-project" icon="right angle" />,
            <Breadcrumb.Section key="breadcrumb-project">
              <Link to={`/manager/projet/${layer.task.project.id}`}>
                Projet : {layer.task.project.name}
              </Link>
            </Breadcrumb.Section>,
            <Breadcrumb.Divider key="bc-divider-module" icon="right angle" />,
            <Breadcrumb.Section key="breadcrumb-module">
              <Link to={`/manager/carte/?projectId=${layer.task.project.id}`}>
                Module : Carte participative
              </Link>
            </Breadcrumb.Section>,
            <Breadcrumb.Divider key="bc-divider-task" icon="right angle" />,
            <Breadcrumb.Section key="breadcrumb-task">
              <Link to={`/manager/tache/${layer.task.id}`}>
                Tâche : {layer.task.name}
              </Link>
            </Breadcrumb.Section>,
            <Breadcrumb.Divider key="bc-divider-layer" icon="right angle" />,
            <Breadcrumb.Section key="breadcrumb-layer">
              <Link to={`/manager/couche/${layer.id}`}>
                Couche : {layer.name}
              </Link>
            </Breadcrumb.Section>,
            <Breadcrumb.Divider
              key="bc-divider-reation-questionnaire"
              icon="right angle"
            />,
            <Breadcrumb.Section key="breadcrumb-field">
              Questionnaire : {questionnaire.name}
            </Breadcrumb.Section>,
          ]}
        >
          <Header as="h5" block>
            Questionnaire : {questionnaire.name}
          </Header>
          <QuestionnaireUsage
            questionnaireId={questionnaire.id}
            asSegment={true}
          />
          <QuestionnaireInfos
            questionnaire={questionnaire}
            editMode={this.state.editMode}
            toEditMode={this.toEditMode}
            toReadMode={this.toReadMode}
            create={false}
          />

          <QuestionnaireFields
            questionnaire={questionnaire}
            editMode={this.state.editMode}
            toEditMode={this.toEditMode}
            toReadMode={this.toReadMode}
            layer={layer}
          />
        </ManagerLayout>
      );
    }

    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider
            key="bc-divider-questionnaires"
            icon="right angle"
          />,
          <Breadcrumb.Section key="breadcrumb-questionnaires">
            <Link to={`/manager/questionnaires${window.location.search}`}>
              Questionnaires
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider
            key="bc-divider-questionnaire"
            icon="right angle"
          />,
          <Breadcrumb.Section key="breadcrumb-questionnaire">
            Questionnaire : {questionnaire.name}
          </Breadcrumb.Section>,
        ]}
      >
        <Header as="h5" block>
          Questionnaire : {questionnaire.name}
        </Header>
        <QuestionnaireUsage
          questionnaireId={questionnaire.id}
          asSegment={true}
        />
        <QuestionnaireInfos
          questionnaire={questionnaire}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
          create={false}
        />

        <QuestionnaireFields
          questionnaire={questionnaire}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
        />
      </ManagerLayout>
    );
  }
}

const QuestionnaireQuery = ({ match }) => {
  if (match.params.layerId) {
    return (
      <EQuery query={GET_LAYER} variables={{ layerId: match.params.layerId }}>
        {(data) => {
          let layer = data.layer;
          return (
            <EQuery
              query={ADMIN_QUESTIONNAIRE}
              variables={{
                questionnaireId: match.params.questionnaireId,
              }}
            >
              {(data) => (
                <Questionnaire
                  layer={layer}
                  questionnaire={data.adminQuestionnaire}
                />
              )}
            </EQuery>
          );
        }}
      </EQuery>
    );
  }
  return (
    <EQuery
      query={ADMIN_QUESTIONNAIRE}
      variables={{
        questionnaireId: match.params.questionnaireId,
      }}
    >
      {(data) => <Questionnaire questionnaire={data.adminQuestionnaire} />}
    </EQuery>
  );
};

export { QuestionnaireQuery as Questionnaire };
