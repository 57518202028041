// React
import React, { useState } from "react";

// Semantic ui
import { Button, Modal, List, Dimmer, Loader } from "semantic-ui-react";

// GraphQL
import { useMutation } from "@apollo/client";
import { ADMIN_DELETE_QUESTIONNAIRE } from "../../_GraphQL/mutations";

// Message
import { ErrorMessage } from "../../../../Common/components/ErrorMessage";

// Types
import { Scalars } from "../../../../_GraphQL/typesDefinition";

type QuestionnaireRefType = {
  __ref: Scalars["ID"];
};

type DeleteQuestionnaireProps = {
  questionnaireId: Scalars["ID"];
  questionnaireName: string;
};

function DeleteQuestionnaire({
  questionnaireId,
  questionnaireName,
}: DeleteQuestionnaireProps) {
  const [modalOpen, setModalOpen] = useState(false);

  function handleCancel() {
    setModalOpen(false);
  }

  const [deleteQuestionnaire, { loading, error }] = useMutation(
    ADMIN_DELETE_QUESTIONNAIRE,
    {
      update(cache) {
        cache.modify({
          fields: {
            adminQuestionnaireSet(existingQuestionnaireRefs = []) {
              return [
                ...existingQuestionnaireRefs.filter(
                  (q: QuestionnaireRefType) =>
                    q.__ref !== `QuestionnaireType:${questionnaireId}`
                ),
              ];
            },
          },
        });
      },
      onCompleted() {
        handleCancel();
      },
      onError(_) {
        // Nécessaire pour évite les crash de type unhandle rejection, mais
        // On ne fait rien car on utilise l'état "error" fournit par useMutation
        // Pour afficher les erreurs.
      },
    }
  );

  return (
    <>
      <List.Icon link name="delete" onClick={() => setModalOpen(true)} />
      <Modal open={modalOpen} onCancel={handleCancel}>
        <Modal.Content>
          {loading ? (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          ) : null}
          {error ? <ErrorMessage error={error} /> : null}
          {error ? null : (
            <p>
              Confirmez-vous la suppression du questionnaire {questionnaireName}{" "}
              ?
            </p>
          )}
          <Button content="Annuler" onClick={handleCancel} />
          {error ? null : (
            <Button
              positive
              content="Supprimer"
              onClick={() =>
                deleteQuestionnaire({
                  variables: {
                    questionnaireId: questionnaireId,
                  },
                })
              }
            />
          )}
        </Modal.Content>
      </Modal>
    </>
  );
}

export { DeleteQuestionnaire };
