// React
import React from "react";

// Color picker
import { SketchPicker } from "react-color";

// Semantic ui
import { Modal, Button, Icon } from "semantic-ui-react";

const ColorDisplayer = ({ color, marginTop, height, width, padding }) => (
  <div
    style={{
      backgroundClip: "border-box",
      borderRadius: "4px",
      padding: `${padding}px`,
      boxShadow:
        "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px",
      marginTop: `${marginTop}px`,
      height: `${height + 2 * padding}px`,
      width: `${width + 2 * padding}px`,
      backgroundColor: "white",
    }}
  >
    <div
      style={{
        height: `${height}px`,
        width: `${width}px`,
        backgroundColor: color,
      }}
    ></div>
  </div>
);

export { ColorDisplayer };

class ColorPicker extends React.Component {
  state = {
    modalOpen: false,
    initColor: "",
  };

  handleOpen = () =>
    this.setState({ modalOpen: true, initColor: this.props.color });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    return (
      <Modal
        size="mini"
        trigger={
          <Button type="button" onClick={this.handleOpen}>
            Choisir une couleur
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>Sélection d'une couleur</Modal.Header>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SketchPicker
              color={this.props.color}
              onChange={this.props.onChange}
            />

            <ColorDisplayer
              color={this.props.color}
              marginTop={10}
              height={20}
              width={200}
              padding={10}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() =>
              this.setState({ modalOpen: false }, () =>
                this.props.onChange({ hex: this.state.initColor })
              )
            }
          >
            <Icon name="remove" /> Annuler
          </Button>
          <Button positive onClick={this.handleClose}>
            <Icon name="checkmark" /> Valider
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ColorPicker;
