// React
import React from "react";

// Semantic ui
import { Checkbox, Table } from "semantic-ui-react";

const DocumentGroupPermissionsFormRow = ({
  groupPermissionsRow,
  handleCheckChange,
}) => (
  <Table.Row>
    <Table.Cell>{groupPermissionsRow.group.name}</Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="view_document_"
        checked={groupPermissionsRow.canViewDocument}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="move_document_"
        checked={groupPermissionsRow.canMoveDocument}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="delete_document_"
        checked={groupPermissionsRow.canDeleteDocument}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
  </Table.Row>
);

export default DocumentGroupPermissionsFormRow;
