// React
import React from "react";

// Semantic ui
import { Button, Form, Grid, Table } from "semantic-ui-react";

// Components
import DrawingPermissionFormRow from "./DrawingPermissionsFormRow";

const DrawingPermissionForm = (props) => (
  <Form onSubmit={props.handleSubmit}>
    <Form.Field disabled={!props.mutation}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Groupe</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Accès en lecture
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Accès en édition
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.permissionLines.map((permission) => (
            <DrawingPermissionFormRow
              key={`table-row-group-${permission.group.name}`}
              permission={permission}
              handleCheckChange={props.handleCheckChange}
            />
          ))}
        </Table.Body>
      </Table>
    </Form.Field>
    {props.mutation ? (
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Button positive type="submit">
              Enregistrer
            </Button>
            <Button negative onClick={props.handleCancel}>
              Annuler
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    ) : null}
  </Form>
);

export default DrawingPermissionForm;
