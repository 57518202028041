// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { ADMIN_DRAWING } from "../../_GraphQL/queries";

// Semantic ui
import { Breadcrumb, Segment, Header, Loader } from "semantic-ui-react";

// Components
import ManagerLayout from "../../components/ManagerLayout";
import DrawingPermissions from "../../components/Draw/DrawingPermissions";
import ErrorMessage from "../../../../Common/components/ErrorMessage";

const toggleMode = () => null;

const Drawing = ({ drawing }) => (
  <ManagerLayout
    breadcrumbItems={[
      <Breadcrumb.Section key="breadcrumb-home">
        <Link to="/manager">Manager</Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key={`bc-divider-sites`} icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-sites">
        <Link to="/manager/sites">Sites</Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key={`bc-divider-site`} icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-site">
        <Link to={`/manager/site/${drawing.project.site.id}`}>
          Site : {drawing.project.site.name}
        </Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key={`bc-divider-project`} icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-project">
        <Link to={`/manager/projet/${drawing.project.id}`}>
          Projet : {drawing.project.name}
        </Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key={`bc-divider-draw`} icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-draw">
        <Link to={`/manager/dessin/?projectId=${drawing.project.id}`}>
          Module : Outil de dessin
        </Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key={`bc-divider-drawing`} icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-drawing">
        Dessin : {drawing.name}
      </Breadcrumb.Section>,
    ]}
  >
    <Header attached="top" as="h5">
      {drawing.name}
    </Header>
    <Segment attached="bottom">
      <img
        src={`/media/dessins/${drawing.backgroundPath}`}
        style={{ maxWidth: "100%" }}
        alt={drawing.name}
      />
    </Segment>

    <DrawingPermissions
      project={drawing.project}
      drawing={drawing}
      toggleMode={toggleMode}
      target="drawing"
    />
  </ManagerLayout>
);

function DrawingQuery({ match }) {
  const { loading, error, data } = useQuery(ADMIN_DRAWING, {
    variables: { drawingId: match.params.drawingId },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return <Drawing drawing={data.adminDrawing} />;
}

export { DrawingQuery as Drawing };
