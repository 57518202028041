// React
import React from "react";

// React Router
import { Link, withRouter } from "react-router-dom";

// GraphQL
import { useMutation } from "@apollo/client";
import { ADMIN_CREATE_USER } from "../../_GraphQL/mutations";

// Semantic ui
import {
  Button,
  Breadcrumb,
  Form,
  Grid,
  Segment,
  Dropdown,
} from "semantic-ui-react";

// Components
import MessageDisplayer from "../../../../Common/components/MessageDisplayer";
import ManagerLayout from "../../components/ManagerLayout";

// Constants
import { COMPONENTS, USER_TYPES } from "../../../../utils/Constants";
import { useState } from "react";

// Message
import {
  addErrorMessage,
  addSuccessMessage,
  getMessageTextFromApolloError,
} from "../../../../_GraphQL/message";

const natureOptions = [
  {
    key: USER_TYPES.LEGAL_PERSON,
    value: USER_TYPES.LEGAL_PERSON,
    text: "Personne morale",
  },
  {
    key: USER_TYPES.NATURAL_PERSON,
    value: USER_TYPES.NATURAL_PERSON,
    text: "Personne physique",
  },
];

function CreateUser({ history }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nature, setNature] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [adminCreateUser] = useMutation(ADMIN_CREATE_USER, {
    onCompleted(data) {
      addSuccessMessage({
        location: COMPONENTS.MANAGER.MANAGER,
        header: "Utilisateur créé",
        content:
          "L'utilisateur a été créé. vous pouvez maintenant ajouter des sites, projets et groupes.",
      });
      history.push(`/manager/utilisateur/${data.adminCreateUser.user.id}`);
    },
    onError(error) {
      let message = getMessageTextFromApolloError(error);
      message.location = COMPONENTS.MANAGER.MANAGER;
      addErrorMessage(message);
    },
  });

  return (
    <ManagerLayout
      breadcrumbItems={[
        <Breadcrumb.Section key="breadcrumb-home">
          <Link to="/manager">Manager</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-users" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-users">
          <Link to="/manager/utilisateurs">Utilisateurs</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-new-user" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-new-user" active>
          Création d'un utilisateur
        </Breadcrumb.Section>,
      ]}
    >
      <Segment>
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            if (password !== passwordConfirm) {
              addErrorMessage({
                header: "Les mots de passes ne correspondent pas",
                location: COMPONENTS.MANAGER.CREATE_USER,
                content:
                  "Les deux mots de passes doivent correspondre strictement.",
              });
            } else if (nature === "") {
              addErrorMessage({
                header: "Type manquant",
                location: COMPONENTS.MANAGER.CREATE_USER,
                content:
                  "Veuillez indiquer s'il s'agit d'une personne physique ou morale.",
              });
            } else {
              adminCreateUser({
                variables: {
                  email: email,
                  password: password,
                  nature: nature,
                  firstName: firstName,
                  lastName: lastName,
                  name: name,
                },
              });
            }
          }}
        >
          <MessageDisplayer location={COMPONENTS.MANAGER.CREATE_USER} />
          <Form.Field>
            <label>Email</label>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Type</label>
            <Dropdown
              required
              selection
              options={natureOptions}
              value={nature}
              onChange={(e, { value }) => setNature(value)}
            />
          </Form.Field>
          {nature === USER_TYPES.NATURAL_PERSON ? (
            <>
              <Form.Field>
                <label>Prénom</label>
                <input
                  type="text"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label>Nom</label>
                <input
                  type="text"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Field>
            </>
          ) : (
            <Form.Field>
              <label>Raison sociale</label>
              <input
                type="text"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Field>
          )}
          <Form.Field>
            <label>Mot de passe</label>
            <input
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Confirmation du mot de passe</label>
            <input
              type="password"
              required
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Form.Field>
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button positive type="submit">
                  Créer
                </Button>
                <Button
                  negative
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/manager/utilisateurs");
                  }}
                >
                  Annuler
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    </ManagerLayout>
  );
}

const CreateUserWithRouter = withRouter(CreateUser);

export { CreateUserWithRouter as CreateUser };
