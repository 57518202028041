// React
import React, { useState } from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { TASK_SET } from "../../_GraphQL/queries";

// Component
import { DeleteTask } from "./DeleteTask";

// Semantic ui
import { Loader, List, Header, Segment } from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { TASK_TYPES, SITE_NAMES } from "../../../../utils/Constants";

function TaskList({ projectId, siteName }) {
  const [modal, setModal] = useState({
    deleteTaskOpen: false,
    taskToDelete: null,
  });

  const { loading, error, data } = useQuery(TASK_SET, {
    variables: { projectId: projectId },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Header as="h4" attached="top">
        Inventaires
      </Header>
      <Segment attached="bottom">
        <List>
          {data.taskSet
            .filter(
              (task) =>
                !task.taskType || task.taskType.code === TASK_TYPES.INVENTORY
            )
            .map((task) => (
              <List.Item key={`task-${task.id}`}>
                <List.Content>
                  <List.Icon
                    link
                    name="delete"
                    onClick={() =>
                      setModal({
                        deleteTaskOpen: true,
                        taskToDelete: task,
                      })
                    }
                  />
                  <Link to={`/manager/tache/${task.id}`}>
                    <strong>{task.order ?? "_"}</strong>&nbsp;-&nbsp;
                    {task.name}
                  </Link>
                </List.Content>
              </List.Item>
            ))}
        </List>
      </Segment>
      {siteName === SITE_NAMES.ICI ? (
        <>
          <Header as="h4" attached="top">
            Appel à idées
          </Header>
          <Segment attached="bottom">
            <List>
              {data.taskSet
                .filter(
                  (task) =>
                    task.taskType && task.taskType.code === TASK_TYPES.IDEAS
                )
                .map((task) => (
                  <List.Item key={`task-${task.id}`}>
                    <List.Content>
                      <List.Icon
                        link
                        name="delete"
                        onClick={() =>
                          setModal({
                            deleteTaskOpen: true,
                            taskToDelete: task,
                          })
                        }
                      />
                      <Link to={`/manager/tache/${task.id}`}>
                        <strong>{task.order ?? "_"}</strong>&nbsp;-&nbsp;
                        {task.name}
                      </Link>
                    </List.Content>
                  </List.Item>
                ))}
            </List>
          </Segment>
        </>
      ) : null}
      <DeleteTask
        task={modal.taskToDelete}
        projectId={projectId}
        modalOpen={modal.deleteTaskOpen}
        closeDeleteTask={() =>
          setModal({
            deleteTaskOpen: false,
            taskToDelete: null,
          })
        }
      />
    </>
  );
}

export { TaskList };
