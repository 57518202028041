import React from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";

const LoaderDisplayer = () => (
  <Segment>
    <Dimmer active inverted>
      <Loader>Chargement en cours...</Loader>
    </Dimmer>
    <br />
    <br />
    <br />
    <br />
    <br />
  </Segment>
);

export default LoaderDisplayer;
