// React
import React from "react";

// React Router
import { withRouter } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { ADMIN_PROJECT_SET } from "../../_GraphQL/queries";

// Semantic ui
import { Loader, Card, Image } from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";

function ProjectListQuery({ siteId, history }) {
  const { loading, error, data } = useQuery(ADMIN_PROJECT_SET, {
    variables: { siteId: siteId },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <Card.Group centered>
      {data.adminProjectSet.map((p) => (
        <Card
          key={`project-${p.id}`}
          link
          onClick={() => history.push(`/manager/projet/${p.id}`)}
        >
          {p.illustrationPath ? (
            <Image
              src={`/media/projects/${p.illustrationPath}`}
              alt={p.name}
              wrapped
              ui={false}
            />
          ) : (
            <div className="image" style={{ height: "290px" }}></div>
          )}
          <Card.Content>
            <Card.Header>{p.name}</Card.Header>
            <Card.Description>{p.description}</Card.Description>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );
}

export default withRouter(ProjectListQuery);
