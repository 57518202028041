// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";

// Route
import { Route, Redirect } from "react-router-dom";
import { IS_CONNECTED } from "../_GraphQL/queries";

function PrivateRoute({ component: Component, ...rest }) {
  const { loading, error, data } = useQuery(IS_CONNECTED);

  if (loading) return null;

  if (error) return null;

  if (!data) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (data.isConnected) {
          return <Component {...props} {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
