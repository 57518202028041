import React, { useState, useEffect } from "react";
import { Form, Segment } from "semantic-ui-react";
import { PATTERN_TYPES } from "../../../../../../utils/Constants";

function BasePatternForm({
  initPatternOpt,
  basePatternOptHeight,
  setBasePatternOptHeight,
  stripePatternOptWeight,
  setStripePatternOptWeight,
  stripePatternOptSpaceWeight,
  setStripePatternOptSpaceWeight,
  setOptions,
  patternTypeCode,
}) {
  // Base Pattern Options

  const [basePatternOptWidth, setBasePatternOptWidth] = useState(16); //: {number} (default: 8) - The width of the pattern.
  const [basePatternOptAngle, setBasePatternOptAngle] = useState(0); //: {number} (default: null) - a quick way to add rotate(angle) to the patternTransform.

  useEffect(() => {
    if (initPatternOpt) {
      if (initPatternOpt.hasOwnProperty("width")) {
        setBasePatternOptWidth(initPatternOpt.width);
      }
      if (initPatternOpt.hasOwnProperty("angle")) {
        setBasePatternOptAngle(initPatternOpt.angle);
      }
    }
  }, [initPatternOpt]);

  useEffect(() => {
    setOptions({
      width: basePatternOptWidth,
      height: basePatternOptHeight,
      angle: basePatternOptAngle,
    });
  }, [
    basePatternOptWidth,
    basePatternOptHeight,
    basePatternOptAngle,
    setOptions,
  ]);

  return (
    <Segment secondary attached>
      <Form.Group widths="equal">
        {patternTypeCode !== PATTERN_TYPES.STRIPES ? (
          <Form.Field>
            <label htmlFor="base-pattern-opt-width">
              Largeur du motif : {basePatternOptWidth}
            </label>
            <input
              id="base-pattern-opt-width"
              type="range"
              min="0"
              max="50"
              step="1"
              value={basePatternOptWidth}
              onChange={(e) => {
                setBasePatternOptWidth(parseInt(e.target.value, 10));
              }}
            />
          </Form.Field>
        ) : null}
        <Form.Field>
          <label htmlFor="base-pattern-opt-height">
            Hauteur du motif : {basePatternOptHeight}
          </label>
          <input
            id="base-pattern-opt-height"
            type="range"
            min="0"
            max="50"
            step="1"
            value={basePatternOptHeight}
            onChange={(e) => {
              const newValue = parseInt(e.target.value, 10);
              if (
                stripePatternOptWeight + stripePatternOptSpaceWeight >
                newValue
              ) {
                if (stripePatternOptWeight > stripePatternOptSpaceWeight) {
                  setStripePatternOptWeight(
                    newValue - stripePatternOptSpaceWeight
                  );
                } else {
                  setStripePatternOptSpaceWeight(
                    newValue - stripePatternOptWeight
                  );
                }
              }
              setBasePatternOptHeight(newValue);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label htmlFor="base-pattern-opt-angle">
          Angle : {basePatternOptAngle}
        </label>
        <input
          id="base-pattern-opt-angle"
          type="range"
          min="0"
          max="360"
          step="1"
          value={basePatternOptAngle}
          onChange={(e) => setBasePatternOptAngle(parseInt(e.target.value, 10))}
        />
      </Form.Field>
    </Segment>
  );
}

export { BasePatternForm };
