// React
import React from "react";

// React Router
import { Link, withRouter } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { ADMIN_TASK } from "../../_GraphQL/queries";

// Semantic ui
import {
  Loader,
  Breadcrumb,
  List,
  Segment,
  Header,
  Grid,
  Button,
  Divider,
} from "semantic-ui-react";
import ManagerLayout from "../../components/ManagerLayout";

// Components
import { TaskInfos } from "../../components/CollaborativeMap/Task/TaskInfos";
import { TaskIllustration } from "../../components/CollaborativeMap/Task/TaskIllustration";
import { TileLayerInfos } from "../../components/CollaborativeMap/Task/TileLayerInfos";
import { TaskGroups } from "../../components/CollaborativeMap/Task/TaskGroups";
import { DeleteLayer } from "../../components/CollaborativeMap/Task/DeleteLayer";
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { TASK_TYPES } from "../../../../utils/Constants";
import { TaskGeoJson } from "../../components/CollaborativeMap/Task/TaskGeoJson";

function positionSort(a, b) {
  const posA = a.position || 1;
  const posB = b.position || 1;
  if (posA === posB) return 0;
  if (posA < posB) return -1;
  return 1;
}
class Task extends React.Component {
  state = {
    editMode: false,
  };

  toReadMode = () => this.setState({ editMode: false });

  toEditMode = () => this.setState({ editMode: true });

  closeDeleteLayer = () => this.setState({ deleteLayerOpen: false });

  render() {
    let { task, taskTypeSet } = this.props;

    const taskTypeOptions = taskTypeSet.map((tt) => ({
      key: tt.id,
      value: tt.id,
      text: tt.text,
    }));

    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-sites"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-sites">
            <Link to="/manager/sites">Sites</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-site"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-site">
            <Link to={`/manager/site/${task.project.site.id}`}>
              Site : {task.project.site.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-project"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-project">
            <Link to={`/manager/projet/${task.project.id}`}>
              Projet : {task.project.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-draw"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-draw">
            <Link to={`/manager/carte/?projectId=${task.project.id}`}>
              Module : Carte participative
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-task"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-task">
            Tâche : {task.name}
          </Breadcrumb.Section>,
        ]}
      >
        <Header as="h4" block>
          Paramètres de la tâche « {task.name} »
        </Header>

        <Segment secondary>
          <Header as="h5">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">Couches</Grid.Column>
                <Grid.Column textAlign="right">
                  {!this.props.editMode ? (
                    <Button
                      primary
                      floated="right"
                      onClick={() =>
                        this.props.history.push(
                          `/manager/couche/creation/${this.props.task.id}`
                        )
                      }
                    >
                      Créer une couche
                    </Button>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <Divider />
          <h5>Couches informatives</h5>
          <List>
            {task.layerSet
              .filter((l) => l.editable === false)
              .sort(positionSort)
              .map((l) => (
                <List.Item key={`layer-${l.id}`}>
                  <List.Content>
                    <List.Icon
                      link
                      name="delete"
                      onClick={() =>
                        this.setState({
                          deleteLayerOpen: true,
                          layerToDelete: l,
                        })
                      }
                    />
                    <Link to={`/manager/couche/${l.id}`}>
                      <strong>{l.position ?? "_"}</strong>&nbsp;-&nbsp;{l.name}
                    </Link>
                  </List.Content>
                </List.Item>
              ))}
          </List>
          <h5>Couches participatives</h5>
          <List>
            {task.layerSet
              .filter((l) => l.editable === true)
              .sort(positionSort)
              .map((l) => (
                <List.Item key={`layer-${l.id}`}>
                  <List.Icon
                    link
                    name="delete"
                    onClick={() =>
                      this.setState({
                        deleteLayerOpen: true,
                        layerToDelete: l,
                      })
                    }
                  />
                  <List.Content>
                    <Link to={`/manager/couche/${l.id}`}>
                      <strong>{l.position ?? "_"}</strong>&nbsp;-&nbsp;{l.name}
                    </Link>
                  </List.Content>
                </List.Item>
              ))}
          </List>

          <DeleteLayer
            layer={this.state.layerToDelete}
            taskId={task.id}
            modalOpen={this.state.deleteLayerOpen}
            closeDeleteLayer={this.closeDeleteLayer}
          />
        </Segment>

        <TaskInfos
          task={task}
          project={this.props.project}
          taskTypeOptions={taskTypeOptions}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
        />

        <TaskIllustration
          task={task}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
        />

        <TileLayerInfos
          task={task}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
        />

        <TaskGroups
          task={task}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
        />
        {task.taskType.code === TASK_TYPES.IDEAS ? (
          <TaskGeoJson
            task={task}
            editMode={this.state.editMode}
            toEditMode={this.toEditMode}
            toReadMode={this.toReadMode}
          />
        ) : null}
      </ManagerLayout>
    );
  }
}

const TaskWithRouter = withRouter(Task);

function TaskQuery({ match }) {
  const { loading, error, data } = useQuery(ADMIN_TASK, {
    variables: {
      taskId: match.params.taskId,
    },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <TaskWithRouter task={data.adminTask} taskTypeSet={data.adminTaskTypeSet} />
  );
}

export { TaskQuery as Task };
