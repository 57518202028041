// React
import React, { useState } from "react";

// React Router
import { Link, withRouter } from "react-router-dom";

// GraphQL
import { useMutation } from "@apollo/client";
import { ADMIN_DRAWING_SET } from "../../_GraphQL/queries";
import { ADMIN_CREATE_DRAWING } from "../../_GraphQL/mutations";

// Semantic ui
import {
  Button,
  Divider,
  Breadcrumb,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
  Loader,
} from "semantic-ui-react";

// Components
import ManagerLayout from "../../components/ManagerLayout";
import {
  addErrorToastMessage,
  getMessageTextFromApolloError,
} from "../../../../_GraphQL/message";

function CreateDrawing({ project, history }) {
  const [name, setName] = useState("");
  const [background, setBackground] = useState();

  const [adminCreateDrawing, { loading, error }] = useMutation(
    ADMIN_CREATE_DRAWING,
    {
      update(cache, { data: { adminCreateDrawing } }) {
        const { adminDrawingSet } = cache.readQuery({
          query: ADMIN_DRAWING_SET,
          variables: { projectId: project.id },
        });
        cache.writeQuery({
          query: ADMIN_DRAWING_SET,
          variables: { projectId: project.id },
          data: {
            adminDrawingSet: adminDrawingSet.concat(adminCreateDrawing.drawing),
          },
        });
      },
      onCompleted(data) {
        history.push(
          `/manager/dessin/dessin/${data.adminCreateDrawing.drawing.id}`
        );
      },
      onError(error) {
        addErrorToastMessage(getMessageTextFromApolloError(error));
      },
    }
  );

  return (
    <ManagerLayout
      breadcrumbItems={[
        <Breadcrumb.Section key="breadcrumb-home">
          <Link to="/manager">Manager</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key={`bc-divider-sites`} icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-sites">
          <Link to="/manager/sites">Sites</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key={`bc-divider-site`} icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-site">
          <Link to={`/manager/site/${project.site.id}`}>
            Site : {project.site.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key={`bc-divider-project`} icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-project">
          <Link to={`/manager/projet/${project.id}`}>
            Projet : {project.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key={`bc-divider-draw`} icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-draw">
          Création d'un dessin
        </Breadcrumb.Section>,
      ]}
    >
      <Segment secondary>
        <Header as="h5">Création d'un dessin</Header>
        <Divider />
        <Form
          error
          onSubmit={(e) => {
            e.preventDefault();
            adminCreateDrawing({
              variables: {
                name: name,
                projectId: project.id,
                background: background,
              },
            });
          }}
        >
          {error && <Message error header="Erreur" content={error.message} />}
          <Form.Field width={6}>
            <label>Nom</label>
            <input
              placeholder="Nom"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Fond</label>
            <input
              type="file"
              required
              onChange={({
                target: {
                  files: [file],
                },
              }) => {
                setBackground(file);
              }}
            />
          </Form.Field>
          {loading ? (
            <Loader />
          ) : (
            <Grid columns={1}>
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Button positive type="submit">
                    <Icon name="checkmark" /> Enregistrer
                  </Button>
                  <Link to={`/manager/site/${project.site.id}`}>
                    <Button negative>Annuler</Button>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Form>
      </Segment>
    </ManagerLayout>
  );
}

const CreateDrawingWithRouter = withRouter(CreateDrawing);

export { CreateDrawingWithRouter as CreateDrawing };
