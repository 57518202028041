import React, { useState, useEffect } from "react";
import { Form, Segment } from "semantic-ui-react";

function CircleShapeForm({ setShapeCircleOptions, initShapeOptions }) {
  const [shapeCircleOptionX, setShapeCircleOptionX] = useState(8); //: {number} (default: 0) - x offset of the circle.
  const [shapeCircleOptionY, setShapeCircleOptionY] = useState(8); //: {number} (default: 0) - y offset of the circle.
  const [shapeCircleOptionRadius, setShapeCircleOptionRadius] = useState(6); //: {number} (default: 0) - radius of the circle.

  // Initialisation
  useEffect(() => {
    if (initShapeOptions) {
      if (initShapeOptions.hasOwnProperty("x")) {
        setShapeCircleOptionX(initShapeOptions.x);
      }

      if (initShapeOptions.hasOwnProperty("y")) {
        setShapeCircleOptionY(initShapeOptions.y);
      }

      if (initShapeOptions.hasOwnProperty("radius")) {
        setShapeCircleOptionRadius(initShapeOptions.radius);
      }
    }
  }, [initShapeOptions]);

  useEffect(() => {
    setShapeCircleOptions({
      x: shapeCircleOptionX,
      y: shapeCircleOptionY,
      radius: shapeCircleOptionRadius,
    });
  }, [
    shapeCircleOptionX,
    shapeCircleOptionY,
    shapeCircleOptionRadius,
    setShapeCircleOptions,
  ]);

  return (
    <Segment secondary attached="bottom">
      <Form.Field>
        <label htmlFor="shape-circle-opt-x">
          Rayon : {shapeCircleOptionRadius}
        </label>
        <input
          id="shape-circle-opt-radius"
          type="range"
          min={0}
          max={20}
          step={1}
          value={shapeCircleOptionRadius}
          onChange={(e) =>
            setShapeCircleOptionRadius(parseInt(e.target.value, 10))
          }
        />
      </Form.Field>

      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="shape-circle-opt-x">
            Décalage X : {shapeCircleOptionX}
          </label>
          <input
            id="shape-circle-opt-x"
            type="range"
            min={0}
            max={20}
            step={1}
            value={shapeCircleOptionX}
            onChange={(e) =>
              setShapeCircleOptionX(parseInt(e.target.value, 10))
            }
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="shape-circle-opt-y">
            Décalage Y : {shapeCircleOptionY}
          </label>
          <input
            id="shape-circle-opt-y"
            type="range"
            min={0}
            max={20}
            step={1}
            value={shapeCircleOptionY}
            onChange={(e) =>
              setShapeCircleOptionY(parseInt(e.target.value, 10))
            }
          />
        </Form.Field>
      </Form.Group>
    </Segment>
  );
}

export { CircleShapeForm };
