// React
import React from "react";

// Semantic ui
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";

// Constants
import DrawingPermissionsQuery from "./DrawingPermissionsQuery";

class DrawingPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modificationsEnabled: false,
    };
  }

  handleCancel = () => {
    this.setState({ modificationsEnabled: false }, () =>
      this.props.toggleMode()
    );
  };

  handleCompleted = () => {
    this.setState({ modificationsEnabled: false }, () =>
      this.props.toggleMode()
    );
  };

  render() {
    return (
      <Segment secondary>
        <Header as="h5">
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column verticalAlign="middle">
                {this.props.target === "project"
                  ? "Autorisations par défaut pour les nouveaux dessins"
                  : "Autorisations"}
              </Grid.Column>
              <Grid.Column textAlign="right">
                {!this.props.editMode && !this.state.modificationsEnabled ? (
                  <Button
                    primary
                    floated="right"
                    onClick={() => {
                      this.setState({ modificationsEnabled: true }, () =>
                        this.props.toggleMode()
                      );
                    }}
                  >
                    Modifier
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <Divider />
        <DrawingPermissionsQuery
          editMode={this.props.editMode}
          toggleMode={this.props.toggleMode}
          project={this.props.project}
          drawing={this.props.drawing}
          editPermissions={this.state.modificationsEnabled}
          handleCancel={this.handleCancel}
          handleCompleted={this.handleCompleted}
          target={this.props.target}
        />
      </Segment>
    );
  }
}

export default DrawingPermissions;
