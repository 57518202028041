import React, { useEffect, useState } from "react";
import { MainMenu } from "./Common/components/MainMenu";
import MainContainer from "./Common/routes/Main";
import { isConnectedVar } from "./_GraphQL/graphQLClient";
import { checkJsonResponse, getCSRF } from "./utils/utils";
import { Message } from "semantic-ui-react";

function App() {
  const [error, setError] = useState(null);

  useEffect(() => {
    function getSession() {
      fetch("/api/session/", {
        credentials: "include",
      })
        .then(checkJsonResponse)
        .then((data) => {
          if (data.isAuthenticated) {
            isConnectedVar(true);
          } else {
            getCSRF((err) => {
              setError({
                header: "Erreur lors de la récupération du jeton csrf",
                body: err,
              });
            });
          }
        })
        .catch((err) => {
          setError({
            header: "Erreur lors de la récupération de la session",
            body: err,
          });
        });
    }
    getSession();
  }, []);

  return (
    <>
      {error ? (
        <Message negative onDismiss={() => setError(false)}>
          <Message.Header>{error.header}</Message.Header>
          <p>{error.body.toString()}</p>
        </Message>
      ) : null}
      <MainMenu />
      <MainContainer />
    </>
  );
}

export default App;
