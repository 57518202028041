// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_WEBSITE } from "../../_GraphQL/queries";

// Semantic ui
import { Loader } from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";

function SiteQuery({ match, render, ...rest }) {
  const { loading, error, data } = useQuery(GET_WEBSITE, {
    variables: { siteId: match.params.siteId },
  });

  if (loading) return <Loader />;

          if (error) return <ErrorMessage error={error} />;

  return render({
    ...rest,
    website: data.website,
  });
}

export default SiteQuery;
