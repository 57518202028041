import React from "react";
import { useReactiveVar } from "@apollo/client";
import { isConnectedVar, menuDisabledVar } from "../../_GraphQL/graphQLClient";
import { useHistory } from "react-router-dom";
import { ApolloConsumer } from "@apollo/client";
import { Menu, Container } from "semantic-ui-react";
import DisableableLink from "./DisableableLink";
import { addErrorToastMessage } from "../../_GraphQL/message";
import { getCSRF } from "../../utils/utils";

function Logout({ client, menuDisabled }) {
  const history = useHistory();
  function logout() {
    fetch("/api/logout", {
      credentials: "include",
    })
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson ? await response.json() : null;

        if (!response.ok) {
          const error = (data && data.detail) || response.status;
          return Promise.reject(error);
        }
        getCSRF((err) => {
          addErrorToastMessage({
            header: "Erreur lors de la récupération du jeton csrf",
            body: err,
          });
        });
        isConnectedVar(false);
        client.clearStore().then(() => {
          client.resetStore();
          history.push("/");
        });
      })
      .catch((err) => {
        addErrorToastMessage({
          header: "Erreur lors de la tentative de déconnexion",
          body: err,
        });
      });
  }
  return (
    <Menu.Menu position="right">
      <Menu.Item
        name="Déconnexion"
        onClick={() => {
          if (!menuDisabled) {
            logout();
          }
        }}
        disabled={menuDisabled}
      />
    </Menu.Menu>
  );
}

function MainMenu() {
  const isAuthenticated = useReactiveVar(isConnectedVar);
  const menuDisabled = useReactiveVar(menuDisabledVar);

  if (isAuthenticated) {
    return (
      <Menu className="no-bottom">
        <Container>
          <Menu.Item
            key="accueil-menu-item"
            as={DisableableLink}
            to="/"
            name="accueil"
            disabled={menuDisabled}
            menuDisabled={menuDisabled}
          />
          <Menu.Item
            key="manager-menu-item"
            as={DisableableLink}
            to="/manager"
            name="Tableau de bord"
            disabled={menuDisabled}
            menuDisabled={menuDisabled}
          />
          <ApolloConsumer>
            {(client) => <Logout client={client} />}
          </ApolloConsumer>
        </Container>
      </Menu>
    );
  }

  return null;
}

export { MainMenu };
