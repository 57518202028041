// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// Semantic ui
import {
  Breadcrumb,
  Header,
  Grid,
  Reveal,
  Image,
  Segment,
  List,
} from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";
import ProjectInfos from "../../components/Project/ProjectInfos";
import ProjectIllustration from "../../components/Project/ProjectIllustration";
import { ProjectPlanning } from "../../components/Project/ProjectPlanning";
import ProjectDelete from "../../components/Project/ProjectDelete";
import ProjectImportUsers from "../../components/Project/ProjectImportUsers";
import { ProjectOpenSignIn } from "../../components/Project/ProjectOpenSignIn";
import { ProjectSite } from "../../components/Project/ProjectSite";

// Medias
import feuille from "../../../../medias/feuille.svg";
import depliant from "../../../../medias/depliant.svg";
import maisons from "../../../../medias/maisons.svg";
import fleche from "../../../../medias/fleche.svg";
import { SITE_NAMES } from "../../../../utils/Constants";
import { useQuery } from "@apollo/client";
import { ADMIN_GROUP_SET } from "../../_GraphQL/queries";

function GetGroups({ siteId, children }) {
  const { loading, error, data } = useQuery(ADMIN_GROUP_SET, {
    variables: { siteId: siteId },
  });

  if (loading || error || !data) return null;

  return children(data.adminGroupSet);
}

class Project extends React.Component {
  state = {
    editMode: false,
  };

  toReadMode = () => this.setState({ editMode: false });

  toEditMode = () => this.setState({ editMode: true });

  render() {
    let { project } = this.props;
    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-sites"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-sites">
            <Link to="/manager/sites">Sites</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-site"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-site">
            <Link to={`/manager/site/${project.site.id}`}>
              Site : {project.site.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-project"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-project">
            Projet : {project.name}
          </Breadcrumb.Section>,
        ]}
      >
        <Header as="h4" block>
          Gestion des modules du projet «&nbsp;{project.name}&nbsp;»
        </Header>
        {project.site.moduleSet.filter((m) => m.name === "filemanager").length >
        0 ? (
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Header as="h2" textAlign="left">
                    Gestionnaire de fichiers
                  </Header>
                  <List as="ol">
                    <List.Item as="li" value="-">
                      créer un espace de stockage de fichiers pour un projet
                    </List.Item>
                    <List.Item as="li" value="-">
                      gérer les droits selon le rôle des utilisateurs
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Reveal animated="move" instant>
                    <Reveal.Content visible>
                      <Link to={`/manager/fichiers/?projectId=${project.id}`}>
                        <Image src={feuille} size="tiny" />
                      </Link>
                    </Reveal.Content>
                    <Reveal.Content hidden>
                      <Link to={`/manager/fichiers/?projectId=${project.id}`}>
                        <Image src={fleche} size="tiny" />
                      </Link>
                    </Reveal.Content>
                  </Reveal>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ) : null}
        {project.site.moduleSet.filter((m) => m.name === "participate").length >
        0 ? (
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Header as="h2" textAlign="left">
                    Carte collaborative
                  </Header>
                  <List as="ol">
                    <List.Item as="li" value="-">
                      possibilité de créer plusieurs cartes (tâches) pour un
                      projet
                    </List.Item>
                    <List.Item as="li" value="-">
                      faire figurer des couches informatives ou à compléter
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Reveal animated="move" instant>
                    <Reveal.Content visible>
                      <Link to={`/manager/carte/?projectId=${project.id}`}>
                        <Image src={depliant} size="tiny" />
                      </Link>
                    </Reveal.Content>
                    <Reveal.Content hidden>
                      <Link to={`/manager/carte/?projectId=${project.id}`}>
                        <Image src={fleche} size="tiny" />
                      </Link>
                    </Reveal.Content>
                  </Reveal>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ) : null}
        {project.site.moduleSet.filter((m) => m.name === "draw").length > 0 ? (
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column width={13}>
                  <Header as="h2" textAlign="left">
                    Outil d&apos;annotation
                  </Header>
                  <List as="ol">
                    <List.Item as="li" value="-">
                      possibilité de créer plusieurs esquisses à annoter pour un
                      projet
                    </List.Item>
                    <List.Item as="li" value="-">
                      gérer les droits selon le rôle de chaque utilisateur
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Reveal animated="move" instant>
                    <Reveal.Content visible>
                      <Link to={`/manager/dessin/?projectId=${project.id}`}>
                        <Image src={maisons} size="tiny" />
                      </Link>
                    </Reveal.Content>
                    <Reveal.Content hidden>
                      <Link to={`/manager/dessin/?projectId=${project.id}`}>
                        <Image src={fleche} size="tiny" />
                      </Link>
                    </Reveal.Content>
                  </Reveal>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ) : null}

        <Header as="h4" block>
          Gestion du projet «&nbsp;{project.name}&nbsp;»
        </Header>

        <GetGroups siteId={project.site.id}>
          {(groups) => (
            <ProjectInfos
              project={project}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
              editMode={this.state.editMode}
              groups={groups}
            />
          )}
        </GetGroups>

        <ProjectIllustration
          project={project}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
          editMode={this.state.editMode}
        />
        {project.site.moduleSet.filter((m) => m.name === "planning").length >
        0 ? (
          <ProjectPlanning
            project={project}
            toEditMode={this.toEditMode}
            toReadMode={this.toReadMode}
            editMode={this.state.editMode}
          />
        ) : null}

        <ProjectImportUsers
          project={project}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
          editMode={this.state.editMode}
        />

        {project.site.name === SITE_NAMES.ICI ? (
          <ProjectOpenSignIn
            project={project}
            editMode={this.state.editMode}
            toEditMode={this.toEditMode}
            toReadMode={this.toReadMode}
          />
        ) : null}

        <ProjectSite
          project={project}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
          editMode={this.state.editMode}
        />

        <ProjectDelete
          project={project}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
          editMode={this.state.editMode}
        />
      </ManagerLayout>
    );
  }
}

export { Project };
