import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { STYLE_FRAG } from "../../../../Common/_GraphQL/fragments";
import { CREATE_STYLE } from "../../_GraphQL/mutations";
import { PATTERN_TYPES } from "../../../../utils/Constants";
import { StyleForm } from "./Form";

export function CreateStyle({
  geoTypeCode,
  setGeoTypeCode,
  pathOptions,
  setPathOptions,
  usePattern,
  setUsePattern,
  patternOptions,
  setPatternOptions,
  patternTypeCode,
  setPatternTypeCode,
  shapeOptions,
  setShapeOptions,
}) {
  const history = useHistory();
  const [createStyle, { loading, error }] = useMutation(CREATE_STYLE, {
    update(cache, { data: { createStyle } }) {
      cache.modify({
        fields: {
          styleSet(existingStyles = []) {
            const newStyleRef = cache.writeFragment({
              data: createStyle.style,
              fragment: STYLE_FRAG,
            });
            return [...existingStyles, newStyleRef];
          },
        },
      });
    },
    onCompleted(data) {
      history.push(
        `/manager/styles?focus=${data.createStyle.style.id}&type=${data.createStyle.style.geoTypeCode}`
      );
    },
    onError() {},
  });

  function submit() {
    if (!pathOptions.dashArray) {
      delete pathOptions.dashArray;
    }

    if (!pathOptions.stroke) {
      delete pathOptions.color;
      delete pathOptions.opacity;
      delete pathOptions.weight;
      delete pathOptions.dashArray;
    }

    if (!pathOptions.fill) {
      delete pathOptions.fillColor;
      delete pathOptions.fillOpacity;
    }

    let variables = {
      geoTypeCode: geoTypeCode,
      usePattern: usePattern,
    };

    if (usePattern) {
      delete pathOptions.fillColor;
      variables.patternTypeCode = patternTypeCode;

      if (patternTypeCode !== PATTERN_TYPES.STRIPES) {
        if (!shapeOptions.fill) {
          delete shapeOptions.fillColor;
          delete shapeOptions.fillOpacity;
        }
        if (!shapeOptions.stroke) {
          delete shapeOptions.color;
          delete shapeOptions.weight;
          delete shapeOptions.opacity;
          delete shapeOptions.dashArray;
        }
        variables.shapeOptions = JSON.stringify(shapeOptions);
      } else {
        delete patternOptions.width;
      }
      variables.patternOptions = JSON.stringify(patternOptions);
    }

    variables.pathOptions = JSON.stringify(pathOptions);

    createStyle({
      variables: variables,
    });
  }

  return (
    <StyleForm
      submit={submit}
      error={error}
      loading={loading}
      geoTypeCode={geoTypeCode}
      setGeoTypeCode={setGeoTypeCode}
      setPathOptions={setPathOptions}
      usePattern={usePattern}
      setUsePattern={setUsePattern}
      setPatternOptions={setPatternOptions}
      patternTypeCode={patternTypeCode}
      setPatternTypeCode={setPatternTypeCode}
      setShapeOptions={setShapeOptions}
    />
  );
}
