// React
import React, { useEffect } from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import {
  GET_ASSIGNED_AND_AVAILABLE_PROJECTS_FOR_USER,
  GET_ASSIGNED_AND_AVAILABLE_SITES_FOR_USER,
} from "../../../_GraphQL/queries";
import { ASSIGN_UNASSIGN_PROJECT_TO_USER } from "../../../_GraphQL/mutations";

// Semantic ui
import {
  Loader,
  Form,
  Grid,
  Header,
  Segment,
  Select,
  Icon,
  List,
} from "semantic-ui-react";

// Component
import ErrorMessage from "../../../../../Common/components/ErrorMessage";
import { EditSegment } from "../../../../../Common/components/EditSegment";

// Constants
import { COMPONENTS } from "../../../../../utils/Constants";

// Message
import { addSuccessMessage } from "../../../../../_GraphQL/message";

const NO_SITE_SELECTED = "NO_SITE_SELECTED";
const DEFAULT_OPTION = {
  key: NO_SITE_SELECTED,
  value: NO_SITE_SELECTED,
  text: "Choisir un site",
};

const projectStates = {
  noChange: 0,
  add: 1,
  remove: 2,
};

function Fields({
  modificationsEnabled,
  projectsAvailable,
  projectsAssigned,
  addProject,
  removeProject,
}) {
  return (
    <>
      <Grid columns={2}>
        <Grid.Row>
          {modificationsEnabled ? (
            <Grid.Column>
              <Header as="h5" attached="top">
                Projets disponibles
              </Header>
              <Segment attached>
                <List selection>
                  {projectsAvailable.length === 0 ? (
                    <List.Item>Ce site ne possède aucune projet.</List.Item>
                  ) : null}
                  {projectsAvailable.map((s) => (
                    <List.Item
                      key={`project-${s.id}`}
                      onClick={() => addProject(s)}
                    >
                      <List.Content floated="right">
                        <span>
                          Affecter
                          <Icon name="angle right" color="orange" />
                        </span>
                      </List.Content>
                      <List.Content>{s.name}</List.Content>
                    </List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          ) : null}
          <Grid.Column>
            <Header as="h5" attached="top">
              Projets affectés
            </Header>
            <Segment attached>
              <List selection={modificationsEnabled}>
                {projectsAssigned.length === 0 ? (
                  <List.Item>
                    Aucun projet affecté pour cette personne.
                  </List.Item>
                ) : null}
                {projectsAssigned.map((s) => {
                  if (modificationsEnabled) {
                    return (
                      <List.Item
                        key={`project-${s.id}`}
                        onClick={() => removeProject(s)}
                      >
                        <List.Content floated="right">{s.name}</List.Content>
                        <span>
                          <Icon name="angle left" color="orange" />
                          Désaffecter
                        </span>
                      </List.Item>
                    );
                  } else {
                    return (
                      <List.Item key={`project-${s.id}`}>{s.name}</List.Item>
                    );
                  }
                })}
              </List>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

function UserProjectsQuery({
  user,
  siteId,
  modificationsEnabled,
  projectsAvailable,
  projectsAssigned,
  addProject,
  removeProject,
  setAssignedAndAvailableProjects,
}) {
  const { loading, error, data } = useQuery(
    GET_ASSIGNED_AND_AVAILABLE_PROJECTS_FOR_USER,
    {
      variables: {
        userId: user.id,
        siteId: siteId,
      },
    }
  );

  useEffect(() => {
    if (data) {
      setAssignedAndAvailableProjects(data);
    }
  }, [data, setAssignedAndAvailableProjects]);

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <Fields
      modificationsEnabled={modificationsEnabled}
      projectsAvailable={projectsAvailable}
      projectsAssigned={projectsAssigned}
      addProject={addProject}
      removeProject={removeProject}
    />
  );
}

function Selector({
  user,
  modificationsEnabled,
  assignedSiteSet,
  projectsAvailable,
  projectsAssigned,
  addProject,
  removeProject,
  setAssignedAndAvailableProjects,
  selectedSiteId,
  setSelectedSiteId,
}) {
  return (
    <>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Form.Group inline>
              <label>Veuillez sélectionner un site pour voir ses projets</label>
              <Form.Field disabled={modificationsEnabled}>
                <Select
                  placeholder="Site"
                  options={[DEFAULT_OPTION].concat(
                    assignedSiteSet.map((s) => ({
                      key: s.id,
                      value: s.id,
                      text: s.name,
                    }))
                  )}
                  value={selectedSiteId}
                  onChange={(_, { value }) => setSelectedSiteId(value)}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {selectedSiteId === NO_SITE_SELECTED ? null : (
        <UserProjectsQuery
          user={user}
          siteId={selectedSiteId}
          modificationsEnabled={modificationsEnabled}
          projectsAvailable={projectsAvailable}
          projectsAssigned={projectsAssigned}
          addProject={addProject}
          removeProject={removeProject}
          setAssignedAndAvailableProjects={setAssignedAndAvailableProjects}
        />
      )}
    </>
  );
}

class UserProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modificationsEnabled: false,
      selectedSiteId: NO_SITE_SELECTED,
      projectsAssigned: [],
      projectsAvailable: [],
    };
    this.toEditMode = this.toEditMode.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.update = this.update.bind(this);
    this.handleCompleted = this.handleCompleted.bind(this);
    this.setAssignedAndAvailableProjects =
      this.setAssignedAndAvailableProjects.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.addProject = this.addProject.bind(this);
    this.removeProject = this.removeProject.bind(this);
  }

  cancelEditProjects = () =>
    this.setState({ modificationsEnabled: false }, () =>
      this.props.toggleMode()
    );

  handleSubmit(mutate) {
    mutate({
      variables: {
        userId: this.props.user.id,
        siteId: this.state.selectedSiteId,
        projectsToAssign: this.state.projectsAssigned
          .filter((s) => s.projectState === projectStates.add)
          .map((s) => s.id),
        projectsToUnassign: this.state.projectsAvailable
          .filter((s) => s.projectState === projectStates.remove)
          .map((s) => s.id),
      },
    });
  }

  update(cache, { data: { adminAssignUnassignProjectToUser } }) {
    cache.writeQuery({
      query: GET_ASSIGNED_AND_AVAILABLE_PROJECTS_FOR_USER,
      variables: {
        userId: this.props.user.id,
        siteId: this.state.selectedSiteId,
      },
      data: {
        adminAvailableProjectSet:
          adminAssignUnassignProjectToUser.adminAvailableProjectSet,
        adminAssignedProjectSet:
          adminAssignUnassignProjectToUser.adminAssignedProjectSet,
      },
    });
  }

  handleCompleted() {
    addSuccessMessage({
      location: COMPONENTS.MANAGER.MANAGER,
      header: "Projets mis à jour",
      content: "Les projets de l'utisateurs ont été mis à jours avec succès.",
    });
    this.setState({ modificationsEnabled: false }, () =>
      this.props.toggleMode()
    );
  }

  toEditMode() {
    if (!this.props.editMode) {
      this.props.toggleMode();
    }
  }

  handleCancel() {
    this.setState({ modificationsEnabled: false });
    if (this.props.editMode) {
      this.props.toggleMode();
    }
  }

  setAssignedAndAvailableProjects(data) {
    this.setState({
      projectsAvailable: data.adminAvailableProjectSet.map((p) => ({
        id: p.id,
        name: p.name,
        projectState: projectStates.noChange,
      })),
      projectsAssigned: data.adminAssignedProjectSet.map((p) => ({
        id: p.id,
        name: p.name,
        projectState: projectStates.noChange,
      })),
    });
  }

  addProject(project) {
    if (project.projectState === projectStates.noChange) {
      project.projectState = projectStates.add;
    }
    if (project.projectState === projectStates.remove) {
      project.projectState = projectStates.noChange;
    }
    this.setState({
      projectsAssigned: this.state.projectsAssigned.concat([project]),
      projectsAvailable: this.state.projectsAvailable.filter(
        (s) => s.id !== project.id
      ),
    });
  }

  removeProject(project) {
    if (project.projectState === projectStates.noChange) {
      project.projectState = projectStates.remove;
    }
    if (project.projectState === projectStates.add) {
      project.projectState = projectStates.noChange;
    }
    this.setState({
      projectsAvailable: this.state.projectsAvailable.concat([project]),
      projectsAssigned: this.state.projectsAssigned.filter(
        (s) => s.id !== project.id
      ),
    });
  }

  render() {
    return (
      <EditSegment
        title="Projets"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_PROJECT_TO_USER}
        update={this.update}
        onCompleted={this.handleCompleted}
        handleSubmit={this.handleSubmit}
        toEditMode={this.toEditMode}
        handleCancel={this.handleCancel}
        editMode={
          this.props.editMode || this.state.selectedSiteId === NO_SITE_SELECTED
        }
      >
        {(enabled) => (
          <Selector
            user={this.props.user}
            assignedSiteSet={this.props.data.adminAssignedSiteSet}
            projectsAvailable={this.state.projectsAvailable}
            projectsAssigned={this.state.projectsAssigned}
            addProject={this.addProject}
            removeProject={this.removeProject}
            setAssignedAndAvailableProjects={
              this.setAssignedAndAvailableProjects
            }
            modificationsEnabled={enabled}
            selectedSiteId={this.state.selectedSiteId}
            setSelectedSiteId={(siteId) =>
              this.setState({ selectedSiteId: siteId })
            }
          />
        )}
      </EditSegment>
    );
  }
}

function UserSitesQuery({ user, editMode, toggleMode }) {
  const { loading, error, data } = useQuery(
    GET_ASSIGNED_AND_AVAILABLE_SITES_FOR_USER,
    {
      variables: { userId: user.id },
    }
  );

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  if (data.adminAssignedSiteSet.length === 0) return null;

  return (
    <UserProjects
      data={data}
      user={user}
      editMode={editMode}
      toggleMode={toggleMode}
    />
  );
}

export { UserSitesQuery as UserProjects };
