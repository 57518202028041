// React
import React, { useState } from "react";

// GraphQL
import { ADMIN_UPDATE_USER } from "../../../_GraphQL/mutations";
import { ADMIN_USER } from "../../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";

// Constants
import { USER_TYPES } from "../../../../../utils/Constants";

// Semantic ui
import { Form, Dropdown } from "semantic-ui-react";

const natureOptions = [
  {
    key: USER_TYPES.LEGAL_PERSON,
    value: USER_TYPES.LEGAL_PERSON,
    text: "Personne morale",
  },
  {
    key: USER_TYPES.NATURAL_PERSON,
    value: USER_TYPES.NATURAL_PERSON,
    text: "Personne physique",
  },
];

function Fields({
  email,
  handleMailChange,
  nature,
  handleNatureChange,
  name,
  handleNameChange,
  firstName,
  handleFirstNameChange,
  lastName,
  handleLastNameChange,
  modificationsEnabled,
}) {
  return (
    <>
      <Form.Field>
        <label>Adresse email</label>
        <input
          type="text"
          required
          value={email}
          onChange={handleMailChange}
          disabled={!modificationsEnabled}
        />
      </Form.Field>
      <Form.Field>
        <label>Type</label>
        <Dropdown
          selection
          options={natureOptions}
          value={nature}
          onChange={handleNatureChange}
          disabled={!modificationsEnabled}
        />
      </Form.Field>
      {nature === USER_TYPES.LEGAL_PERSON ? (
        <Form.Field>
          <label>Raison sociale</label>
          <input
            type="text"
            required
            value={name}
            onChange={handleNameChange}
            disabled={!modificationsEnabled}
          />
        </Form.Field>
      ) : (
        <>
          <Form.Field>
            <label>Prénom</label>
            <input
              type="text"
              required
              value={firstName}
              onChange={handleFirstNameChange}
              disabled={!modificationsEnabled}
            />
          </Form.Field>
          <Form.Field>
            <label>Nom</label>
            <input
              type="text"
              required
              value={lastName}
              onChange={handleLastNameChange}
              disabled={!modificationsEnabled}
            />
          </Form.Field>
        </>
      )}
    </>
  );
}

function UserInfos({ user, toggleMode, editMode }) {
  let initName = "";
  if (
    user.profile &&
    user.profile.nature.code === USER_TYPES.LEGAL_PERSON &&
    user.profile.name
  ) {
    initName = user.profile.name;
  }
  const [firstName, setFirstName] = useState(user.firstName || "");
  const [lastName, setLastName] = useState(user.lastName || "");
  const [email, setEmail] = useState(user.email);
  const [name, setName] = useState(initName);
  const [nature, setNature] = useState(
    user.profile ? user.profile.nature.code : USER_TYPES.NATURAL_PERSON
  );

  function handleSubmit(updateUser) {
    updateUser({
      variables: {
        userId: user.id,
        email: email,
        nature: nature,
        firstName: firstName,
        lastName: lastName,
        name: name,
      },
    });
  }

  function update(cache, { data: { adminUpdateUser } }) {
    cache.writeQuery({
      query: ADMIN_USER,
      variables: {
        userId: user.id,
      },
      data: { adminUser: adminUpdateUser.user },
    });
  }

  function handleCompleted() {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setName(initName);
    setNature(
      user.profile ? user.profile.nature.code : USER_TYPES.NATURAL_PERSON
    );
    if (editMode) {
      toggleMode();
    }
  }

  function toEditMode() {
    if (!editMode) {
      toggleMode();
    }
  }

  function handleCancel() {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setName(initName);
    setNature(
      user.profile ? user.profile.nature.code : USER_TYPES.NATURAL_PERSON
    );
    toggleMode();
  }

  return (
    <EditSegment
      title="Informations générales"
      buttonText="Modifier"
      mutation={ADMIN_UPDATE_USER}
      update={update}
      onCompleted={handleCompleted}
      handleSubmit={handleSubmit}
      toEditMode={toEditMode}
      handleCancel={handleCancel}
      editMode={editMode}
    >
      {(enabled) => (
        <Fields
          email={email}
          handleMailChange={(e) => setEmail(e.target.value)}
          nature={nature}
          handleNatureChange={(_, { value }) => setNature(value)}
          name={name}
          handleNameChange={(e) => setName(e.target.value)}
          firstName={firstName}
          handleFirstNameChange={(e) => setFirstName(e.target.value)}
          lastName={lastName}
          handleLastNameChange={(e) => setLastName(e.target.value)}
          modificationsEnabled={enabled}
        />
      )}
    </EditSegment>
  );
}

export { UserInfos };
