export const nantesIslandLatLngs = [
  [
    {
      lng: -1.56259,
      lat: 47.209774,
    },
    {
      lng: -1.572555,
      lat: 47.206975,
    },
    {
      lng: -1.578053,
      lat: 47.200211,
    },
    {
      lng: -1.57393,
      lat: 47.199511,
    },
    {
      lng: -1.556404,
      lat: 47.199161,
    },
    {
      lng: -1.544205,
      lat: 47.199977,
    },
    {
      lng: -1.536474,
      lat: 47.20266,
    },
    {
      lng: -1.525821,
      lat: 47.206742,
    },
    {
      lng: -1.520838,
      lat: 47.210357,
    },
    {
      lng: -1.525305,
      lat: 47.214205,
    },
    {
      lng: -1.539223,
      lat: 47.212106,
    },
    {
      lng: -1.54936,
      lat: 47.208607,
    },
    {
      lng: -1.56259,
      lat: 47.209774,
    },
  ],
];

export const nantesLineCoord = [
  {
    lat: 47.214598196276306,
    lng: -1.5674614906311037,
  },
  {
    lat: 47.21458362105744,
    lng: -1.5661954879760744,
  },
  {
    lat: 47.21506460116485,
    lng: -1.5634918212890627,
  },
  {
    lat: 47.21593909927426,
    lng: -1.5639209747314455,
  },
  {
    lat: 47.21857708119804,
    lng: -1.5661740303039553,
  },
  {
    lat: 47.21848963638659,
    lng: -1.5644145011901858,
  },
  {
    lat: 47.21866452586528,
    lng: -1.5635561943054201,
  },
  {
    lat: 47.218853988816214,
    lng: -1.5633630752563477,
  },
  {
    lat: 47.21933493018963,
    lng: -1.5634918212890627,
  },
  {
    lat: 47.220238505214816,
    lng: -1.56231164932251,
  },
  {
    lat: 47.22034052078248,
    lng: -1.5619254112243655,
  },
  {
    lat: 47.219174616883244,
    lng: -1.5613245964050293,
  },
  {
    lat: 47.21787751866836,
    lng: -1.5591359138488772,
  },
  {
    lat: 47.21730911731686,
    lng: -1.5596723556518555,
  },
  {
    lat: 47.217046776176446,
    lng: -1.5594363212585451,
  },
  {
    lat: 47.21566217643329,
    lng: -1.5609169006347658,
  },
  {
    lat: 47.215501852022264,
    lng: -1.5608096122741701,
  },
  {
    lat: 47.21612857196378,
    lng: -1.5564966201782229,
  },
  {
    lat: 47.21497715056071,
    lng: -1.5560674667358398,
  },
  {
    lat: 47.21405891051022,
    lng: -1.5552735328674319,
  },
  {
    lat: 47.212863717104845,
    lng: -1.5540289878845217,
  },
  {
    lat: 47.21280541429848,
    lng: -1.5536427497863772,
  },
  {
    lat: 47.213286410534174,
    lng: -1.5523982048034668,
  },
];

export const nantesLine = [
  {
    lat: 47.19840265794471,
    lng: -1.5882968902587893,
  },
  {
    lat: 47.19997723868725,
    lng: -1.589326858520508,
  },
  {
    lat: 47.20878236586453,
    lng: -1.5871810913085938,
  },
  {
    lat: 47.214262965228855,
    lng: -1.5858936309814453,
  },
  {
    lat: 47.21787751866836,
    lng: -1.5878677368164062,
  },
  {
    lat: 47.22656309922327,
    lng: -1.5829753875732424,
  },
  {
    lat: 47.22761226765389,
    lng: -1.5819454193115234,
  },
  {
    lat: 47.22802027199088,
    lng: -1.5805721282958984,
  },
  {
    lat: 47.22953568917481,
    lng: -1.5724182128906252,
  },
  {
    lat: 47.23326576226855,
    lng: -1.5657234191894533,
  },
];
