// React
import React from "react";

// React Routers
import { Link } from "react-router-dom";

// Semantic ui
import { Header, Grid, List, Image, Reveal, Segment } from "semantic-ui-react";

// Medias
import carte from "../../../medias/carte.svg";
import portraits from "../../../medias/portraits.svg";
import liste from "../../../medias/liste.svg";
import fleche from "../../../medias/fleche.svg";
import style from "../../../medias/style.svg";

export function Home() {
  return (
    <>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <Header as="h2" textAlign="left">
                Les projets
              </Header>
              <List as="ol">
                <List.Item as="li" value="-">
                  créer un projet pour un nouveau territoire
                </List.Item>
                <List.Item as="li" value="-">
                  associer des outils à un projet : fichiers partagés / carte
                  collaborative / outil d&apos;annotation
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Reveal animated="move" instant>
                <Reveal.Content visible>
                  <Link to={"manager/sites"}>
                    <Image src={carte} size="tiny" />
                  </Link>
                </Reveal.Content>
                <Reveal.Content hidden>
                  <Link to={"manager/sites"}>
                    <Image src={fleche} size="tiny" />
                  </Link>
                </Reveal.Content>
              </Reveal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <Header as="h2" textAlign="left">
                Les utilisateurs
              </Header>
              <List as="ol">
                <List.Item as="li" value="-">
                  identifier les utilisateurs associés aux projets
                </List.Item>
                <List.Item as="li" value="-">
                  gérer le profil et le rôle de chaque utilisateur
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Reveal animated="move" instant>
                <Reveal.Content visible>
                  <Link to={"manager/utilisateurs"}>
                    <Image src={portraits} size="tiny" />
                  </Link>
                </Reveal.Content>
                <Reveal.Content hidden>
                  <Link to={"manager/utilisateurs"}>
                    <Image src={fleche} size="tiny" />
                  </Link>
                </Reveal.Content>
              </Reveal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <Header as="h2" textAlign="left">
                Les questionnaires
              </Header>
              <List as="ol">
                <List.Item as="li" value="-">
                  créer un formulaire-type : champs, valeurs possibles (liste
                  déroulante, case à cocher, autre)
                </List.Item>
                <List.Item as="li" value="-">
                  gérer les formulaires existants
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Reveal animated="move" instant>
                <Reveal.Content visible>
                  <Link to={"manager/questionnaires"}>
                    <Image src={liste} size="tiny" />
                  </Link>
                </Reveal.Content>
                <Reveal.Content hidden>
                  <Link to={"manager/questionnaires"}>
                    <Image src={fleche} size="tiny" />
                  </Link>
                </Reveal.Content>
              </Reveal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <Header as="h2" textAlign="left">
                Les styles
              </Header>
              <List as="ol">
                <List.Item as="li" value="-">
                  Créer des styles
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Reveal animated="move" instant>
                <Reveal.Content visible>
                  <Link to={"manager/styles"}>
                    <Image src={style} size="tiny" />
                  </Link>
                </Reveal.Content>
                <Reveal.Content hidden>
                  <Link to={"manager/styles"}>
                    <Image src={fleche} size="tiny" />
                  </Link>
                </Reveal.Content>
              </Reveal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}
