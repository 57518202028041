import React from "react";
import { PolyPath } from "./PolyPath";
import { PolyPattern } from "./PolyPattern";

function SvgPolyBasePattern({
  styleId,
  pathOptions,
  patternOptions,
  children,
}) {
  const uid = `stylePreview${styleId}`;
  const fill = `url(#${uid})`;

  return (
    <svg width="100" height="65">
      <g>
        <PolyPath pathOptions={pathOptions} fill={fill} />
      </g>
      <defs>
        <PolyPattern uid={uid} patternOptions={patternOptions}>
          {children}
        </PolyPattern>
      </defs>
    </svg>
  );
}

export { SvgPolyBasePattern };
