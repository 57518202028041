// EQUery : Enhanced Query
// Ce composant permet de ne pas réécrire la gestion du chargement et la gestion des erreurs
// à chaque appel du composant Apollo Query
// La technique utilisé est "render props".

// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";

// Semantic ui
import { Loader, Dimmer, Segment } from "semantic-ui-react";

// Components
import { handleError } from "./ErrorSender";

const EQuery = ({
  children,
  query,
  variables,
  fetchPolicy = "cache-first",
}) => {
  const { loading, error, data } = useQuery(query, { variables, fetchPolicy });

  if (loading) {
    return (
      <Segment>
        <Dimmer active inverted>
          <Loader>Chargement en cours...</Loader>
        </Dimmer>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Segment>
    );
  }

  if (error) {
    handleError(error);
    return null;
  }

  return children(data);
};

export default EQuery;
