import { StyleButton } from "./StyleButton";

const INLINE_STYLES = [
  { label: "Gras", style: "BOLD" },
  { label: "Italique", style: "ITALIC" },
  { label: "Souligné", style: "UNDERLINE" },
];

export function InlineStyleControls({ editorState, onToggle }) {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}
