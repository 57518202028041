import React, { useState, useEffect } from "react";
import { PATTERN_TYPES } from "../../../../../../utils/Constants";
import { BasePatternForm } from "./BasePatternForm";
import { StripesPatternForm } from "./StripesPatternForm";

function PatternForm({ initPatternOpt, patternTypeCode, setPatternOptions }) {
  const [basePatternOptions, setBasePatternOptions] = useState({});
  const [baseHeight, setBaseHeight] = useState(16); //: {number} (default: 8) - The height of the pattern.

  const [stripesPatternOptions, setStripesPatternOptions] = useState({});
  const [stripesWeight, setStripesWeight] = useState(8); //: {number} (default: 4) - The width of the primary stripe.
  const [stripesSpaceWeight, setStripesSpaceWeight] = useState(8); //: {number} (default: 4) - The width of the secondaty stripe, typically an empty space.

  useEffect(() => {
    if (initPatternOpt) {
      if (initPatternOpt.hasOwnProperty("height")) {
        setBaseHeight(initPatternOpt.height);
      }

      if (initPatternOpt.hasOwnProperty("weight")) {
        setStripesWeight(initPatternOpt.weight);
      }

      if (initPatternOpt.hasOwnProperty("spaceWeight")) {
        setStripesSpaceWeight(initPatternOpt.spaceWeight);
      }
    }
  }, [initPatternOpt]);

  useEffect(() => {
    if (patternTypeCode === PATTERN_TYPES.STRIPES) {
      setPatternOptions({
        ...basePatternOptions,
        ...stripesPatternOptions,
      });
    } else {
      setPatternOptions(basePatternOptions);
    }
  }, [
    basePatternOptions,
    stripesPatternOptions,
    setPatternOptions,
    patternTypeCode,
  ]);

  return (
    <>
      <BasePatternForm
        basePatternOptHeight={baseHeight}
        setBasePatternOptHeight={setBaseHeight}
        stripePatternOptWeight={stripesWeight}
        setStripePatternOptWeight={setStripesWeight}
        stripePatternOptSpaceWeight={stripesSpaceWeight}
        setStripePatternOptSpaceWeight={setStripesSpaceWeight}
        setOptions={setBasePatternOptions}
        initPatternOpt={initPatternOpt}
        patternTypeCode={patternTypeCode}
      />

      {patternTypeCode === PATTERN_TYPES.STRIPES ? (
        <StripesPatternForm
          initPatternOpt={initPatternOpt}
          basePatternOptHeight={baseHeight}
          weight={stripesWeight}
          setWeight={setStripesWeight}
          spaceWeight={stripesSpaceWeight}
          setSpaceWeight={setStripesSpaceWeight}
          setOptions={setStripesPatternOptions}
        />
      ) : null}
    </>
  );
}

export { PatternForm };
