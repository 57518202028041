// React
import React, { useState, useEffect } from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_LISTS_FOR_FILTERS } from "../../_GraphQL/queries";

// React Router
import { Link } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";

// Semantic ui
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
  Grid,
  Loader,
} from "semantic-ui-react";

// Constants
import { QUESTIONNAIRE_FILTER } from "../../../../utils/Constants";

// Components
import ManagerLayout from "../../components/ManagerLayout";
import { QuestionnaireFilter } from "../../components/Questionnaire/QuestionnaireFilter";
import { QuestionnaireList } from "../../components/Questionnaire/QuestionnaireList";
import ErrorMessage from "../../../../Common/components/ErrorMessage";

function QuestionnaireSet({ listForFilters }) {
  const [orphan, setOrphan] = useQueryParam("orphan", StringParam);
  const [siteId, setSiteId] = useQueryParam("siteId", StringParam);
  const [projectId, setProjectId] = useQueryParam("projectId", StringParam);
  const [taskId, setTaskId] = useQueryParam("taskId", StringParam);
  const [search, setSearch] = useState("");
  const [projectSet, setProjectSet] = useState([]);
  const [taskSet, setTaskSet] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setOrphan(searchParams.get("orphan") || QUESTIONNAIRE_FILTER.NON_ORPHELIN);
  }, [setOrphan]);

  useEffect(() => {
    if (!siteId) {
      setProjectId(null);
      setProjectSet([]);
    } else {
      const site = listForFilters.siteSet.find((s) => s.id === siteId);
      setProjectSet(site.projectSet);
      if (site.projectSet.length === 0) {
        setProjectId(null);
      } else if (site.projectSet.length === 1) {
        setProjectId(site.projectSet[0].id);
      }
    }
  }, [siteId, setProjectId, setProjectSet, listForFilters.siteSet]);

  useEffect(() => {
    if (!projectId) {
      setTaskId(null);
      setTaskSet([]);
    } else if (projectSet.length !== 0) {
      const project = projectSet.find((p) => p.id === projectId);
      if (project) {
        setTaskSet(project.taskSet);
        if (project.taskSet.length === 0) {
          setTaskId(null);
        } else if (project.taskSet.length === 1) {
          setTaskId(project.taskSet[0].id);
        }
      }
    }
  }, [projectId, projectSet, setTaskId, setTaskSet, listForFilters.siteSet]);

  function handleOrphanChange(_, { value }) {
    setOrphan(value);
    // A remplacer par un useEffect sur orphan ?
    if (value !== QUESTIONNAIRE_FILTER.NON_ORPHELIN) {
      setSiteId(undefined);
      setProjectId(undefined);
      setTaskId(undefined);
    }
  }

  let sites = [{ key: null, value: null, text: "Tous les sites" }];

  sites = sites.concat(
    listForFilters.siteSet.map((s) => ({
      key: s.id,
      value: s.id,
      text: s.name,
    }))
  );

  return (
    <ManagerLayout
      breadcrumbItems={[
        <Breadcrumb.Section key="breadcrumb-home">
          <Link to="/manager">Manager</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-sites" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-sites" active>
          Questionnaires
        </Breadcrumb.Section>,
      ]}
    >
      <Segment secondary>
        <Header as="h5">
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column verticalAlign="middle">
                Liste des questionnaires disponibles sur la plateforme
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Link
                  to={`/manager/questionnaire/nouveau?orphan=${orphan}&siteId=${siteId}&projectId=${projectId}&taskId=${taskId}`}
                >
                  <Button primary>Ajouter un questionnaire</Button>
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>

        <Divider />

        <QuestionnaireFilter
          orphan={orphan}
          handleOrphanChange={handleOrphanChange}
          siteId={siteId}
          handleSiteChange={(_, { value }) => {
            setSiteId(value);
            setProjectId(null);
          }}
          sites={sites}
          projectId={projectId}
          handleProjectChange={(_, { value }) => {
            setProjectId(value);
            setTaskId(null);
          }}
          projectSet={projectSet}
          taskId={taskId}
          handleTaskChange={(_, { value }) => setTaskId(value)}
          taskSet={taskSet}
          handleSearchChange={(e) => setSearch(e.target.value)}
        />

        <QuestionnaireList
          search={search}
          orphan={orphan}
          siteId={siteId}
          projectId={projectId}
          taskId={taskId}
        />
      </Segment>
    </ManagerLayout>
  );
}

function QuestionnaireManagerQuery({ setBreadcrumbItems }) {
  const { loading, error, data } = useQuery(GET_LISTS_FOR_FILTERS);

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <QuestionnaireSet
      listForFilters={data}
      setBreadcrumbItems={setBreadcrumbItems}
    />
  );
}

export { QuestionnaireManagerQuery as QuestionnaireSet };
