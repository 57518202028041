// React
import React from "react";

// Semantic ui
import { Form, Table } from "semantic-ui-react";

// Components
import RootFolderGroupPermissionsFormRow from "./RootFolderGroupPermissionsFormRow";

class RootFolderGroupPermissionsForm extends React.Component {
  render() {
    return (
      <Form.Field disabled={this.props.disabled}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Groupe</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Accès en lecture
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Peut ajouter des dossiers
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Peut ajouter des documents
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.groupPermissionsRows.map((groupPermissionsRow) => (
              <RootFolderGroupPermissionsFormRow
                key={`table-row-group-${groupPermissionsRow.group.name}`}
                groupPermissionsRow={groupPermissionsRow}
                handleCheckChange={this.props.handleCheckChange}
              />
            ))}
          </Table.Body>
        </Table>
      </Form.Field>
    );
  }
}

export default RootFolderGroupPermissionsForm;
