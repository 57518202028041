import React, { useEffect, useState, useRef } from "react";
import {
  Segment,
  Header,
  Grid,
  Button,
  Divider,
  Icon,
  Form,
  Dropdown,
  Table,
  Message,
  Modal,
  Dimmer,
  Loader,
  Ref,
} from "semantic-ui-react";
import { OPEN_SIGN_IN_FRAG } from "../../_GraphQL/fragments";
import { ADMIN_GET_OPEN_SIGN_IN_SET_AND_GROUP_SET } from "../../_GraphQL/queries";
import {
  ADMIN_CREATE_OPEN_SIGN_IN,
  ADMIN_UPDATE_OPEN_SIGN_IN,
  ADMIN_DELETE_OPEN_SIGN_IN,
} from "../../_GraphQL/mutations";
import { useMutation, useQuery } from "@apollo/client";
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { isElementOutViewport } from "../../../../utils/utils";
import { Wysiwyg } from "../../../../Common/components/Wysiwyg";
import { WysiwygReader } from "../../../../Common/components/WysiwygReader";

// Gestion du scroll pour recentre
// Gestion des états exterieur
// Récupération et affichage de la liste
// Gestion des états d'ajouts modification

const MODES = {
  READ: "READ",
  UPDATE: "UPDATE",
  CREATE: "CREATE",
};

const scrollIntoViewOptions = {
  behavior: "smooth",
  block: "start",
};

function ProjectOpenSignInCreate({
  baseUrl,
  groupOptions,
  projectId,
  slugExist,
  toReadMode,
}) {
  const [createOpenSignIn, { loading, error }] = useMutation(
    ADMIN_CREATE_OPEN_SIGN_IN,
    {
      onError() {},
      update(cache, { data: { adminCreateOpenSignIn } }) {
        cache.modify({
          fields: {
            adminGetOpenSignInSet(existingOpenSignInSet = []) {
              const newOpenSignInRef = cache.writeFragment({
                data: adminCreateOpenSignIn.openSignIn,
                fragment: OPEN_SIGN_IN_FRAG,
              });
              return [...existingOpenSignInSet, newOpenSignInRef];
            },
          },
        });
      },
      onCompleted() {
        toReadMode();
      },
    }
  );

  const [slug, setSlug] = useState("");
  const [groupId, setGroupId] = useState(-1);
  const [formError, setFormError] = useState([]);

  if (loading) return null;

  return (
    <>
      {error ? (
        <Table.Row>
          <Table.Cell colSpan={4}>
            <ErrorMessage error={error} />
          </Table.Cell>
        </Table.Row>
      ) : null}
      {formError.length > 0 ? (
        <Table.Row>
          <Table.Cell colSpan={4}>
            <Message error>
              <Message.Header>{`Le formulaire comporte ${
                formError.length === 1 ? "une erreur" : "des erreurs"
              }`}</Message.Header>
              <Message.List>
                {formError.map((err) => (
                  <Message.Item key={err}>{err}</Message.Item>
                ))}
              </Message.List>
            </Message>
          </Table.Cell>
        </Table.Row>
      ) : null}
      <Table.Row active verticalAlign="bottom">
        <Table.Cell>
          {baseUrl + slug}
          <input
            value={slug}
            required
            pattern="[a-z\-]+"
            onChange={(e) => setSlug(e.target.value)}
          />
        </Table.Cell>
        <Table.Cell>
          <Dropdown
            selection
            options={groupOptions}
            value={groupId}
            onChange={(e, { value }) => setGroupId(value)}
            required
          />
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Button color="yellow" icon onClick={() => toReadMode()}>
            <Icon name="undo" />
          </Button>
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Button
            color="green"
            icon
            onClick={() => {
              let invalid = [];
              if (!slug) {
                invalid.push("L'url ne peut pas être vide.");
              }
              if (groupId === -1) {
                invalid.push("Un groupe doit être sélectionné.");
              }

              if (slugExist(null, slug)) {
                invalid.push("Ce slug est déjà utilisé.");
              }

              if (invalid.length === 0) {
                setFormError([]);
                createOpenSignIn({
                  variables: {
                    projectId: projectId,
                    slug: slug,
                    groupId: groupId,
                  },
                });
              } else {
                setFormError(invalid);
                invalid = [];
              }
            }}
          >
            <Icon name="check" />
          </Button>
        </Table.Cell>
      </Table.Row>
    </>
  );
}

function ProjectOpenSignInUpdate({
  baseUrl,
  groupOptions,
  openSignIn,
  slugExist,
  toReadMode,
}) {
  const [updateOpenSignIn, { loading, error }] = useMutation(
    ADMIN_UPDATE_OPEN_SIGN_IN,
    {
      onError() {},
      update(cache, { data: { adminUpdateOpenSignIn } }) {
        cache.modify({
          id: `OpenSignInType:${openSignIn.id}`,
          fields: {
            slug() {
              return adminUpdateOpenSignIn.openSignIn.slug;
            },
            group() {
              return adminUpdateOpenSignIn.openSignIn.groip;
            },
          },
        });
      },
      onCompleted() {
        toReadMode();
      },
    }
  );

  const [slug, setSlug] = useState("");
  const [groupId, setGroupId] = useState(-1);
  const [formError, setFormError] = useState([]);
  const [notice, setNotice] = useState("");
  const [welcome, setWelcome] = useState(null);

  useEffect(() => setSlug(openSignIn.slug), [openSignIn.slug]);
  useEffect(() => setGroupId(openSignIn.group.id), [openSignIn.group.id]);
  useEffect(() => setWelcome(openSignIn.welcome), [openSignIn.welcome]);

  if (loading) return null;

  return (
    <>
      {error ? (
        <Table.Row>
          <Table.Cell colSpan={4}>
            <ErrorMessage error={error} />
          </Table.Cell>
        </Table.Row>
      ) : null}

      {formError.length > 0 ? (
        <Table.Row>
          <Table.Cell colSpan={4}>
            <Message error>
              <Message.Header>{`Le formulaire comporte ${
                formError.length === 1 ? "une erreur" : "des erreurs"
              }`}</Message.Header>
              <Message.List>
                {formError.map((err) => (
                  <Message.Item key={err}>{err}</Message.Item>
                ))}
              </Message.List>
            </Message>
          </Table.Cell>
        </Table.Row>
      ) : null}
      <Table.Row active verticalAlign="bottom">
        <Table.Cell>
          {baseUrl + slug}
          <input
            value={slug}
            required
            pattern="[a-z\-]+"
            onChange={(e) => setSlug(e.target.value)}
          />
          <br />
          Notice (pour accompagner les utilisateurs à l'arrivée sur la page
          d'inscription)
          <input
            type="file"
            onChange={({
              target: {
                files: [file],
              },
            }) => setNotice(file)}
          />
          <Segment inverted>
            <label>Message de bienvenue</label>
            <Wysiwyg
              placeholder="Description"
              value={openSignIn.welcome}
              onChange={(e) => setWelcome(e)}
            />
          </Segment>
        </Table.Cell>
        <Table.Cell>
          <Dropdown
            options={groupOptions}
            value={groupId}
            onChange={(e, { value }) => setGroupId(value)}
            required
          />
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Button icon color="yellow" onClick={() => toReadMode()}>
            <Icon name="undo" />
          </Button>
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Button
            icon
            onClick={() => {
              let invalid = [];
              if (!slug) {
                invalid.push("L'url ne peut pas être vide.");
              }
              if (groupId === -1) {
                invalid.push("Un groupe doit être sélectionné.");
              }

              if (slugExist(openSignIn.id, slug)) {
                invalid.push("Ce slug est déjà utilisé.");
              }

              if (invalid.length === 0) {
                setFormError([]);
                updateOpenSignIn({
                  variables: {
                    openSignInId: openSignIn.id,
                    slug: slug,
                    groupId: groupId,
                    notice: notice,
                    welcome: welcome,
                  },
                });
              } else {
                setFormError(invalid);
                invalid = [];
              }
            }}
            color="green"
          >
            <Icon name="check" />
          </Button>
        </Table.Cell>
      </Table.Row>
    </>
  );
}

function DeleteModal({ deleteId, setDeleteId }) {
  const [doDelete, { loading, error }] = useMutation(
    ADMIN_DELETE_OPEN_SIGN_IN,
    {
      variables: { openSignInId: deleteId },
      onError() {},
      update(cache, { data: { adminDeleteOpenSignIn } }) {
        cache.modify({
          fields: {
            adminGetOpenSignInSet(existingOpenSignInRefs, { readField }) {
              return existingOpenSignInRefs.filter((openSignInRef) => {
                return (
                  readField("id", openSignInRef) !==
                  adminDeleteOpenSignIn.openSignInId.toString()
                );
              });
            },
          },
        });
      },
      onCompleted() {
        setDeleteId(-1);
      },
    }
  );
  return (
    <Modal open={deleteId !== -1} onClose={() => setDeleteId(-1)}>
      <Modal.Header>Confirmation suppression</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          {loading ? (
            <Dimmer active>
              <Loader />
            </Dimmer>
          ) : null}
          {error ? <ErrorMessage error={error} /> : null}
          <p>Souhaitez-vous supprimer l'inscription ouverte ?</p>
        </Modal.Description>
      </Modal.Content>
      {loading ? null : (
        <Modal.Actions>
          <Button color="black" onClick={() => setDeleteId(-1)}>
            Annuler
          </Button>
          <Button
            content="Confirmer"
            labelPosition="right"
            icon="checkmark"
            onClick={() => doDelete()}
            positive
          />
        </Modal.Actions>
      )}
    </Modal>
  );
}

function ProjectOpenSignInRow({
  baseUrl,
  groupOptions,
  openSignIn,
  setDeleteId,
  setSiblingsMode,
  siblingsMode,
  slugExist,
  toEditMode,
  toReadMode,
}) {
  const [mode, setMode] = useState(MODES.READ);

  if (mode === MODES.READ) {
    return (
      <Table.Row disabled={siblingsMode !== MODES.READ}>
        <Table.Cell>
          {baseUrl + openSignIn.slug}
          <br />
          Texte de bienvenue :<br />
          <WysiwygReader raw={openSignIn.welcome} />
        </Table.Cell>
        <Table.Cell>{openSignIn.group.name}</Table.Cell>
        <Table.Cell textAlign="center">
          {siblingsMode === MODES.READ ? (
            <>
              <Button
                icon
                onClick={() => setDeleteId(openSignIn.id)}
                color="red"
              >
                <Icon name="delete" />
              </Button>
            </>
          ) : null}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {siblingsMode === MODES.READ ? (
            <Button
              icon
              onClick={() => {
                setMode(MODES.UPDATE);
                setSiblingsMode(MODES.UPDATE);
                toEditMode();
              }}
              color="blue"
            >
              <Icon name="edit" />
            </Button>
          ) : null}
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <ProjectOpenSignInUpdate
      baseUrl={baseUrl}
      groupOptions={groupOptions}
      openSignIn={openSignIn}
      slugExist={slugExist}
      toReadMode={() => {
        setMode(MODES.READ);
        setSiblingsMode(MODES.READ);
        toReadMode();
      }}
    />
  );
}

function ProjectOpenSignIn({ project, editMode, toEditMode, toReadMode }) {
  const segmentRef = useRef();
  const [mode, setMode] = useState(MODES.READ);
  const [deleteId, setDeleteId] = useState(-1);
  const { loading, error, data } = useQuery(
    ADMIN_GET_OPEN_SIGN_IN_SET_AND_GROUP_SET,
    {
      variables: {
        projectId: project.id,
        siteId: project.site.id,
      },
    }
  );

  if (loading) return null;

  if (error) return <ErrorMessage error={error} />;

  const baseUrl = `${project.site.domain}/${project.slug}/inscription/`;

  const groupOptions = [
    {
      key: -1,
      text: "Sélection groupe",
      value: -1,
    },
    ...data.adminGroupSet.map((group) => ({
      key: group.id,
      text: group.name,
      value: group.id,
    })),
  ];

  function slugExist(id, slug) {
    const otherSlugs = data.adminGetOpenSignInSet
      .filter((openSignIn) => openSignIn.id !== id)
      .map((openSignIn) => openSignIn.slug);
    return otherSlugs.includes(slug);
  }

  if (
    mode !== MODES.READ &&
    segmentRef.current &&
    isElementOutViewport(segmentRef.current)
  ) {
    var rect = segmentRef.current.getBoundingClientRect();
    if (rect.height > window.innerHeight) {
      scrollIntoViewOptions.block = "start";
    } else {
      scrollIntoViewOptions.block = "end";
    }
    segmentRef.current.scrollIntoView(scrollIntoViewOptions);
  }
  const style =
    mode !== MODES.READ
      ? { boxShadow: "0px 0px 10px 8px rgba(116,132,145,1)" }
      : null;
  return (
    <Ref innerRef={segmentRef}>
      <Segment secondary style={style}>
        <Header as="h5">
          <Grid columns={2}>
            <Grid.Row style={{ minHeight: "42px" }}>
              <Grid.Column verticalAlign="middle">
                Inscriptions ouvertes
                <br />
                Le slug doit être renseigné au niveau des informations du
                projet.
                <br />
                Ex : pour une consultation auprès des habitants choisir
                &laquo;consultation&raquo; dans le champ url et sélectionner le
                groupe habitant.
                <br />
                L'url complète sera alors
                https://ici.realta-inno.fr/bret-rom/inscription/consultation
              </Grid.Column>
              <Grid.Column textAlign="right">
                {!editMode && mode === MODES.READ ? (
                  <Button
                    primary
                    floated="right"
                    onClick={() => {
                      setMode(MODES.CREATE);
                      toEditMode();
                    }}
                  >
                    Ajouter
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <Divider />
        <Form error onSubmit={(e) => e.preventDefault()}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={8}>Url</Table.HeaderCell>
                <Table.HeaderCell width={4}>Groupe</Table.HeaderCell>
                <Table.HeaderCell
                  width={2}
                  textAlign="center"
                ></Table.HeaderCell>
                <Table.HeaderCell width={2}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.adminGetOpenSignInSet.map((openSignIn) => (
                <ProjectOpenSignInRow
                  key={`OpenSignInRow-${openSignIn.id}`}
                  baseUrl={baseUrl}
                  groupOptions={groupOptions}
                  openSignIn={openSignIn}
                  setDeleteId={setDeleteId}
                  setSiblingsMode={setMode}
                  siblingsMode={mode}
                  slugExist={slugExist}
                  toEditMode={toEditMode}
                  toReadMode={toReadMode}
                />
              ))}
              {mode === MODES.CREATE ? (
                <ProjectOpenSignInCreate
                  baseUrl={baseUrl}
                  groupOptions={groupOptions}
                  projectId={project.id}
                  slugExist={slugExist}
                  toReadMode={() => {
                    setMode(MODES.READ);
                    toReadMode();
                  }}
                />
              ) : null}
            </Table.Body>
          </Table>
        </Form>
        <DeleteModal deleteId={deleteId} setDeleteId={setDeleteId} />
      </Segment>
    </Ref>
  );
}

export { ProjectOpenSignIn };
