import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import { StyleSet } from "../../Modules/Manager/components/Styles/StyleSet";

function StyleSelector({ initStyle, setStyle, geoTypeCode }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeStyle, setActiveStyle] = useState();

  useEffect(() => {
    if (!activeStyle && initStyle) {
      setActiveStyle(initStyle);
    }
  }, [initStyle, activeStyle]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      onOpen={() => setModalOpen(true)}
      trigger={
        <Button onClick={(e) => e.preventDefault()}>
          Sélectionner un style{" "}
        </Button>
      }
    >
      <StyleSet
        activeStyle={activeStyle}
        setActiveStyle={setActiveStyle}
        geoTypeCode={geoTypeCode}
      />
      <Form>
        <Button
          primary
          onClick={() => {
            setStyle(activeStyle);
            setModalOpen(false);
          }}
        >
          Sélectionner
        </Button>
        <Button
          secondary
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Annuler
        </Button>
      </Form>
    </Modal>
  );
}

export { StyleSelector };
