import { StyleButton } from "./StyleButton";

const BLOCK_TYPES = [
  { label: "Titre 1", style: "header-one" },
  { label: "Titre 2", style: "header-two" },
  { label: "Titre 3", style: "header-three" },
  { label: "Citation", style: "blockquote" },
  { label: "Liste à puces", style: "unordered-list-item" },
  { label: "Liste numérotée", style: "ordered-list-item" },
];

export function BlockStyleControls({ editorState, onToggle }) {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}
