// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_USER } from "../../../_GraphQL/queries";
import { ASSIGN_UNASSIGN_GROUP_TO_USER } from "../../../_GraphQL/mutations";

// Semantic ui
import { Loader, Grid, Header, Icon, Segment, List } from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../../Common/components/ErrorMessage";
import { EditSegment } from "../../../../../Common/components/EditSegment";

const groupStates = {
  noChange: "noChange",
  add: "add",
  remove: "remove",
};

function Fields({
  groupsAvailable,
  groupsAssigned,
  enabled,
  addGroup,
  removeGroup,
}) {
  return (
    <Grid columns={2}>
      <Grid.Row>
        {enabled ? (
          <Grid.Column>
            <Header as="h5" attached="top">
              Groupes disponibles
            </Header>
            <Segment attached>
              <List selection>
                {groupsAvailable.map((s) => (
                  <List.Item key={`group-${s.id}`} onClick={() => addGroup(s)}>
                    <List.Content floated="right">
                      <span>
                        Affecter
                        <Icon name="angle right" color="orange" />
                      </span>
                    </List.Content>
                    <List.Content>{s.name}</List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>
          </Grid.Column>
        ) : null}
        <Grid.Column>
          <Header as="h5" attached="top">
            Groupes affectés
          </Header>
          <Segment attached>
            <List selection={enabled}>
              {groupsAssigned.length === 0 ? (
                <List.Item>Aucun groupe affecté pour cette personne.</List.Item>
              ) : null}
              {groupsAssigned.map((s) => {
                if (enabled) {
                  return (
                    <List.Item
                      key={`group-${s.id}`}
                      onClick={() => removeGroup(s)}
                    >
                      <List.Content floated="right">{s.name}</List.Content>
                      <span>
                        <Icon name="angle left" color="orange" />
                        Désaffecter
                      </span>
                    </List.Item>
                  );
                } else {
                  return <List.Item key={`group-${s.id}`}>{s.name}</List.Item>;
                }
              })}
            </List>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

class UserGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modificationsEnabled: false,
      groupsAvailable: props.data.adminAvailableGroupSet.map((s) => ({
        id: s.id,
        name: s.name,
        groupState: groupStates.noChange,
      })),
      groupsAssigned: props.data.adminAssignedGroupSet.map((s) => ({
        id: s.id,
        name: s.name,
        groupState: groupStates.noChange,
      })),
    };

    this.toEditMode = this.toEditMode.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCompleted = this.handleCompleted.bind(this);
    this.update = this.update.bind(this);
  }

  addGroup = (group) => {
    if (group.groupState === groupStates.noChange) {
      group.groupState = groupStates.add;
    }

    if (group.groupState === groupStates.remove) {
      group.groupState = groupStates.noChange;
    }

    this.setState({
      groupsAvailable: this.state.groupsAvailable.filter(
        (s) => s.id !== group.id
      ),
      groupsAssigned: this.state.groupsAssigned.concat([group]),
    });
  };

  removeGroup = (group) => {
    if (group.groupState === groupStates.noChange) {
      group.groupState = groupStates.remove;
    }
    if (group.groupState === groupStates.add) {
      group.groupState = groupStates.noChange;
    }
    this.setState({
      groupsAvailable: this.state.groupsAvailable.concat([group]),
      groupsAssigned: this.state.groupsAssigned.filter(
        (s) => s.id !== group.id
      ),
    });
  };

  handleCancel() {
    this.setState(
      {
        modificationsEnabled: false,
        groupsAvailable: this.props.data.adminAvailableGroupSet.map((s) => ({
          id: s.id,
          name: s.name,
          groupState: groupStates.noChange,
        })),
        groupsAssigned: this.props.data.adminAssignedGroupSet.map((s) => ({
          id: s.id,
          name: s.name,
          groupState: groupStates.noChange,
        })),
      },
      () => this.props.toggleMode()
    );
  }

  handleCompleted(data) {
    this.setState(
      {
        modificationsEnabled: false,
      },
      () => this.props.toggleMode()
    );
  }

  update(cache, { data: { adminAssignUnassignGroupToUser } }) {
    cache.writeQuery({
      query: GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_USER,
      variables: {
        userId: this.props.user.id,
      },
      data: {
        adminAvailableGroupSet:
          adminAssignUnassignGroupToUser.adminAvailableGroupSet,
        adminAssignedGroupSet:
          adminAssignUnassignGroupToUser.adminAssignedGroupSet,
      },
    });
  }

  handleSubmit(mutate) {
    mutate({
      variables: {
        userId: this.props.user.id,
        groupsToUnassign: this.state.groupsAvailable
          .filter((s) => s.groupState === groupStates.remove)
          .map((s) => s.id),
        groupsToAssign: this.state.groupsAssigned
          .filter((s) => s.groupState === groupStates.add)
          .map((s) => s.id),
      },
    });
  }

  toEditMode() {
    if (!this.props.editMode) {
      this.props.toggleMode();
    }
  }

  render() {
    return (
      <EditSegment
        title="Groupes"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_GROUP_TO_USER}
        update={this.update}
        onCompleted={this.handleCompleted}
        handleSubmit={this.handleSubmit}
        toEditMode={this.toEditMode}
        handleCancel={this.handleCancel}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            groupsAvailable={this.state.groupsAvailable}
            groupsAssigned={this.state.groupsAssigned}
            addGroup={this.addGroup}
            removeGroup={this.removeGroup}
            enabled={enabled}
          />
        )}
      </EditSegment>
    );
  }
}

function UserGroupsQuery({ user, editMode, toggleMode }) {
  const { loading, error, data, client } = useQuery(
    GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_USER,
    {
      variables: {
        userId: user.id,
      },
    }
  );

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <UserGroups
      data={data}
      user={user}
      client={client}
      editMode={editMode}
      toggleMode={toggleMode}
    />
  );
}

export { UserGroupsQuery as UserGroups };
