import React, { useState } from "react";
import { Icon, Modal, Button, Dimmer, Loader } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { ErrorMessage } from "../../../../Common/components/ErrorMessage";
import { DELETE_STYLE } from "../../_GraphQL/mutations";

export function DeleteStyle({ style }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteStyle, { loading, error }] = useMutation(DELETE_STYLE, {
    variables: {
      styleId: style.id,
    },
    update(cache) {
      cache.evict({ id: cache.identify(style) });
      cache.gc();
    },
    onCompleted() {
      setModalOpen(false);
    },
  });

  return (
    <>
      <Modal
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
        trigger={
          <Button icon>
            <Icon name="delete" />
          </Button>
        }
      >
        <Modal.Header>Confirmation suppresion</Modal.Header>
        <Modal.Content image>
          Confirmez-vous la suppression du style ?
          {error ? <ErrorMessage error={error} /> : null}
        </Modal.Content>
        {loading ? (
          <Dimmer>
            <Loader />
          </Dimmer>
        ) : (
          <Modal.Actions>
            <Button color="black" onClick={() => setModalOpen(false)}>
              Non
            </Button>
            <Button
              content="Oui"
              labelPosition="right"
              icon="checkmark"
              onClick={() => deleteStyle()}
              positive
            />
          </Modal.Actions>
        )}
      </Modal>
    </>
  );
}
