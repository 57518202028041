// React
import React from "react";

// React Router
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

// GraphQL
import EQuery from "../../../utils/EQuery";
import { GET_PROJECT } from "../../../Common/_GraphQL/queries";

// Semantic ui
import { Breadcrumb, Header, Segment } from "semantic-ui-react";

// Component
import RootFolder from "../components/FileManager/RootFolder/RootFolder";
import DefaultGroupPermissions from "../components/FileManager/DefaultGroupPermissions/DefaultGroupPermissions";

// Types
import { ProjectType } from "../../../_GraphQL/typesDefinition";

type Data = {
  project: ProjectType;
};

type Props = {
  project: ProjectType;
};

type State = {
  editMode: boolean;
};

class FileManagerLayout extends React.Component<Props, State> {
  state = {
    editMode: false,
  };

  toggleMode = () =>
    this.setState((prevState) => ({ editMode: !prevState.editMode }));

  render() {
    return (
      <>
        <Segment>
          <Breadcrumb>
            <Breadcrumb.Section key="breadcrumb-home">
              <Link to="/manager">Manager</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={`bc-divider-sites`} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-sites">
              <Link to="/manager/sites">Sites</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={`bc-divider-site`} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-site">
              <Link to={`/manager/site/${this.props.project.site.id}`}>
                Site : {this.props.project.site.name}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={`bc-divider-project`} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-project">
              <Link to={`/manager/projet/${this.props.project.id}`}>
                Projet : {this.props.project.name}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider
              key={`bc-divider-filemanager`}
              icon="right angle"
            />
            <Breadcrumb.Section key="breadcrumb-filemanager">
              Module : Gestionnaire de fichiers
            </Breadcrumb.Section>
          </Breadcrumb>
        </Segment>

        <Header as="h4" block>
          Paramètres du gestionnaire de fichiers pour le projet « 
          {this.props.project.name} »
        </Header>

        <RootFolder
          project={this.props.project}
          editMode={this.state.editMode}
          toggleMode={this.toggleMode}
        />

        <DefaultGroupPermissions
          project={this.props.project}
          editMode={this.state.editMode}
          toggleMode={this.toggleMode}
          fileType="folder"
        />

        <DefaultGroupPermissions
          project={this.props.project}
          editMode={this.state.editMode}
          toggleMode={this.toggleMode}
          fileType="document"
        />
      </>
    );
  }
}

// Composant qui gère le gestionnaire de fichier pour un projet donné
// Si le projet n'est pas renseigné, redirection vers le choix des sites

const FileManager = () => {
  let params = new URLSearchParams(window.location.search);
  let projectId = params.get("projectId");

  if (!projectId) {
    return <Redirect to="/manager/sites" />;
  }

  return (
    <EQuery
      query={GET_PROJECT}
      variables={{ projectId: projectId }}
    >
      {(data: Data) => <FileManagerLayout project={data.project} />}
    </EQuery>
  );
};

export { FileManager };
