// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";

// Semantic ui
import { Loader } from "semantic-ui-react";
import {
  FOLDER_GRP_PERM_SET_SITE_GRP_SET,
  ADMIN_GROUP_SET,
} from "../../../_GraphQL/queries";

// Components
import RootFolderForm from "./RootFolderForm";
import RootFolderMutation from "./RootFolderMutation";
import ErrorMessage from "../../../../../Common/components/ErrorMessage";

// Constants
import { FILEMANAGER_PERMISSIONS } from "../../../../../utils/Constants";

function RootFolder(props) {
  const { loading, error, data } = useQuery(props.query, {
    variables: props.variables,
  });

  if (loading) return <Loader />;

  if (error)
    return (
      <ErrorMessage
        header={"Erreur lors du chargement des permissions."}
        error={error}
      />
    );

  const { folderId } = props;

  let groups = data.adminGroupSet;

  let initialGroupPermissionsRows = [];

  if (props.modificationsEnabled) {
    groups.forEach((group) => {
      let initialGroupPermissionsRow = {
        group: group,
        canViewFolder: false,
        canAddFolder: false,
        canAddDocument: false,
      };

      if (data.adminFolderGroupPermissionSet) {
        data.adminFolderGroupPermissionSet
          .filter((groupPermission) => groupPermission.groupId === group.id)
          .forEach((groupPermission) => {
            const perm_codename = groupPermission.permission.toString();
            let perm =
              FILEMANAGER_PERMISSIONS[
                perm_codename.substring(0, perm_codename.indexOf(folderId))
              ];
            initialGroupPermissionsRow[perm] = true;
          });
      }

      initialGroupPermissionsRows.push(initialGroupPermissionsRow);
    });

    return (
      <RootFolderMutation
        groupPermissionsRows={initialGroupPermissionsRows}
        project={props.project}
        toReadMode={props.toReadMode}
        refetch={props.refetch}
        folderId={folderId}
      />
    );
  }

  let groupPermissionsRows = [];

  if (props.project.rootFolder) {
    data.adminFolderGroupPermissionSet.forEach((groupPermission) => {
      if (
        groupPermissionsRows.find((i) => i.group.id === groupPermission.groupId)
      ) {
        const perm_codename = groupPermission.permission.toString();
        groupPermissionsRows.find(
          (i) => i.group.id === groupPermission.groupId
        )[
          FILEMANAGER_PERMISSIONS[
            perm_codename.substring(0, perm_codename.indexOf(folderId))
          ]
        ] = true;
      } else {
        const group = groups.find((g) => g.id === groupPermission.groupId);
        if (group) {
          groupPermissionsRows.push({
            group: groups.find((g) => g.id === groupPermission.groupId),
            canViewFolder:
              groupPermission.permission === "view_folder_" + folderId,
            canAddFolder:
              groupPermission.permission === "add_folder_to_folder_" + folderId,
            canAddDocument:
              groupPermission.permission ===
              "add_document_to_folder_" + folderId,
          });
        }
      }
    });
  }

  return (
    <RootFolderForm
      project={props.project}
      rootFolderName={
        props.project.rootFolder ? props.project.rootFolder.name : null
      }
      groupPermissionsRows={groupPermissionsRows}
      modificationsEnabled={props.modificationsEnabled}
    />
  );
}

function RootFolderQuery(props) {
  let query, variables;
  let folderId;

  if (props.project.rootFolder) {
    folderId = props.project.rootFolder.id;
    query = FOLDER_GRP_PERM_SET_SITE_GRP_SET;
    variables = {
      folderId: folderId,
      siteId: props.project.site.id,
    };
  } else {
    query = ADMIN_GROUP_SET;
    variables = { siteId: props.project.site.id };
  }

  return (
    <RootFolder
      query={query}
      variables={variables}
      folderId={folderId}
      {...props}
    />
  );
}

export default RootFolderQuery;
