import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Message } from "semantic-ui-react";
import { Viewer } from "../../components/Styles/Viewer";
import { UpdateStyle } from "../../components/Styles/UpdateStyle";
import LoaderDisplayer from "../../../../Common/components/LoaderDisplayer";
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { useQuery } from "@apollo/client";
import { GET_STYLE } from "../../_GraphQL/queries";
import {
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  PATTERN_TYPES,
} from "../../../../utils/Constants";

function Update({
  styleId,
  initGeoTypeCode,
  initPathOptions,
  initUsePattern,
  initPatternOptions,
  initPatternTypeCode,
  initShapeOptions,
  usingLayerSet,
  usingQuestionnaireFieldLabelSet,
}) {
  const [geoTypeCode, setGeoTypeCode] = useState(initGeoTypeCode);
  const [usePattern, setUsePattern] = useState(initUsePattern);
  const [patternTypeCode, setPatternTypeCode] = useState(initPatternTypeCode);

  const [pathOptions, setPathOptions] = useState({});
  const [patternOptions, setPatternOptions] = useState({});
  const [shapeOptions, setShapeOptions] = useState({});

  useEffect(() => {
    setGeoTypeCode(initGeoTypeCode);
  }, [initGeoTypeCode]);

  return (
    <Grid>
      <Grid.Row columns={3}>
        <UpdateStyle
          pathOptions={pathOptions}
          setPathOptions={setPathOptions}
          patternOptions={patternOptions}
          setPatternOptions={setPatternOptions}
          shapeOptions={shapeOptions}
          setShapeOptions={setShapeOptions}
          geoTypeCode={geoTypeCode}
          setGeoTypeCode={setGeoTypeCode}
          usePattern={usePattern}
          setUsePattern={setUsePattern}
          patternTypeCode={patternTypeCode}
          setPatternTypeCode={setPatternTypeCode}
          styleId={styleId}
          initPathOptions={initPathOptions}
          initPatternOptions={initPatternOptions}
          initShapeOptions={initShapeOptions}
          usingLayerSet={usingLayerSet}
          usingQuestionnaireFieldLabelSet={usingQuestionnaireFieldLabelSet}
        />
        <Grid.Column>
          <Viewer
            geoTypeCode={geoTypeCode}
            pathOptions={pathOptions}
            usePattern={usePattern}
            patternType={patternTypeCode}
            patternOptions={patternOptions}
            shapeOptions={shapeOptions}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function FetchStyle({ styleId }) {
  const { loading, error, data } = useQuery(GET_STYLE, {
    variables: { styleId: styleId },
  });

  if (loading) return <LoaderDisplayer />;

  if (error || !data) return <ErrorMessage error={error} />;

  const { style } = data;

  let props = {
    initPathOptions: JSON.parse(style.pathOptions),
  };

  if (
    style.geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON &&
    style.usePattern
  ) {
    props.initPatternOptions = JSON.parse(style.patternOptions);
    if (style.patternTypeCode !== PATTERN_TYPES.STRIPES) {
      props.initShapeOptions = JSON.parse(style.shapeOptions);
    }
  }

  return (
    <Update
      styleId={style.id}
      initGeoTypeCode={style.geoTypeCode}
      initUsePattern={style.usePattern}
      initPatternTypeCode={style.patternTypeCode}
      usingLayerSet={style.usingLayerSet}
      usingQuestionnaireFieldLabelSet={style.usingQuestionnaireFieldLabelSet}
      {...props}
    />
  );
}

function GetStyleId() {
  let { styleId } = useParams();

  if (!styleId) {
    return <Message error>Identifiant de style manquant.</Message>;
  }

  return <FetchStyle styleId={styleId} />;
}

export { GetStyleId as Update };
