// React
import React from "react";

// Semantic ui
import { Form, Checkbox, Table } from "semantic-ui-react";

// GraphQL
import { ASSIGN_UNASSIGN_RESTR_BTW_USERS_PERM_TO_GROUP } from "../../../_GraphQL/mutations";
import {
  GET_GROUP_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET,
  GET_LAYER,
} from "../../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";
import EQuery from "../../../../../utils/EQuery";

// Constants
import {
  MESSAGE_TYPE,
  COLLABORATIVE_MAP_PERMISSIONS,
} from "../../../../../utils/Constants";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

const GroupToolRow = ({
  enabled,
  groupToolRow,
  handleCheckChange,
  layerId,
}) => (
  <Table.Row>
    <Table.Cell>{groupToolRow.group.name}</Table.Cell>

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId}
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId}
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId}
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES + layerId}
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES + layerId}
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>
  </Table.Row>
);

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields(this.props.enabled);
  }

  render() {
    return (
      <Form.Field>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Groupe</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Voir les géométries des autres utilisateurs
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Voir les infos des géométries des autres utilisateurs
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Editer les infos des géométries des autres utilisateurs
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Editer les géométries des autres utilisateurs
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Supprimer les géométries des autres utilisateurs
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.groupToolsRowSet
              .sort((a, b) => {
                if (a.group.name === b.group.name) {
                  return 0;
                } else if (a.group.name === null) {
                  return 1;
                } else if (b.group.name === null) {
                  return -1;
                } else {
                  return a.group.name
                    .toLowerCase()
                    .localeCompare(b.group.name.toLowerCase());
                }
              })
              .map((groupToolsRow) => (
                <GroupToolRow
                  enabled={this.props.enabled}
                  key={`table-row-group-${groupToolsRow.group.name}`}
                  groupToolRow={groupToolsRow}
                  handleCheckChange={this.props.handleCheckChange}
                  layerId={this.props.layerId}
                />
              ))}
          </Table.Body>
        </Table>
      </Form.Field>
    );
  }
}

class LayerGroupRestrBtwUsersPerm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialGroupToolsRowSet: [],
      groupToolsRowSet: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = (edit) => {
    let groupToolsRowSet = [];

    if (edit) {
      this.props.data.adminAssignedGroupForLayerSet.forEach((g) => {
        let groupToolRow = {
          group: g,
        };

        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES +
            this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES +
            this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES +
            this.props.layer.id
        ] = false;

        let toolSet =
          this.props.data.adminGroupWithAssignedRestrBtwUsersPermSetSet.find(
            (gt) => gt.id === g.id
          );

        if (toolSet) {
          toolSet.assignedRestrBtwUsersPermSet.forEach(
            (t) => (groupToolRow[t.codename] = true)
          );
        }

        groupToolsRowSet.push(groupToolRow);
      });
    } else {
      this.props.data.adminGroupWithAssignedRestrBtwUsersPermSetSet.forEach(
        (groupTool) => {
          let groupToolsRow = {
            group: { id: groupTool.id, name: groupTool.name },
          };
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES +
              this.props.layer.id
          ] = groupTool.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS +
              this.props.layer.id
          ] = groupTool.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS +
              this.props.layer.id
          ] = groupTool.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES +
              this.props.layer.id
          ] = groupTool.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES +
              this.props.layer.id
          ] = groupTool.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES +
                this.props.layer.id
          );

          groupToolsRowSet.push(groupToolsRow);
        }
      );
    }

    this.setState({
      groupToolsRowSet: JSON.parse(JSON.stringify(groupToolsRowSet)),
      initialGroupToolsRowSet: JSON.parse(JSON.stringify(groupToolsRowSet)),
    });
  };

  handleSubmit = (mutate) => {
    let groups = [];

    const tools = [
      COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES +
        this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES +
        this.props.layer.id,
    ];

    this.state.initialGroupToolsRowSet.forEach((initRow) => {
      let group = {
        groupId: initRow.group.id,
        permissionToAssignSet: [],
        permissionToUnassignSet: [],
      };

      const currentRow = this.state.groupToolsRowSet.find(
        (row) => row.group.id === initRow.group.id
      );
      for (let t of tools) {
        // ceux qui étaint checked au départ et qui maintenant sont unchecked
        if (initRow[t] && !currentRow[t]) {
          group.permissionToUnassignSet.push(t);
        }

        // ceux qui étaient unchecked au départ et qui maintenant sont checked
        if (!initRow[t] && currentRow[t]) {
          group.permissionToAssignSet.push(t);
        }
      }

      groups.push(group);
    });

    mutate({
      variables: {
        layerId: this.props.layer.id,
        groupToAssignUnassignSet: groups,
      },
    });
  };

  update = (
    cache,
    { data: { adminAssignUnassignRestrBtwUsersPermToGroup } }
  ) => {
    let data = cache.readQuery({
      query: GET_GROUP_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET,
      variables: { layerId: this.props.layer.id },
    });

    cache.writeQuery({
      query: GET_GROUP_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET,
      variables: { layerId: this.props.layer.id },
      data: {
        adminAssignedGroupForLayerSet: data.adminAssignedGroupForLayerSet,
        adminGroupWithAssignedRestrBtwUsersPermSetSet:
          adminAssignUnassignRestrBtwUsersPermToGroup.groupWithAssignedRestrBtwUsersPermSetSet,
      },
    });

    data = cache.readQuery({
      query: GET_LAYER,
      variables: { layerId: this.props.layer.id },
    });

    cache.writeQuery({
      query: GET_LAYER,
      variables: { layerId: this.props.layer.id },
      data: {
        ...data,
        adminGroupWithAssignedRestrBtwUsersPermSetSet:
          adminAssignUnassignRestrBtwUsersPermToGroup.groupWithAssignedRestrBtwUsersPermSetSet,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      type: MESSAGE_TYPE.POSITIVE,
      content:
        "L'affectation des restrictions entre utilisateurs a été mise à jour.",
    });
    this.toReadMode();
  };

  handleCheckChange = (group, tool, checked) => {
    this.setState((prevState) => {
      let groupToolsRow = prevState.groupToolsRowSet.find(
        (al) => al.group.id === group.id
      );

      groupToolsRow[tool] = checked;

      let layerId = this.props.layer.id;

      if (checked) {
        if (
          tool === COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId ||
          tool ===
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES + layerId ||
          tool ===
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES + layerId
        ) {
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId
          ] = checked;
        } else if (
          tool ===
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId
        ) {
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId
          ] = checked;
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId
          ] = checked;
        }
      } else {
        if (
          tool ===
          COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId
        ) {
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId
          ] = checked;
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId
          ] = checked;
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES + layerId
          ] = checked;
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES + layerId
          ] = checked;
        } else if (
          tool ===
          COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId
        ) {
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId
          ] = checked;
        }
      }

      return {
        groupToolsRowSet: prevState.groupToolsRowSet,
      };
    });
  };

  render() {
    return (
      <EditSegment
        title="Restrictions entre les groupes"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_RESTR_BTW_USERS_PERM_TO_GROUP}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            editable={this.state.editable}
            enabled={enabled}
            initFields={this.initFields}
            groupToolsRowSet={this.state.groupToolsRowSet}
            handleCheckChange={this.handleCheckChange}
            layerId={this.props.layer.id}
          />
        )}
      </EditSegment>
    );
  }
}

const LayerGroupRestrBtwUsersPermQuery = (props) => {
  return (
    <EQuery
      query={GET_GROUP_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET}
      variables={{ layerId: props.layer.id }}
    >
      {(data) => (
        <LayerGroupRestrBtwUsersPerm
          data={data}
          layer={props.layer}
          editMode={props.editMode}
          toEditMode={props.toEditMode}
          toReadMode={props.toReadMode}
        />
      )}
    </EQuery>
  );
};

export default LayerGroupRestrBtwUsersPermQuery;
