// React
import React from "react";

// GraphQL
import {
  FOLDER_GRP_PERM_SET_SITE_GRP_SET,
  ADMIN_GROUP_SET,
} from "../../../_GraphQL/queries";
import { GET_PROJECT } from "../../../../../Common/_GraphQL/queries";
import { UPDATE_PROJECT_ROOT_FOLDER } from "../../../_GraphQL/mutations";

// Components
import RootFolderForm from "./RootFolderForm";
import EMutation from "../../../../../utils/EMutation";

// Constants
import * as constants from "../../../../../utils/Constants";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

const viewFolderTrigger = ["add_folder_to_folder_", "add_document_to_folder_"];

class RootFolderMutation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rootFolderName: this.props.project.rootFolder
        ? this.props.project.rootFolder.name
        : "",
      initialGroupPermissionsRows: [],
      groupPermissionsRows: [],
    };
  }

  handlerootFolderNameChange = (e) =>
    this.setState({
      rootFolderName: e.target.value,
    });

  handleCheckChange = (group, permission, checked) => {
    this.setState((prevState) => {
      let groupPermissionsRow = prevState.groupPermissionsRows.find(
        (groupPermissionsRow) => groupPermissionsRow.group.id === group.id
      );

      let perm = constants.FILEMANAGER_PERMISSIONS[permission];
      groupPermissionsRow[perm] = checked;

      if (checked) {
        if (
          viewFolderTrigger.includes(permission) &&
          !groupPermissionsRow.canViewFolder
        ) {
          groupPermissionsRow.canViewFolder = true;
        }
      } else {
        if (permission === "view_folder_") {
          groupPermissionsRow.canViewFolder = false;
          groupPermissionsRow.canAddFolder = false;
          groupPermissionsRow.canAddDocument = false;
        }
      }
      return {
        groupPermissionsRows: prevState.groupPermissionsRows,
      };
    });
  };

  getGroupPermissionToAddSet = () => {
    let groupPermissionToAddSet = [];
    let initRows = this.state.initialGroupPermissionsRows;
    let rows = this.state.groupPermissionsRows;

    for (let i = 0; i < initRows.length; i++) {
      if (!initRows[i].canViewFolder && rows[i].canViewFolder) {
        groupPermissionToAddSet.push({
          groupId: initRows[i].group.id,
          permission: "view_folder_",
        });
      }

      if (!initRows[i].canAddFolder && rows[i].canAddFolder) {
        groupPermissionToAddSet.push({
          groupId: initRows[i].group.id,
          permission: "add_folder_to_folder_",
        });
      }

      if (!initRows[i].canAddDocument && rows[i].canAddDocument) {
        groupPermissionToAddSet.push({
          groupId: initRows[i].group.id,
          permission: "add_document_to_folder_",
        });
      }
    }
    return groupPermissionToAddSet;
  };

  getGroupPermissionToRemoveSet = () => {
    let groupPermissionToRemoveSet = [];
    let initRows = this.state.initialGroupPermissionsRows;
    let rows = this.state.groupPermissionsRows;

    for (let i = 0; i < initRows.length; i++) {
      if (initRows[i].canViewFolder && !rows[i].canViewFolder) {
        groupPermissionToRemoveSet.push({
          groupId: initRows[i].group.id,
          permission: "view_folder_",
        });
      }

      if (initRows[i].canAddFolder && !rows[i].canAddFolder) {
        groupPermissionToRemoveSet.push({
          groupId: initRows[i].group.id,
          permission: "add_folder_to_folder_",
        });
      }

      if (initRows[i].canAddDocument && !rows[i].canAddDocument) {
        groupPermissionToRemoveSet.push({
          groupId: initRows[i].group.id,
          permission: "add_document_to_folder_",
        });
      }
    }

    return groupPermissionToRemoveSet;
  };

  componentDidMount() {
    // JSON.parse(JSON.stringyFy) -> utiliser pour faire un copie sans référence
    // [...array] ne suffit pas parce qu'il y a des objets dans mon tableau
    this.setState({
      groupPermissionsRows: JSON.parse(
        JSON.stringify(this.props.groupPermissionsRows)
      ),
      initialGroupPermissionsRows: JSON.parse(
        JSON.stringify(this.props.groupPermissionsRows)
      ),
    });
  }

  handleSubmit = (mutate) => {
    mutate({
      variables: {
        projectId: this.props.project.id,
        rootFolderName: this.state.rootFolderName,
        groupPermissionToAddSet: this.getGroupPermissionToAddSet(),
        groupPermissionToRemoveSet: this.getGroupPermissionToRemoveSet(),
      },
    });
  };

  render() {
    return (
      <EMutation
        mutation={UPDATE_PROJECT_ROOT_FOLDER}
        onCompleted={() => {
          addSuccessToastMessage({
            header: "Dossier racine mis à jour",
            content: "Le dossier racine a été mis à jour avec succès.",
          });
          this.props.toReadMode();
        }}
        update={(cache, { data: { adminUpdateProjectRootFolder } }) => {
          cache.writeQuery({
            query: GET_PROJECT,
            variables: { projectId: this.props.project.id },
            data: { project: adminUpdateProjectRootFolder.project },
          });

          let newData = {};
          if (!this.props.project.rootFolder) {
            let grpSet = cache.readQuery({
              query: ADMIN_GROUP_SET,
              variables: {
                siteId: this.props.project.site.id,
              },
            });
            newData.adminGroupSet = grpSet.adminGroupSet;
          }

          cache.writeQuery({
            query: FOLDER_GRP_PERM_SET_SITE_GRP_SET,
            variables: {
              folderId: this.props.project.rootFolder
                ? this.props.project.rootFolder.id
                : adminUpdateProjectRootFolder.project.rootFolder.id,
              siteId: this.props.project.site.id,
            },
            data: {
              ...newData,
              adminFolderGroupPermissionSet:
                adminUpdateProjectRootFolder.folderGroupPermissionSet,
            },
          });
        }}
      >
        {(updateProject) => (
          <RootFolderForm
            project={this.props.project}
            groupPermissionsRows={this.state.groupPermissionsRows}
            handleCheckChange={this.handleCheckChange}
            rootFolderName={this.state.rootFolderName}
            handlerootFolderNameChange={this.handlerootFolderNameChange}
            modificationsEnabled={true}
            handleSubmit={() => this.handleSubmit(updateProject)}
            cancel={this.props.toReadMode}
          />
        )}
      </EMutation>
    );
  }
}

export default RootFolderMutation;
