// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";

// Semantic ui
import { Loader } from "semantic-ui-react";
import {
  GET_GROUP_PERMISSION_AND_GROUP_FOR_SITE,
  GET_GROUP_PERMISSION_FOR_DRAWING_AND_GROUP_FOR_SITE,
} from "../../_GraphQL/queries";

// Components
import DrawingPermissionsMutation from "./DrawingPermissionsMutation";
import DrawingPermissionsForm from "./DrawingPermissionsForm";
import ErrorMessage from "../../../../Common/components/ErrorMessage";

function DrawingPermissions({
  query,
  variables,
  target,
  project,
  drawing,
  handleCancel,
  handleCompleted,
  editPermissions,
}) {
  const { loading, error, data } = useQuery(query, {
    variables: variables,
  });

  if (loading) return <Loader />;

  if (error) {
    return (
      <ErrorMessage
        Header={"Erreur lors du chargement des permissions."}
        error={error}
      />
    );
  }

  let defaultPermissions = [];

  if (target === "project") {
    defaultPermissions = data.adminDefaultDrawingGroupPermissionSet;
  } else {
    defaultPermissions = data.adminDrawingGroupPermissionSet;
  }

  let groups = data.adminGroupSet;

  if (editPermissions) {
    return (
      <DrawingPermissionsMutation
        permissions={defaultPermissions}
        groups={groups}
        project={project}
        drawing={drawing}
        handleCancel={handleCancel}
        handleCompleted={handleCompleted}
        target={target}
      />
    );
  }

  let permissionLines = [];

  let res = [];

  if (target === "project") {
    res = data.adminDefaultDrawingGroupPermissionSet;
  } else {
    res = data.adminDrawingGroupPermissionSet;
  }

  res.forEach((gp) => {
    if (permissionLines.find((o) => o.group.id === gp.group.id)) {
      if (gp.permission.codename === "view_drawing") {
        permissionLines.find((o) => o.group.id === gp.group.id).canView = true;
      } else {
        permissionLines.find(
          (o) => o.group.id === gp.group.id
        ).canChange = true;
      }
    } else {
      permissionLines.push({
        group: gp.group,
        canView: gp.permission.codename === "view_drawing",
        canChange: gp.permission.codename === "change_drawing",
      });
    }
  });

  return <DrawingPermissionsForm permissionLines={permissionLines} />;
}

function DrawingPermissionsQuery({
  target,
  project,
  drawing,
  handleCancel,
  handleCompleted,
  editPermissions,
}) {
  let query = "";
  let variables = "";

  if (target === "project") {
    query = GET_GROUP_PERMISSION_AND_GROUP_FOR_SITE;
    variables = {
      projectId: project.id,
      siteId: project.site.id,
    };
  } else {
    query = GET_GROUP_PERMISSION_FOR_DRAWING_AND_GROUP_FOR_SITE;
    variables = {
      drawingId: drawing.id,
      siteId: project.site.id,
    };
  }

  return (
    <DrawingPermissions
      query={query}
      variables={variables}
      target={target}
      project={project}
      drawing={drawing}
      handleCancel={handleCancel}
      handleCompleted={handleCompleted}
      editPermissions={editPermissions}
    />
  );
}

export default DrawingPermissionsQuery;
