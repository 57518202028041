// GraphQL
import { gql } from "@apollo/client";
import {
  DRAWING_FRAG_WITH_PARENTS,
  DRAWING_GROUP_PERMISSION_FRAG,
  DEFAULT_DRAWING_GROUP_PERMISSION_FRAG,
  WEBSITE_FRAG,
  LAYER_FRAG,
  QUESTIONNAIRE_FRAG,
  TASK_FRAG,
  OPEN_SIGN_IN_FRAG,
} from "./fragments";
import {
  DRAWING_FRAG,
  DEFAULT_FOLDER_GROUP_PERMISSION_FRAG,
  GROUP_PERMISSION_FRAG,
  USER_FRAG,
  DEFAULT_DOCUMENT_GROUP_PERMISSION_FRAG,
  STYLE_FRAG,
} from "../../../Common/_GraphQL/fragments";

export const ADMIN_DRAWING = gql`
  query adminDrawing($drawingId: ID!) {
    adminDrawing(drawingId: $drawingId) {
      ...DrawingWithParentsFrag
    }
  }
  ${DRAWING_FRAG_WITH_PARENTS}
`;

export const ADMIN_DRAWING_SET = gql`
  query adminDrawingSet($projectId: ID!) {
    adminDrawingSet(projectId: $projectId) {
      ...DrawingFrag
    }
  }
  ${DRAWING_FRAG}
`;

export const GET_GROUP_PERMISSION_AND_GROUP_FOR_SITE = gql`
  query getGroupPermissionGroupForSite($projectId: ID!, $siteId: ID!) {
    adminDefaultDrawingGroupPermissionSet(projectId: $projectId) {
      ...DefaultDrawingGroupPermissionFrag
    }
    adminGroupSet(siteId: $siteId) {
      id
      name
    }
  }
  ${DEFAULT_DRAWING_GROUP_PERMISSION_FRAG}
`;

export const GET_GROUP_PERMISSION_FOR_DRAWING_AND_GROUP_FOR_SITE = gql`
  query getGroupPermissionForDrawingAndGroupForSite(
    $drawingId: ID!
    $siteId: ID!
  ) {
    adminDrawingGroupPermissionSet(drawingId: $drawingId) {
      ...DrawingGroupPermissionFrag
    }
    adminGroupSet(siteId: $siteId) {
      id
      name
    }
  }
  ${DRAWING_GROUP_PERMISSION_FRAG}
`;

export const GET_LAYER = gql`
  query AdminLayer($layerId: ID!) {
    layer(layerId: $layerId) {
      ...LayerFrag
    }
    adminGroupWithAssignedToolPermissionSetSet(layerId: $layerId) {
      id
      name
      assignedToolPermissionSet(layerId: $layerId) {
        id
        codename
      }
    }
    adminUserWithAssignedToolPermissionSetSet(layerId: $layerId) {
      id
      firstName
      lastName
      email
      assignedToolPermissionSet(layerId: $layerId) {
        id
        codename
      }
    }
    adminGroupWithAssignedRestrBtwUsersPermSetSet(layerId: $layerId) {
      id
      name
      assignedRestrBtwUsersPermSet(layerId: $layerId) {
        id
        codename
      }
    }
    adminUserWithAssignedRestrBtwUsersPermSetSet(layerId: $layerId) {
      id
      firstName
      lastName
      email
      assignedRestrBtwUsersPermSet(layerId: $layerId) {
        id
        codename
      }
    }
  }
  ${LAYER_FRAG}
`;

export const GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_TASK = gql`
  query AssignedAndAvailableGroupsForTask($taskId: ID!) {
    adminAvailableGroupForTaskSet(taskId: $taskId) {
      id
      name
    }
    adminAssignedGroupForTaskSet(taskId: $taskId) {
      id
      name
    }
  }
`;

export const TASK_SET = gql`
  query taskSet($projectId: ID!) {
    taskSet(projectId: $projectId) {
      id
      name
      order
      illustration
      illustrationPath
      taskType {
        id
        code
      }
    }
  }
`;

export const ADMIN_TILE_LAYERS = gql`
  query adminTileLayers {
    adminTileLayers {
      id
      name
    }
  }
`;

export const ADMIN_PROJECT_SET = gql`
  query adminProjectSet($siteId: ID!) {
    adminProjectSet(siteId: $siteId) {
      id
      name
      illustrationPath
      description
    }
  }
`;

export const GET_WEBSITE = gql`
  query website($siteId: ID!) {
    website(siteId: $siteId) {
      ...WebsiteFrag
    }
  }
  ${WEBSITE_FRAG}
`;

export const ADMIN_MANAGEABLE_WEBSITE_SET = gql`
  query adminManageableWebsiteSet {
    adminManageableWebsiteSet {
      id
      site {
        id
        name
        domain
      }
      illustrationPath
      description
    }
  }
`;

export const ADMIN_USER_SET = gql`
  query adminUserSet(
    $active: Boolean!
    $sortColumn: String!
    $sortDirection: String!
    $groupId: ID
    $siteId: ID
    $projectId: ID
  ) {
    adminUserSet(
      active: $active
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      groupId: $groupId
      siteId: $siteId
      projectId: $projectId
    ) {
      ...UserFrag
    }
  }
  ${USER_FRAG}
`;

export const ADMIN_USER = gql`
  query adminUser($userId: ID!) {
    adminUser(userId: $userId) {
      ...UserFrag
    }
  }
  ${USER_FRAG}
`;

export const GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_USER = gql`
  query AssignedAndAvailableGroupsForUser($userId: ID!) {
    adminAvailableGroupSet(userId: $userId) {
      id
      name
    }
    adminAssignedGroupSet(userId: $userId) {
      id
      name
    }
  }
`;

export const GET_ASSIGNED_AND_AVAILABLE_PROJECTS_FOR_USER = gql`
  query AssignedAndAvailableProjectsForUser($userId: ID!, $siteId: ID!) {
    adminAvailableProjectSet(userId: $userId, siteId: $siteId) {
      id
      name
    }
    adminAssignedProjectSet(userId: $userId, siteId: $siteId) {
      id
      name
    }
  }
`;

export const GET_ASSIGNED_AND_AVAILABLE_SITES_FOR_USER = gql`
  query AssignedAndAvailableSitesForUser($userId: ID!) {
    adminAvailableSiteSet(userId: $userId) {
      id
      name
    }
    adminAssignedSiteSet(userId: $userId) {
      id
      name
    }
  }
`;

export const GET_LISTS_FOR_FILTERS = gql`
  query GetListForFilters {
    siteSet {
      id
      name
      projectSet {
        id
        name
        taskSet {
          id
          name
        }
      }
    }
    groupSet {
      id
      name
    }
  }
`;

export const ADMIN_TASK = gql`
  query AdminTask($taskId: ID!) {
    adminTask(taskId: $taskId) {
      ...TaskFrag
    }
    adminTaskTypeSet {
      id
      code
      text
    }
  }
  ${TASK_FRAG}
`;

// File manager

export const DEFAULT_FOLDER_GRP_PERM_AND_GRP_FOR_SITE = gql`
  query DefaultFolderGrpPermAndGrpForSite($projectId: String!, $siteId: ID!) {
    defaultFolderGroupPermissionSet(projectIdOrSlug: $projectId) {
      ...DefaultFolderGroupPermissionFrag
    }
    adminGroupSet(siteId: $siteId) {
      id
      name
    }
  }
  ${DEFAULT_FOLDER_GROUP_PERMISSION_FRAG}
`;

export const DEFAULT_DOC_GRP_PERM_AND_GRP_FOR_SITE = gql`
  query DefaultDocGrpPermAndGrpForSite($projectId: String!, $siteId: ID!) {
    defaultDocumentGroupPermissionSet(projectIdOrSlug: $projectId) {
      ...DefaultDocumentGroupPermissionFrag
    }
    adminGroupSet(siteId: $siteId) {
      id
      name
    }
  }
  ${DEFAULT_DOCUMENT_GROUP_PERMISSION_FRAG}
`;

export const FOLDER_GRP_PERM_SET_SITE_GRP_SET = gql`
  query AdminFolderGrpPermAndGrpForSite($siteId: ID!, $folderId: ID) {
    adminFolderGroupPermissionSet(folderId: $folderId) {
      ...GroupPermissionFrag
    }
    adminGroupSet(siteId: $siteId) {
      id
      name
    }
  }
  ${GROUP_PERMISSION_FRAG}
`;

export const ADMIN_GROUP_SET = gql`
  query AdminGroupSet($siteId: ID!) {
    adminGroupSet(siteId: $siteId) {
      id
      name
    }
  }
`;

export const ICON_LIST = gql`
  query AdminIconeList {
    adminIconeList
  }
`;

export const GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_LAYER = gql`
  query AssignedAndAvailableGroupsForLayer($layerId: ID!) {
    adminAvailableGroupForLayerSet(layerId: $layerId) {
      id
      name
    }
    adminAssignedGroupForLayerSet(layerId: $layerId) {
      id
      name
    }
  }
`;

export const GET_ASSIGNED_AND_AVAILABLE_USERS_FOR_LAYER = gql`
  query assignedAndAvailableUsersForLayer($projectId: ID!, $layerId: ID!) {
    adminAvailableUserForLayerSet(projectId: $projectId, layerId: $layerId) {
      id
      firstName
      lastName
      email
    }
    adminAssignedUserForLayerSet(layerId: $layerId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_GROUP_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET = gql`
  query adminGroupWithAssignedToolPermissionSetSet($layerId: ID!) {
    adminGroupWithAssignedToolPermissionSetSet(layerId: $layerId) {
      id
      name
      assignedToolPermissionSet(layerId: $layerId) {
        id
        codename
      }
    }
    adminAssignedGroupForLayerSet(layerId: $layerId) {
      id
      name
    }
  }
`;

export const GET_USER_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET = gql`
  query adminUserWithAssignedToolPermissionSetSet($layerId: ID!) {
    adminUserWithAssignedToolPermissionSetSet(layerId: $layerId) {
      id
      firstName
      lastName
      email
      assignedToolPermissionSet(layerId: $layerId) {
        id
        codename
      }
    }
    adminUserAllowedToViewLayerSet(layerId: $layerId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_GROUP_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET = gql`
  query adminGroupWithAssignedRestrBtwUsersPermSetSet($layerId: ID!) {
    adminGroupWithAssignedRestrBtwUsersPermSetSet(layerId: $layerId) {
      id
      name
      assignedRestrBtwUsersPermSet(layerId: $layerId) {
        id
        codename
      }
    }
    adminAssignedGroupForLayerSet(layerId: $layerId) {
      id
      name
    }
  }
`;

export const GET_USER_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET = gql`
  query adminUserWithAssignedRestrBtwUsersPermSetSet($layerId: ID!) {
    adminUserWithAssignedRestrBtwUsersPermSetSet(layerId: $layerId) {
      id
      firstName
      lastName
      email
      assignedRestrBtwUsersPermSet(layerId: $layerId) {
        id
        codename
      }
    }
    adminUserAllowedToViewLayerSet(layerId: $layerId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const ADMIN_QUESTIONNAIRE_SET = gql`
  query adminQuestionnaireSet(
    $orphelin: String
    $taskId: ID
    $siteId: ID
    $projectId: ID
  ) {
    adminQuestionnaireSet(
      orphelin: $orphelin
      taskId: $taskId
      siteId: $siteId
      projectId: $projectId
    ) {
      ...QuestionnaireFrag
    }
  }

  ${QUESTIONNAIRE_FRAG}
`;

export const ADMIN_QUESTIONNAIRE = gql`
  query adminQuestionnaire($questionnaireId: ID!) {
    adminQuestionnaire(questionnaireId: $questionnaireId) {
      ...QuestionnaireFrag
    }
    adminFieldTypeSet {
      id
      code
      text
    }
  }
  ${QUESTIONNAIRE_FRAG}
`;

export const ADMIN_GROUP_SITE = gql`
  query AdminGroupSite($siteId: ID!) {
    adminAvailableGroupForSiteSet(siteId: $siteId) {
      id
      name
    }
    adminAssignedGroupForSiteSet(siteId: $siteId) {
      id
      name
    }
  }
`;

export const ADMIN_MODULE_SITE = gql`
  query adminModuleSite($siteId: ID!) {
    adminAvailableModuleSet(siteId: $siteId) {
      id
      name
    }
    adminAssignedModuleSet(siteId: $siteId) {
      id
      name
    }
  }
`;

export const ADMIN_LAYER_HAS_FEATURES = gql`
  query AdminLayerHasFeatures($layerId: ID!) {
    adminLayerHasFeatures(layerId: $layerId)
  }
`;

export const ADMIN_QUESTIONNAIRE_USAGE = gql`
  query AdminQuestionnaireUsage($questionnaireId: ID!) {
    adminQuestionnaireUsage(questionnaireId: $questionnaireId) {
      id
      name
      task {
        id
        name
      }
    }
  }
`;

export const ADMIN_GET_OPEN_SIGN_IN_SET_AND_GROUP_SET = gql`
  query AdminGetOpenSignInSetAndGroupSet($projectId: ID!, $siteId: ID!) {
    adminGetOpenSignInSet(projectId: $projectId) {
      ...OpenSignInFrag
    }
    adminGroupSet(siteId: $siteId) {
      id
      name
    }
  }
  ${OPEN_SIGN_IN_FRAG}
`;

export const STYLE_SET = gql`
  query styleSet {
    styleSet {
      ...StyleFrag
    }
  }
  ${STYLE_FRAG}
`;

export const GET_STYLE = gql`
  query GetStyle($styleId: ID!) {
    style(styleId: $styleId) {
      ...StyleFrag
    }
  }
  ${STYLE_FRAG}
`;

export const GET_SITE_SET = gql`
  query GetSiteSet {
    siteSet {
      id
      name
    }
  }
`;
