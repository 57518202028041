import React from "react";
import {
  Loader,
  Form,
  Button,
  Segment,
  Dimmer,
  Select,
  Grid,
  Message,
} from "semantic-ui-react";
import { ErrorMessage } from "../../../../Common/components/ErrorMessage";
import {
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  PATTERN_TYPES,
} from "../../../../utils/Constants";
import { ShapeForm } from "./Forms/Shape/ShapeForm";
import { PatternForm } from "./Forms/Pattern/PatternForm";
import { PathForm } from "./Forms/Path/PathForm";
import { GEOMETRY_CODE_OPTIONS } from "../../../../utils/Constants";
import { useHistory, Link } from "react-router-dom";

function StyleForm({
  styleId,
  initStyle,
  initPatternOptions,
  initShapeOptions,
  submit,
  error,
  loading,
  setPathOptions,
  setPatternOptions,
  setShapeOptions,
  geoTypeCode,
  setGeoTypeCode,
  usePattern,
  setUsePattern,
  patternTypeCode,
  setPatternTypeCode,
  usingLayerSet,
  usingQuestionnaireFieldLabelSet,
}) {
  const history = useHistory();

  return (
    <>
      <Grid.Column>
        <Segment>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            {error ? <ErrorMessage error={error} /> : null}

            <Segment secondary>
              <Form.Field>
                <label>Type de géométrie</label>
                <Select
                  options={GEOMETRY_CODE_OPTIONS}
                  value={geoTypeCode}
                  onChange={(e, { value }) => setGeoTypeCode(value)}
                />
              </Form.Field>
            </Segment>

            <PathForm
              setPathOptions={setPathOptions}
              usePattern={usePattern}
              setUsePattern={setUsePattern}
              patternTypeCode={patternTypeCode}
              setPatternTypeCode={setPatternTypeCode}
              geoTypeCode={geoTypeCode}
              initStyle={initStyle}
            />
          </Form>
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Segment>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
            warning={
              (usingLayerSet && usingLayerSet.length > 0) ||
              (usingQuestionnaireFieldLabelSet &&
                usingQuestionnaireFieldLabelSet.length > 0)
            }
          >
            {loading ? (
              <Dimmer>
                <Loader />
              </Dimmer>
            ) : null}
            {error ? <ErrorMessage error={error} /> : null}
            {usingLayerSet || usingQuestionnaireFieldLabelSet ? (
              <Message warning>
                <Message.Header>
                  Si vous modifiez ce style, les éléments suivants seront
                  impactés :
                </Message.Header>
                <Message.List>
                  {usingLayerSet.map((layer) => (
                    <Message.Item key={`using-layer-${layer.id}`}>
                      La couche{" "}
                      <Link to={`/manager/couche/${layer.id}`}>
                        {layer.name}
                      </Link>{" "}
                      de la tâche{" "}
                      <Link to={`/manager/couche/${layer.task.id}`}>
                        {layer.task.name}
                      </Link>{" "}
                      du projet{" "}
                      <Link to={`/manager/projet/${layer.task.project.id}`}>
                        {layer.task.project.name}
                      </Link>
                      .
                    </Message.Item>
                  ))}
                  {usingQuestionnaireFieldLabelSet.map((qFieldLabel) => (
                    <Message.Item
                      key={`using-questionnairefieldlabel-${qFieldLabel.id}`}
                    >
                      Le label{" "}
                      <Link
                        to={`/manager/questionnaire/${qFieldLabel.field.questionnaire.id}/champ/${qFieldLabel.field.id}/label/${qFieldLabel.id}`}
                      >
                        {qFieldLabel.text}
                      </Link>{" "}
                      de la question{" "}
                      <Link
                        to={`/manager/questionnaire/${qFieldLabel.field.questionnaire.id}/champ/${qFieldLabel.field.id}`}
                      >
                        {qFieldLabel.field.text}
                      </Link>{" "}
                      du questionnaire{" "}
                      <Link
                        to={`/manager/questionnaire/${qFieldLabel.field.questionnaire.id}`}
                      >
                        {qFieldLabel.field.questionnaire.name}
                      </Link>
                      .
                    </Message.Item>
                  ))}
                </Message.List>
              </Message>
            ) : null}

            {geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON &&
            usePattern ? (
              <>
                <PatternForm
                  initPatternOpt={initPatternOptions}
                  patternTypeCode={patternTypeCode}
                  setPatternOptions={setPatternOptions}
                />
                {patternTypeCode !== PATTERN_TYPES.STRIPES ? (
                  <ShapeForm
                    initShapeOptions={initShapeOptions}
                    setShapeOptions={setShapeOptions}
                    patternTypeCode={patternTypeCode}
                  />
                ) : null}
              </>
            ) : null}

            <Form.Field>
              <Button
                disabled={loading}
                onClick={() =>
                  history.push(
                    `/manager/styles${styleId ? `?focus=${styleId}` : ""}`
                  )
                }
              >
                Annuler
              </Button>
              <Button disabled={loading} type="submit" positive>
                Enregistrer
              </Button>
            </Form.Field>
          </Form>
        </Segment>
      </Grid.Column>
    </>
  );
}

export { StyleForm };
