import React, { useState, useEffect } from "react";
import { Form, Segment } from "semantic-ui-react";

function StripesPatternForm({
  initPatternOpt,
  basePatternOptHeight,
  setOptions,
  weight,
  setWeight,
  spaceWeight,
  setSpaceWeight,
}) {
  const [color, setColor] = useState("#89d814"); //: {color} (default: #000000) - The color of the primary stripe.
  const [spaceColor, setSpaceColor] = useState("#ffffff"); //: {color} (default: #ffffff) - The color of the secondary stripe.
  const [opacity, setOpacity] = useState(1.0); //: {0.0 - 1.0} (default: 1.0) - The opacity of the primary stripe.
  const [spaceOpacity, setSpaceOpacity] = useState(0.0); //: {0.0 - 1.0} (default: 0.0) - The opacity of the secondary stripe.

  // Initialisation
  useEffect(() => {
    if (initPatternOpt) {
      if (initPatternOpt.hasOwnProperty("color")) {
        setColor(initPatternOpt.color);
      }

      if (initPatternOpt.hasOwnProperty("spaceColor")) {
        setSpaceColor(initPatternOpt.spaceColor);
      }

      if (initPatternOpt.hasOwnProperty("opacity")) {
        setOpacity(initPatternOpt.opacity);
      }

      if (initPatternOpt.hasOwnProperty("spaceOpacity")) {
        setSpaceOpacity(initPatternOpt.spaceOpacity);
      }
    }
  }, [initPatternOpt]);

  useEffect(() => {
    setOptions({
      weight: weight,
      spaceWeight: spaceWeight,
      color: color,
      spaceColor: spaceColor,
      opacity: opacity,
      spaceOpacity: spaceOpacity,
    });
  }, [
    weight,
    spaceWeight,
    color,
    spaceColor,
    opacity,
    spaceOpacity,
    setOptions,
  ]);

  return (
    <Segment secondary attached="bottom">
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="strip-pattern-opt-weight">
            Hauteur ligne : {weight}
          </label>
          <input
            id="strip-pattern-opt-weight"
            type="range"
            min="0"
            max={basePatternOptHeight}
            step="1"
            value={weight}
            onChange={(e) => {
              const newValue = parseInt(e.target.value, 10);
              if (newValue + spaceWeight <= basePatternOptHeight) {
                setWeight(newValue);
              } else {
                if (newValue <= basePatternOptHeight) {
                  setWeight(newValue);
                  setSpaceWeight(basePatternOptHeight - newValue);
                }
              }
            }}
          />
        </Form.Field>

        <Form.Field>
          <label htmlFor="strip-pattern-opt-space-weight">
            Hauteur inter-ligne : {spaceWeight}
          </label>
          <input
            id="strip-pattern-opt-space-weight"
            type="range"
            min="0"
            max={basePatternOptHeight}
            step="1"
            value={spaceWeight}
            onChange={(e) => {
              const newValue = parseInt(e.target.value, 10);
              if (newValue + weight <= basePatternOptHeight) {
                setSpaceWeight(newValue);
              } else {
                if (newValue <= basePatternOptHeight) {
                  setSpaceWeight(newValue);
                  setWeight(basePatternOptHeight - newValue);
                }
              }
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="stripe-pattern-opt-color">Couleur ligne</label>
          <input
            id="stripe-pattern-opt-color"
            type="color"
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
        </Form.Field>

        <Form.Field>
          <label htmlFor="stripe-pattern-opt-space-color">
            Couleur inter-ligne
          </label>
          <input
            id="stripe-pattern-opt-space-color"
            type="color"
            value={spaceColor}
            onChange={(e) => {
              setSpaceColor(e.target.value);
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="stripe-pattern-opt-opacity">
            Opacité ligne : {opacity}
          </label>
          <input
            id="stripe-pattern-opt-opacity"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={opacity}
            onChange={(e) => {
              setOpacity(parseFloat(e.target.value));
            }}
          />
        </Form.Field>

        <Form.Field>
          <label htmlFor="stripe-pattern-opt-space-opacity">
            Opacité inter-ligne : {spaceOpacity}
          </label>
          <input
            id="stripe-pattern-opt-space-opacity"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={spaceOpacity}
            onChange={(e) => {
              setSpaceOpacity(parseFloat(e.target.value));
            }}
          />
        </Form.Field>
      </Form.Group>
    </Segment>
  );
}

export { StripesPatternForm };
