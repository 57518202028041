// React
import React from "react";

// Semantic ui
import { Breadcrumb, Segment } from "semantic-ui-react";

// Constants
import { COMPONENTS } from "../../../utils/Constants";

// Components
import MessageDisplayer from "../../../Common/components/MessageDisplayer";

const ManagerLayout = ({ breadcrumbItems, children }) => (
  <>
    <Segment>
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </Segment>

    <MessageDisplayer location={COMPONENTS.MANAGER.MANAGER} />

    {children}
  </>
);

export default ManagerLayout;
