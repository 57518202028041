// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// Semantic ui
import {
  Form,
  Dropdown,
  Grid,
  Button,
  Segment,
  Header,
  Divider,
} from "semantic-ui-react";

// GraphQL
import { UPDATE_LAYER_QUESTIONNAIRE } from "../../../_GraphQL/mutations";
import { ADMIN_QUESTIONNAIRE_SET } from "../../../_GraphQL/queries";

// Components
import EQuery from "../../../../../utils/EQuery";
import EMutation from "../../../../../utils/EMutation";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";
import { QuestionnaireUsage } from "../../QuestionnaireUsage";
import { QUESTIONNAIRE_FILTER } from "../../../../../utils/Constants";
class EditForm extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    const questionnnaireRead = this.props.questionnaire
      ? `Questionnaire : ${this.props.questionnaire.name}`
      : "Aucun questionnaire affecté à cette couche.";
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.props.handleSubmit(this.props.mutate);
        }}
        warning
      >
        <Form.Field width={4}>
          {this.props.editing ? (
            <>
              <label>Questionnaire</label>
              <Dropdown
                placeholder="Type"
                required
                selection
                options={this.props.questionnaireSet}
                value={this.props.selectedQuestionnaireId}
                onChange={this.props.onQuestionnaireChange}
              />
            </>
          ) : (
            <label>{questionnnaireRead}</label>
          )}
        </Form.Field>
        {this.props.editing ? (
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button positive type="submit">
                  Enregistrer
                </Button>
                <Button negative onClick={this.props.cancel}>
                  Annuler
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
      </Form>
    );
  }
}

class LayerQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQuestionnaireId: -1,
    };
  }

  onQuestionnaireChange = (e, { value }) =>
    this.setState({ selectedQuestionnaireId: value });

  toReadMode = () =>
    this.setState({ editing: false }, () => this.props.toReadMode());

  toEditMode = () =>
    this.setState({ editing: true }, () => this.props.toEditMode());

  initFields = () => {
    this.setState({
      selectedQuestionnaireId: this.props.layer.questionnaire
        ? this.props.layer.questionnaire.id
        : -1,
    });
  };

  handleSubmit = (mutate) => {
    let variables = {
      layerId: this.props.layer.id,
      questionnaireId: this.state.selectedQuestionnaireId,
    };

    mutate({ variables });
  };

  update = (cache, { data: { updateLayerQuestionnaire } }) => {
    cache.modify({
      id: `LayerType:${this.props.layer.id}`,
      fields: {
        questionnaire() {
          return updateLayerQuestionnaire.layer.questionnaire;
        },
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Le questionnaire a été mis à jour.",
    });
    this.toReadMode();
  };

  render() {
    let buttons = null;
    const { layer, questionnaireSet } = this.props;

    if (!this.props.editMode && !this.state.editing) {
      if (layer.questionnaire) {
        buttons = (
          <Button.Group>
            <Button
              content="Modifier le questionnaire"
              as={Link}
              to={`/manager/couche/${layer.id}/questionnaire/${layer.questionnaire.id}`}
            />
            <Button.Or text="ou" />
            <Button primary onClick={this.toEditMode}>
              Sélectionner un autre questionnaire
            </Button>
          </Button.Group>
        );
      } else {
        buttons = (
          <Button.Group>
            <Button
              content="Créer un questionnaire"
              as={Link}
              to={`/manager/couche/${layer.id}/questionnaire/nouveau`}
            />
            <Button.Or text="ou" />
            <Button primary onClick={this.toEditMode}>
              Sélectionner un questionnaire existant
            </Button>
          </Button.Group>
        );
      }
    }

    return (
      <Segment secondary>
        <Header as="h5">
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column verticalAlign="middle" width={4}>
                Questionnaire
              </Grid.Column>
              <Grid.Column width={12} textAlign="right">
                {buttons}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <Divider />
        {this.state.editing ? (
          <EMutation
            mutation={UPDATE_LAYER_QUESTIONNAIRE}
            update={this.update}
            onCompleted={this.onCompleted}
          >
            {(mutate) => (
              <EditForm
                onQuestionnaireChange={this.onQuestionnaireChange}
                selectedQuestionnaireId={this.state.selectedQuestionnaireId}
                questionnaire={layer.questionnaire}
                handleSubmit={this.handleSubmit}
                mutate={mutate}
                editing={true}
                cancel={this.toReadMode}
                questionnaireSet={questionnaireSet}
                initFields={this.initFields}
              />
            )}
          </EMutation>
        ) : (
          <EditForm
            questionnaire={layer.questionnaire}
            selectedQuestionnaireId={this.state.selectedQuestionnaireId}
            editing={false}
            cancel={this.toReadMode}
            questionnaireSet={questionnaireSet}
            initFields={this.initFields}
          />
        )}
        {layer.questionnaire ? (
          <>
            <QuestionnaireUsage
              questionnaireId={layer.questionnaire.id}
              asSegment={false}
            />
          </>
        ) : null}
      </Segment>
    );
  }
}

const LayerQuestionnaireQuery = ({
  layer,
  editMode,
  toEditMode,
  toReadMode,
}) => {
  return (
    <EQuery
      query={ADMIN_QUESTIONNAIRE_SET}
      variables={{ orphelin: QUESTIONNAIRE_FILTER.ALL }}
    >
      {(data) => {
        const questionnaireSet = data.adminQuestionnaireSet.map((q) => ({
          key: q.id,
          value: q.id,
          text: q.name,
        }));

        return (
          <LayerQuestionnaire
            questionnaireSet={questionnaireSet}
            layer={layer}
            editMode={editMode}
            toEditMode={toEditMode}
            toReadMode={toReadMode}
          />
        );
      }}
    </EQuery>
  );
};

export default LayerQuestionnaireQuery;
