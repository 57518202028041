import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Segment,
  Modal,
  Button,
  Icon,
  Dimmer,
  Loader,
  Message,
} from "semantic-ui-react";
import ErrorMessage from "../../../../../Common/components/ErrorMessage";
import { ADMIN_PWD_RESET_AND_SEND } from "../../../_GraphQL/mutations";

export function UserPasswordReset({ editMode, userId }) {
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [reset, { error, loading }] = useMutation(ADMIN_PWD_RESET_AND_SEND, {
    onCompleted() {
      setSuccess(true);
      setTimeout(() => setSuccess(false), 5000);
      setOpen(false);
    },
    onError() {},
  });

  return (
    <Segment secondary>
      {success ? (
        <Message positive>
          Le nouveau mot de passe a été envoyé à l&apos;utilisateur.
        </Message>
      ) : null}
      <Modal
        open={open}
        trigger={
          <Button disabled={editMode}>
            Réinitialiser et envoyer le mot de passe
          </Button>
        }
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Content>
          {loading ? (
            <Dimmer active>
              <Loader active />
            </Dimmer>
          ) : (
            <>
              {error ? <ErrorMessage error={error} /> : null}
              Le mot de passe actuel de l&apos;utilisateur sera remplacé par un
              mot de passe généré aléatoirement.
              <br />
              Le nouveau mot de passe sera envoyé par mail à l&apos;utilisateur.
              <br />
              Confirmez-vous ces actions ?
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setOpen(false)}>
            <Icon name="remove" /> Non
          </Button>
          <Button
            color="green"
            onClick={() => reset({ variables: { userId: userId } })}
          >
            <Icon name="checkmark" /> Oui
          </Button>
        </Modal.Actions>
      </Modal>
    </Segment>
  );
}
