// React
import React from "react";

// GraphQL
import { useMutation } from "@apollo/client";
import { ADMIN_TOGGLE_USER_ACTIVITY } from "../../../_GraphQL/mutations";
import { ADMIN_USER } from "../../../_GraphQL/queries";

// Constants
import { COMPONENTS } from "../../../../../utils/Constants";

// Semantic ui
import { Button, Grid, Header, Segment } from "semantic-ui-react";

// Message
import {
  addSuccessMessage,
  addErrorMessage,
  getMessageTextFromApolloError,
} from "../../../../../_GraphQL/message";

const UserActivity = ({ user, editMode }) => {
  const [adminToggleUserActivity] = useMutation(ADMIN_TOGGLE_USER_ACTIVITY, {
    update(cache, { data: { adminToggleUserActivity } }) {
      cache.writeQuery({
        query: ADMIN_USER,
        variables: {
          userId: user.id,
        },
        data: { adminUser: adminToggleUserActivity.user },
      });
    },
    onCompleted() {
      addSuccessMessage({
        location: COMPONENTS.MANAGER.MANAGER,
        header: `Utilisateur ${user.isActive ? "activé" : "désactivé"}`,
        content: `L'utilisateur a été ${
          user.isActive ? "activé" : "désactivé"
        }`,
      });
    },
    onError(error) {
      let message = getMessageTextFromApolloError(error);
      message.location = COMPONENTS.MANAGER.MANAGER;
      addErrorMessage(message);
    },
  });

  return (
    <Segment secondary>
      <Header as="h5">
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              L&apos;utilisateur est {user.isActive ? "actif" : "inactif"}
            </Grid.Column>
            <Grid.Column textAlign="right">
              {!editMode ? (
                <Button
                  primary
                  floated="right"
                  onClick={() =>
                    adminToggleUserActivity({
                      variables: { userId: user.id },
                    })
                  }
                >
                  {user.isActive ? "Désactiver" : "Activer"} l&apos;utilisateur
                </Button>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Header>
    </Segment>
  );
};

export { UserActivity };
