// React
import React, { Fragment } from "react";

// Semantic ui
import { Form, Checkbox } from "semantic-ui-react";

// GraphQL
import { UPDATE_PROJECT } from "../../_GraphQL/mutations";

// Components
import { EditSegment } from "../../../../Common/components/EditSegment";

// Message
import { addSuccessToastMessage } from "../../../../_GraphQL/message";
import { SITE_NAMES } from "../../../../utils/Constants";

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <>
        <Form.Field width={6}>
          <label>Nom</label>
          <input
            placeholder="Nom"
            required
            value={this.props.name}
            onChange={this.props.onNameChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={6}>
          <label>Description</label>
          <input
            placeholder="Description"
            value={this.props.description}
            onChange={this.props.onDescriptionChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        {this.props.ici ? (
          <>
            <Form.Field width={12}>
              <label>
                Slug - Nom qui apparait dans l'adresse internet du projet.
                <br />
                Caractères minuscules et tirets uniquement, pas d'espace.
                <br />
                Ex: pour le pojet &laquo;PLUi Bretagne romantique&raquo; choisir
                &laquo;bret-rom&raquo; ce qui donnera l'adresse :
                https://ici.realta-inno.fr/bret-rom/
              </label>
              <input
                placeholder="Slug"
                value={this.props.slug}
                onChange={this.props.onSlugChange}
                disabled={!this.props.enabled}
                pattern="[a-z\-]+"
              />
            </Form.Field>

            <Form.Field width={6}>
              <label>Activer les notifications</label>
              <Checkbox
                checked={this.props.notifications}
                onChange={this.props.onNotificationsChange}
                disabled={!this.props.enabled}
              />
            </Form.Field>
            {this.props.notifications ? (
              <Form.Field width={6}>
                <label>Groupes autorisés à envoyer des notifications</label>
                {this.props.groups.map((group) => (
                  <Fragment key={group.id}>
                    <Checkbox
                      disabled={!this.props.enabled}
                      label={group.name}
                      value={group.id}
                      checked={this.props.notificationsSenderGroups.includes(
                        group.id
                      )}
                      onChange={this.props.onNotificationsSenderGroupsChange}
                    />
                    <br />
                  </Fragment>
                ))}
              </Form.Field>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

class ProjectInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      slug: "",
      notifications: false,
      notificationsSenderGroups: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    let notificationsSenderGroups = [];

    if (this.props.project.notificationsSenderGroups) {
      notificationsSenderGroups =
        this.props.project.notificationsSenderGroups.split(",");
    }

    this.setState({
      name: this.props.project.name,
      description: this.props.project.description || "",
      slug: this.props.project.slug || "",
      notifications: this.props.project.notificationsActive,
      notificationsSenderGroups: notificationsSenderGroups,
    });
  };

  onNameChange = (e) => this.setState({ name: e.target.value });

  onDescriptionChange = (e) => this.setState({ description: e.target.value });

  onSlugChange = (e) => this.setState({ slug: e.target.value });

  onNotificationsChange = (e, { checked }) =>
    this.setState({ notifications: checked });

  onNotificationsSenderGroupsChange = (e, { value, checked }) => {
    if (checked) {
      this.setState({
        notificationsSenderGroups: [
          ...this.state.notificationsSenderGroups,
          value,
        ],
      });
    } else {
      this.setState({
        notificationsSenderGroups: this.state.notificationsSenderGroups.filter(
          (group) => group !== value
        ),
      });
    }
  };

  handleSubmit = (mutate) =>
    mutate({
      variables: {
        projectId: this.props.project.id,
        name: this.state.name,
        description: this.state.description,
        slug: this.state.slug,
        notificationsActive: this.state.notifications,
        notificationsSenderGroups: this.state.notificationsSenderGroups,
      },
    });

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Les informations ont été enregistrées.",
    });
    this.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="Informations"
        buttonText="Modifier"
        mutation={UPDATE_PROJECT}
        onCompleted={this.onCompleted}
        update={() => {}}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            name={this.state.name}
            description={this.state.description}
            ici={this.props.project.site.name === SITE_NAMES.ICI}
            slug={this.state.slug}
            enabled={enabled}
            initFields={this.initFields}
            onNameChange={this.onNameChange}
            onDescriptionChange={this.onDescriptionChange}
            onSlugChange={this.onSlugChange}
            notifications={this.state.notifications}
            notificationsSenderGroups={this.state.notificationsSenderGroups}
            onNotificationsChange={this.onNotificationsChange}
            onNotificationsSenderGroupsChange={
              this.onNotificationsSenderGroupsChange
            }
            groups={this.props.groups}
          />
        )}
      </EditSegment>
    );
  }
}

export default ProjectInfos;
