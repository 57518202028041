// React
import React from "react";

// Semantic ui
import { Form, Image, Input } from "semantic-ui-react";

// GraphQL
import { UPDATE_TASK_ILLUSTRATION } from "../../../_GraphQL/mutations";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    if (this.props.enabled) {
      return (
        <Form.Field width={6}>
          <label>Illustration</label>
          <Input type="file" onChange={this.props.onIllustrationChange} />
        </Form.Field>
      );
    } else if (this.props.illustrationPath) {
      return (
        <Image
          src={`/media/tasks/${this.props.illustrationPath}`}
          alt="Illustration"
        />
      );
    } else {
      return null;
    }
  }
}

class TaskIllustration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      illustration: "",
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => this.setState({ illustration: "" });

  onIllustrationChange = ({
    target: {
      files: [file],
    },
  }) => this.setState({ illustration: file });

  handleSubmit = (mutate) =>
    mutate({
      variables: {
        illustration: this.state.illustration,
        taskId: this.props.task.id,
      },
    });

  update = (cache, { data: { updateTaskIllustration } }) => {
    cache.modify({
      id: `TaskType:${this.props.task.id}`,
      fields: {
        illustration() {
          return updateTaskIllustration.task.illustration;
        },
        illustrationPath() {
          return updateTaskIllustration.task.illustrationPath;
        },
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "L'image a été enregistrée.",
    });
    this.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="Illustration"
        buttonText="Modifier"
        mutation={UPDATE_TASK_ILLUSTRATION}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            illustration={this.state.illustration}
            illustrationPath={this.props.task.illustrationPath}
            enabled={enabled}
            initFields={this.initFields}
            onIllustrationChange={this.onIllustrationChange}
          />
        )}
      </EditSegment>
    );
  }
}

export { TaskIllustration };
