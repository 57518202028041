// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { ADMIN_QUESTIONNAIRE_SET } from "../../_GraphQL/queries";

// Semantic ui
import { Table, Loader, Message } from "semantic-ui-react";

// Components
import { DeleteQuestionnaire } from "./DeleteQuestionnaire";
import ErrorMessage from "../../../../Common/components/ErrorMessage";

function QuestionnaireList({ questionnaireSet }) {
  if (questionnaireSet.length === 0) {
    return <Message>Aucun questionnnaire pour ce filtre</Message>;
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Intitulé du questionnaire</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {questionnaireSet.map((q) => (
          <Table.Row key={`questionnaire-${q.id}`}>
            <Table.Cell selectable>
              <Link
                to={`/manager/questionnaire/${q.id}${window.location.search}`}
              >
                {q.name}
              </Link>
            </Table.Cell>

            <Table.Cell textAlign="center" width={1}>
              <DeleteQuestionnaire
                questionnaireId={q.id}
                questionnaireName={q.name}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

function QuestionnaireListQuery(props) {
  const { loading, error, data } = useQuery(ADMIN_QUESTIONNAIRE_SET, {
    variables: {
      orphelin: props.orphan,
      taskId: props.taskId,
      siteId: props.siteId,
      projectId: props.projectId,
    },
    fetchPolicy: "network-only",
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  const questionnaireSet = data.adminQuestionnaireSet.filter((q) =>
    q.name.toLowerCase().includes(props.search.toLowerCase())
  );

  return <QuestionnaireList questionnaireSet={questionnaireSet} />;
}

export { QuestionnaireListQuery as QuestionnaireList };
