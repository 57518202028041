import React, { useState, useEffect } from "react";
import { Header, Segment, Form, Checkbox, Select } from "semantic-ui-react";
import {
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  PATTERN_TYPES,
} from "../../../../../../utils/Constants";

const patternTypeCodeOptions = [
  {
    key: PATTERN_TYPES.STRIPES,
    value: PATTERN_TYPES.STRIPES,
    text: "Hachures",
  },
  {
    key: PATTERN_TYPES.RECTANGLE,
    value: PATTERN_TYPES.RECTANGLE,
    text: "Rectangles",
  },
  {
    key: PATTERN_TYPES.CIRCLE,
    value: PATTERN_TYPES.CIRCLE,
    text: "Cercles",
  },
  {
    key: PATTERN_TYPES.PATH,
    value: PATTERN_TYPES.PATH,
    text: "Forme libre",
  },
];

function PathForm({
  initStyle,
  geoTypeCode,
  setPathOptions,
  usePattern,
  setUsePattern,
  patternTypeCode,
  setPatternTypeCode,
}) {
  // Styles Path Options
  const [poStroke, setPoStroke] = useState(true); // 	Boolean 	true 	Whether to draw stroke along the path. Set it to false to disable borders on polygons or circles.
  const [poColor, setPoColor] = useState("#3388ff"); // 	String 	'#3388ff' 	Stroke color
  const [poWeight, setPoWeight] = useState(3); // 	Number 	3 	Stroke width in pixels
  const [poOpacity, setPoOpacity] = useState(1.0); // 	Number 	1.0 	Stroke opacity
  const [poDashArray, setPoDashArray] = useState(""); // 	String 	null 	A string that defines the stroke dash pattern. Doesn't work on Canvas-powered layers in some old browsers.-powered layers in some old browsers.
  const [poFill, setPoFill] = useState(true); // 	Boolean 	depends 	Whether to fill the path with color. Set it to false to disable filling on polygons or circles.
  const [poFillColor, setPoFillColor] = useState("#3388ff"); // 	String 	* 	Fill color. Defaults to the value of the color option
  const [poFillOpacity, setPoFillOpacity] = useState(0.5); // 	Number 	0.2 	Fill opacity.

  // Initialisation
  useEffect(() => {
    if (initStyle) {
      if (initStyle.hasOwnProperty("stroke")) {
        setPoStroke(initStyle.stroke);
      }

      if (initStyle.hasOwnProperty("color")) {
        setPoColor(initStyle.color);
      }

      if (initStyle.hasOwnProperty("weight")) {
        setPoWeight(initStyle.weight);
      }

      if (initStyle.hasOwnProperty("opacity")) {
        setPoOpacity(initStyle.opacity);
      }

      if (initStyle.hasOwnProperty("dashArray")) {
        setPoDashArray(initStyle.dashArray);
      }

      if (initStyle.hasOwnProperty("fill")) {
        setPoFill(initStyle.fill);
      }

      if (initStyle.hasOwnProperty("fillColor")) {
        setPoFillColor(initStyle.fillColor);
      }

      if (initStyle.hasOwnProperty("fillOpacity")) {
        setPoFillOpacity(initStyle.fillOpacity);
      }
    }
  }, [initStyle]);

  useEffect(() => {
    if (geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON) {
      setPathOptions({
        stroke: poStroke,
        color: poColor,
        weight: poWeight,
        opacity: poOpacity,
        dashArray: poDashArray,
        fill: poFill,
        fillColor: poFillColor,
        fillOpacity: poFillOpacity,
      });
    } else {
      setPathOptions({
        stroke: poStroke,
        color: poColor,
        weight: poWeight,
        opacity: poOpacity,
        dashArray: poDashArray,
      });
    }
  }, [
    poStroke,
    poColor,
    poWeight,
    poOpacity,
    poDashArray,
    poFill,
    poFillColor,
    poFillOpacity,
    geoTypeCode,
    setPathOptions,
  ]);

  useEffect(() => {
    if (!poFill) {
      setUsePattern(false);
    }
  }, [poFill, setUsePattern]);

  return (
    <>
      <Header block as="h5" attached="top">
        Contour
      </Header>
      {geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON ? (
        <Segment secondary attached={poStroke ? true : "bottom"}>
          <Form.Field>
            <Checkbox
              label="Afficher le contour"
              checked={poStroke}
              onChange={() => setPoStroke(!poStroke)}
            />
          </Form.Field>
        </Segment>
      ) : null}
      {poStroke ? (
        <Segment secondary attached="bottom">
          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="po-weight">Épaisseur : {poWeight}</label>
              <input
                id="po-weight"
                type="range"
                min="1"
                max="20"
                step="1"
                value={poWeight}
                onChange={(e) => setPoWeight(parseInt(e.target.value, 10))}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="po-opacity">
                Opacité du contour : {poOpacity}
              </label>
              <input
                id="po-opacity"
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={poOpacity}
                onChange={(e) => setPoOpacity(parseFloat(e.target.value))}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="po-color">Couleur</label>
              <input
                id="po-color"
                type="color"
                value={poColor}
                onChange={(e) => setPoColor(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="po-dash-array">Pointillés</label>
              <input
                id="po-dash-array"
                type="text"
                value={poDashArray}
                onChange={(e) => setPoDashArray(e.target.value)}
              />
            </Form.Field>
          </Form.Group>
        </Segment>
      ) : null}

      {geoTypeCode === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON ? (
        <>
          <Header block as="h5" attached="top">
            Remplissage
          </Header>
          <Segment secondary attached={poFill ? true : "bottom"}>
            <Form.Field>
              <Checkbox
                label="Remplir le polygone"
                checked={poFill}
                onChange={() => setPoFill(!poFill)}
              />
            </Form.Field>
            {poFill ? (
              <>
                <Form.Field>
                  <Checkbox
                    label="Remplir avec un motif"
                    checked={usePattern}
                    onChange={() => setUsePattern(!usePattern)}
                  />
                </Form.Field>
                {usePattern ? (
                  <Form.Field>
                    <Select
                      options={patternTypeCodeOptions}
                      value={patternTypeCode}
                      onChange={(e, { value }) => setPatternTypeCode(value)}
                    />
                  </Form.Field>
                ) : null}
              </>
            ) : null}
          </Segment>
          {poFill ? (
            <Segment secondary attached={usePattern ? true : "bottom"}>
              {!usePattern ? (
                <Form.Field>
                  <label htmlFor="po-fill-color">Couleur</label>
                  <input
                    id="po-fill-color"
                    type="color"
                    value={poFillColor}
                    onChange={(e) => {
                      setPoFillColor(e.target.value);
                    }}
                  />
                </Form.Field>
              ) : null}
              <Form.Field>
                <label htmlFor="po-fill-opacity">
                  Opacité : {poFillOpacity}
                </label>
                <input
                  id="po-fill-opacity"
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={poFillOpacity}
                  onChange={(e) => {
                    setPoFillOpacity(parseFloat(e.target.value));
                  }}
                />
              </Form.Field>
            </Segment>
          ) : null}
        </>
      ) : null}
    </>
  );
}

export { PathForm };
