// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import { GET_LAYER } from "../../_GraphQL/queries";

// Semantic ui
import { Breadcrumb, Header } from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";
import QuestionnaireInfos from "../../components/Questionnaire/QuestionnaireInfos";
import EQuery from "../../../../utils/EQuery";

class CreateQuestionnaire extends React.Component {
  backToQuestionnaires = () =>
    this.props.history.push("/manager/questionnaires");

  backToLayer = () =>
    this.props.history.push(
      `/manager/couche/${this.props.match.params.layerId}`
    );

  render() {
    const {
      match: {
        params: { layerId, orphan, siteId, projectId, taskId },
      },
    } = this.props;

    if (layerId) {
      return (
        <EQuery query={GET_LAYER} variables={{ layerId: layerId }}>
          {(data) => {
            const layer = data.layer;
            return (
              <ManagerLayout
                breadcrumbItems={[
                  <Breadcrumb.Section key="breadcrumb-home">
                    <Link to="/manager">Manager</Link>
                  </Breadcrumb.Section>,
                  <Breadcrumb.Divider
                    key="bc-divider-sites"
                    icon="right angle"
                  />,
                  <Breadcrumb.Section key="breadcrumb-sites">
                    <Link to="/manager/sites">Sites</Link>
                  </Breadcrumb.Section>,
                  <Breadcrumb.Divider
                    key="bc-divider-site"
                    icon="right angle"
                  />,
                  <Breadcrumb.Section key="breadcrumb-site">
                    <Link to={`/manager/site/${layer.task.project.site.id}`}>
                      Site : {layer.task.project.site.name}
                    </Link>
                  </Breadcrumb.Section>,
                  <Breadcrumb.Divider
                    key="bc-divider-project"
                    icon="right angle"
                  />,
                  <Breadcrumb.Section key="breadcrumb-project">
                    <Link to={`/manager/projet/${layer.task.project.id}`}>
                      Projet : {layer.task.project.name}
                    </Link>
                  </Breadcrumb.Section>,
                  <Breadcrumb.Divider
                    key="bc-divider-module"
                    icon="right angle"
                  />,
                  <Breadcrumb.Section key="breadcrumb-module">
                    <Link
                      to={`/manager/carte/?projectId=${layer.task.project.id}`}
                    >
                      Module : Carte participative
                    </Link>
                  </Breadcrumb.Section>,
                  <Breadcrumb.Divider
                    key="bc-divider-task"
                    icon="right angle"
                  />,
                  <Breadcrumb.Section key="breadcrumb-task">
                    <Link to={`/manager/tache/${layer.task.id}`}>
                      Tâche : {layer.task.name}
                    </Link>
                  </Breadcrumb.Section>,
                  <Breadcrumb.Divider
                    key="bc-divider-layer"
                    icon="right angle"
                  />,
                  <Breadcrumb.Section key="breadcrumb-layer">
                    <Link to={`/manager/couche/${layer.id}`}>
                      Couche : {layer.name}
                    </Link>
                  </Breadcrumb.Section>,
                  <Breadcrumb.Divider
                    key="bc-divider-reation-questionnaire"
                    icon="right angle"
                  />,
                  <Breadcrumb.Section key="breadcrumb-field">
                    Création d&apos;un questionnaire
                  </Breadcrumb.Section>,
                ]}
              >
                <Header as="h5" block>
                  Création d&apos;un questionnaire
                </Header>
                <QuestionnaireInfos
                  create={true}
                  layerId={layerId}
                  toReadMode={this.backToLayer}
                />
              </ManagerLayout>
            );
          }}
        </EQuery>
      );
    }

    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider
            key="bc-divider-questionnaires"
            icon="right angle"
          />,
          <Breadcrumb.Section key="breadcrumb-questionnaires">
            <Link to="/manager/questionnaires">Questionnaires</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider
            key="bc-divider-reation-questionnaire"
            icon="right angle"
          />,
          <Breadcrumb.Section key="breadcrumb-field">
            Création d&apos;un questionnaire
          </Breadcrumb.Section>,
        ]}
      >
        <Header as="h5" block>
          Création d&apos;un questionnaire
        </Header>
        <QuestionnaireInfos
          create={true}
          toReadMode={this.backToQuestionnaires}
          orphan={orphan}
          siteId={siteId}
          projectId={projectId}
          taskId={taskId}
        />
      </ManagerLayout>
    );
  }
}

export { CreateQuestionnaire };
