import React, { useEffect, useState } from "react";
import { Form, Segment } from "semantic-ui-react";

function RectShapeForm({ setShapeRectOptions, initShapeOptions }) {
  const [shapeRectOptionX, setShapeRectOptionX] = useState(3); // {number} (default: 0) - x offset of the rectangle.
  const [shapeRectOptionY, setShapeRectOptionY] = useState(3); // {number} (default: 0) - y offset of the rectangle.
  const [shapeRectOptionWidth, setShapeRectOptionWidth] = useState(10); // {number} (default: 10) - width of the rectangle.
  const [shapeRectOptionHeight, setShapeRectOptionHeight] = useState(10); // {number} (default: 10) - height of the rectangle.
  const [shapeRectOptionRx, setShapeRectOptionRx] = useState(0); // {number} (default: null) - x radius for rounded corners
  const [shapeRectOptionRy, setShapeRectOptionRy] = useState(0); // {number} (default: null) - y radius for rounded corners

  // Initialisation
  useEffect(() => {
    if (initShapeOptions) {
      if (initShapeOptions.hasOwnProperty("x")) {
        setShapeRectOptionX(initShapeOptions.x);
      }

      if (initShapeOptions.hasOwnProperty("y")) {
        setShapeRectOptionY(initShapeOptions.y);
      }

      if (initShapeOptions.hasOwnProperty("width")) {
        setShapeRectOptionWidth(initShapeOptions.width);
      }

      if (initShapeOptions.hasOwnProperty("height")) {
        setShapeRectOptionHeight(initShapeOptions.height);
      }

      if (initShapeOptions.hasOwnProperty("rx")) {
        setShapeRectOptionRx(initShapeOptions.rx);
      }

      if (initShapeOptions.hasOwnProperty("ry")) {
        setShapeRectOptionRy(initShapeOptions.ry);
      }
    }
  }, [initShapeOptions]);

  useEffect(() => {
    setShapeRectOptions({
      x: shapeRectOptionX,
      y: shapeRectOptionY,
      width: shapeRectOptionWidth,
      height: shapeRectOptionHeight,
      rx: shapeRectOptionRx,
      ry: shapeRectOptionRy,
    });
  }, [
    shapeRectOptionX,
    shapeRectOptionY,
    shapeRectOptionWidth,
    shapeRectOptionHeight,
    shapeRectOptionRx,
    shapeRectOptionRy,
    setShapeRectOptions,
  ]);

  return (
    <Segment secondary attached="bottom">
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="shape-rect-opt-x">
            Décalage en abscisse : {shapeRectOptionX}
          </label>
          <input
            id="shape-rect-opt-x"
            type="range"
            min={0}
            max={20}
            step={1}
            value={shapeRectOptionX}
            onChange={(e) => setShapeRectOptionX(parseInt(e.target.value, 10))}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="shape-rect-opt-y">
            Décalage en ordonnée : {shapeRectOptionY}
          </label>
          <input
            id="shape-rect-opt-y"
            type="range"
            min={0}
            max={20}
            step={1}
            value={shapeRectOptionY}
            onChange={(e) => setShapeRectOptionY(parseInt(e.target.value, 10))}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="shape-rect-opt-width">
            Largeur du rectangle : {shapeRectOptionWidth}
          </label>
          <input
            id="shape-rect-opt-width"
            type="range"
            min={0}
            max={50}
            step={1}
            value={shapeRectOptionWidth}
            onChange={(e) =>
              setShapeRectOptionWidth(parseInt(e.target.value, 10))
            }
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="shape-rect-opt-height">
            Hauteur du rectangle : {shapeRectOptionHeight}
          </label>
          <input
            id="shape-rect-opt-height"
            type="range"
            min={0}
            max={50}
            step={1}
            value={shapeRectOptionHeight}
            onChange={(e) =>
              setShapeRectOptionHeight(parseInt(e.target.value, 10))
            }
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="shape-rect-opt-rx">
            Rayon X : {shapeRectOptionRx}
          </label>
          <input
            id="shape-rect-opt-rx"
            type="range"
            min={0}
            max={20}
            step={1}
            value={shapeRectOptionRx}
            onChange={(e) => setShapeRectOptionRx(parseInt(e.target.value, 10))}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="shape-rect-opt-ry">
            Rayon Y : {shapeRectOptionRy}
          </label>
          <input
            id="shape-rect-opt-ry"
            type="range"
            min={0}
            max={20}
            step={1}
            value={shapeRectOptionRy}
            onChange={(e) => setShapeRectOptionRy(parseInt(e.target.value, 10))}
          />
        </Form.Field>
      </Form.Group>
    </Segment>
  );
}

export { RectShapeForm };
