import React from "react";
import PrivateRoute from "../../../../utils/Privateroute";
import { Styles as StyleList } from "../../pages/Styles/Styles";
import { Create as CreateStyle } from "../../pages/Styles/Create";
import { Update as UpdateStyle } from "../../pages/Styles/Update";

function Styles() {
  return (
    <>
      <PrivateRoute path="/manager/styles" exact component={StyleList} />

      <PrivateRoute
        path="/manager/style/creation"
        exact
        component={CreateStyle}
      />

      <PrivateRoute
        path="/manager/style/modification/:styleId(\d+)"
        exact
        component={UpdateStyle}
      />
    </>
  );
}

export { Styles };
