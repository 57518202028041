// GraphQL
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { messageSetVar } from "./message";
import { makeVar } from "@apollo/client";

export const menuDisabledVar = makeVar(false);
export const fullScreenVar = makeVar(false);
export const isConnectedVar = makeVar(false);
export const csrfVar = makeVar(null);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        messageSet: {
          read() {
            return messageSetVar();
          },
        },
        menuDisabled: {
          read() {
            return menuDisabledVar();
          },
        },
        isConnected: {
          read() {
            return isConnectedVar();
          },
        },
        style(_, { args, toReference }) {
          return toReference({
            __typename: "StyleType",
            id: args.styleId,
          });
        },
        adminAvailableGroupForLayerSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        adminAssignedGroupForLayerSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        adminAvailableGroupForSiteSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        adminAssignedGroupForSiteSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        adminGroupSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        adminAvailableModuleSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        adminAssignedModuleSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        adminProjectSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
        projectUserSet: {
          merge(existing = [], incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const uploadLink = createUploadLink({ uri: "/graphql/" });

const typeDefs = `
    type MessageType {
        id: ID!
        autoClose: Boolean!
        closeTime: Int
        location: String!
        type: String!
        header: String
        content: String
        messageList: [String]
    }
`;

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([uploadLink]),
  typeDefs,
});

client.onResetStore(() => {
  menuDisabledVar(false);
  fullScreenVar(false);
  isConnectedVar(false);
  messageSetVar([]);
});

export default client;
