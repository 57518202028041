// React
import React from "react";

// Semantic ui
import { Form, Dropdown } from "semantic-ui-react";

// GraphQL
import { UPDATE_TILE_LAYER } from "../../../_GraphQL/mutations";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

import { TARGET } from "../CreateTask/CreateTileLayer";
import { GET_PROJECT } from "../../../../../Common/_GraphQL/queries";

const typeOptions = [
  {
    key: "streets-v12",
    value: "streets-v12",
    text: "Rue",
  },
  {
    key: "satellite-streets-v12",
    value: "satellite-streets-v12",
    text: "Rue + Satellite",
  },
  {
    key: "satellite-v9",
    value: "satellite-v9",
    text: "Satellite",
  },
  {
    key: "outdoors-v12",
    value: "outdoors-v12",
    text: "Topographique",
  },
];

export { typeOptions };

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    const forProject = this.props.target === TARGET.TARGET_PROJECT;

    return (
      <>
        {forProject ? null : (
          <>
            <Form.Field width={6}>
              <label>Nom</label>
              <input
                placeholder="Nom"
                required
                value={this.props.name}
                onChange={this.props.onChangeName}
                disabled={!this.props.enabled}
              />
            </Form.Field>
            <Form.Field width={6}>
              <label>Description</label>
              <input
                placeholder="Description"
                required
                value={this.props.description}
                onChange={this.props.onChangeDescription}
                disabled={!this.props.enabled}
              />
            </Form.Field>
          </>
        )}
        <Form.Field width={4}>
          <label>Type</label>
          <Dropdown
            placeholder="Type"
            required
            selection
            options={typeOptions}
            value={this.props.mapId}
            onChange={this.props.onChangeMapId}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={2}>
          <label>Latitude</label>
          <input
            required
            type="number"
            step="0.000001"
            value={this.props.latitude}
            onChange={this.props.onChangeLatitude}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={2}>
          <label>Longitude</label>
          <input
            required
            type="number"
            step="0.000001"
            value={this.props.longitude}
            onChange={this.props.onChangeLongitude}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={2}>
          <label>Zoom initial</label>
          <input
            required
            type="number"
            step="0.01"
            value={this.props.zoomInit}
            onChange={this.props.onChangeZoomInit}
            disabled={!this.props.enabled}
          />
        </Form.Field>

        {forProject ? null : (
          <>
            <Form.Field width={2}>
              <label>Zoom minimum</label>
              <input
                required
                type="number"
                step="0.01"
                value={this.props.zoomMin}
                onChange={this.props.onChangeZoomMin}
                disabled={!this.props.enabled}
              />
            </Form.Field>
            <Form.Field width={2}>
              <label>Zoom maximum</label>
              <input
                required
                type="number"
                step="0.01"
                value={this.props.zoomMax}
                onChange={this.props.onChangeZoomMax}
                disabled={!this.props.enabled}
              />
            </Form.Field>
          </>
        )}
      </>
    );
  }
}

class TileLayerInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      mapId: "",
      latitude: 0,
      longitude: 0,
      zoomInit: 0,
      zoomMin: 0,
      zoomMax: 0,
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    if (this.props.target === TARGET.TARGET_PROJECT) {
      this.setState({
        name: this.props.tileLayer.name,
        description: this.props.tileLayer.description,
        mapId: this.props.tileLayer.mapId,
        latitude: this.props.tileLayer.latitude,
        longitude: this.props.tileLayer.longitude,
        zoomInit: this.props.tileLayer.zoomInit,
        zoomMin: this.props.tileLayer.zoomMin,
        zoomMax: this.props.tileLayer.zoomMax,
      });
    } else {
      this.setState({
        name: this.props.task.tileLayer.name,
        description: this.props.task.tileLayer.description,
        mapId: this.props.task.tileLayer.mapId,
        latitude: this.props.task.tileLayer.latitude,
        longitude: this.props.task.tileLayer.longitude,
        zoomInit: this.props.task.tileLayer.zoomInit,
        zoomMin: this.props.task.tileLayer.zoomMin,
        zoomMax: this.props.task.tileLayer.zoomMax,
      });
    }
  };

  onChangeName = (e) => this.setState({ name: e.target.value });

  onChangeDescription = (e) => this.setState({ description: e.target.value });

  onChangeMapId = (e, data) => this.setState({ mapId: data.value });

  onChangeLatitude = (e) => this.setState({ latitude: e.target.value });

  onChangeLongitude = (e) => this.setState({ longitude: e.target.value });

  onChangeZoomInit = (e) => this.setState({ zoomInit: e.target.value });

  onChangeZoomMin = (e) => this.setState({ zoomMin: e.target.value });

  onChangeZoomMax = (e) => this.setState({ zoomMax: e.target.value });

  handleSubmit = (mutate) => {
    const tileLayerId =
      this.props.target === TARGET.TARGET_PROJECT
        ? this.props.tileLayer.id
        : this.props.task.tileLayer.id;
    mutate({
      variables: {
        tileLayerId: tileLayerId,
        name: this.state.name,
        description: this.state.description,
        mapId: this.state.mapId,
        latitude: parseFloat(this.state.latitude),
        longitude: parseFloat(this.state.longitude),
        zoomInit: parseFloat(this.state.zoomInit),
        zoomMin: parseFloat(this.state.zoomMin),
        zoomMax: parseFloat(this.state.zoomMax),
      },
    });
  };

  update = (
    cache,
    {
      data: {
        updateTileLayer: { tileLayer },
      },
    }
  ) => {
    if (this.props.target === TARGET.TARGET_PROJECT) {
      const { project } = cache.readQuery({
        query: GET_PROJECT,
        variables: {
          projectId: this.props.projectId,
        },
      });
      cache.writeQuery({
        query: GET_PROJECT,
        variables: {
          projectId: this.props.projectId,
        },
        data: {
          project: { ...project, tileLayer: tileLayer },
        },
      });
    } else {
      return null;
    }
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Le fond de carte a été enregistré.",
    });
    this.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="Fond de carte"
        buttonText="Modifier"
        mutation={UPDATE_TILE_LAYER}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            target={this.props.target}
            name={this.state.name}
            onChangeName={this.onChangeName}
            description={this.state.description}
            onChangeDescription={this.onChangeDescription}
            mapId={this.state.mapId}
            onChangeMapId={this.onChangeMapId}
            longitude={this.state.longitude}
            onChangeLongitude={this.onChangeLongitude}
            latitude={this.state.latitude}
            onChangeLatitude={this.onChangeLatitude}
            zoomInit={this.state.zoomInit}
            onChangeZoomInit={this.onChangeZoomInit}
            zoomMin={this.state.zoomMin}
            onChangeZoomMin={this.onChangeZoomMin}
            zoomMax={this.state.zoomMax}
            onChangeZoomMax={this.onChangeZoomMax}
            enabled={enabled}
            initFields={this.initFields}
          />
        )}
      </EditSegment>
    );
  }
}

export { TileLayerInfos };
