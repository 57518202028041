// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import {
  FILEMANAGER_PERMISSIONS,
  FILEMANAGER_PERMISSIONS_REVERSE,
} from "../../../../../utils/Constants";

// Semantic ui
import { Loader } from "semantic-ui-react";
import {
  DEFAULT_FOLDER_GRP_PERM_AND_GRP_FOR_SITE,
  DEFAULT_DOC_GRP_PERM_AND_GRP_FOR_SITE,
} from "../../../_GraphQL/queries";

// Components
import DefaultGroupPermissionsMutation from "./DefaultGroupPermissionsMutation";
import DefaultGroupPermissionsForm from "../../../../../Common/components/GroupPermission/GroupPermissionsForm";
import ErrorMessage from "../../../../../Common/components/ErrorMessage";

const filesTypes = {
  folder: {
    query: DEFAULT_FOLDER_GRP_PERM_AND_GRP_FOR_SITE,
  },
  document: {
    query: DEFAULT_DOC_GRP_PERM_AND_GRP_FOR_SITE,
  },
};

function DefaultGroupPermissionsQuery(props) {
  const { loading, error, data } = useQuery(filesTypes[props.fileType].query, {
    variables: {
      projectId: props.project.id.toString(),
      siteId: props.project.site.id,
    },
  });

  if (loading) return <Loader />;

  if (error) {
    return (
      <ErrorMessage
        Header={"Erreur lors du chargement des permissions."}
        error={error}
      />
    );
  }

  let groupPermissionSet =
    props.fileType === "folder"
      ? data.defaultFolderGroupPermissionSet
      : data.defaultDocumentGroupPermissionSet;
  let groups = data.adminGroupSet;

  if (props.editAuthorizations) {
    return (
      <DefaultGroupPermissionsMutation
        authorizations={groupPermissionSet}
        groups={groups}
        project={props.project}
        drawing={props.drawing}
        handleCancel={props.handleCancel}
        handleCompleted={props.handleCompleted}
        fileType={props.fileType}
      />
    );
  }

  let groupPermissionsRows = [];

  groupPermissionSet.forEach((gp) => {
    let perm = FILEMANAGER_PERMISSIONS[gp.permission];
    if (groupPermissionsRows.find((o) => o.group.id === gp.group.id)) {
      groupPermissionsRows.find((o) => o.group.id === gp.group.id)[perm] = true;
    } else {
      groupPermissionsRows.push({
        group: gp.group,
        canViewFolder:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canViewFolder,
        canChangeFolder:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canChangeFolder,
        canMoveFolder:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canMoveFolder,
        canDeleteFolder:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canDeleteFolder,
        canAddFolder:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canAddFolder,
        canAddDocument:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canAddDocument,
        canViewDocument:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canViewDocument,
        canMoveDocument:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canMoveDocument,
        canDeleteDocument:
          gp.permission === FILEMANAGER_PERMISSIONS_REVERSE.canDeleteDocument,
      });
    }
  });

  return (
    <DefaultGroupPermissionsForm
      groupPermissionsRows={groupPermissionsRows}
      fileType={props.fileType}
    />
  );
}

export default DefaultGroupPermissionsQuery;
