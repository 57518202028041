import React, { useEffect } from "react";
import { Loader, Segment } from "semantic-ui-react";
import { STYLE_SET } from "../../_GraphQL/queries";
import { useQuery } from "@apollo/client";
import { ErrorMessage } from "../../../../Common/components/ErrorMessage";
import { useLocation } from "react-router-dom";
import { Preview } from "./Preview/Preview";

function StyleSet({ styleSet, activeStyle, setActiveStyle, geoTypeCode }) {
  const query = new URLSearchParams(useLocation().search);
  const focusStyleId = query.get("focus");

  useEffect(() => {
    if (!activeStyle && styleSet.length > 0) {
      if (focusStyleId) {
        setActiveStyle(styleSet.find((style) => style.id === focusStyleId));
        // todo: gérer le type de géométrie du style qui a le focus
      } else {
        setActiveStyle(styleSet[0]);
      }
    }
  }, [setActiveStyle, styleSet, activeStyle, focusStyleId]);

  useEffect(() => {
    if (activeStyle) {
      if (styleSet.length > 0) {
        if (!styleSet.find((style) => style.id === activeStyle.id)) {
          setActiveStyle(styleSet[0]);
        }
      } else {
        setActiveStyle(null);
      }
    }
  }, [geoTypeCode, activeStyle, setActiveStyle, styleSet]);

  return (
    <Segment>
      <div style={{ display: "flex", flexWrap: "wrap", cursor: "pointer" }}>
        {styleSet.map((style) => (
          <div
            style={{
              display: "grid",
              alignItems: "center",
              height: "65px",
              borderRadius: "6px",
              boxShadow:
                activeStyle && activeStyle.id === style.id
                  ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                  : "",
            }}
            onClick={() => setActiveStyle(style)}
            key={`style-${style.id}`}
          >
            <Preview style={style} geoTypeCode={geoTypeCode} />
          </div>
        ))}
      </div>
    </Segment>
  );
}

function StyleSetQuery({ activeStyle, setActiveStyle, geoTypeCode }) {
  const { loading, error, data } = useQuery(STYLE_SET);

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  if (!data) return null;

  const styleSet = data.styleSet
    .filter((style) => style.geoTypeCode === geoTypeCode)
    .sort((a, b) => a.id - b.id);

  return (
    <StyleSet
      styleSet={styleSet}
      activeStyle={activeStyle}
      setActiveStyle={setActiveStyle}
      geoTypeCode={geoTypeCode}
    />
  );
}

export { StyleSetQuery as StyleSet };
