// React
import React from "react";

// GraphQL
import EMutation from "../../../../../utils/EMutation";
import { TASK_SET } from "../../../_GraphQL/queries";
import { CREATE_TASK } from "../../../_GraphQL/mutations";

// Semantic ui
import {
  Segment,
  Button,
  Header,
  Form,
  Input,
  Icon,
  Grid,
} from "semantic-ui-react";

class CreateTaskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      illustration: "",
    };
  }

  handleSubmit = (mutate) => {
    let variables = {
      name: this.state.name,
      illustration: this.state.illustration,
      projectId: this.props.project.id,
      tileLayerId: this.props.tileLayerId,
    };

    mutate.call(this, { variables }).catch((e) => {
      /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
    });
  };

  render() {
    return (
      <>
        <Segment attached>
          <Header as="h5">Création d&apos;une tâche</Header>

          <EMutation
            mutation={CREATE_TASK}
            update={(cache, { data: { createTask } }) => {
              const data = cache.readQuery({
                query: TASK_SET,
                variables: { projectId: this.props.project.id },
              });
              cache.writeQuery({
                query: TASK_SET,
                variables: { projectId: this.props.project.id },
                data: {
                  taskSet: data.taskSet.concat([createTask.task]),
                },
              });
            }}
            onCompleted={(data) =>
              this.props.goToAutorisations(data.createTask.task)
            }
          >
            {(mutate) => (
              <Form>
                <Form.Field width={6}>
                  <label>Nom</label>
                  <input
                    placeholder="Nom"
                    required
                    value={this.state.name}
                    onChange={(event) =>
                      this.setState({ name: event.target.value })
                    }
                  />
                </Form.Field>
                <Form.Field width={6}>
                  <label>Illustration</label>
                  <Input
                    type="file"
                    onChange={({
                      target: {
                        files: [file],
                      },
                    }) => this.setState({ illustration: file })}
                  />
                </Form.Field>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Button
                        positive
                        onClick={this.handleSubmit.bind(this, mutate)}
                      >
                        <Icon name="checkmark" /> Enregistrer
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </EMutation>
        </Segment>
        <Segment attached="bottom">
          <Button onClick={() => this.props.goToTileLayer()}>
            Retourner à l&apos;étape précédente
          </Button>
        </Segment>
      </>
    );
  }
}

export { CreateTaskForm };
