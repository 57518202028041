// React
import React from "react";

// GraphQL
import { useMutation } from "@apollo/client";
import { TASK_SET } from "../../_GraphQL/queries";
import { DELETE_TASK } from "../../_GraphQL/mutations";

// Semantic ui
import { Button, Modal } from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import LoaderDisplayer from "../../../../Common/components/LoaderDisplayer";

const DeleteTaskMutation = ({ task, closeDeleteTask, projectId }) => {
  const [deleteTask, { loading, error }] = useMutation(DELETE_TASK, {
    update(cache) {
      let data = cache.readQuery({
        query: TASK_SET,
        variables: { projectId },
      });
      cache.writeQuery({
        query: TASK_SET,
        variables: { projectId },
        data: {
          taskSet: data.taskSet.filter((t) => t.id !== task.id),
        },
      });
    },
    onCompleted() {
      closeDeleteTask();
    },
  });

  if (loading) {
    return (
      <Modal.Content>
        <LoaderDisplayer />
      </Modal.Content>
    );
  }

  if (error) {
    return (
      <Modal.Content>
        <ErrorMessage error={error} />
        <Button content="Annuler" onClick={closeDeleteTask} />
      </Modal.Content>
    );
  }

  return (
    <Modal.Content>
      <p>Confirmez-vous la suppression de la tâche {task.name} ?</p>
      <Button content="Annuler" onClick={closeDeleteTask} />
      <Button
        positive
        content="Supprimer"
        onClick={() =>
          deleteTask({ variables: { taskId: task.id } }).catch((e) => {
            /* Le catch sert à éviter un unhandled rejection error en cas d'erreur réseau. */
          })
        }
      />
    </Modal.Content>
  );
};

export const DeleteTask = ({ task, modalOpen, closeDeleteTask, projectId }) => (
  <Modal open={modalOpen} onCancel={closeDeleteTask}>
    <DeleteTaskMutation
      closeDeleteTask={closeDeleteTask}
      task={task}
      projectId={projectId}
    />
  </Modal>
);
