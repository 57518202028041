import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "../../utils/Privateroute";
import Manager from "../../Modules/Manager/routes/Manager";
import { Home } from "../../Modules/Home/Home";
import { PasswordResetRequest } from "../../Modules/Profile/pages/PasswordResetRequest";
import { PasswordReset } from "../../Modules/Profile/pages/PasswordReset";

const MainContainer = () => {
  return (
    <Switch>
      <Route
        key="home-container-route"
        render={({ location }) => <Home location={location} />}
        exact
        path="/"
      />
      <PrivateRoute
        key="manager-container-route"
        path="/manager"
        component={Manager}
      />
      <Route
        key="password-reset-request"
        path="/compte/mdp-oublie"
        component={PasswordResetRequest}
      />
      <Route
        key="password-reset"
        path="/compte/nouveau-mdp"
        component={PasswordReset}
      />
    </Switch>
  );
};

export default MainContainer;
