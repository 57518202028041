// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { ADMIN_DRAWING_SET } from "../../_GraphQL/queries";

// Semantic ui
import { Loader, List } from "semantic-ui-react";

// Component
import ErrorMessage from "../../../../Common/components/ErrorMessage";

function DrawingListQuery({ projectId }) {
  const { loading, error, data } = useQuery(ADMIN_DRAWING_SET, {
    variables: { projectId: projectId },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <List>
      {data.adminDrawingSet.map((drawing) => (
        <List.Item key={`drawing-${drawing.id}`}>
          <Link to={`/manager/dessin/dessin/${drawing.id}`}>
            {drawing.name}
          </Link>
        </List.Item>
      ))}
    </List>
  );
}

export default DrawingListQuery;
