// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { ADMIN_USER } from "../../_GraphQL/queries";

// Semantic ui
import { Loader, Breadcrumb } from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";

import { UserInfos } from "../../components/Users/User/UserInfos";
import { UserSites } from "../../components/Users/User/UserSites";
import { UserProjects } from "../../components/Users/User/UserProjects";
import { UserGroups } from "../../components/Users/User/UserGroups";
import { UserPassword } from "../../components/Users/User/UserPassword";
import { UserActivity } from "../../components/Users/User/UserActivity";
import { UserPasswordReset } from "../../components/Users/User/UserPasswordReset";

import ErrorMessage from "../../../../Common/components/ErrorMessage";

// Constants
import { USER_TYPES } from "../../../../utils/Constants";

class User extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
    };
  }

  toggleMode = () =>
    this.setState((prevState) => ({ editMode: !prevState.editMode }));

  render() {
    const user = this.props.user;
    let name = user.email;
    if (
      user.profile &&
      user.profile.nature.code === USER_TYPES.LEGAL_PERSON &&
      user.profile.name !== ""
    ) {
      name = user.profile.name;
    } else {
      if (user.firstName || user.lastName) {
        name = `${user.firstName} ${user.lastName}`;
      }
    }

    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={`bc-divider-users`} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-users">
            <Link to={`/manager/utilisateurs${window.location.search}`}>
              Utilisateurs
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider
            key={`bc-divider-utilisateur`}
            icon="right angle"
          />,
          <Breadcrumb.Section key="breadcrumb-utilisateur" active>
            {name}
          </Breadcrumb.Section>,
        ]}
      >
        {user.isActive ? (
          <>
            <UserInfos
              user={user}
              editMode={this.state.editMode}
              toggleMode={this.toggleMode}
            />
            <UserSites
              user={user}
              editMode={this.state.editMode}
              toggleMode={this.toggleMode}
            />
            <UserProjects
              user={user}
              editMode={this.state.editMode}
              toggleMode={this.toggleMode}
            />
            <UserGroups
              user={user}
              editMode={this.state.editMode}
              toggleMode={this.toggleMode}
            />
            <UserPassword
              user={user}
              editMode={this.state.editMode}
              toggleMode={this.toggleMode}
            />
            <UserPasswordReset
              userId={user.id}
              editMode={this.state.editMode}
            />
          </>
        ) : null}
        <UserActivity user={user} editMode={this.state.editMode} />
      </ManagerLayout>
    );
  }
}

function UserQuery({ match, setBreadcrumbItems }) {
  const { loading, error, data } = useQuery(ADMIN_USER, {
    variables: {
      userId: match.params.userId,
    },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return <User user={data.adminUser} setBreadcrumbItems={setBreadcrumbItems} />;
}

export { UserQuery as User };
