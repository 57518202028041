// React
import React from "react";

// React Router
import { withRouter, Link, useHistory } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { ADMIN_MANAGEABLE_WEBSITE_SET } from "../../_GraphQL/queries";

// Semantic ui
import {
  Breadcrumb,
  Divider,
  Header,
  Card,
  Segment,
  Image,
  Loader,
} from "semantic-ui-react";

// Components
import ManagerLayout from "../../components/ManagerLayout";
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { SITE_NAMES } from "../../../../utils/Constants";

function SiteCard({ website }) {
  const history = useHistory();
  return (
    <Card link onClick={() => history.push(`/manager/site/${website.site.id}`)}>
      {website.illustrationPath ? (
        <Image
          src={`/media/websites/${website.illustrationPath}`}
          alt={website.site.name}
          wrapped
          ui={false}
        />
      ) : (
        <div className="image" style={{ height: "290px" }}></div>
      )}
      <Card.Content>
        <Card.Header>{website.site.name}</Card.Header>
        <Card.Description>{website.description}</Card.Description>
      </Card.Content>
    </Card>
  );
}

const SiteList = ({ websites, history }) => (
  <ManagerLayout
    breadcrumbItems={[
      <Breadcrumb.Section key="breadcrumb-home">
        <Link to="/manager">Manager</Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key={"bc-divider-sites"} icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-sites" active>
        Sites
      </Breadcrumb.Section>,
    ]}
  >
    <Segment secondary>
      <Header as="h5">ICI</Header>
      <Divider />
      <Card.Group centered>
        {websites
          .filter((a) => a.site.name === SITE_NAMES.ICI)
          .map((website) => (
            <SiteCard key={`site-${website.site.id}`} website={website} />
          ))}
      </Card.Group>
    </Segment>
    <Segment secondary>
      <Header as="h5">
        Liste des sites génériques disponibles sur la plateforme
      </Header>
      <Divider />
      <Card.Group centered>
        {websites
          .filter(
            (a) =>
              (a.site.name === SITE_NAMES.CITTANOVA) |
              (a.site.name === SITE_NAMES.SINOPIA)
          )
          .map((website) => (
            <SiteCard key={`site-${website.site.id}`} website={website} />
          ))}
      </Card.Group>
    </Segment>
    <Segment secondary>
      <Header as="h5">
        Liste des sites dédiés disponibles sur la plateforme
      </Header>
      <Divider />
      <Card.Group centered>
        {websites
          .filter(
            (a) =>
              (a.site.name !== SITE_NAMES.CITTANOVA) &
              (a.site.name !== SITE_NAMES.SINOPIA) &
              (a.site.name !== SITE_NAMES.ICI)
          )
          .map((website) => (
            <SiteCard key={`site-${website.site.id}`} website={website} />
          ))}
      </Card.Group>
    </Segment>
  </ManagerLayout>
);

const SiteListWithRouter = withRouter(SiteList);

function SiteListQuery() {
  const { loading, error, data } = useQuery(ADMIN_MANAGEABLE_WEBSITE_SET);

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return <SiteListWithRouter websites={data.adminManageableWebsiteSet} />;
}

export { SiteListQuery as SiteList };
