// React
import React from "react";

// React Router
import { withRouter, Link } from "react-router-dom";

// GraphQL
import EQuery from "../../../../utils/EQuery";
import { GET_LAYER, ADMIN_QUESTIONNAIRE } from "../../_GraphQL/queries";

// Semantic ui
import { Breadcrumb, Header } from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";
import QuestionnaireFieldLabelInfos from "../../components/Questionnaire/QuestionnaireFieldLabelInfos";

class CreateQuestionnaireFieldLabel extends React.Component {
  backToQuestionnaireField = () =>
    this.props.history.push(
      `/manager/questionnaire/${this.props.questionnaire.id}/champ/${this.props.questionnaireField.id}`
    );

  backToQuestionnaireFieldForLayer = () =>
    this.props.history.push(
      `/manager/couche/${this.props.match.params.layerId}/questionnaire/${this.props.questionnaire.id}/champ/${this.props.questionnaireField.id}`
    );

  render() {
    const { questionnaire, questionnaireField, layer } = this.props;
    let breadcrumbItems = [
      <Breadcrumb.Section key="breadcrumb-home">
        <Link to="/manager">Manager</Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key="bc-divider-questionnaires" icon="right angle" />,
    ];
    let toReadMode = this.backToQuestionnaireField;
    let layerId = null;
    if (layer) {
      toReadMode = this.backToQuestionnaireFieldForLayer;
      layerId = layer.id;
      breadcrumbItems.push([
        <Breadcrumb.Section key="breadcrumb-sites">
          <Link to="/manager/sites">Sites</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-site" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-site">
          <Link to={`/manager/site/${layer.task.project.site.id}`}>
            Site : {layer.task.project.site.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-project" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-project">
          <Link to={`/manager/projet/${layer.task.project.id}`}>
            Projet : {layer.task.project.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-module" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-module">
          <Link to={`/manager/carte/?projectId=${layer.task.project.id}`}>
            Module : Carte participative
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-task" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-task">
          <Link to={`/manager/tache/${layer.task.id}`}>
            Tâche : {layer.task.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-layer" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-layer">
          <Link to={`/manager/couche/${layer.id}`}>Couche : {layer.name}</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-questionnaire"
          icon="right angle"
        />,
        <Breadcrumb.Section key="breadcrumb-questionnaire">
          <Link
            to={`/manager/couche/${layer.id}/questionnaire/${questionnaire.id}`}
          >
            Questionnaire : {questionnaire.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-field-label" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-field-label">
          <Link
            to={`/manager/couche/${layer.id}/questionnaire/${questionnaire.id}/champ/${questionnaireField.id}`}
          >
            Champ : {questionnaireField.text}
          </Link>
        </Breadcrumb.Section>,
      ]);
    } else {
      breadcrumbItems.push([
        <Breadcrumb.Section key="breadcrumb-questionnaires">
          <Link to="/manager/questionnaires">Questionnaires</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-questionnaire"
          icon="right angle"
        />,
        <Breadcrumb.Section key="breadcrumb-questionnaire">
          <Link to={`/manager/questionnaire/${questionnaire.id}`}>
            Questionnaire : {questionnaire.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-field-label" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-field-label">
          <Link
            to={`/manager/questionnaire/${questionnaire.id}/champ/${questionnaireField.id}`}
          >
            Champ : {questionnaireField.text}
          </Link>
        </Breadcrumb.Section>,
      ]);
    }

    breadcrumbItems.push([
      <Breadcrumb.Divider key="bc-divider-label" icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-label">
        Création d&apos;un label
      </Breadcrumb.Section>,
    ]);

    return (
      <ManagerLayout breadcrumbItems={breadcrumbItems}>
        <Header as="h5" block>
          Création d&apos;un label
        </Header>
        <QuestionnaireFieldLabelInfos
          questionnaire={questionnaire}
          questionnaireField={questionnaireField}
          toReadMode={toReadMode}
          createNewLabel={true}
          layerId={layerId}
        />
      </ManagerLayout>
    );
  }
}

const CreateQuestionnaireFieldLabelWithRouter = withRouter(
  CreateQuestionnaireFieldLabel
);

const CreateQuestionnaireFieldLabelQuery = ({ match, layer }) => (
  <EQuery
    query={ADMIN_QUESTIONNAIRE}
    variables={{ questionnaireId: match.params.questionnaireId }}
  >
    {(data) => {
      const questionnaireField =
        data.adminQuestionnaire.questionnairefieldSet.find(
          (field) => field.id === match.params.questionnaireFieldId
        );

      return (
        <CreateQuestionnaireFieldLabelWithRouter
          questionnaire={data.adminQuestionnaire}
          questionnaireField={questionnaireField}
          layer={layer}
        />
      );
    }}
  </EQuery>
);

export { CreateQuestionnaireFieldLabelQuery };

const CreateQuestionnaireFieldLabelEntry = ({ match }) => {
  if (match.params.layerId) {
    return (
      <EQuery query={GET_LAYER} variables={{ layerId: match.params.layerId }}>
        {(data) => (
          <CreateQuestionnaireFieldLabelQuery
            match={match}
            layer={data.layer}
          />
        )}
      </EQuery>
    );
  }

  return <CreateQuestionnaireFieldLabelQuery match={match} />;
};

export default CreateQuestionnaireFieldLabelEntry;
