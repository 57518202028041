// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import {
  GET_ASSIGNED_AND_AVAILABLE_PROJECTS_FOR_USER,
  GET_ASSIGNED_AND_AVAILABLE_SITES_FOR_USER,
} from "../../../_GraphQL/queries";
import { ASSIGN_UNASSIGN_SITE_TO_USER } from "../../../_GraphQL/mutations";

// Semantic ui
import { Loader, Grid, Header, Icon, Segment, List } from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../../Common/components/ErrorMessage";
import MessageDisplayer from "../../../../../Common/components/MessageDisplayer";
import { EditSegment } from "../../../../../Common/components/EditSegment";

// Constants
import { COMPONENTS } from "../../../../../utils/Constants";

// Message
import { addErrorMessage } from "../../../../../_GraphQL/message";

const siteStates = {
  noChange: "noChange",
  add: "add",
  remove: "remove",
};

function Fields({
  modificationsEnabled,
  sitesAvailable,
  sitesAssigned,
  addSite,
  removeSite,
}) {
  return (
    <>
      <MessageDisplayer location={COMPONENTS.MANAGER.SITES} />
      <Grid columns={2}>
        <Grid.Row>
          {modificationsEnabled ? (
            <Grid.Column>
              <Header as="h5" attached="top">
                Sites disponibles
              </Header>
              <Segment attached>
                <List selection>
                  {sitesAvailable.map((s) => (
                    <List.Item key={`site-${s.id}`} onClick={() => addSite(s)}>
                      <List.Content floated="right">
                        <span>
                          Affecter
                          <Icon name="angle right" color="orange" />
                        </span>
                      </List.Content>
                      <List.Content>{s.name}</List.Content>
                    </List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          ) : null}
          <Grid.Column>
            <Header as="h5" attached="top">
              Sites affectés
            </Header>
            <Segment attached>
              <List selection={modificationsEnabled}>
                {sitesAssigned.length === 0 ? (
                  <List.Item>Aucun site affecté pour cette personne</List.Item>
                ) : null}
                {sitesAssigned.map((s) => {
                  if (modificationsEnabled) {
                    return (
                      <List.Item
                        key={`site-${s.id}`}
                        onClick={() => removeSite(s)}
                      >
                        <List.Content floated="right">{s.name}</List.Content>
                        <span>
                          <Icon name="angle left" color="orange" />
                          Désaffecter
                        </span>
                      </List.Item>
                    );
                  } else {
                    return <List.Item key={`site-${s.id}`}>{s.name}</List.Item>;
                  }
                })}
              </List>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

class UserSites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modificationsEnabled: false,
      sitesAvailable: props.data.adminAvailableSiteSet.map((s) => ({
        id: s.id,
        name: s.name,
        siteState: siteStates.noChange,
      })),
      sitesAssigned: props.data.adminAssignedSiteSet.map((s) => ({
        id: s.id,
        name: s.name,
        siteState: siteStates.noChange,
      })),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.update = this.update.bind(this);
    this.toEditMode = this.toEditMode.bind(this);
    this.handleCompleted = this.handleCompleted.bind(this);
    this.removeSite = this.removeSite.bind(this);
  }

  addSite = (site) => {
    if (site.siteState === siteStates.noChange) {
      site.siteState = siteStates.add;
    }
    if (site.siteState === siteStates.remove) {
      site.siteState = siteStates.noChange;
    }
    this.setState({
      sitesAvailable: this.state.sitesAvailable.filter((s) => s.id !== site.id),
      sitesAssigned: this.state.sitesAssigned.concat([site]),
    });
  };

  removeSite = (site) => {
    this.props.client
      .query({
        query: GET_ASSIGNED_AND_AVAILABLE_PROJECTS_FOR_USER,
        variables: {
          siteId: site.id,
          userId: this.props.user.id,
        },
      })
      .then((res) => {
        if (res.data.adminAssignedProjectSet.length > 0) {
          addErrorMessage({
            header: "Ce site possède des projets affectés",
            location: COMPONENTS.MANAGER.SITES,
            content:
              "Vous ne pouvez pas désaffecter un site pour lequel des projets sont affectés. Veuillez supprimer les projets affectés pour ce site.",
          });
        } else {
          if (site.siteState === siteStates.noChange) {
            site.siteState = siteStates.remove;
          }
          if (site.siteState === siteStates.add) {
            site.siteState = siteStates.noChange;
          }
          this.setState({
            sitesAvailable: this.state.sitesAvailable.concat([site]),
            sitesAssigned: this.state.sitesAssigned.filter(
              (s) => s.id !== site.id
            ),
          });
        }
      });
  };

  handleSubmit(mutate) {
    mutate({
      variables: {
        userId: this.props.user.id,
        sitesToUnassign: this.state.sitesAvailable
          .filter((s) => s.siteState === siteStates.remove)
          .map((s) => s.id),
        sitesToAssign: this.state.sitesAssigned
          .filter((s) => s.siteState === siteStates.add)
          .map((s) => s.id),
      },
    });
  }

  handleCancel = () =>
    this.setState(
      {
        modificationsEnabled: false,
        sitesAvailable: this.props.data.adminAvailableSiteSet.map((s) => ({
          id: s.id,
          name: s.name,
          siteState: siteStates.noChange,
        })),
        sitesAssigned: this.props.data.adminAssignedSiteSet.map((s) => ({
          id: s.id,
          name: s.name,
          siteState: siteStates.noChange,
        })),
      },
      () => this.props.toggleMode()
    );

  handleCompleted(data) {
    this.setState(
      {
        modificationsEnabled: false,
        sitesAvailable:
          data.adminAssignUnassignSiteToUser.adminAvailableSiteSet.map((s) => ({
            id: s.id,
            name: s.name,
            siteState: siteStates.noChange,
          })),
        sitesAssigned:
          data.adminAssignUnassignSiteToUser.adminAssignedSiteSet.map((s) => ({
            id: s.id,
            name: s.name,
            siteState: siteStates.noChange,
          })),
      },
      () => this.props.toggleMode()
    );
  }

  update(cache, { data: { adminAssignUnassignSiteToUser } }) {
    cache.writeQuery({
      query: GET_ASSIGNED_AND_AVAILABLE_SITES_FOR_USER,
      variables: {
        userId: this.props.user.id,
      },
      data: {
        adminAvailableSiteSet:
          adminAssignUnassignSiteToUser.adminAvailableSiteSet,
        adminAssignedSiteSet:
          adminAssignUnassignSiteToUser.adminAssignedSiteSet,
      },
    });
  }

  toEditMode() {
    if (!this.props.editMode) {
      this.props.toggleMode();
    }
  }

  render() {
    return (
      <EditSegment
        title="Sites"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_SITE_TO_USER}
        update={this.update}
        onCompleted={this.handleCompleted}
        handleSubmit={this.handleSubmit}
        toEditMode={this.toEditMode}
        handleCancel={this.handleCancel}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            sitesAvailable={this.state.sitesAvailable}
            sitesAssigned={this.state.sitesAssigned}
            addSite={this.addSite}
            removeSite={this.removeSite}
            modificationsEnabled={enabled}
          />
        )}
      </EditSegment>
    );
  }
}

function UserSitesQuery({ user, editMode, toggleMode }) {
  const { loading, error, data, client } = useQuery(
    GET_ASSIGNED_AND_AVAILABLE_SITES_FOR_USER,
    {
      variables: { userId: user.id },
    }
  );

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <UserSites
      data={data}
      user={user}
      client={client}
      editMode={editMode}
      toggleMode={toggleMode}
    />
  );
}

export { UserSitesQuery as UserSites };
