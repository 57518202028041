// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import {
  Breadcrumb,
  Header,
  Segment,
  Button,
  Step,
  Icon,
} from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";

import { SelectOrCreateTileLayer } from "../../components/CollaborativeMap/CreateTask/SelectOrCreateTileLayer";
import {
  CreateTileLayer,
  TARGET,
} from "../../components/CollaborativeMap/CreateTask/CreateTileLayer";
import { CreateTaskForm } from "../../components/CollaborativeMap/CreateTask/CreateTaskForm";
import { SetAutorisations } from "../../components/CollaborativeMap/CreateTask/SetAutorisations";

import { CREATE_TILE_LAYER } from "../../_GraphQL/mutations";

const steps = {
  tileLayer: "tileLayer",
  addTileLayer: "addTileLayer",
  task: "task",
  autorisations: "autorisations",
};

class CreateTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: steps.tileLayer,
    };
  }

  goToTask = (tileLayerId) => {
    this.setState({
      step: steps.task,
      tileLayerId: tileLayerId,
    });
  };

  handleCompleted = (tileLayerId) => {
    this.setState({
      step: steps.task,
      tileLayerId: tileLayerId,
    });
  };

  goToTileLayer = () => {
    this.setState({
      step: steps.tileLayer,
    });
  };

  handleCancel = (e) => {
    if (e.detail === 0) {
      return;
    }
    this.setState({
      step: steps.tileLayer,
    });
  };

  goToAutorisations = (task) => {
    this.setState({
      step: steps.autorisations,
      task: task,
    });
  };

  goToAddTileLayer = () => {
    this.setState({
      step: steps.addTileLayer,
    });
  };

  render() {
    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-sites"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-sites">
            <Link to="/manager/sites">Sites</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-site"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-site">
            <Link to={`/manager/site/${this.props.project.site.id}`}>
              Site : {this.props.project.site.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-project"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-project">
            <Link to={`/manager/projet/${this.props.project.id}`}>
              Projet : {this.props.project.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-draw"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-draw">
            <Link to={`/manager/carte/?projectId=${this.props.project.id}`}>
              Module : Carte participative
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-task"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-task">
            Création d&apos;une Tâche
          </Breadcrumb.Section>,
        ]}
      >
        <Header as="h4" block>
          Création de tâche pour le projet « {this.props.project.name} »
        </Header>

        <div>
          <Step.Group attached="top" widths={3} ordered>
            <Step
              active={
                this.state.step === steps.tileLayer ||
                this.state.step === steps.addTileLayer
              }
              completed={this.state.step === steps.task}
            >
              <Step.Content>
                <Step.Title>Fond de carte</Step.Title>
                <Step.Description>
                  Sélectionner ou créer un fond de carte
                </Step.Description>
              </Step.Content>
            </Step>
            <Step
              active={this.state.step === steps.task}
              completed={this.state.step === steps.autorisations}
            >
              <Step.Content>
                <Step.Title>Tâche</Step.Title>
                <Step.Description>Création de la tâche</Step.Description>
              </Step.Content>
            </Step>
            <Step active={this.state.step === steps.autorisations}>
              <Step.Content>
                <Step.Title>Autorisations</Step.Title>
                <Step.Description>
                  Donner les autorisations aux groupes
                </Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
          {this.state.step === steps.tileLayer ? (
            <SelectOrCreateTileLayer
              project={this.props.project}
              goToTask={this.goToTask}
              goToAddTileLayer={this.goToAddTileLayer}
            />
          ) : null}
          {this.state.step === steps.addTileLayer ? (
            <CreateTileLayer
              handleCancel={this.handleCancel}
              handleCompleted={this.handleCompleted}
              mutation={CREATE_TILE_LAYER}
              target={TARGET.TARGET_TASK}
            />
          ) : null}
          {this.state.step === steps.task ? (
            <CreateTaskForm
              project={this.props.project}
              goToTileLayer={this.goToTileLayer}
              goToAutorisations={this.goToAutorisations}
              tileLayerId={this.state.tileLayerId}
            />
          ) : null}
          {this.state.step === steps.autorisations ? (
            <SetAutorisations task={this.state.task} />
          ) : null}
        </div>

        <Segment>
          <Link to={`/manager/carte/?projectId=${this.props.project.id}`}>
            <Button negative>
              <Icon name="delete" />
              Annuler la création de tâche
            </Button>
          </Link>
        </Segment>
      </ManagerLayout>
    );
  }
}

export { CreateTask };
