// Composant qui gère l'outil de dessin pour un projet donné
// Si le projet n'est pas renseigné, redirection vers le choix des sites

// React
import React from "react";

// React Router
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../../../Common/_GraphQL/queries";

// Semantic ui
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
  Grid,
  Loader,
} from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import DrawingListQuery from "../../components/Draw/DrawingListQuery";
import DrawingPermissions from "../../components/Draw/DrawingPermissions";

class DrawLayout extends React.Component {
  state = {
    editMode: false,
  };

  toggleMode = () =>
    this.setState((prevState) => ({ editMode: !prevState.editMode }));

  render() {
    return (
      <>
        <Segment>
          <Breadcrumb>
            <Breadcrumb.Section key="breadcrumb-home">
              <Link to="/manager">Manager</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={`bc-divider-sites`} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-sites">
              <Link to="/manager/sites">Sites</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={`bc-divider-site`} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-site">
              <Link to={`/manager/site/${this.props.project.site.id}`}>
                Site : {this.props.project.site.name}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={`bc-divider-project`} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-project">
              <Link to={`/manager/projet/${this.props.project.id}`}>
                Projet : {this.props.project.name}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={`bc-divider-draw`} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-draw">
              Module : Outil de dessin
            </Breadcrumb.Section>
          </Breadcrumb>
        </Segment>

        <Header as="h4" block>
          Paramètres de l'outil de dessin pour le projet « 
          {this.props.project.name} »
        </Header>

        <Segment secondary>
          <Header as="h5">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  Liste des dessins du projet
                </Grid.Column>
                <Grid.Column textAlign="right">
                  {!this.state.editMode ? (
                    <Link
                      to={`/manager/dessin/creation/${this.props.project.id}`}
                    >
                      <Button primary>Ajouter un dessin au projet</Button>
                    </Link>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <Divider />
          <DrawingListQuery projectId={this.props.project.id} />
        </Segment>

        <DrawingPermissions
          project={this.props.project}
          editMode={this.state.editMode}
          toggleMode={this.toggleMode}
          target="project"
        />
      </>
    );
  }
}

function DrawQuery({ projectId }) {
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { projectId: projectId },
  });

  if (loading) return <Loader />;

  if (error)
    return (
      <ErrorMessage
        header={"Impossible de récupérer le projet."}
        error={error}
      />
    );

  return <DrawLayout project={data.project} />;
}

const Draw = () => {
  let params = new URLSearchParams(window.location.search);
  let projectId = params.get("projectId");

  if (!projectId) {
    return <Redirect to="/manager/sites" />;
  }

  return <DrawQuery projectId={projectId} />;
};

export { Draw };
