import React, { useState } from "react";
import { Dimmer, Loader, Dropdown } from "semantic-ui-react";
import { EditSegment } from "../../../../Common/components/EditSegment";
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { useQuery } from "@apollo/client";
import { CHANGE_PROJECT_SITE } from "../../_GraphQL/mutations";
import { GET_SITE_SET, ADMIN_PROJECT_SET } from "../../_GraphQL/queries";

const baseOption = [
  {
    key: -1,
    value: -1,
    text: "Aucun site disponible",
  },
];

function Fields({ siteId, handleSiteChange }) {
  const { loading, error, data } = useQuery(GET_SITE_SET);

  return (
    <>
      {loading ? (
        <Dimmer>
          <Loader />
        </Dimmer>
      ) : null}
      {error ? (
        <ErrorMessage error={error} />
      ) : (
        <Dropdown
          placeholder="Nouveau site"
          fluid
          selection
          options={
            data
              ? data.siteSet.map((site) => ({
                  key: site.id,
                  value: site.id,
                  text: site.name,
                }))
              : baseOption
          }
          value={siteId}
          onChange={handleSiteChange}
        />
      )}
    </>
  );
}

function ProjectSite({ project, toEditMode, toReadMode, editMode }) {
  const [siteId, setSiteId] = useState(project.site.id);
  const prevSiteId = project.site.id;

  function handleSubmit(mutate) {
    mutate({
      variables: {
        projectId: project.id,
        siteId: siteId,
      },
    });
  }

  function handleCancel() {
    setSiteId(project.site.id);
    toReadMode();
  }

  function onCompleted() {
    toReadMode();
  }

  function update(cache, { data: { changeProjectSite } }) {
    const before = cache.readQuery({
      query: ADMIN_PROJECT_SET,
      variables: {
        siteId: prevSiteId,
      },
    });

    if (before && before.adminProjectSet) {
      cache.writeQuery({
        query: ADMIN_PROJECT_SET,
        variables: {
          siteId: prevSiteId,
        },
        data: {
          adminProjectSet: before.adminProjectSet.filter(
            (proj) => proj.id !== project.id
          ),
        },
      });
    }

    const after = cache.readQuery({
      query: ADMIN_PROJECT_SET,
      variables: {
        siteId: changeProjectSite.project.site.id,
      },
    });

    if (after && after.adminProjectSet) {
      cache.writeQuery({
        query: ADMIN_PROJECT_SET,
        variables: {
          siteId: changeProjectSite.project.site.id,
        },
        data: {
          adminProjectSet: [
            ...after.adminProjectSet,
            changeProjectSite.project,
          ],
        },
      });
    }
  }

  return (
    <EditSegment
      title=""
      buttonText="Basculer le projet sur un autre site"
      mutation={CHANGE_PROJECT_SITE}
      onCompleted={onCompleted}
      update={update}
      handleSubmit={handleSubmit}
      toEditMode={toEditMode}
      handleCancel={handleCancel}
      editMode={editMode}
    >
      {(enabled) => {
        if (enabled) {
          return (
            <Fields
              siteId={siteId}
              handleSiteChange={(e, { value }) => setSiteId(value)}
            />
          );
        } else {
          return null;
        }
      }}
    </EditSegment>
  );
}

export { ProjectSite };
