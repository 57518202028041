// React
import React from "react";

// React Router
import { withRouter } from "react-router-dom";

// Semantic ui
import {
  Table,
  Segment,
  Header,
  Grid,
  Button,
  Divider,
} from "semantic-ui-react";
import { DeleteQuestionnaireFieldLabel } from "./DeleteQuestionnaireFieldLabel";

class QuestionnaireFieldLabels extends React.Component {
  render() {
    return (
      <Segment secondary>
        <Header as="h5">
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column verticalAlign="middle">Liste des labels</Grid.Column>
              <Grid.Column textAlign="right">
                {!this.props.editMode ? (
                  <Button
                    primary
                    floated="right"
                    onClick={() => {
                      if (this.props.layer) {
                        this.props.history.push(
                          `/manager/couche/${this.props.layer.id}/questionnaire/${this.props.questionnaire.id}/champ/${this.props.questionnaireField.id}/label/nouveau`
                        );
                      } else {
                        this.props.history.push(
                          `/manager/questionnaire/${this.props.questionnaire.id}/champ/${this.props.questionnaireField.id}/label/nouveau`
                        );
                      }
                    }}
                  >
                    Ajouter un label
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <Divider />
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Label</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.questionnaireField.questionnairefieldlabelSet.map(
              (label) => (
                <Table.Row key={`label-row-${label.id}`}>
                  <Table.Cell
                    className="clickable"
                    onClick={() => {
                      if (this.props.layer) {
                        this.props.history.push(
                          `/manager/couche/${this.props.layer.id}/questionnaire/${this.props.questionnaire.id}/champ/${this.props.questionnaireField.id}/label/${label.id}`
                        );
                      } else {
                        this.props.history.push(
                          `/manager/questionnaire/${this.props.questionnaire.id}/champ/${this.props.questionnaireField.id}/label/${label.id}`
                        );
                      }
                    }}
                  >
                    {label.text}
                  </Table.Cell>
                  <Table.Cell>
                    <DeleteQuestionnaireFieldLabel
                      questionnaireId={this.props.questionnaire.id}
                      questionnaireFieldId={this.props.questionnaireField.id}
                      questionnaireFieldLabelId={label.id}
                      questionnaireFieldLabelName={label.text}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default withRouter(QuestionnaireFieldLabels);
