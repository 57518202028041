// React
import React from "react";

// Semantic ui
import { Form, Checkbox, Table } from "semantic-ui-react";

// GraphQL
import { ASSIGN_UNASSIGN_TOOL_PERMISSION_TO_GROUP } from "../../../_GraphQL/mutations";
import { GET_GROUP_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET } from "../../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";
import EQuery from "../../../../../utils/EQuery";

// Constants
import {
  COLLABORATIVE_MAP_PERMISSIONS,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
} from "../../../../../utils/Constants";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

const GroupToolRow = ({
  enabled,
  point,
  line,
  poly,
  groupToolRow,
  handleCheckChange,
  layerId,
}) => (
  <Table.Row>
    <Table.Cell>{groupToolRow.group.name}</Table.Cell>
    {point ? (
      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER + layerId}
          checked={
            groupToolRow[
              COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(groupToolRow.group, value, checked)
          }
        />
      </Table.Cell>
    ) : null}
    {line ? (
      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER + layerId}
          checked={
            groupToolRow[
              COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(groupToolRow.group, value, checked)
          }
        />
      </Table.Cell>
    ) : null}
    {poly ? (
      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER + layerId}
          checked={
            groupToolRow[
              COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(groupToolRow.group, value, checked)
          }
        />
      </Table.Cell>
    ) : null}

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER + layerId}
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER + layerId
        }
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER + layerId}
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>

    <Table.Cell textAlign="center">
      <Checkbox
        disabled={!enabled}
        value={COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER + layerId}
        checked={
          groupToolRow[
            COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER + layerId
          ]
        }
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupToolRow.group, value, checked)
        }
      />
    </Table.Cell>
  </Table.Row>
);

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields(this.props.enabled);
  }

  render() {
    return (
      <Form.Field>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Groupe</Table.HeaderCell>
              {this.props.point ? (
                <Table.HeaderCell textAlign="center">
                  Ajouter un point
                </Table.HeaderCell>
              ) : null}
              {this.props.line ? (
                <Table.HeaderCell textAlign="center">
                  Ajouter une ligne
                </Table.HeaderCell>
              ) : null}
              {this.props.poly ? (
                <Table.HeaderCell textAlign="center">
                  Ajouter une surface
                </Table.HeaderCell>
              ) : null}
              <Table.HeaderCell textAlign="center">
                Éditer une géométrie
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Éditer un questionnaire
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Supprimer une géométrie
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Export SHP</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.groupToolsRowSet
              .sort((a, b) => {
                if (a.group.name === b.group.name) {
                  return 0;
                } else if (a.group.name === null) {
                  return 1;
                } else if (b.group.name === null) {
                  return -1;
                } else {
                  return a.group.name
                    .toLowerCase()
                    .localeCompare(b.group.name.toLowerCase());
                }
              })
              .map((groupToolsRow) => (
                <GroupToolRow
                  enabled={this.props.enabled}
                  point={this.props.point}
                  line={this.props.line}
                  poly={this.props.poly}
                  key={`table-row-group-${groupToolsRow.group.name}`}
                  groupToolRow={groupToolsRow}
                  handleCheckChange={this.props.handleCheckChange}
                  layerId={this.props.layerId}
                />
              ))}
          </Table.Body>
        </Table>
      </Form.Field>
    );
  }
}

class LayerGroupTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialGroupToolsRowSet: [],
      groupToolsRowSet: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = (edit) => {
    let groupToolsRowSet = [];

    if (edit) {
      this.props.data.adminAssignedGroupForLayerSet.forEach((g) => {
        let groupToolRow = {
          group: g,
        };

        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER + this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER + this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER +
            this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER +
            this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
            this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER +
            this.props.layer.id
        ] = false;
        groupToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER +
            this.props.layer.id
        ] = false;

        let toolSet =
          this.props.data.adminGroupWithAssignedToolPermissionSetSet.find(
            (gt) => gt.id === g.id
          );

        if (toolSet) {
          toolSet.assignedToolPermissionSet.forEach(
            (t) => (groupToolRow[t.codename] = true)
          );
        }

        groupToolsRowSet.push(groupToolRow);
      });
    } else {
      this.props.data.adminGroupWithAssignedToolPermissionSetSet.forEach(
        (groupTool) => {
          let groupToolsRow = {
            group: { id: groupTool.id, name: groupTool.name },
          };
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER +
              this.props.layer.id
          ] = groupTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER +
              this.props.layer.id
          ] = groupTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER +
              this.props.layer.id
          ] = groupTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER +
              this.props.layer.id
          ] = groupTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
              this.props.layer.id
          ] = groupTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER +
              this.props.layer.id
          ] = groupTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER +
                this.props.layer.id
          );
          groupToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER +
              this.props.layer.id
          ] = groupTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER +
                this.props.layer.id
          );

          groupToolsRowSet.push(groupToolsRow);
        }
      );
    }

    this.setState({
      groupToolsRowSet: JSON.parse(JSON.stringify(groupToolsRowSet)),
      initialGroupToolsRowSet: JSON.parse(JSON.stringify(groupToolsRowSet)),
    });
  };

  handleSubmit = (mutate) => {
    let groups = [];

    const tools = [
      COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER +
        this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
        this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER +
        this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER + this.props.layer.id,
    ];

    this.state.initialGroupToolsRowSet.forEach((initRow) => {
      let group = {
        groupId: initRow.group.id,
        permissionToAssignSet: [],
        permissionToUnassignSet: [],
      };

      const currentRow = this.state.groupToolsRowSet.find(
        (row) => row.group.id === initRow.group.id
      );
      for (let t of tools) {
        // ceux qui étaint checked au départ et qui maintenant sont unchecked
        if (initRow[t] && !currentRow[t]) {
          group.permissionToUnassignSet.push(t);
        }

        // ceux qui étaient unchecked au départ et qui maintenant sont checked
        if (!initRow[t] && currentRow[t]) {
          group.permissionToAssignSet.push(t);
        }
      }

      groups.push(group);
    });

    mutate({
      variables: {
        layerId: this.props.layer.id,
        groupToAssignUnassignSet: groups,
      },
    });
  };

  update = (cache, { data: { adminAssignUnassignToolPermissionToGroup } }) => {
    let data = cache.readQuery({
      query: GET_GROUP_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET,
      variables: { layerId: this.props.layer.id },
    });

    cache.writeQuery({
      query: GET_GROUP_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET,
      variables: { layerId: this.props.layer.id },
      data: {
        adminAssignedGroupForLayerSet: data.adminAssignedGroupForLayerSet,
        adminGroupWithAssignedToolPermissionSetSet:
          adminAssignUnassignToolPermissionToGroup.adminAssignedToolGroupSet,
      },
    });

    cache.modify({
      id: `LayerType:${this.props.layer.id}`,
      fields: {
        adminGroupWithAssignedToolPermissionSetSet() {
          return adminAssignUnassignToolPermissionToGroup.adminAssignedToolGroupSet;
        },
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "L'affectation des outils a été mise à jour.",
    });
    this.toReadMode();
  };

  handleCheckChange = (group, tool, checked) => {
    this.setState((prevState) => {
      let groupToolsRow = prevState.groupToolsRowSet.find(
        (al) => al.group.id === group.id
      );

      groupToolsRow[tool] = checked;

      return {
        groupToolsRowSet: prevState.groupToolsRowSet,
      };
    });
  };

  render() {
    return (
      <EditSegment
        title="Outils : Permissions des groupes"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_TOOL_PERMISSION_TO_GROUP}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            editable={this.state.editable}
            enabled={enabled}
            initFields={this.initFields}
            groupToolsRowSet={this.state.groupToolsRowSet}
            point={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT
            )}
            line={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE
            )}
            poly={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code ===
                COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
            )}
            handleCheckChange={this.handleCheckChange}
            layerId={this.props.layer.id}
          />
        )}
      </EditSegment>
    );
  }
}

const LayerGroupToolsQuery = (props) => {
  return (
    <EQuery
      query={GET_GROUP_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET}
      variables={{ layerId: props.layer.id }}
    >
      {(data) => (
        <LayerGroupTools
          data={data}
          layer={props.layer}
          editMode={props.editMode}
          toEditMode={props.toEditMode}
          toReadMode={props.toReadMode}
        />
      )}
    </EQuery>
  );
};

export default LayerGroupToolsQuery;
