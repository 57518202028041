import React, { useState, useEffect } from "react";
import { PATTERN_TYPES } from "../../../../../../utils/Constants";
import { BaseShapeForm } from "./BaseShapeForm";
import { CircleShapeForm } from "./CircleShapeForm";
import { RectShapeForm } from "./RectShapeForm";
import { PathShapeForm } from "./PathShapeForm";

function ShapeForm({ initShapeOptions, patternTypeCode, setShapeOptions }) {
  const [baseOptions, setBaseOptions] = useState({});
  const [pathOptions, setPathOptions] = useState({});
  const [circleOptions, setCircleOptions] = useState({});
  const [rectOptions, setRectOptions] = useState({});

  useEffect(() => {
    let options = {};
    if (patternTypeCode === PATTERN_TYPES.CIRCLE) {
      options = {
        ...baseOptions,
        ...circleOptions,
      };
    } else if (patternTypeCode === PATTERN_TYPES.RECTANGLE) {
      options = {
        ...baseOptions,
        ...rectOptions,
      };
    } else if (patternTypeCode === PATTERN_TYPES.PATH) {
      options = {
        ...baseOptions,
        ...pathOptions,
      };
    }
    setShapeOptions(options);
  }, [
    baseOptions,
    pathOptions,
    circleOptions,
    rectOptions,
    setShapeOptions,
    patternTypeCode,
  ]);

  return (
    <>
      <BaseShapeForm
        setShapeBaseOptions={setBaseOptions}
        initShapeOptions={initShapeOptions}
      />
      {patternTypeCode === PATTERN_TYPES.CIRCLE ? (
        <CircleShapeForm
          setShapeCircleOptions={setCircleOptions}
          initShapeOptions={initShapeOptions}
        />
      ) : null}
      {patternTypeCode === PATTERN_TYPES.RECTANGLE ? (
        <RectShapeForm
          setShapeRectOptions={setRectOptions}
          initShapeOptions={initShapeOptions}
        />
      ) : null}
      {patternTypeCode === PATTERN_TYPES.PATH ? (
        <PathShapeForm
          setShapePathOptions={setPathOptions}
          initShapeOptions={initShapeOptions}
        />
      ) : null}
    </>
  );
}

export { ShapeForm };
