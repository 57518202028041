// React
import React from "react";

// GraphQL
import { useMutation } from "@apollo/client";
import {
  DEFAULT_FOLDER_GRP_PERM_AND_GRP_FOR_SITE,
  DEFAULT_DOC_GRP_PERM_AND_GRP_FOR_SITE,
} from "../../../_GraphQL/queries";
import {
  ADMIN_ADD_REMOVE_DEFAULT_FOLDER_GROUP_PERMISSION,
  ADMIN_ADD_REMOVE_DEFAULT_DOCUMENT_GROUP_PERMISSION,
} from "../../../_GraphQL/mutations";

// Components
import GroupPermissionsForm from "../../../../../Common/components/GroupPermission/GroupPermissionsForm";

// Constants
import * as constants from "../../../../../utils/Constants";

// Message
import {
  addSuccessToastMessage,
  addErrorToastMessage,
  getMessageTextFromApolloError,
} from "../../../../../_GraphQL/message";

const viewFolderTrigger = [
  "change_folder_",
  "move_folder_",
  "delete_folder_",
  "add_folder_to_folder_",
  "add_document_to_folder_",
];
const viewDocTrigger = ["delete_document_", "move_document_"];

function DefaultGroupPermissions(props) {
  const [mut] = useMutation(props.mut, {
    update(a, b) {
      props.update(a, b);
    },
    onCompleted() {
      addSuccessToastMessage({
        header: "Informations mises à jour",
        content: "Les permissions ont été mises à jours avec succès.",
      });
      props.handleCompleted();
    },
    onError(error) {
      addErrorToastMessage(getMessageTextFromApolloError(error));
    },
  });

  return (
    <GroupPermissionsForm
      groupPermissionsRows={props.groupPermissionsRows}
      mutation={true}
      handleSubmit={() => props.handleSubmit(mut)}
      handleCancel={props.handleCancel}
      handleCheckChange={props.handleCheckChange}
      fileType={props.fileType}
    />
  );
}

class DefaultGroupPermissionsMutation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupPermissionsRows: [],
      groupPermissionToRemoveSet: [],
      groupPermissionToAddSet: [],
    };
  }

  componentDidMount() {
    let groupPermissionsRows = [];
    this.props.groups.forEach((group) => {
      groupPermissionsRows.push({
        group: group,
        canViewFolder: false,
        canChangeFolder: false,
        canMoveFolder: false,
        canDeleteFolder: false,
        canAddFolder: false,
        canAddDocument: false,
        canViewDocument: false,
        canMoveDocument: false,
        canDeleteDocument: false,
      });

      let groupPermissions = this.props.authorizations.filter(
        (a) => a.group.id === group.id
      );

      groupPermissions.forEach((gp) => {
        let perm = constants.FILEMANAGER_PERMISSIONS[gp.permission];
        groupPermissionsRows.find((o) => o.group.id === gp.group.id)[
          perm
        ] = true;
      });
    });

    this.setState({
      groupPermissionsRows: groupPermissionsRows,
    });
  }

  handleCheckChange = (group, permission, checked) => {
    let addViewFolder = false;
    let addViewDoc = false;
    let removeAll = false;
    this.setState(
      (prevState) => {
        let a = prevState.groupPermissionsRows.find(
          (al) => al.group.id === group.id
        );

        let perm = constants.FILEMANAGER_PERMISSIONS[permission];
        a[perm] = checked;
        if (checked) {
          if (viewFolderTrigger.includes(permission) && !a.canViewFolder) {
            a.canViewFolder = true;
            addViewFolder = true;
          }
          if (viewDocTrigger.includes(permission) && !a.canViewDocument) {
            a.canViewDocument = true;
            addViewDoc = true;
          }
        } else {
          if (
            permission === "view_folder_" ||
            permission === "view_document_"
          ) {
            removeAll = true;
            a.canViewFolder = false;
            a.canChangeFolder = false;
            a.canMoveFolder = false;
            a.canDeleteFolder = false;
            a.canAddFolder = false;
            a.canAddDocument = false;
            a.canViewDocument = false;
            a.canMoveDocument = false;
            a.canDeleteDocument = false;
          }
        }
        return {
          groupPermissionsRows: prevState.groupPermissionsRows,
        };
      },
      () => {
        if (checked) {
          if (
            this.state.groupPermissionToRemoveSet.find(
              (gp) => gp.groupId === group.id && gp.permission === permission
            )
          ) {
            if (addViewFolder) {
              // Si addViewFolder, view_folder_ décoché et si autre perm dans remove alors décoché aussi donc
              // view_folder_ forcement dans remove
              // donc un seul cas
              this.setState((prevState) => ({
                groupPermissionToRemoveSet:
                  prevState.groupPermissionToRemoveSet.filter(
                    (gp) =>
                      !(
                        gp.groupId === group.id && gp.permission === permission
                      ) &&
                      !(
                        gp.groupId === group.id &&
                        gp.permission === "view_folder_"
                      )
                  ),
              }));
            } else if (addViewDoc) {
              // Si addViewFolder, view_document_ décoché et si autre perm dans remove alors décoché aussi donc
              // view_document_ forcement dans remove
              // donc un seul cas
              this.setState((prevState) => ({
                groupPermissionToRemoveSet:
                  prevState.groupPermissionToRemoveSet.filter(
                    (gp) =>
                      !(
                        gp.groupId === group.id && gp.permission === permission
                      ) &&
                      !(
                        gp.groupId === group.id &&
                        gp.permission === "view_document_"
                      )
                  ),
              }));
            } else {
              this.setState((prevState) => ({
                groupPermissionToRemoveSet:
                  prevState.groupPermissionToRemoveSet.filter(
                    (gp) =>
                      !(gp.groupId === group.id && gp.permission === permission)
                  ),
              }));
            }
          } else {
            if (addViewFolder) {
              if (
                this.state.groupPermissionToRemoveSet.find(
                  (gp) =>
                    gp.groupId === group.id && gp.permission === "view_folder_"
                )
              ) {
                this.setState((prevState) => ({
                  groupPermissionToAddSet:
                    prevState.groupPermissionToAddSet.concat({
                      groupId: group.id,
                      permission: permission,
                    }),
                  groupPermissionToRemoveSet:
                    prevState.groupPermissionToRemoveSet.filter(
                      (gp) =>
                        !(
                          gp.groupId === group.id &&
                          gp.permission === "view_folder_"
                        )
                    ),
                }));
              } else {
                this.setState((prevState) => ({
                  groupPermissionToAddSet:
                    prevState.groupPermissionToAddSet.concat([
                      {
                        groupId: group.id,
                        permission: permission,
                      },
                      {
                        groupId: group.id,
                        permission: "view_folder_",
                      },
                    ]),
                }));
              }
            } else if (addViewDoc) {
              if (
                this.state.groupPermissionToRemoveSet.find(
                  (gp) =>
                    gp.groupId === group.id &&
                    gp.permission === "view_document_"
                )
              ) {
                this.setState((prevState) => ({
                  groupPermissionToAddSet:
                    prevState.groupPermissionToAddSet.concat({
                      groupId: group.id,
                      permission: permission,
                    }),
                  groupPermissionToRemoveSet:
                    prevState.groupPermissionToRemoveSet.filter(
                      (gp) =>
                        !(
                          gp.groupId === group.id &&
                          gp.permission === "view_document_"
                        )
                    ),
                }));
              } else {
                this.setState((prevState) => ({
                  groupPermissionToAddSet:
                    prevState.groupPermissionToAddSet.concat([
                      {
                        groupId: group.id,
                        permission: permission,
                      },
                      {
                        groupId: group.id,
                        permission: "view_document_",
                      },
                    ]),
                }));
              }
            } else {
              this.setState((prevState) => ({
                groupPermissionToAddSet:
                  prevState.groupPermissionToAddSet.concat({
                    groupId: group.id,
                    permission: permission,
                  }),
              }));
            }
          }
        } else {
          if (removeAll) {
            this.setState((prevState) => ({
              groupPermissionToAddSet: prevState.groupPermissionToAddSet.filter(
                (gp) => gp.groupId !== group.id
              ),
              groupPermissionToRemoveSet: prevState.groupPermissionToRemoveSet
                .filter((gp) => gp.groupId !== group.id)
                .concat(
                  this.props.authorizations
                    .filter((a) => a.group.id === group.id)
                    .map((a) => ({
                      groupId: a.group.id,
                      permission: a.permission,
                    }))
                ),
            }));
          } else {
            if (
              this.state.groupPermissionToAddSet.find(
                (gp) => gp.groupId === group.id && gp.permission === permission
              )
            ) {
              this.setState((prevState) => ({
                groupPermissionToAddSet:
                  prevState.groupPermissionToAddSet.filter(
                    (gp) =>
                      !(gp.groupId === group.id && gp.permission === permission)
                  ),
              }));
            } else {
              this.setState((prevState) => ({
                groupPermissionToRemoveSet:
                  prevState.groupPermissionToRemoveSet.concat({
                    groupId: group.id,
                    permission: permission,
                  }),
              }));
            }
          }
        }
      }
    );
  };

  handleSubmit = (mutate) => {
    mutate({
      variables: {
        projectId: this.props.project.id,
        groupPermissionToAddSet: this.state.groupPermissionToAddSet.map(
          (add) => ({
            groupId: add.groupId,
            permission: add.permission,
          })
        ),
        groupPermissionToRemoveSet: this.state.groupPermissionToRemoveSet.map(
          (rem) => ({
            groupId: rem.groupId,
            permission: rem.permission,
          })
        ),
      },
    });
  };

  update = (cache, { data }) => {
    const { fileType } = this.props;
    const query =
      fileType === "folder"
        ? DEFAULT_FOLDER_GRP_PERM_AND_GRP_FOR_SITE
        : DEFAULT_DOC_GRP_PERM_AND_GRP_FOR_SITE;
    const variables = {
      projectId: this.props.project.id.toString(),
      siteId: this.props.project.site.id,
    };
    let a = cache.readQuery({
      query: query,
      variables: variables,
    });
    let updatedGrpPermSet =
      fileType === "folder"
        ? data.adminAddRemoveDefaultFolderGroupPermission
            .defaultFolderGroupPermissionSet
        : data.adminAddRemoveDefaultDocumentGroupPermission
            .defaultDocumentGroupPermissionSet;

    let updatedData = { adminGroupSet: a.adminGroupSet };
    if (fileType === "folder") {
      updatedData.defaultFolderGroupPermissionSet = updatedGrpPermSet;
    } else {
      updatedData.defaultDocumentGroupPermissionSet = updatedGrpPermSet;
    }
    cache.writeQuery({
      query: query,
      variables: variables,
      data: updatedData,
    });
  };

  render() {
    let { fileType } = this.props;
    let mutation =
      fileType === "folder"
        ? ADMIN_ADD_REMOVE_DEFAULT_FOLDER_GROUP_PERMISSION
        : ADMIN_ADD_REMOVE_DEFAULT_DOCUMENT_GROUP_PERMISSION;

    return (
      <DefaultGroupPermissions
        update={this.update}
        handleCompleted={this.props.handleCompleted}
        mut={mutation}
        groupPermissionsRows={this.state.groupPermissionsRows}
        mutation={true}
        handleSubmit={this.handleSubmit}
        handleCancel={this.props.handleCancel}
        handleCheckChange={this.handleCheckChange}
        fileType={fileType}
      />
    );
  }
}

export default DefaultGroupPermissionsMutation;
