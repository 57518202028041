// React
import React from "react";

// Semantic ui
import {
  Form,
  Checkbox,
  Button,
  Modal,
  Dimmer,
  Dropdown,
  Loader,
} from "semantic-ui-react";

// GraphQL
import { UPDATE_TASK } from "../../../_GraphQL/mutations";
import { ADMIN_TASK } from "../../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";
import { Wysiwyg } from "../../../../../Common/components/Wysiwyg";
import { WysiwygReader } from "../../../../../Common/components/WysiwygReader";
import { SITE_NAMES } from "../../../../../utils/Constants";

const dataGouv =
  "https://cadastre.data.gouv.fr/bundler/cadastre-etalab/communes/";
const parcelleApi = (inseeCode) => `${dataGouv}${inseeCode}/geojson/parcelles`;

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <>
        <Form.Field width={6} required>
          <label>Numéro (pour ordre d'affichage)</label>
          <input
            type="number"
            required
            value={this.props.order}
            onChange={this.props.onChangeOrder}
            disabled={!this.props.enabled}
          />
        </Form.Field>

        <Form.Field width={6} required>
          <label>Type de tâche</label>
          <Dropdown
            placeholder="Type de tâche"
            required
            selection
            options={this.props.taskTypeOptions}
            value={this.props.taskTypeId}
            onChange={this.props.onTaskTypeIdChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={6} required>
          <label>Nom / Titre</label>
          <input
            placeholder="Nom"
            required
            value={this.props.name}
            onChange={this.props.onChangeName}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        {/* <Form.Field width={6}>
          <label>Sous-titre</label>
          <input
            placeholder="Sous-titre"
            value={this.props.subtitle}
            onChange={this.props.onChangeSubtitle}
            disabled={!this.props.enabled}
          />
    </Form.Field> 
        <Form.Field width={6}>
          <label>
            Extrait (affiché lors du choix de la tâche dans l'inventaire)
          </label>
          {this.props.enabled ? (
            <Wysiwyg
              placeholder="Extrait"
              value={this.props.excerpt}
              onChange={this.props.onChangeExcerpt}
            />
          ) : (
            <WysiwygReader raw={this.props.excerpt} />
          )}
        </Form.Field>*/}
        <Form.Field width={6} required>
          <label>
            Description complète (affiché dans l'encart gauche de la carte)
          </label>
          {this.props.enabled ? (
            <Wysiwyg
              placeholder="Description"
              value={this.props.description}
              onChange={this.props.onDescriptionChange}
            />
          ) : (
            <WysiwygReader raw={this.props.description} />
          )}
        </Form.Field>
        <Form.Field width={6}>
          <label>
            Slug (caractères minuscules et tirets uniquement, pas d'espace)
          </label>
          <input
            placeholder="Slug"
            value={this.props.slug}
            onChange={this.props.onSlugChange}
            disabled={!this.props.enabled}
            pattern="[a-z\-]+"
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="Utiliser les données cadastre.data.gouv.fr pour afficher les parcelles ?"
            checked={this.props.useApicarto}
            onChange={this.props.handleApicartoChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        {this.props.useApicarto ? (
          <>
            <Form.Group>
              <Form.Field width={6}>
                <label>
                  Codes Insee des communes (séparés par une virgule)
                </label>
                <input
                  placeholder="Lise des codes Insee des communes séparés par une virgule"
                  value={this.props.municipalities}
                  onChange={this.props.handleMunicipalitiesChange}
                  disabled={!this.props.enabled}
                />
              </Form.Field>
              <Form.Field width={6}>
                <label>Fichier excel des communes (code Insee)</label>
                <input
                  type="file"
                  onChange={this.props.handleFileChange}
                  disabled={!this.props.enabled}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>
                Vérifier que les communes listées ont des données cadastrale
                renseignées sur cadastre.data.gouv.fr
              </label>
              <Button
                disabled={!this.props.enabled}
                onClick={this.props.checkMunicipalities}
                type="button"
              >
                Vérifier
              </Button>
            </Form.Field>
          </>
        ) : null}
        <Form.Field>
          <Checkbox
            label="Tâche active ?"
            checked={this.props.active}
            onChange={this.props.handleActiveChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        {this.props.ici ? (
          <>
            <Form.Field>
              <Checkbox
                label="Afficher le coeur ?"
                checked={this.props.showHeart}
                onChange={this.props.handleShowHeartChange}
                disabled={!this.props.enabled}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Activer la recherche par adresse"
                checked={this.props.geocodingActive}
                onChange={this.props.handleGeocodingActiveChange}
                disabled={!this.props.enabled}
              />
            </Form.Field>
          </>
        ) : null}
      </>
    );
  }
}

class TaskInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      // subtitle: "",
      // excerptInit: "{}",
      // excerpt: "{}",
      descriptionInit: "{}",
      description: "{}",
      useApicarto: false,
      municipalities: "",
      file: null,
      modalOpen: false,
      modalContent: "",
      slug: "",
      order: 1,
      active: true,
      taskTypeId: -1,
      showHeart: false,
      geocodingActive: false,
    };
  }

  handleFileChange = ({
    target: {
      files: [file],
    },
  }) => {
    this.setState({ file: file });
  };

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    const {
      name,
      useApicarto,
      municipalities,
      slug,
      // subtitle,
      description,
      // excerpt,
      order,
      active,
      taskType,
      showHeart,
      geocodingActive,
    } = this.props.task;
    this.setState({
      name: name,
      // subtitle: subtitle || "",
      // excerptInit: excerpt,
      // excerpt: excerpt,
      useApicarto: useApicarto,
      municipalities: municipalities,
      slug: slug || "",
      descriptionInit: description,
      description: description,
      order: order || 1,
      active: active,
      taskTypeId: taskType.id,
      showHeart: showHeart,
      geocodingActive: geocodingActive,
    });
  };

  onChangeOrder = (e) => this.setState({ order: e.target.value });

  onChangeName = (e) => this.setState({ name: e.target.value });

  // onChangeSubtitle = (e) => this.setState({ subtitle: e.target.value });

  // onChangeExcerpt = (excerpt) => this.setState({ excerpt: excerpt });

  onSlugChange = (e) => this.setState({ slug: e.target.value });

  onDescriptionChange = (desc) => this.setState({ description: desc });

  handleApicartoChange = (e, { value, checked }) =>
    this.setState({ useApicarto: checked });

  handleActiveChange = (e, { value, checked }) =>
    this.setState({ active: checked });

  handleShowHeartChange = (e, { value, checked }) =>
    this.setState({ showHeart: checked });

  handleGeocodingActiveChange = (e, { value, checked }) =>
    this.setState({ geocodingActive: checked });

  handleMunicipalitiesChange = (e) =>
    this.setState({ municipalities: e.target.value });

  onTaskTypeIdChange = (e, data) => this.setState({ taskTypeId: data.value });

  handleSubmit = (mutate) =>
    mutate({
      variables: {
        order:
          this.state.order === undefined || this.state.order === null
            ? null
            : parseInt(this.state.order, 10),
        name: this.state.name,
        // subtitle: this.state.subtitle,
        // excerpt: this.state.excerpt,
        taskId: this.props.task.id,
        useApicarto: this.state.useApicarto,
        active: this.state.active,
        municipalities: this.state.useApicarto ? this.state.municipalities : "",
        file: this.state.useApicarto ? this.state.file : null,
        slug: this.state.slug,
        description: this.state.description,
        taskTypeId: this.state.taskTypeId,
        showHeart: this.state.showHeart,
        geocodingActive: this.state.geocodingActive,
      },
    }).then(() => this.setState({ file: null }));

  update = (cache, { data: { updateTask } }) => {
    cache.writeQuery({
      query: ADMIN_TASK,
      variables: { taskId: this.props.task.id },
      data: {
        adminTask: updateTask.task,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Le nom a été enregistré.",
    });
    this.toReadMode();
  };

  checkMunicipalities = async () => {
    this.setState({
      modalContent: (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ),
      modalOpen: true,
    });
    // display loader
    let missingMunicipalities = [];
    const urls = await this.state.municipalities.split(",");
    for (const code of urls) {
      const response = await fetch(parcelleApi(code));
      const json = await response.json();
      if (json.code === 404) {
        missingMunicipalities.push(code);
      }
    }
    if (missingMunicipalities.length === 0) {
      this.setState({ modalContent: "Toutes les communes ont des données" });
    } else {
      this.setState({
        modalContent: (
          <>
            <p>
              Les communes suivantes n'ont pas de données
              <ul>
                {missingMunicipalities.map((m) => (
                  <li>{m}</li>
                ))}
              </ul>
            </p>
          </>
        ),
      });
    }
  };

  render() {
    return (
      <>
        <EditSegment
          title="Informations"
          buttonText="Modifier"
          mutation={UPDATE_TASK}
          onCompleted={this.onCompleted}
          update={this.update}
          handleSubmit={this.handleSubmit}
          toEditMode={this.props.toEditMode}
          handleCancel={this.props.toReadMode}
          editMode={this.props.editMode}
        >
          {(enabled) => (
            <Fields
              order={this.state.order}
              active={this.state.active}
              taskTypeId={this.state.taskTypeId}
              onTaskTypeIdChange={this.onTaskTypeIdChange}
              taskTypeOptions={this.props.taskTypeOptions}
              name={this.state.name}
              // subtitle={this.state.subtitle}
              // excerpt={this.state.excerptInit}
              slug={this.state.slug}
              showHeart={this.state.showHeart}
              geocodingActive={this.state.geocodingActive}
              description={this.state.descriptionInit}
              enabled={enabled}
              initFields={this.initFields}
              onChangeName={this.onChangeName}
              onChangeOrder={this.onChangeOrder}
              // onChangeSubtitle={this.onChangeSubtitle}
              onChangeExcerpt={this.onChangeExcerpt}
              onSlugChange={this.onSlugChange}
              onDescriptionChange={this.onDescriptionChange}
              useApicarto={this.state.useApicarto}
              handleApicartoChange={this.handleApicartoChange}
              handleActiveChange={this.handleActiveChange}
              handleShowHeartChange={this.handleShowHeartChange}
              handleGeocodingActiveChange={this.handleGeocodingActiveChange}
              municipalities={this.state.municipalities}
              handleMunicipalitiesChange={this.handleMunicipalitiesChange}
              file={this.state.file}
              handleFileChange={this.handleFileChange}
              checkMunicipalities={this.checkMunicipalities}
              ici={this.props.task.project.site.name === SITE_NAMES.ICI}
            />
          )}
        </EditSegment>
        <Modal
          onClose={() => this.setState({ modalOpen: false })}
          open={this.state.modalOpen}
        >
          <Modal.Header>Résultat de la vérification</Modal.Header>
          <Modal.Content>{this.state.modalContent}</Modal.Content>
          <Modal.Actions>
            <Button
              content="ok"
              labelPosition="right"
              icon="check"
              onClick={() => this.setState({ modalOpen: false })}
              positive
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export { TaskInfos };
