// React
import React, { useState } from "react";

// React Router
import { Link, withRouter } from "react-router-dom";

// GraphQL
import { useMutation } from "@apollo/client";
import { ADMIN_PROJECT_SET } from "../../_GraphQL/queries";
import { CREATE_PROJECT } from "../../_GraphQL/mutations";

// Semantic ui
import {
  Button,
  Divider,
  Breadcrumb,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
} from "semantic-ui-react";

// Components
import ManagerLayout from "../../components/ManagerLayout";

// Message
import {
  addErrorMessage,
  getMessageTextFromApolloError,
} from "../../../../_GraphQL/message";

function CreateProject({ website, history }) {
  const [name, setName] = useState("");

  const [adminCreateProject, { error }] = useMutation(CREATE_PROJECT, {
    update(cache, { data: { adminCreateProject } }) {
      const { adminProjectSet } = cache.readQuery({
        query: ADMIN_PROJECT_SET,
        variables: { siteId: website.site.id },
      });
      cache.writeQuery({
        query: ADMIN_PROJECT_SET,
        variables: { siteId: website.site.id },
        data: {
          adminProjectSet: adminProjectSet.concat(adminCreateProject.project),
        },
      });
    },
    onCompleted() {
      history.push(`/manager/site/${website.site.id}`);
    },
    onError(error) {
      addErrorMessage(getMessageTextFromApolloError(error));
    },
  });

  return (
    <ManagerLayout
      breadcrumbItems={[
        <Breadcrumb.Section key="breadcrumb-home">
          <Link to="/manager">Manager</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key={`bc-divider-sites`} icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-sites">
          <Link to="/manager/sites">Sites</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key={`bc-divider-site`} icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-site">
          <Link to={`/manager/site/${website.site.id}`}>
            Site : {website.site.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key={`bc-divider-project`} icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-project">
          Création d'un projet
        </Breadcrumb.Section>,
      ]}
    >
      <Segment secondary>
        <Header as="h5">Création d'un projet</Header>
        <Divider />
        <Form
          error
          onSubmit={(e) => {
            e.preventDefault();
            adminCreateProject({
              variables: {
                name: name,
                siteId: website.site.id,
              },
            });
          }}
        >
          {error && <Message error header="Erreur" content={error.message} />}
          <Form.Field width={6}>
            <label>Nom</label>
            <input
              placeholder="Nom"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Field>
          <Grid columns={1}>
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button positive type="submit">
                  <Icon name="checkmark" /> Enregistrer
                </Button>
                <Link to={`/manager/site/${website.site.id}`}>
                  <Button negative>Annuler</Button>
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    </ManagerLayout>
  );
}

const CreateProjectWithRouter = withRouter(CreateProject);

export { CreateProjectWithRouter as CreateProject };
