import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_STYLE } from "../../_GraphQL/mutations";
import { PATTERN_TYPES } from "../../../../utils/Constants";
import { StyleForm } from "./Form";

function UpdateStyle({
  styleId,
  geoTypeCode,
  setGeoTypeCode,
  pathOptions,
  setPathOptions,
  usePattern,
  setUsePattern,
  patternOptions,
  setPatternOptions,
  patternTypeCode,
  setPatternTypeCode,
  shapeOptions,
  setShapeOptions,
  initPathOptions,
  initPatternOptions,
  initShapeOptions,
  usingLayerSet,
  usingQuestionnaireFieldLabelSet,
}) {
  const history = useHistory();
  const [updateStyle, { loading, error }] = useMutation(UPDATE_STYLE, {
    onCompleted({ updateStyle }) {
      history.push(
        `/manager/styles?focus=${updateStyle.style.id}&type=${updateStyle.style.geoTypeCode}`
      );
    },
    onError() {},
  });

  function submit() {
    if (!pathOptions.dashArray) {
      delete pathOptions.dashArray;
    }

    if (!pathOptions.stroke) {
      delete pathOptions.color;
      delete pathOptions.opacity;
      delete pathOptions.weight;
      delete pathOptions.dashArray;
    }

    if (!pathOptions.fill) {
      delete pathOptions.fillColor;
      delete pathOptions.fillOpacity;
    }

    let variables = {
      styleId: styleId,
      geoTypeCode: geoTypeCode,
      usePattern: usePattern,
    };

    if (usePattern) {
      delete pathOptions.fillColor;
      variables.patternTypeCode = patternTypeCode;

      if (patternTypeCode !== PATTERN_TYPES.STRIPES) {
        if (!shapeOptions.fill) {
          delete shapeOptions.fillColor;
          delete shapeOptions.fillOpacity;
        }
        if (!shapeOptions.stroke) {
          delete shapeOptions.color;
          delete shapeOptions.weight;
          delete shapeOptions.opacity;
          delete shapeOptions.dashArray;
        }
        variables.shapeOptions = JSON.stringify(shapeOptions);
      } else {
        delete patternOptions.width;
      }
      variables.patternOptions = JSON.stringify(patternOptions);
    }

    variables.pathOptions = JSON.stringify(pathOptions);

    updateStyle({
      variables: variables,
    });
  }

  return (
    <StyleForm
      styleId={styleId}
      initStyle={initPathOptions}
      initPatternOptions={initPatternOptions}
      initShapeOptions={initShapeOptions}
      submit={submit}
      error={error}
      loading={loading}
      geoTypeCode={geoTypeCode}
      setGeoTypeCode={setGeoTypeCode}
      setPathOptions={setPathOptions}
      usePattern={usePattern}
      setUsePattern={setUsePattern}
      setPatternOptions={setPatternOptions}
      patternTypeCode={patternTypeCode}
      setPatternTypeCode={setPatternTypeCode}
      setShapeOptions={setShapeOptions}
      usingLayerSet={usingLayerSet}
      usingQuestionnaireFieldLabelSet={usingQuestionnaireFieldLabelSet}
    />
  );
}

export { UpdateStyle };
