// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// Semantic ui
import { Button, Breadcrumb, Grid, Header, Segment } from "semantic-ui-react";

// Component
import ProjectListQuery from "../../components/Site/ProjectListQuery";
import ManagerLayout from "../../components/ManagerLayout";
import SiteGroupsQuery from "../../components/Site/SiteGroups";
import SiteModulesQuery from "../../components/Site/SiteModules";
import { SITE_NAMES } from "../../../../../src/utils/Constants";

class Site extends React.Component {
  state = {
    editMode: false,
  };

  toReadMode = () => this.setState({ editMode: false });

  toEditMode = () => this.setState({ editMode: true });

  render() {
    let { website } = this.props;
    const siteLink = `https://${website.site.domain}/`;
    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-sites"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-sites">
            <Link to="/manager/sites">Sites</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key={"bc-divider-site"} icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-site" active>
            Site : {website.site.name}
          </Breadcrumb.Section>,
        ]}
      >
        <Segment>
          <Header as="h5">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  Liste des projets du site «&nbsp;{website.site.name}&nbsp;»
                  accessible via l&apos;adresse{" "}
                  <a href={siteLink} target="_blank" rel="noopener noreferrer">
                    {siteLink}
                  </a>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  {website.site.name === SITE_NAMES.ICI &&
                  !this.props.editMode ? (
                    <Link to={`/manager/projet/creation/${website.site.id}`}>
                      <Button primary>Ajouter un projet au site</Button>
                    </Link>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
        </Segment>
        <ProjectListQuery siteId={website.site.id} />

        <SiteGroupsQuery
          siteId={website.site.id}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
        />

        <SiteModulesQuery
          siteId={website.site.id}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
        />
      </ManagerLayout>
    );
  }
}

export { Site };
