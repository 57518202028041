// React
import React, { useEffect, useRef } from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import { ADMIN_USER_SET } from "../../_GraphQL/queries";

// React Router
import { withRouter } from "react-router-dom";

// Semantic ui
import {
  Form,
  Icon,
  Image,
  Input,
  Menu,
  Table,
  Loader,
  Segment,
} from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";

// Constants
import { USER_TYPES } from "../../../../utils/Constants";
import { useState } from "react";

export const NAV_BUTTON = {
  FIRST: "FIRST",
  LAST: "LAST",
  PREV: "PREV",
  NEXT: "NEXT",
};

const nbRows = 9;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function UserList({
  users,
  page,
  firstNameClicked,
  emailClicked,
  sortColumn,
  sortDirection,
  history,
  nbPage,
  setNbPage,
  handleNavClick,
  setPage,
}) {
  const prevCount = usePrevious(users.length);

  useEffect(() => {
    if (prevCount !== users.length) {
      setNbPage(Math.ceil(users.length / nbRows));
    }
  }, [users, prevCount, setNbPage]);

  const [pageValue, setPageValue] = useState(1);

  useEffect(() => {
    setPageValue(page);
  }, [page]);

  if (
    page === null ||
    page === undefined ||
    nbRows === null ||
    nbRows === undefined
  )
    return null;

  if (users.length === 0) {
    return (
      <Segment>
        <p>Aucun utilisateur n'a été trouvé pour ces filtres</p>
      </Segment>
    );
  }

  let startIndex = nbRows * (page - 1);
  let endIndex = startIndex + nbRows;
  let usersToDisplay = users.slice(startIndex, endIndex);

  return (
    <Table celled selectable attached>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell className="clickable" onClick={firstNameClicked}>
            {sortColumn === "first_name" ? (
              <Icon
                name={
                  sortDirection === "asc" ? "sort ascending" : "sort descending"
                }
              />
            ) : (
              <Icon name="sort"></Icon>
            )}
            Nom
          </Table.HeaderCell>
          <Table.HeaderCell className="clickable" onClick={emailClicked}>
            {sortColumn === "email" ? (
              <Icon
                name={
                  sortDirection === "asc" ? "sort ascending" : "sort descending"
                }
              />
            ) : (
              <Icon name="sort"></Icon>
            )}
            Email
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {usersToDisplay.map((u) => {
          let displayName = "";
          if (u.profile && u.profile.nature.code === USER_TYPES.LEGAL_PERSON) {
            displayName = u.profile.name;
          } else {
            displayName = `${u.firstName} ${u.lastName}`;
          }
          return (
            <Table.Row
              key={`user-${u.id}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(
                  `/manager/utilisateur/${u.id}${window.location.search}`
                );
              }}
              className="clickable"
            >
              <Table.Cell textAlign="center" width={1}>
                {u.profile && u.profile.avatarPath ? (
                  <Image
                    src={`/media/avatars/${u.profile.avatarPath}`}
                    avatar
                  />
                ) : (
                  <Icon name="user outline" />
                )}
              </Table.Cell>
              <Table.Cell>{displayName}</Table.Cell>
              <Table.Cell>{u.email}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <Menu floated="right" pagination>
              <Menu.Item
                as="a"
                icon
                onClick={() => handleNavClick(NAV_BUTTON.FIRST)}
              >
                <Icon name="angle double left" />
              </Menu.Item>
              <Menu.Item
                as="a"
                icon
                onClick={() => handleNavClick(NAV_BUTTON.PREV)}
              >
                <Icon name="angle left" />
              </Menu.Item>
              <Menu.Item as="a">
                <Form.Group inline>
                  <label>Page</label>
                  <Input
                    value={pageValue}
                    onChange={(_, { value }) => setPageValue(value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        if (pageValue < 1) {
                          setPageValue(1);
                        } else if (pageValue > nbPage) {
                          setPageValue(nbPage);
                        } else {
                          setPage(pageValue);
                        }
                      }
                    }}
                  />
                  <label> sur {nbPage}</label>
                </Form.Group>
              </Menu.Item>
              <Menu.Item
                as="a"
                icon
                onClick={() => handleNavClick(NAV_BUTTON.NEXT)}
              >
                <Icon name="angle right" />
              </Menu.Item>
              <Menu.Item
                as="a"
                icon
                onClick={() => handleNavClick(NAV_BUTTON.LAST)}
              >
                <Icon name="angle double right" />
              </Menu.Item>
            </Menu>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

const UserListWithRouter = withRouter(UserList);

function UserListQuery({
  active,
  sortColumn,
  sortDirection,
  firstNameClicked,
  emailClicked,
  groupId,
  siteId,
  projectId,
  search,
  nbRows,
  nbPage,
  setNbPage,
  handleNavClick,
  setPage,
  page,
}) {
  const { loading, error, data } = useQuery(ADMIN_USER_SET, {
    variables: {
      active: active,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      groupId: groupId,
      siteId: siteId,
      projectId: projectId,
    },
    fetchPolicy: "network-only",
  });

  if (loading || !data) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  function searchFilter(item) {
    let lowerSearch = search.toLowerCase();
    return (
      item.lastName.toLowerCase().includes(lowerSearch) ||
      item.firstName.toLowerCase().includes(lowerSearch) ||
      item.email.toLowerCase().includes(lowerSearch) ||
      (item.profile &&
        item.profile.name &&
        item.profile.name.toLowerCase().includes(lowerSearch))
    );
  }

  let users = data.adminUserSet.filter(searchFilter);

  return (
    <UserListWithRouter
      users={users}
      firstNameClicked={firstNameClicked}
      emailClicked={emailClicked}
      sortColumn={sortColumn}
      nbPage={nbPage}
      nbRows={nbRows}
      setNbPage={setNbPage}
      handleNavClick={handleNavClick}
      page={page}
      setPage={setPage}
    />
  );
}

export { UserListQuery as UserList };
