// React
import React from "react";

// GraphQL
import { ICON_LIST } from "../../../_GraphQL/queries";

// Semantic ui
import { Modal, Button } from "semantic-ui-react";

// Component
import EQuery from "../../../../../utils/EQuery";

class IconPicker extends React.Component {
  state = { modalOpen: false };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    return (
      <Modal
        trigger={
          <Button type="button" onClick={this.handleOpen}>
            Choisir une icône
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>Sélection d'une icône</Modal.Header>
        <Modal.Content>
          <EQuery query={ICON_LIST}>
            {(data) => {
              return (
                <>
                  {data.adminIconeList.map((element) => {
                    return (
                      <img
                        key={`element-${element}`}
                        style={{ margin: "3px", cursor: "pointer" }}
                        width="30px"
                        height="30px"
                        alt={element}
                        src={`/static/back/resources/media/markers/${element}`}
                        onClick={() =>
                          this.setState({ modalOpen: false }, () =>
                            this.props.pickIcon(element)
                          )
                        }
                      />
                    );
                  })}
                </>
              );
            }}
          </EQuery>
        </Modal.Content>
      </Modal>
    );
  }
}

export default IconPicker;
