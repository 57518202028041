// React
import React from "react";

// Semantic ui
import { Form, Image, Input } from "semantic-ui-react";

// GraphQL
import { UPDATE_PROJECT_ILLUSTRATION } from "../../_GraphQL/mutations";
import { GET_PROJECT } from "../../../../Common/_GraphQL/queries";

// Components
import { EditSegment } from "../../../../Common/components/EditSegment";

// Message
import {
  addErrorToastMessage,
  addSuccessToastMessage,
} from "../../../../_GraphQL/message";

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    if (this.props.enabled) {
      return (
        <Form.Field width={6}>
          <label>Illustration</label>
          <Input type="file" onChange={this.props.onIllustrationChange} />
        </Form.Field>
      );
    } else {
      if (this.props.illustrationPath) {
        return (
          <Image
            src={`/media/projects/${this.props.illustrationPath}`}
            alt="Illustration"
          />
        );
      } else {
        return <p>Aucune illustration</p>;
      }
    }
  }
}

class ProjectIllustration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      illustration: "",
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => this.setState({ illustration: "" });

  onIllustrationChange = ({
    target: {
      files: [file],
    },
  }) => this.setState({ illustration: file });

  handleSubmit = (mutate) => {
    if (this.state.illustration === "") {
      addErrorToastMessage({
        header: "Fichier manquant",
        content: "Aucune illustration n'a été renseignée.",
      });
      return;
    }
    mutate({
      variables: {
        illustration: this.state.illustration,
        projectId: this.props.project.id,
      },
    });
  };

  update = (cache, { data: { adminUpdateProjectIllustration } }) => {
    cache.writeQuery({
      query: GET_PROJECT,
      variables: { projectId: this.props.project.id },
      data: {
        project: adminUpdateProjectIllustration.project,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "L'image a été enregistrée.",
    });
    this.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="Illustration"
        buttonText="Modifier"
        mutation={UPDATE_PROJECT_ILLUSTRATION}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            illustration={this.state.illustration}
            illustrationPath={this.props.project.illustrationPath}
            enabled={enabled}
            initFields={this.initFields}
            onIllustrationChange={this.onIllustrationChange}
          />
        )}
      </EditSegment>
    );
  }
}

export default ProjectIllustration;
