// React
import React from "react";

// React Router
import { withRouter, Link } from "react-router-dom";

// GraphQL
import { GET_LAYER } from "../../_GraphQL/queries";
import EQuery from "../../../../utils/EQuery";
import { ADMIN_QUESTIONNAIRE } from "../../_GraphQL/queries";

// Semantic ui
import { Breadcrumb, Header } from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";
import QuestionnaireFieldInfos from "../../components/Questionnaire/QuestionnaireFieldInfos";

class CreateQuestionnaireField extends React.Component {
  backToQuestionnaire = () =>
    this.props.history.push(
      `/manager/questionnaire/${this.props.questionnaire.id}`
    );

  backToQuestionnaireForLayer = () =>
    this.props.history.push(
      `/manager/couche/${this.props.match.params.layerId}/questionnaire/${this.props.questionnaire.id}`
    );

  render() {
    const { questionnaire, fieldTypeSet, layer } = this.props;
    let breadcrumbItems = [
      <Breadcrumb.Section key="breadcrumb-home">
        <Link to="/manager">Manager</Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key="bc-divider-sites" icon="right angle" />,
    ];
    let layerId = null;
    let toReadMode = null;
    if (layer) {
      layerId = layer.id;
      toReadMode = this.backToQuestionnaireForLayer;
      breadcrumbItems.push([
        <Breadcrumb.Section key="breadcrumb-sites">
          <Link to="/manager/sites">Sites</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-site" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-site">
          <Link to={`/manager/site/${layer.task.project.site.id}`}>
            Site : {layer.task.project.site.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-project" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-project">
          <Link to={`/manager/projet/${layer.task.project.id}`}>
            Projet : {layer.task.project.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-module" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-module">
          <Link to={`/manager/carte/?projectId=${layer.task.project.id}`}>
            Module : Carte participative
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-task" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-task">
          <Link to={`/manager/tache/${layer.task.id}`}>
            Tâche : {layer.task.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-layer" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-layer">
          <Link to={`/manager/couche/${layer.id}`}>Couche : {layer.name}</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-questionnaire"
          icon="right angle"
        />,
        <Breadcrumb.Section key="breadcrumb-questionnaire">
          <Link
            to={`/manager/couche/${layer.id}/questionnaire/${questionnaire.id}`}
          >
            Questionnaire : {questionnaire.name}
          </Link>
        </Breadcrumb.Section>,
      ]);
    } else {
      toReadMode = this.backToQuestionnaire;
      breadcrumbItems.push([
        <Breadcrumb.Section key="breadcrumb-questionnaires">
          <Link to="/manager/questionnaires">Questionnaires</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-questionnaire"
          icon="right angle"
        />,
        <Breadcrumb.Section key="breadcrumb-questionnaire">
          <Link to={`/manager/questionnaire/${questionnaire.id}`}>
            Questionnaire : {questionnaire.name}
          </Link>
        </Breadcrumb.Section>,
      ]);
    }

    breadcrumbItems.push([
      <Breadcrumb.Divider key="bc-divider-create-field" icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-create-field">
        Création d&apos;un Champ
      </Breadcrumb.Section>,
    ]);

    return (
      <ManagerLayout breadcrumbItems={breadcrumbItems}>
        <Header as="h5" block>
          Création d&apos;un Champ
        </Header>
        <QuestionnaireFieldInfos
          questionnaire={questionnaire}
          toReadMode={toReadMode}
          fieldTypeSet={fieldTypeSet}
          create={true}
          layerId={layerId}
        />
      </ManagerLayout>
    );
  }
}

const CreateQuestionnaireFieldWithRouter = withRouter(CreateQuestionnaireField);

const CreateQuestionnaireFieldQuery = ({ match, layer }) => (
  <EQuery
    query={ADMIN_QUESTIONNAIRE}
    variables={{ questionnaireId: match.params.questionnaireId }}
  >
    {(data) => {
      const fieldTypeSet = data.adminFieldTypeSet.map((type) => ({
        key: type.id,
        value: type.code,
        text: type.text,
      }));

      return (
        <CreateQuestionnaireFieldWithRouter
          questionnaire={data.adminQuestionnaire}
          fieldTypeSet={fieldTypeSet}
          layer={layer}
        />
      );
    }}
  </EQuery>
);

export { CreateQuestionnaireFieldQuery };

const CreateQuestionnaireFieldEntry = ({ match }) => {
  if (match.params.layerId) {
    return (
      <EQuery query={GET_LAYER} variables={{ layerId: match.params.layerId }}>
        {(data) => (
          <CreateQuestionnaireFieldQuery match={match} layer={data.layer} />
        )}
      </EQuery>
    );
  }

  return <CreateQuestionnaireFieldQuery match={match} />;
};

export default CreateQuestionnaireFieldEntry;
