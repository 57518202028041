// React
import React, { ReactNode } from "react";

// Semantic ui
import {
  Segment,
  Form,
  Grid,
  Button,
  Header,
  Divider,
  Ref,
} from "semantic-ui-react";

// Component
import { COMPONENTS } from "../../utils/Constants";
import EMutation from "../../utils/EMutation";

// utils
import { isElementOutViewport } from "../../utils/utils";

const scrollIntoViewOptions: ScrollIntoViewOptions = {
  behavior: "smooth",
  block: "start",
};

type ReadFormProps = {
  fields: (enable: boolean, fieldsDidUpdate?: () => void) => ReactNode;
};

function ReadForm({ fields }: ReadFormProps) {
  return <Form>{fields(false)}</Form>;
}

// Pour le type des mutation, useMutation renvoie un typle
// MutationTuple<TData, TVariables>
// TData étant défini par l'appel à useMutation
// c'est donc un type générique et on peut mettre ce qu'on veut.
// que se passe t'il si j'ajoute une signature plus précise mais que derrière useMutation reste appelée sans type précisé ?
type EditFormProps = ReadFormProps & {
  mutate?: any;
  fieldsDidUpdate?: () => void;
  saveDisabled?: boolean;
  handleCancel: () => void;
  handleSubmit: (mutation: any) => void;
};

function EditForm({
  mutate,
  fieldsDidUpdate,
  saveDisabled,
  handleCancel,
  handleSubmit,
  fields,
}: EditFormProps) {
  return (
    <Form onSubmit={() => handleSubmit(mutate)} warning>
      {fieldsDidUpdate ? fields(true, fieldsDidUpdate) : fields(true)}
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Button positive type="submit" disabled={saveDisabled}>
              Enregistrer
            </Button>
            <Button negative onClick={handleCancel}>
              Annuler
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

type EditSegmentProps = {
  toEditMode: () => void;
  handleCancel: () => void;
  handleSubmit: (mutation: any) => void;
  onCompleted: (data: any) => void;
  children: (enable: boolean) => ReactNode;
  openInEditMode: boolean;
  title: string;
  buttonText: string;
  editMode: boolean;
  mutation: any;
  update: any;
  saveDisabled?: boolean;
};

type EditSegmentState = {
  editing: boolean;
};

class EditSegment extends React.Component<EditSegmentProps, EditSegmentState> {
  segmentRef: React.RefObject<HTMLDivElement>;

  public static defaultProps = {
    openInEditMode: false,
  };

  constructor(props: EditSegmentProps) {
    super(props);
    this.segmentRef = React.createRef();
    this.state = { editing: false };
    this.fieldsDidUpdate = this.fieldsDidUpdate.bind(this);
  }

  toEditMode = () =>
    this.setState({ editing: true }, () => this.props.toEditMode());

  handleCancel = () =>
    this.setState({ editing: false }, () => this.props.handleCancel());

  onCompleted = (data: any) => {
    this.setState({ editing: false }, () => this.props.onCompleted(data));
  };

  componentDidMount() {
    if (this.props.openInEditMode) {
      this.setState({ editing: true });
    }
  }

  showSegment() {
    if (
      this.state.editing &&
      this.segmentRef.current &&
      isElementOutViewport(this.segmentRef.current)
    ) {
      var rect = this.segmentRef.current.getBoundingClientRect();
      if (rect.height > window.innerHeight) {
        scrollIntoViewOptions.block = "start";
      } else {
        scrollIntoViewOptions.block = "end";
      }
      this.segmentRef.current.scrollIntoView(scrollIntoViewOptions);
    }
  }

  fieldsDidUpdate() {
    this.showSegment();
  }

  render() {
    this.showSegment();
    const style = this.state.editing
      ? { boxShadow: "0px 0px 10px 8px rgba(116,132,145,1)" }
      : null;
    return (
      <Ref innerRef={this.segmentRef}>
        <Segment secondary style={style}>
          <Header as="h5">
            <Grid columns={2}>
              <Grid.Row style={{ minHeight: "36px" }}>
                <Grid.Column verticalAlign="middle">
                  {this.props.title}
                </Grid.Column>
                <Grid.Column textAlign="right">
                  {!this.props.editMode && !this.state.editing ? (
                    <Button primary floated="right" onClick={this.toEditMode}>
                      {this.props.buttonText}
                    </Button>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <Divider />
          {this.state.editing ? (
            <EMutation
              mutation={this.props.mutation}
              update={this.props.update}
              onCompleted={this.onCompleted}
              location={COMPONENTS.COMMON.INDEX}
            >
              {(mutate: any) => (
                <EditForm
                  fieldsDidUpdate={this.fieldsDidUpdate}
                  handleSubmit={this.props.handleSubmit}
                  mutate={mutate}
                  fields={this.props.children}
                  handleCancel={this.handleCancel}
                  saveDisabled={this.props.saveDisabled}
                />
              )}
            </EMutation>
          ) : (
            <ReadForm fields={this.props.children} />
          )}
        </Segment>
      </Ref>
    );
  }
}

export { EditSegment };
