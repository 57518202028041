// React
import React from "react";

// React Router
import { withRouter } from "react-router-dom";

// Semantic ui
import { Form, Dropdown, Checkbox, Popup, Icon, Grid } from "semantic-ui-react";

// GraphQL
import {
  ADMIN_UPDATE_QUESTIONNAIRE_FIELD,
  ADMIN_CREATE_QUESTIONNAIRE_FIELD,
} from "../../_GraphQL/mutations";
import { ADMIN_QUESTIONNAIRE } from "../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../Common/components/EditSegment";

// Constants
import { QUESTIONNAIRE_FIELD_TYPES } from "../../../../utils/Constants";

// Message
import {
  addErrorToastMessage,
  addSuccessToastMessage,
} from "../../../../_GraphQL/message";

const checkboxOptions = [
  { key: "True", value: true, text: "Coché" },
  { key: "False", value: false, text: "Décoché" },
];

const re = /^[0-9\b]+$/;

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    const minimumText =
      this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.TEXT
        ? "Nombre minimum de caractères"
        : "Minimum";
    const maximumText =
      this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.TEXT
        ? "Nombre maximum de caractères"
        : "Maximum";

    const questionSet = this.props.questionnaire.questionnairefieldSet
      .filter(
        (qf) =>
          qf.id !== this.props.questionnaireFieldId &&
          (qf.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.CHECKBOX ||
            qf.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.RADIO ||
            qf.fieldType.code === QUESTIONNAIRE_FIELD_TYPES.SELECT)
      )
      .map((qField) => ({
        key: qField.id,
        value: qField.id,
        text: qField.text,
      }));

    let parentQuestion, labelSet;

    if (this.props.parentId) {
      parentQuestion = this.props.questionnaire.questionnairefieldSet.find(
        (qf) => qf.id === this.props.parentId
      );

      labelSet = [
        { key: -1, value: -1, text: "N'importe quel élément sélectionné" },
        ...parentQuestion.questionnairefieldlabelSet.map((qfl) => ({
          key: qfl.id,
          value: qfl.id,
          text: qfl.text,
        })),
      ];
    }

    return (
      <>
        <Form.Field width={6}>
          <label>Intitulé</label>
          <input
            placeholder="Intitulé"
            required
            value={this.props.text}
            onChange={this.props.onTextChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={6}>
          <label>Type</label>
          <Dropdown
            required
            selection
            value={this.props.fieldTypeCode}
            options={this.props.fieldTypeSet}
            onChange={this.props.onFielTypeChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        {this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.SELECT &&
        this.props.multiselectAllowed ? (
          <Form.Field width={6}>
            <Checkbox
              label="Sélection multiple"
              checked={this.props.multiselect}
              onChange={this.props.onMultiselectChange}
              disabled={!this.props.enabled}
            />
          </Form.Field>
        ) : null}
        <Form.Field width={6}>
          <Checkbox
            label="Champ obligatoire"
            checked={this.props.required}
            onChange={this.props.onRequiredChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={6}>
          <Checkbox
            label="Lecture seule"
            checked={this.props.readOnly}
            onChange={this.props.onReadOnlyChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        {this.props.fieldTypeCode !== QUESTIONNAIRE_FIELD_TYPES.CHECKBOX &&
        this.props.fieldTypeCode !== QUESTIONNAIRE_FIELD_TYPES.RADIO ? (
          <Form.Field width={6}>
            <label>Indice dans le champ quand il est vide</label>
            <input
              placeholder="Indice"
              value={this.props.placeHolder}
              onChange={this.props.onPlaceHolderChange}
              disabled={!this.props.enabled}
            />
          </Form.Field>
        ) : null}
        <Form.Field width={6}>
          <label>Bulle d&apos;aide</label>
          <input
            placeholder="Aide"
            value={this.props.helptext}
            onChange={this.props.onHelptextChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={8}>
          <label>Attribut</label>
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column width={6}>
                <input
                  placeholder="Propriété SHP"
                  value={this.props.shpField}
                  onChange={this.props.onShpFieldChange}
                  disabled={!this.props.enabled}
                  maxLength="10"
                  width={60}
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle" width={8}>
                <Popup
                  trigger={
                    <Icon
                      circular
                      name="question"
                      size="tiny"
                      color="teal"
                      inverted
                    />
                  }
                  content="10 caractères max. lettre a-z, chiffre 0-9 et _ autorisés"
                  size="mini"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
        {this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.TEXT ||
        this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.IMAGE ? (
          <Form.Field width={3}>
            <Checkbox
              label={
                this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.TEXT
                  ? "Réponse sur plusieurs lignes"
                  : "Possibilité d'ajouter plusieurs images"
              }
              checked={this.props.multiline}
              onChange={this.props.onMultilineChange}
              disabled={!this.props.enabled}
            />
          </Form.Field>
        ) : null}
        {this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.DECIMAL ? (
          <Form.Field>
            <Checkbox
              label="Nombre décimal"
              checked={this.props.decimal}
              onChange={this.props.onDecimalChange}
              disabled={!this.props.enabled}
            />
          </Form.Field>
        ) : null}
        {this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.DECIMAL &&
        this.props.decimal ? (
          <Form.Field>
            <Form.Input
              width={2}
              label="Nombre de décimales"
              required
              value={this.props.decimalDigits}
              onChange={this.props.onDecimalDigitsChange}
              disabled={!this.props.enabled}
            />
          </Form.Field>
        ) : null}
        {this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.DECIMAL ||
        this.props.fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.TEXT ? (
          <>
            <Form.Field>
              <Form.Input
                width={2}
                label={minimumText}
                value={this.props.minimum}
                onChange={this.props.onMinimumChange}
                disabled={!this.props.enabled}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                width={2}
                label={maximumText}
                value={this.props.maximum}
                onChange={this.props.onMaximumChange}
                disabled={!this.props.enabled}
              />
            </Form.Field>
          </>
        ) : null}
        <Form.Field width={6}>
          <Checkbox
            label="Cette question dépend d'une autre"
            checked={this.props.isChild}
            onChange={this.props.onIsChildChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        {this.props.isChild ? (
          <>
            {/* filtrer sur les questions de type radio select et checkbox qui ne sont pas celle ci */}
            <Form.Field width={6}>
              <label>Question parente</label>
              <Dropdown
                required
                selection
                value={this.props.parentId}
                options={questionSet}
                onChange={this.props.onParentIdChange}
                disabled={!this.props.enabled}
              />
            </Form.Field>
            {
              /* si la question parent est de type checkbox */
              parentQuestion &&
              parentQuestion.fieldType.code ===
                QUESTIONNAIRE_FIELD_TYPES.CHECKBOX ? (
                <Form.Field width={6}>
                  <label>
                    Etat qui déclenche l&apos;affichage de la question
                  </label>
                  <Dropdown
                    required
                    selection
                    value={this.props.parentAnswer}
                    options={checkboxOptions}
                    onChange={this.props.onParentAnswerChange}
                    disabled={!this.props.enabled}
                  />
                </Form.Field>
              ) : null
            }

            {
              /* si la question parente est de type select ou radio */
              parentQuestion &&
              (parentQuestion.fieldType.code ===
                QUESTIONNAIRE_FIELD_TYPES.RADIO ||
                parentQuestion.fieldType.code ===
                  QUESTIONNAIRE_FIELD_TYPES.SELECT) ? (
                <Form.Field width={6}>
                  <label>
                    Sélection qui déclenche l&apos;affichage de la question
                  </label>
                  {/* ajouter n'importe quel champ à la liste des réponses à la question */}
                  <Dropdown
                    required
                    selection
                    value={this.props.parentLabelId}
                    options={labelSet}
                    onChange={this.props.onParentLabelIdChange}
                    disabled={!this.props.enabled}
                  />
                </Form.Field>
              ) : null
            }
          </>
        ) : null}
      </>
    );
  }
}

class QuestionnaireFieldInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldTypeCode: "",
      text: "",
      placeHolder: "",
      shpField: "",
      required: false,
      readOnly: false,
      helptext: "",
      multiline: false,
      multiselect: false,
      minimum: "",
      maximum: "",
      decimalDigits: "",
      decimal: false,
      isChild: false,
      parentId: null,
      parentAnswer: null,
      parentLabelId: null,
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    const { questionnaireField, create } = this.props;
    let parentLabelId;
    if (questionnaireField) {
      const parentQuestionnaireFieldLabel =
        questionnaireField.parentQuestionnaireFieldLabel;
      if (parentQuestionnaireFieldLabel) {
        parentLabelId = parentQuestionnaireFieldLabel.id;
      } else {
        parentLabelId = -1;
      }
    }

    if (!create) {
      this.setState({
        text: questionnaireField.text,
        fieldTypeCode: questionnaireField.fieldType.code,
        placeHolder: questionnaireField.placeHolder || "",
        shpField: questionnaireField.shpField || "",
        required: questionnaireField.required || false,
        readOnly: questionnaireField.readOnly || false,
        minimum: questionnaireField.minimum || "",
        helptext: questionnaireField.helptext || "",
        multiline: questionnaireField.multiline || false,
        multiselect: questionnaireField.multiselect || false,
        maximum: questionnaireField.maximum || "",
        decimalDigits: questionnaireField.decimalDigits || "",
        decimal: questionnaireField.decimal || false,
        isChild: questionnaireField.parentQuestionnaireField !== null,
        parentId: questionnaireField.parentQuestionnaireField
          ? questionnaireField.parentQuestionnaireField.id
          : null,
        parentAnswer: questionnaireField.parentQuestionnaireFieldAnswer,
        parentLabelId: parentLabelId,
      });
    }
  };

  onTextChange = (e) => this.setState({ text: e.target.value });

  onPlaceHolderChange = (e) => this.setState({ placeHolder: e.target.value });

  onShpFieldChange = (e) => this.setState({ shpField: e.target.value });

  onHelptextChange = (e) => this.setState({ helptext: e.target.value });

  onMinimumChange = (e) => {
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ minimum: e.target.value });
    }
  };

  onMaximumChange = (e) => {
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ maximum: e.target.value });
    }
  };

  onDecimalDigitsChange = (e) => {
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ decimalDigits: e.target.value });
    }
  };

  onFielTypeChange = (e, { value }) => this.setState({ fieldTypeCode: value });

  onParentIdChange = (e, { value }) => this.setState({ parentId: value });

  onParentLabelIdChange = (e, { value }) =>
    this.setState({ parentLabelId: value });

  onParentAnswerChange = (e, { value }) =>
    this.setState({ parentAnswer: value });

  onRequiredChange = (e, { checked }) => this.setState({ required: checked });

  onReadOnlyChange = (e, { checked }) => this.setState({ readOnly: checked });

  onMultilineChange = (e, { checked }) => this.setState({ multiline: checked });

  onMultiselectChange = (e, { checked }) =>
    this.setState({ multiselect: checked });

  onDecimalChange = (e, { checked }) => this.setState({ decimal: checked });

  onIsChildChange = (e, { checked }) => {
    if (!checked) {
      this.setState({
        isChild: checked,
        parentId: null,
        parentLabelId: null,
      });
    } else {
      this.setState({ isChild: checked });
    }
  };

  handleSubmit = (mutate) => {
    if (this.state.isChild) {
      if (!this.state.parentId) {
        addErrorToastMessage({
          header: "Informations manquantes",
          content: "Veuillez renseigner la question parente.",
        });
        return;
      }
    }

    let variables = {
      questionnaireId: this.props.questionnaire.id,
      text: this.state.text,
      fieldTypeId: this.props.fieldTypeSet.find(
        (f) => f.value === this.state.fieldTypeCode
      ).key,
      required: this.state.required,
      readOnly: this.state.readOnly,
      multiline: this.state.multiline,
      multiselect: this.state.multiselect,
      decimal: this.state.decimal,
    };

    if (!this.props.create) {
      variables.questionnaireFieldId = this.props.questionnaireField.id;
    }

    if (this.state.placeHolder !== "") {
      variables.placeHolder = this.state.placeHolder;
    }

    if (this.state.shpField !== "") {
      variables.shpField = this.state.shpField;
    }

    if (this.state.minimum !== "") {
      variables.minimum = parseInt(this.state.minimum, 10);
    }

    if (this.state.helptext !== "") {
      variables.helptext = this.state.helptext;
    }

    if (this.state.maximum !== "") {
      variables.maximum = parseInt(this.state.maximum, 10);
    }

    if (this.state.decimalDigits !== "") {
      variables.decimalDigits = parseInt(this.state.decimalDigits, 10);
    }

    if (this.state.parentId) {
      variables.parentQuestionnaireFieldId = this.state.parentId;

      if (
        this.state.parentAnswer === null &&
        this.state.parentLabelId === null
      ) {
        addErrorToastMessage({
          header: "Informations manquantes",
          content:
            "Veuillez préciser la réponse attendue pour la question parente.",
        });
        return;
      }

      if (this.state.parentAnswer !== null) {
        variables.parentQuestionnaireFieldAnswer = this.state.parentAnswer;
      }

      if (
        this.state.parentLabelId !== null &&
        this.state.parentLabelId !== -1
      ) {
        variables.parentQuestionnaireFieldLabelId = this.state.parentLabelId;
      }
    }

    mutate({
      variables: variables,
    });
  };

  update = (
    cache,
    { data: { adminUpdateQuestionnaireField, adminCreateQuestionnaireField } }
  ) => {
    let data = cache.readQuery({
      query: ADMIN_QUESTIONNAIRE,
      variables: { questionnaireId: this.props.questionnaire.id },
    });

    if (this.props.create) {
      cache.writeQuery({
        query: ADMIN_QUESTIONNAIRE,
        variables: {
          questionnaireId: this.props.questionnaire.id,
        },
        data: {
          adminFieldTypeSet: data.adminFieldTypeSet,
          adminQuestionnaire: {
            ...data.adminQuestionnaire,
            questionnairefieldSet:
              data.adminQuestionnaire.questionnairefieldSet.concat([
                adminCreateQuestionnaireField.questionnaireField,
              ]),
          },
        },
      });
    } else {
      cache.writeQuery({
        query: ADMIN_QUESTIONNAIRE,
        variables: {
          questionnaireId: this.props.questionnaire.id,
        },
        data: {
          adminFieldTypeSet: data.adminFieldTypeSet,
          adminQuestionnaire: adminUpdateQuestionnaireField.questionnaire,
        },
      });
    }
  };

  onCompleted = () => {
    if (this.props.create) {
      if (this.props.layerId) {
        this.props.history.push(
          `/manager/couche/${this.props.layerId}/questionnaire/${this.props.questionnaire.id}`
        );
      } else {
        this.props.history.push(
          `/manager/questionnaire/${this.props.questionnaire.id}`
        );
      }
    } else {
      addSuccessToastMessage({
        header: "Confirmation enregistrement",
        content: "Le informations ont été enregistrées.",
      });
      this.toReadMode();
    }
  };

  render() {
    const mutate = this.props.create
      ? ADMIN_CREATE_QUESTIONNAIRE_FIELD
      : ADMIN_UPDATE_QUESTIONNAIRE_FIELD;
    const questionnaireFieldId = this.props.questionnaireField
      ? this.props.questionnaireField.id
      : null;
    return (
      <EditSegment
        title="Informations"
        buttonText="Modifier"
        mutation={mutate}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
        openInEditMode={this.props.create}
        saveDisabled={this.state.fieldTypeCode === "" || this.state.text === ""}
      >
        {(enabled) => (
          <Fields
            questionnaire={this.props.questionnaire}
            questionnaireId={this.props.questionnaire.id}
            questionnaireFieldId={questionnaireFieldId}
            fieldTypeCode={this.state.fieldTypeCode}
            onFielTypeChange={this.onFielTypeChange}
            fieldTypeSet={this.props.fieldTypeSet}
            text={this.state.text}
            onTextChange={this.onTextChange}
            placeHolder={this.state.placeHolder}
            onPlaceHolderChange={this.onPlaceHolderChange}
            shpField={this.state.shpField}
            onShpFieldChange={this.onShpFieldChange}
            required={this.state.required}
            onRequiredChange={this.onRequiredChange}
            readOnly={this.state.readOnly}
            onReadOnlyChange={this.onReadOnlyChange}
            minimum={this.state.minimum}
            onMinimumChange={this.onMinimumChange}
            helptext={this.state.helptext}
            onHelptextChange={this.onHelptextChange}
            multiline={this.state.multiline}
            onMultilineChange={this.onMultilineChange}
            multiselectAllowed={
              this.props.create || !this.props.questionnaireField.isDecorator
            }
            multiselect={this.state.multiselect}
            onMultiselectChange={this.onMultiselectChange}
            maximum={this.state.maximum}
            onMaximumChange={this.onMaximumChange}
            decimalDigits={this.state.decimalDigits}
            onDecimalDigitsChange={this.onDecimalDigitsChange}
            decimal={this.state.decimal}
            onDecimalChange={this.onDecimalChange}
            isChild={this.state.isChild}
            onIsChildChange={this.onIsChildChange}
            parentId={this.state.parentId}
            onParentIdChange={this.onParentIdChange}
            parentAnswer={this.state.parentAnswer}
            onParentAnswerChange={this.onParentAnswerChange}
            parentLabelId={this.state.parentLabelId}
            onParentLabelIdChange={this.onParentLabelIdChange}
            enabled={enabled}
            initFields={this.initFields}
          />
        )}
      </EditSegment>
    );
  }
}

export default withRouter(QuestionnaireFieldInfos);
