import { Link } from "react-router-dom";
import React from "react";

const handleClick = (e, menuDisabled) => {
  if (menuDisabled) {
    e.preventDefault();
  }
};

const DisableableLink = ({ menuDisabled, ...rest }) => {
  return <Link {...rest} onClick={(e) => handleClick(e, menuDisabled)} />;
};

export default DisableableLink;
