// React
import React, { useState } from "react";

// Semantic ui
import {
  Form,
  Table,
  Loader,
  Button,
  Checkbox,
  Message,
  Popup,
  Segment,
  Modal,
  Dimmer,
} from "semantic-ui-react";

// GraphQL
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROJECT_USER_SET } from "../../../../Common/_GraphQL/queries";
import {
  IMPORT_USER_SET,
  ADMIN_REMOVE_USERS_FROM_PROJECT,
} from "../../_GraphQL/mutations";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { EditSegment } from "../../../../Common/components/EditSegment";

// Constants
import { USER_TYPES } from "../../../../utils/Constants";

// Message
import { addSuccessToastMessage } from "../../../../_GraphQL/message";

function GetProjectUsersExcelFile({ project }) {
  const [error, setError] = useState();
  let projectName = project.name.replace(/[\\/:?"<>|]/gi, "_");

  function getExcel(projectId) {
    const url = `/manager/fichier-utilisateurs/${projectId}`;
    fetch(url, {
      credentials: "include",
    })
      .then(function (response) {
        // Response.ok est une propriété de la réponse de fetch
        // Elle doit être à "true" si tout va bien
        if (!response.ok) {
          throw Error(response.statusText);
        }
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => {
            throw Error(data.message ?? "Erreur inconnue.");
          });
        }
        return response;
      })
      .then((response) => response.blob())
      .then((blob) => {
        let d = document.createElement("a");
        d.className = "download";
        d.download = `utilisateur-${projectName}.xlsx`;
        d.href = URL.createObjectURL(blob);
        document.body.appendChild(d);
        d.click();
        d.parentElement.removeChild(d);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  return (
    <>
      {error ? (
        <Segment inverted color="red" tertiary>
          {error}
        </Segment>
      ) : null}
      <Segment basic>
        Téléchargez le fichier Excel d'import utilisateurs ici :{" "}
        <a
          href={`/manager/fichier-utilisateurs/${project.id}`}
          download
          onClick={(e) => {
            e.preventDefault();
            getExcel(project.id);
          }}
        >
          {`utilisateurs-${projectName}.xlsx`}
        </a>
      </Segment>
    </>
  );
}

function DeleteProjectUserSetModal({
  open,
  setOpen,
  userToRemoveSet,
  project,
}) {
  const [removeUsersFromProject, { loading, error, client }] = useMutation(
    ADMIN_REMOVE_USERS_FROM_PROJECT,
    {
      variables: {
        projectId: project.id,
        userToRemoveSet: userToRemoveSet.map((user_to_rem) => user_to_rem.id),
      },
      update(cache, { data: { adminRemoveUsersFromProject } }) {
        client.writeQuery({
          query: GET_PROJECT_USER_SET,
          data: {
            projectUserSet: adminRemoveUsersFromProject.projectUserSet,
          },
          variables: {
            projectId: project.id,
          },
        });
      },
      onError() {},
      onCompleted() {
        setOpen(false);
      },
    }
  );

  return (
    <Modal open={open}>
      <Modal.Header>Retrait des utilisateurs</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {error ? <ErrorMessage error={error} /> : null}
          {loading ? (
            <Dimmer active>
              <Loader />
            </Dimmer>
          ) : null}
          <Message warning>
            Les utilisateurs suivants seront retirés du projets {project.name} :
            <ul>
              {userToRemoveSet.map((user_to_rem) => (
                <li key={user_to_rem.id}>{user_to_rem.email}</li>
              ))}
            </ul>
            Les utilisateurs ne seront pas supprimés de la plateforme.
          </Message>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {loading ? (
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : null}
        <Button color="black" onClick={() => setOpen(false)}>
          Annuler
        </Button>
        <Button
          content="Retirer les utilisateurs"
          labelPosition="right"
          icon="checkmark"
          onClick={() => removeUsersFromProject()}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

function DeleteProjectUserSet({ projectUserSet, project }) {
  const [open, setOpen] = useState(false);
  const [userToRemoveSet, setUserToRemoveSet] = useState([]);

  function handleCheckboxClick(user) {
    if (userToRemoveSet.some((user_to_rem) => user_to_rem.id === user.id)) {
      setUserToRemoveSet(
        userToRemoveSet.filter((user_to_rem) => user_to_rem.id !== user.id)
      );
    } else {
      setUserToRemoveSet([...userToRemoveSet, user]);
    }
  }

  return (
    <>
      <GetProjectUsersExcelFile project={project} />
      <div style={{ overflow: "auto", maxHeight: 200 }}>
        <Table size="small" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Nom</Table.HeaderCell>
              <Table.HeaderCell>Prénom</Table.HeaderCell>
              <Table.HeaderCell>Raison sociale</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Groupes</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {projectUserSet.map((u) => {
              return (
                <Table.Row key={`user-${u.id}`}>
                  <Table.Cell>
                    {u.profile &&
                    u.profile.nature.code === USER_TYPES.LEGAL_PERSON
                      ? "Personne morale"
                      : "Personne physique"}
                  </Table.Cell>
                  <Table.Cell>{u.lastName}</Table.Cell>
                  <Table.Cell>{u.firstName}</Table.Cell>
                  <Table.Cell>
                    {u.profile &&
                    u.profile.nature.code === USER_TYPES.LEGAL_PERSON
                      ? u.profile.name
                      : ""}
                  </Table.Cell>
                  <Table.Cell>{u.email}</Table.Cell>
                  <Table.Cell>
                    {u.groups.map((group) => group.name).join(", ")}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <input
                      type="checkbox"
                      checked={userToRemoveSet.some(
                        (user_to_rem) => user_to_rem.id === u.id
                      )}
                      onChange={() => handleCheckboxClick(u)}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Popup
                  content="Retirer du projet les utilisateurs sélectionnés"
                  trigger={
                    <Button
                      disabled={userToRemoveSet.length === 0}
                      onClick={() => setOpen(true)}
                      icon="remove user"
                    />
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <DeleteProjectUserSetModal
          open={open}
          setOpen={setOpen}
          userToRemoveSet={userToRemoveSet}
          project={project}
        />
      </div>
    </>
  );
}

function ProjectUserSet({ project }) {
  const { loading, error, data } = useQuery(GET_PROJECT_USER_SET, {
    variables: { projectId: project.id },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <DeleteProjectUserSet
      projectUserSet={data.projectUserSet}
      project={project}
    />
  );
}

function Fields({
  enabled,
  name,
  handleFileChange,
  project,
  sendEmail,
  handleSendEmailChange,
}) {
  if (enabled) {
    return (
      <>
        {sendEmail ? null : (
          <Message warning>
            <Message.Header>Attention</Message.Header>
            <Message.Content>
              Si vous ne souhaitez pas prévenir les utilisateurs par mail, vous
              devez avoir renseigné la colonne &laquo;&nbsp;Mot de
              passe&nbsp;&raquo; du fichier d'import d'utilisateur.{" "}
            </Message.Content>
          </Message>
        )}
        <Form.Field width={6}>
          <label>Envoyer le mot de passe au utilisateurs par mail ?</label>
          <Checkbox
            checked={sendEmail}
            onChange={handleSendEmailChange}
            toggle
          />
        </Form.Field>
        <Form.Field width={6}>
          <label>Fichier excel des utilisateurs</label>
          <input
            type="file"
            required
            value={name}
            onChange={handleFileChange}
            disabled={!enabled}
          />
        </Form.Field>
      </>
    );
  } else {
    return <ProjectUserSet project={project} />;
  }
}

class ProjectInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      sendEmail: true,
    };
  }

  handleFileChange = ({
    target: {
      files: [file],
    },
  }) => {
    this.setState({ file: file });
  };

  handleSendEmailChange = () => {
    this.setState({ sendEmail: !this.state.sendEmail });
  };

  handleSubmit = (mutate) =>
    mutate({
      variables: {
        projectId: this.props.project.id,
        file: this.state.file,
        sendEmail: this.state.sendEmail,
      },
    }).catch((e) => {
      /** Do nothing */
    });

  update = (cache, { data: { importUserSet } }) => {
    cache.writeQuery({
      query: GET_PROJECT_USER_SET,
      variables: { projectId: this.props.project.id },
      data: {
        projectUserSet: importUserSet.projectUserSet,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Les informations ont été enregistrées.",
    });
    this.props.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="Utilisateurs"
        buttonText="Importer"
        mutation={IMPORT_USER_SET}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            project={this.props.project}
            file={this.state.file}
            enabled={enabled}
            handleFileChange={this.handleFileChange}
            sendEmail={this.state.sendEmail}
            handleSendEmailChange={this.handleSendEmailChange}
          />
        )}
      </EditSegment>
    );
  }
}

export default ProjectInfos;
