// React
import React from "react";

// Semantic ui
import { Form, Checkbox, Table } from "semantic-ui-react";

// GraphQL
import { ASSIGN_UNASSIGN_TOOL_PERMISSION_TO_USER } from "../../../_GraphQL/mutations";
import { GET_USER_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET } from "../../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";
import EQuery from "../../../../../utils/EQuery";

// Constants
import {
  COLLABORATIVE_MAP_PERMISSIONS,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  USER_TYPES,
} from "../../../../../utils/Constants";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

const GroupToolRow = ({
  enabled,
  point,
  line,
  poly,
  userToolRow,
  handleCheckChange,
  layerId,
}) => {
  const user = userToolRow.user;
  let name =
    user.profile && user.profile.nature.code === USER_TYPES.LEGAL_PERSON
      ? user.profile.name
      : `${user.firstName} ${user.lastName}`;
  if (name === " ") {
    name = user.email;
  } else {
    name = `${name} (${user.email})`;
  }
  return (
    <Table.Row>
      <Table.Cell>{name}</Table.Cell>
      {point ? (
        <Table.Cell textAlign="center">
          <Checkbox
            disabled={!enabled}
            value={COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER + layerId}
            checked={
              userToolRow[
                COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER + layerId
              ]
            }
            onChange={(e, { value, checked }) =>
              handleCheckChange(userToolRow.user, value, checked)
            }
          />
        </Table.Cell>
      ) : null}
      {line ? (
        <Table.Cell textAlign="center">
          <Checkbox
            disabled={!enabled}
            value={COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER + layerId}
            checked={
              userToolRow[
                COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER + layerId
              ]
            }
            onChange={(e, { value, checked }) =>
              handleCheckChange(userToolRow.user, value, checked)
            }
          />
        </Table.Cell>
      ) : null}
      {poly ? (
        <Table.Cell textAlign="center">
          <Checkbox
            disabled={!enabled}
            value={COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER + layerId}
            checked={
              userToolRow[
                COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER + layerId
              ]
            }
            onChange={(e, { value, checked }) =>
              handleCheckChange(userToolRow.user, value, checked)
            }
          />
        </Table.Cell>
      ) : null}

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER + layerId}
          checked={
            userToolRow[
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userToolRow.user, value, checked)
          }
        />
      </Table.Cell>

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER + layerId
          }
          checked={
            userToolRow[
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
                layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userToolRow.user, value, checked)
          }
        />
      </Table.Cell>

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER + layerId
          }
          checked={
            userToolRow[
              COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userToolRow.user, value, checked)
          }
        />
      </Table.Cell>

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER + layerId}
          checked={
            userToolRow[
              COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userToolRow.user, value, checked)
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields(this.props.enabled);
  }

  render() {
    return (
      <Form.Field>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Utilisateurs</Table.HeaderCell>
              {this.props.point ? (
                <Table.HeaderCell textAlign="center">
                  Ajouter un point
                </Table.HeaderCell>
              ) : null}
              {this.props.line ? (
                <Table.HeaderCell textAlign="center">
                  Ajouter une ligne
                </Table.HeaderCell>
              ) : null}
              {this.props.poly ? (
                <Table.HeaderCell textAlign="center">
                  Ajouter une surface
                </Table.HeaderCell>
              ) : null}
              <Table.HeaderCell textAlign="center">
                Éditer une géométrie
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Éditer un questionnaire
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Supprimer une géométrie
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Export SHP</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.userToolsRowSet
              .sort((a, b) => {
                let aName =
                  a.user.profile &&
                  a.user.profile.nature.code === USER_TYPES.LEGAL_PERSON
                    ? a.user.profile.name
                    : a.user.firstName;
                if (!aName) {
                  aName = a.user.email;
                }
                let bName =
                  b.user.profile &&
                  b.user.profile.nature.code === USER_TYPES.LEGAL_PERSON
                    ? b.user.profile.name
                    : b.user.firstName;
                if (!bName) {
                  bName = b.user.email;
                }
                if (aName === bName) {
                  return 0;
                } else if (aName === null) {
                  return 1;
                } else if (bName === null) {
                  return -1;
                } else {
                  return aName.toLowerCase().localeCompare(bName.toLowerCase());
                }
              })
              .map((userToolsRow) => (
                <GroupToolRow
                  enabled={this.props.enabled}
                  point={this.props.point}
                  line={this.props.line}
                  poly={this.props.poly}
                  key={`table-row-user-${userToolsRow.user.id}`}
                  userToolRow={userToolsRow}
                  handleCheckChange={this.props.handleCheckChange}
                  layerId={this.props.layerId}
                />
              ))}
          </Table.Body>
        </Table>
      </Form.Field>
    );
  }
}

class LayerUserTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialUserToolsRowSet: [],
      userToolsRowSet: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = (edit) => {
    let userToolsRowSet = [];

    if (edit) {
      this.props.data.adminUserAllowedToViewLayerSet.forEach((g) => {
        let userToolRow = {
          user: g,
        };

        userToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER + this.props.layer.id
        ] = false;
        userToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER + this.props.layer.id
        ] = false;
        userToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER +
            this.props.layer.id
        ] = false;
        userToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER +
            this.props.layer.id
        ] = false;
        userToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
            this.props.layer.id
        ] = false;
        userToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER +
            this.props.layer.id
        ] = false;
        userToolRow[
          COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER +
            this.props.layer.id
        ] = false;

        let toolSet =
          this.props.data.adminUserWithAssignedToolPermissionSetSet.find(
            (gt) => gt.id === g.id
          );

        if (toolSet) {
          toolSet.assignedToolPermissionSet.forEach(
            (t) => (userToolRow[t.codename] = true)
          );
        }

        userToolsRowSet.push(userToolRow);
      });
    } else {
      this.props.data.adminUserWithAssignedToolPermissionSetSet.forEach(
        (userTool) => {
          let userToolsRow = {
            user: {
              id: userTool.id,
              firstName: userTool.firstName,
              lastName: userTool.lastName,
              email: userTool.email,
            },
          };
          userToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER +
              this.props.layer.id
          ] = userTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER +
                this.props.layer.id
          );
          userToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER +
              this.props.layer.id
          ] = userTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER +
                this.props.layer.id
          );
          userToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER +
              this.props.layer.id
          ] = userTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER +
                this.props.layer.id
          );
          userToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER +
              this.props.layer.id
          ] = userTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER +
                this.props.layer.id
          );
          userToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
              this.props.layer.id
          ] = userTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
                this.props.layer.id
          );
          userToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER +
              this.props.layer.id
          ] = userTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER +
                this.props.layer.id
          );
          userToolsRow[
            COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER +
              this.props.layer.id
          ] = userTool.assignedToolPermissionSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER +
                this.props.layer.id
          );

          userToolsRowSet.push(userToolsRow);
        }
      );
    }

    this.setState({
      userToolsRowSet: JSON.parse(JSON.stringify(userToolsRowSet)),
      initialUserToolsRowSet: JSON.parse(JSON.stringify(userToolsRowSet)),
    });
  };

  handleSubmit = (mutate) => {
    let users = [];

    const tools = [
      COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EDIT_GEOMETRY_ON_LAYER +
        this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EDIT_QUESTIONNAIRE_ON_LAYER +
        this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.DELETE_GEOMETRY_ON_LAYER +
        this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EXPORT_SHP_FROM_LAYER + this.props.layer.id,
    ];

    this.state.initialUserToolsRowSet.forEach((initRow) => {
      let user = {
        userId: initRow.user.id,
        permissionToAssignSet: [],
        permissionToUnassignSet: [],
      };

      const currentRow = this.state.userToolsRowSet.find(
        (row) => row.user.id === initRow.user.id
      );
      for (let t of tools) {
        // ceux qui étaint checked au départ et qui maintenant sont unchecked
        if (initRow[t] && !currentRow[t]) {
          user.permissionToUnassignSet.push(t);
        }

        // ceux qui étaient unchecked au départ et qui maintenant sont checked
        if (!initRow[t] && currentRow[t]) {
          user.permissionToAssignSet.push(t);
        }
      }

      users.push(user);
    });

    mutate({
      variables: {
        layerId: this.props.layer.id,
        userToAssignUnassignSet: users,
      },
    });
  };

  update = (cache, { data: { adminAssignUnassignToolPermissionToUser } }) => {
    let data = cache.readQuery({
      query: GET_USER_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET,
      variables: { layerId: this.props.layer.id },
    });

    cache.writeQuery({
      query: GET_USER_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET,
      variables: { layerId: this.props.layer.id },
      data: {
        adminUserAllowedToViewLayerSet: data.adminUserAllowedToViewLayerSet,
        adminUserWithAssignedToolPermissionSetSet:
          adminAssignUnassignToolPermissionToUser.adminUserWithAssignedToolPermissionSetSet,
      },
    });

    cache.modify({
      id: `LayerType:${this.props.layer.id}`,
      fields: {
        adminUserWithAssignedToolPermissionSetSet() {
          return adminAssignUnassignToolPermissionToUser.adminUserWithAssignedToolPermissionSetSet;
        },
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "L'affectation des outils a été mise à jour.",
    });
    this.toReadMode();
  };

  handleCheckChange = (user, tool, checked) => {
    this.setState((prevState) => {
      let userToolsRow = prevState.userToolsRowSet.find(
        (al) => al.user.id === user.id
      );

      userToolsRow[tool] = checked;

      return {
        userToolsRowSet: prevState.userToolsRowSet,
      };
    });
  };

  render() {
    return (
      <EditSegment
        title="Outils : Permissions des utilisateurs"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_TOOL_PERMISSION_TO_USER}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            editable={this.state.editable}
            enabled={enabled}
            initFields={this.initFields}
            userToolsRowSet={this.state.userToolsRowSet}
            point={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT
            )}
            line={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE
            )}
            poly={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code ===
                COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
            )}
            handleCheckChange={this.handleCheckChange}
            layerId={this.props.layer.id}
          />
        )}
      </EditSegment>
    );
  }
}

const LayerUserToolsQuery = (props) => {
  return (
    <EQuery
      query={GET_USER_WITH_ASSIGNED_TOOL_PERMISSION_SET_SET}
      variables={{ layerId: props.layer.id }}
    >
      {(data) => (
        <LayerUserTools
          data={data}
          layer={props.layer}
          editMode={props.editMode}
          toEditMode={props.toEditMode}
          toReadMode={props.toReadMode}
        />
      )}
    </EQuery>
  );
};

export default LayerUserToolsQuery;
