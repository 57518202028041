import { csrfVar } from "../_GraphQL/graphQLClient";

export const buildTreeAndGetRoots = (questionnairefieldSet) => {
  let lookup = [];

  questionnairefieldSet.forEach((qf) => {
    lookup.push({
      id: qf.id,
      node: { associatedObject: qf },
    });
  });

  lookup.forEach((lu) => {
    let item = lu.node;
    if (item.associatedObject.parentQuestionnaireField) {
      let proposedParent = lookup.find(
        (lkp) => item.associatedObject.parentQuestionnaireField.id === lkp.id
      ).node;
      lu.node.parent = proposedParent;

      if (proposedParent.children) {
        proposedParent.children.push(item);
      } else {
        proposedParent.children = [item];
      }
    }
  });

  return lookup.filter((l) => l.node.parent == null).map((item) => item.node);
};

// From SO
// https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try
export function tryParseJSON(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}

  return false;
}

export const sorter = (column, direction) => (a, b) => {
  if (a[column] === b[column]) {
    return 0;
  } else if (a[column] === null) {
    return 1;
  } else if (b[column] === null) {
    return -1;
  } else if (direction === "ascending") {
    return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
  } else {
    return b[column].toLowerCase().localeCompare(a[column].toLowerCase());
  }
};

export function isElementOutViewport(el) {
  var rect = el.getBoundingClientRect();
  return rect.bottom > window.innerHeight;
}

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function checkJsonResponse(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response.json();
  } else {
    throw Error(response.statusText);
  }
}

export function getCSRF(handleError) {
  fetch("/api/csrf/", {
    credentials: "include",
  })
    .then((res) => {
      let csrfToken = res.headers.get("X-CSRFToken");
      csrfVar(csrfToken);
    })
    .catch(handleError);
}
