// React
import React from "react";

// React Router
import { Redirect } from "react-router-dom";

// GraphQL
import { useReactiveVar } from "@apollo/client";
import { isConnectedVar } from "../../_GraphQL/graphQLClient";

const HomeRedirect = ({ from }) => {
  const isConnected = useReactiveVar(isConnectedVar);

  if (isConnected) {
    return (
      <Redirect
        to={{
          pathname: from.pathname,
          search: from.search,
        }}
      />
    );
  }
  return null;
};

export default HomeRedirect;
