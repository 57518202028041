import { ListItemType } from "../Common/types/commonTypes";

export function listItemSorter(a: ListItemType, b: ListItemType) {
  if (a.name === b.name) {
    return 0;
  } else if (a.name === null) {
    return 1;
  } else if (b.name === null) {
    return -1;
  } else {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  }
}
