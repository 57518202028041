// Composant qui gère l'outil de dessin pour un projet donné
// Si le projet n'est pas renseigné, redirection vers le choix des sites

// React
import React, { useState } from "react";

// React Router
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../../../Common/_GraphQL/queries";

// Semantic ui
import {
  Breadcrumb,
  Button,
  Divider,
  Header,
  Segment,
  Grid,
  Loader,
} from "semantic-ui-react";

// Component
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { TaskList } from "../../components/CollaborativeMap/TaskList";
import {
  CreateTileLayer,
  TARGET,
} from "../../components/CollaborativeMap/CreateTask/CreateTileLayer";
import { TileLayerInfos } from "../../components/CollaborativeMap/Task/TileLayerInfos";
import { CREATE_TILE_LAYER_FOR_PROJECT } from "../../_GraphQL/mutations";
import { SITE_NAMES } from "../../../../utils/Constants";

function CreateTileLayerSegment({ projectId }) {
  const [newTileLayer, setNewTileLayer] = useState(false);

  if (newTileLayer) {
    return (
      <CreateTileLayer
        target={TARGET.TARGET_PROJECT}
        projectId={projectId}
        mutation={CREATE_TILE_LAYER_FOR_PROJECT}
        handleCompleted={() => null}
        handleCancel={() => setNewTileLayer(false)}
      />
    );
  } else {
    return (
      <Segment>
        <Button onClick={() => setNewTileLayer(true)}>
          Créer un fond de carte pour le projet (Uniquement pour l'appel à
          idées)
        </Button>
      </Segment>
    );
  }
}

class CollaborativeMapLayout extends React.Component {
  state = {
    editMode: false,
  };

  toggleMode = () =>
    this.setState((prevState) => ({ editMode: !prevState.editMode }));

  render() {
    return (
      <>
        <Segment>
          <Breadcrumb>
            <Breadcrumb.Section key="breadcrumb-home">
              <Link to="/manager">Manager</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={"bc-divider-sites"} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-sites">
              <Link to="/manager/sites">Sites</Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={"bc-divider-site"} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-site">
              <Link to={`/manager/site/${this.props.project.site.id}`}>
                Site : {this.props.project.site.name}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={"bc-divider-project"} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-project">
              <Link to={`/manager/projet/${this.props.project.id}`}>
                Projet : {this.props.project.name}
              </Link>
            </Breadcrumb.Section>
            <Breadcrumb.Divider key={"bc-divider-draw"} icon="right angle" />
            <Breadcrumb.Section key="breadcrumb-draw">
              Module : Carte Participative
            </Breadcrumb.Section>
          </Breadcrumb>
        </Segment>

        <Header as="h4" block>
          Paramètres des cartes participatives pour le projet «&nbsp;
          {this.props.project.name}&nbsp;»
        </Header>

        <Segment secondary>
          <Header as="h5">
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column verticalAlign="middle">
                  Liste des tâches du projet «&nbsp;{this.props.project.name}
                  &nbsp;»
                </Grid.Column>
                <Grid.Column textAlign="right">
                  {!this.state.editMode ? (
                    <Link
                      to={`/manager/tache/creation/${this.props.project.id}`}
                    >
                      <Button primary>Ajouter une tâche au projet</Button>
                    </Link>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <Divider />
          <TaskList
            projectId={this.props.project.id}
            siteName={this.props.project.site.name}
          />
        </Segment>

        {this.props.project.site.name === SITE_NAMES.ICI ? (
          this.props.project.tileLayer ? (
            <TileLayerInfos
              projectId={this.props.project.id}
              target={TARGET.TARGET_PROJECT}
              tileLayer={this.props.project.tileLayer}
              toEditMode={() => null}
              toReadMode={() => null}
            />
          ) : (
            <CreateTileLayerSegment projectId={this.props.project.id} />
          )
        ) : null}
      </>
    );
  }
}

function CollaborativeMapQuery({ projectId }) {
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: {
      projectId: projectId,
    },
  });

  if (loading) return <Loader />;

  if (error)
    return (
      <ErrorMessage
        header={"Impossible de récupérer le projet."}
        error={error}
      />
    );

  return <CollaborativeMapLayout project={data.project} />;
}

const CollaborativeMap = () => {
  let params = new URLSearchParams(window.location.search);
  let projectId = params.get("projectId");

  if (!projectId) {
    return <Redirect to="/manager/sites" />;
  }

  return <CollaborativeMapQuery projectId={projectId} />;
};

export { CollaborativeMap };
