import React, { useEffect, useState } from "react";
import { Grid, Button, Select, Segment, Icon } from "semantic-ui-react";
import { StyleSet } from "../../components/Styles/StyleSet";
import { Viewer } from "../../components/Styles/Viewer";
import { useHistory } from "react-router-dom";
import { DeleteStyle } from "../../components/Styles/DeleteStyle";
import {
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  PATTERN_TYPES,
} from "../../../../utils/Constants";
import { GEOMETRY_CODE_OPTIONS } from "../../../../utils/Constants";
import { useLocation } from "react-router-dom";

function Actions({ style }) {
  const history = useHistory();

  return (
    <Button.Group basic size="small">
      <Button
        icon
        onClick={() => history.push(`/manager/style/modification/${style.id}`)}
      >
        <Icon name="edit" />
      </Button>
      {style.usingLayerSet.length === 0 &&
      style.usingQuestionnaireFieldLabelSet.length === 0 ? (
        <DeleteStyle style={style} />
      ) : null}
    </Button.Group>
  );
}

function Styles() {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const initGeoTypeCode = query.get("type");
  // Style sélectionné, tel que fournit en base
  const [activeStyle, setActiveStyle] = useState();

  // Filtres
  // -> type de géométrie
  const [geoTypeCode, setGeoTypeCode] = useState(
    initGeoTypeCode ?? COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
  );
  // -> use pattern et type de pattern quand polygone ?

  const [pathOptions, setPathOptions] = useState({});
  const [usePattern, setUsePattern] = useState(false);
  const [patternTypeCode, setPatternTypeCode] = useState(PATTERN_TYPES.STRIPES);
  const [patternOptions, setPatternOptions] = useState({});
  const [shapeOptions, setShapeOptions] = useState({});

  useEffect(() => {
    if (activeStyle && activeStyle.pathOptions) {
      setPathOptions(JSON.parse(activeStyle.pathOptions));
      setUsePattern(activeStyle.usePattern);
      if (activeStyle.usePattern) {
        setPatternOptions(JSON.parse(activeStyle.patternOptions));
        setPatternTypeCode(activeStyle.patternTypeCode);
        if (activeStyle.patternTypeCode !== PATTERN_TYPES.STRIPES) {
          setShapeOptions(JSON.parse(activeStyle.shapeOptions));
        }
      }
    }
  }, [activeStyle]);

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Segment>
            <Button
              onClick={() =>
                history.push(`/manager/style/creation?type=${geoTypeCode}`)
              }
            >
              Créer un style
            </Button>
          </Segment>
          <Segment>
            <Select
              options={GEOMETRY_CODE_OPTIONS}
              value={geoTypeCode}
              onChange={(e, { value }) => setGeoTypeCode(value)}
            />
          </Segment>
          <StyleSet
            setActiveStyle={setActiveStyle}
            activeStyle={activeStyle}
            geoTypeCode={geoTypeCode}
          />
        </Grid.Column>
        <Grid.Column>
          {activeStyle ? (
            <>
              <Viewer
                pathOptions={pathOptions}
                patternOptions={patternOptions}
                geoTypeCode={geoTypeCode}
                usePattern={usePattern}
                patternType={patternTypeCode}
                shapeOptions={shapeOptions}
              />
              <Segment>
                <Actions style={activeStyle} />
              </Segment>
            </>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export { Styles };
