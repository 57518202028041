// React
import React from "react";

// Semantic ui
import { Checkbox, Table } from "semantic-ui-react";

const DefaultFolderGroupPermissionsFormRow = ({
  groupPermissionsRow,
  handleCheckChange,
}) => (
  <Table.Row>
    <Table.Cell>{groupPermissionsRow.group.name}</Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="view_folder_"
        checked={groupPermissionsRow.canViewFolder}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="move_folder_"
        checked={groupPermissionsRow.canMoveFolder}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="change_folder_"
        checked={groupPermissionsRow.canChangeFolder}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="delete_folder_"
        checked={groupPermissionsRow.canDeleteFolder}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="add_folder_to_folder_"
        checked={groupPermissionsRow.canAddFolder}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
    <Table.Cell textAlign="center">
      <Checkbox
        value="add_document_to_folder_"
        checked={groupPermissionsRow.canAddDocument}
        onChange={(e, { value, checked }) =>
          handleCheckChange(groupPermissionsRow.group, value, checked)
        }
      />
    </Table.Cell>
  </Table.Row>
);

export default DefaultFolderGroupPermissionsFormRow;
