// React
import React from "react";

// GraphQL
import { useMutation } from "@apollo/client";
import {
  GET_GROUP_PERMISSION_AND_GROUP_FOR_SITE,
  GET_GROUP_PERMISSION_FOR_DRAWING_AND_GROUP_FOR_SITE,
} from "../../_GraphQL/queries";
import {
  ADMIN_ADD_REMOVE_DEFAULT_DRAWING_GROUP_PERMISSION,
  ADMIN_ADD_REMOVE_DRAWING_GROUP_PERMISSION,
} from "../../_GraphQL/mutations";

// Components
import DrawingPermissionsForm from "./DrawingPermissionsForm";

// Message
import {
  addErrorToastMessage,
  addSuccessToastMessage,
  getMessageTextFromApolloError,
} from "../../../../_GraphQL/message";

function DrawingPermissions(props) {
  const [mut] = useMutation(props.mut, {
    update(cache, data) {
      props.update(cache, data);
    },
    onCompleted() {
      addSuccessToastMessage({
        header: "Informations mises à jour",
        content: "Les autorisations ont été mises à jours avec succès.",
      });
      props.handleCompleted();
    },
    onError(error) {
      addErrorToastMessage(getMessageTextFromApolloError(error));
    },
  });

  return (
    <DrawingPermissionsForm
      permissionLines={props.permissionLines}
      mutation={true}
      handleSubmit={() => props.handleSubmit(mut)}
      handleCancel={props.handleCancel}
      handleCheckChange={props.handleCheckChange}
    />
  );
}

class DrawingPermissionsMutation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionLines: [],
      groupPermissionToRemoveSet: [],
      groupPermissionToAddSet: [],
    };
  }

  componentDidMount() {
    let permissionLines = [];
    this.props.groups.forEach((group) => {
      permissionLines.push({
        group: group,
        canView: false,
        canChange: false,
      });

      let groupPermissions = this.props.permissions.filter(
        (a) => a.group.id === group.id
      );

      groupPermissions.forEach((gp) => {
        if (gp.permission.codename === "view_drawing") {
          permissionLines.find(
            (o) => o.group.id === gp.group.id
          ).canView = true;
        } else {
          permissionLines.find(
            (o) => o.group.id === gp.group.id
          ).canChange = true;
        }
      });
    });

    this.setState({
      permissionLines: permissionLines,
    });
  }

  handleCheckChange = (group, permission, checked) => {
    let addViewDrawing = false;
    let removeChangeDrawing = false;
    this.setState(
      (prevState) => {
        let a = prevState.permissionLines.find(
          (al) => al.group.id === group.id
        );
        if (permission === "change_drawing") {
          if (checked && !a.canView) {
            a.canView = true;
            addViewDrawing = true;
          }
          a.canChange = checked;
        } else {
          if (!checked && a.canChange) {
            removeChangeDrawing = true;
            a.canChange = false;
          }
          a.canView = checked;
        }
        return {
          permissionLines: prevState.permissionLines,
        };
      },
      () => {
        if (checked) {
          if (
            this.state.groupPermissionToRemoveSet.find(
              (gp) => gp.groupId === group.id && gp.permission === permission
            )
          ) {
            if (addViewDrawing) {
              this.setState((prevState) => ({
                groupPermissionToRemoveSet:
                  prevState.groupPermissionToRemoveSet.filter(
                    (gp) =>
                      !(
                        gp.groupId === group.id && gp.permission === permission
                      ) &&
                      !(
                        gp.groupId === group.id &&
                        gp.permission === "view_drawing"
                      )
                  ),
              }));
            } else {
              this.setState((prevState) => ({
                groupPermissionToRemoveSet:
                  prevState.groupPermissionToRemoveSet.filter(
                    (gp) =>
                      !(gp.groupId === group.id && gp.permission === permission)
                  ),
              }));
            }
          } else {
            if (addViewDrawing) {
              if (
                this.state.groupPermissionToRemoveSet.find(
                  (gp) =>
                    gp.groupId === group.id && gp.permission === "view_drawing"
                )
              ) {
                this.setState((prevState) => ({
                  groupPermissionToAddSet:
                    prevState.groupPermissionToAddSet.concat({
                      groupId: group.id,
                      permission: permission,
                    }),
                  groupPermissionToRemoveSet:
                    prevState.groupPermissionToRemoveSet.filter(
                      (gp) =>
                        !(
                          gp.groupId === group.id &&
                          gp.permission === "view_drawing"
                        )
                    ),
                }));
              } else {
                this.setState((prevState) => ({
                  groupPermissionToAddSet:
                    prevState.groupPermissionToAddSet.concat([
                      {
                        groupId: group.id,
                        permission: permission,
                      },
                      {
                        groupId: group.id,
                        permission: "view_drawing",
                      },
                    ]),
                }));
              }
            } else {
              this.setState((prevState) => ({
                groupPermissionToAddSet:
                  prevState.groupPermissionToAddSet.concat({
                    groupId: group.id,
                    permission: permission,
                  }),
              }));
            }
          }
        } else {
          if (removeChangeDrawing) {
            this.setState((prevState) => ({
              groupPermissionToAddSet: prevState.groupPermissionToAddSet.filter(
                (gp) => gp.groupId !== group.id
              ),
              groupPermissionToRemoveSet: prevState.groupPermissionToRemoveSet
                .filter((gp) => gp.groupId !== group.id)
                .concat(
                  this.props.permissions
                    .filter((a) => a.group.id === group.id)
                    .map((a) => ({
                      groupId: a.group.id,
                      permission: a.permission.codename,
                    }))
                ),
            }));
          } else {
            if (
              this.state.groupPermissionToAddSet.find(
                (gp) => gp.groupId === group.id && gp.permission === permission
              )
            ) {
              this.setState((prevState) => ({
                groupPermissionToAddSet:
                  prevState.groupPermissionToAddSet.filter(
                    (gp) =>
                      !(gp.groupId === group.id && gp.permission === permission)
                  ),
              }));
            } else {
              this.setState((prevState) => ({
                groupPermissionToRemoveSet:
                  prevState.groupPermissionToRemoveSet.concat({
                    groupId: group.id,
                    permission: permission,
                  }),
              }));
            }
          }
        }
      }
    );
  };

  handleSubmit = (mutate) => {
    if (this.props.target === "project") {
      mutate({
        variables: {
          projectId: this.props.project.id,
          groupPermissionToAddSet: this.state.groupPermissionToAddSet,
          groupPermissionToRemoveSet: this.state.groupPermissionToRemoveSet,
        },
      });
    } else {
      mutate({
        variables: {
          drawingId: this.props.drawing.id,
          groupPermissionToAddSet: this.state.groupPermissionToAddSet,
          groupPermissionToRemoveSet: this.state.groupPermissionToRemoveSet,
        },
      });
    }
  };

  update = (cache, { data }) => {
    if (this.props.target === "project") {
      let a = cache.readQuery({
        query: GET_GROUP_PERMISSION_AND_GROUP_FOR_SITE,
        variables: {
          projectId: this.props.project.id,
          siteId: this.props.project.site.id,
        },
      });
      cache.writeQuery({
        query: GET_GROUP_PERMISSION_AND_GROUP_FOR_SITE,
        variables: {
          projectId: this.props.project.id,
          siteId: this.props.project.site.id,
        },
        data: {
          adminDefaultDrawingGroupPermissionSet:
            data.adminAddRemoveDefaultDrawingGroupPermission
              .defaultDrawingGroupPermissionSet,
          adminGroupSet: a.adminGroupSet,
        },
      });
    } else {
      let a = cache.readQuery({
        query: GET_GROUP_PERMISSION_FOR_DRAWING_AND_GROUP_FOR_SITE,
        variables: {
          drawingId: this.props.drawing.id,
          siteId: this.props.drawing.project.site.id,
        },
      });
      cache.writeQuery({
        query: GET_GROUP_PERMISSION_FOR_DRAWING_AND_GROUP_FOR_SITE,
        variables: {
          drawingId: this.props.drawing.id,
          siteId: this.props.drawing.project.site.id,
        },
        data: {
          adminDrawingGroupPermissionSet:
            data.adminAddRemoveDrawingGroupPermission.drawingGroupPermissionSet,
          adminGroupSet: a.adminGroupSet,
        },
      });
    }
  };

  render() {
    let mutation = "";
    if (this.props.target === "project") {
      mutation = ADMIN_ADD_REMOVE_DEFAULT_DRAWING_GROUP_PERMISSION;
    } else {
      mutation = ADMIN_ADD_REMOVE_DRAWING_GROUP_PERMISSION;
    }

    return (
      <DrawingPermissions
        mut={mutation}
        handleCompleted={this.props.handleCompleted}
        update={this.update}
        permissionLines={this.state.permissionLines}
        mutation={true}
        handleSubmit={this.handleSubmit}
        handleCancel={this.props.handleCancel}
        handleCheckChange={this.handleCheckChange}
      />
    );
  }
}

export default DrawingPermissionsMutation;
