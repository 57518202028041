import React from "react";
import { Form, Input } from "semantic-ui-react";
import { IMPORT_TASK_GEOJSON } from "../../../_GraphQL/mutations";
import { EditSegment } from "../../../../../Common/components/EditSegment";
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    if (this.props.enabled) {
      return (
        <Form.Field width={6}>
          <label>
            Fichier au format GeoJSON (Système géodésique EPSG:4326 WGS84 - non
            projeté)
          </label>
          <Input type="file" onChange={this.props.onGeoJsonChange} />
        </Form.Field>
      );
    } else if (this.props.hasData) {
      return <p>Cette tâche possède de données.</p>;
    } else {
      return null;
    }
  }
}

class TaskGeoJson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geojson: "",
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => this.setState({ geojson: "" });

  onGeoJsonChange = ({
    target: {
      files: [file],
    },
  }) => this.setState({ geojson: file });

  handleSubmit = (mutate) =>
    mutate({
      variables: {
        data: this.state.geojson,
        taskId: this.props.task.id,
      },
    });

  update = (cache, { data: { importTaskGeojson } }) => {
    cache.modify({
      id: `TaskType:${this.props.task.id}`,
      fields: {
        geoJson() {
          return importTaskGeojson.task.geoJson;
        },
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Le geojson a été enregistré.",
    });
    this.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="GeoJson"
        buttonText="Modifier"
        mutation={IMPORT_TASK_GEOJSON}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            geojson={this.state.geojson}
            enabled={enabled}
            initFields={this.initFields}
            onGeoJsonChange={this.onGeoJsonChange}
            hasData={this.props.task.geoJson != null}
          />
        )}
      </EditSegment>
    );
  }
}

export { TaskGeoJson };
