import React, { useState } from "react";
import { isConnectedVar, csrfVar } from "../../_GraphQL/graphQLClient";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Message,
  Segment,
  Grid,
  Header,
} from "semantic-ui-react";
import { useReactiveVar } from "@apollo/client";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const csrf = useReactiveVar(csrfVar);

  function login(event) {
    event.preventDefault();
    fetch("/api/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    })
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson ? await response.json() : null;

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.detail) || response.status;
          return Promise.reject(error);
        }
        isConnectedVar(true);
      })
      .catch((err) => {
        setError({
          header: "Erreur lors de l'authentification",
          message: err,
        });
      });
  }

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment>
          <Header as="h2" textAlign="center">
            Connexion au Manager
          </Header>
          <Form onSubmit={login} size="large">
            <Form.Field>
              <Input
                iconPosition="left"
                icon="at"
                placeholder="Adresse email"
                type="text"
                name="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <Input
                iconPosition="left"
                icon="lock"
                placeholder="Mot de passe"
                required
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <Link to="/compte/mdp-oublie">Mot de passe oublié ?</Link>
            </Form.Field>
            {error ? (
              <Message negative>
                <Message.Header>{error.header}</Message.Header>
                <p>{error.message}</p>
              </Message>
            ) : null}
            <Form.Field>
              <Button primary type="submit" name="notsubmit">
                Connexion
              </Button>
            </Form.Field>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export { Login };
