// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import EQuery from "../../../../utils/EQuery";
import { GET_LAYER, ADMIN_QUESTIONNAIRE } from "../../_GraphQL/queries";

// Semantic ui
import { Breadcrumb, Header } from "semantic-ui-react";

// Component
import ManagerLayout from "../../components/ManagerLayout";
import QuestionnaireFieldLabelInfos from "../../components/Questionnaire/QuestionnaireFieldLabelInfos";

class QuestionnaireFieldLabel extends React.Component {
  state = {
    editMode: false,
  };

  toReadMode = () => this.setState({ editMode: false });

  toEditMode = () => this.setState({ editMode: true });

  render() {
    const {
      questionnaire,
      questionnaireField,
      questionnaireFieldLabel,
      layer,
    } = this.props;
    let breadcrumbItems = [
      <Breadcrumb.Section key="breadcrumb-home">
        <Link to="/manager">Manager</Link>
      </Breadcrumb.Section>,
      <Breadcrumb.Divider key="bc-divider-questionnaires" icon="right angle" />,
    ];
    if (layer) {
      breadcrumbItems.push([
        <Breadcrumb.Section key="breadcrumb-sites">
          <Link to="/manager/sites">Sites</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-site" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-site">
          <Link to={`/manager/site/${layer.task.project.site.id}`}>
            Site : {layer.task.project.site.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-project" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-project">
          <Link to={`/manager/projet/${layer.task.project.id}`}>
            Projet : {layer.task.project.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-module" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-module">
          <Link to={`/manager/carte/?projectId=${layer.task.project.id}`}>
            Module : Carte participative
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-task" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-task">
          <Link to={`/manager/tache/${layer.task.id}`}>
            Tâche : {layer.task.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-layer" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-layer">
          <Link to={`/manager/couche/${layer.id}`}>Couche : {layer.name}</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-questionnaire"
          icon="right angle"
        />,
        <Breadcrumb.Section key="breadcrumb-questionnaire">
          <Link
            to={`/manager/couche/${layer.id}/questionnaire/${questionnaire.id}`}
          >
            Questionnaire : {questionnaire.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-field" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-field">
          <Link
            to={`/manager/couche/${layer.id}/questionnaire/${questionnaire.id}/champ/${questionnaireField.id}`}
          >
            Champ : {questionnaireField.text}
          </Link>
        </Breadcrumb.Section>,
      ]);
    } else {
      breadcrumbItems.push([
        <Breadcrumb.Section key="breadcrumb-home">
          <Link to="/manager">Manager</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-questionnaires"
          icon="right angle"
        />,
        <Breadcrumb.Section key="breadcrumb-questionnaires">
          <Link to="/manager/questionnaires">Questionnaires</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key="bc-divider-questionnaire"
          icon="right angle"
        />,
        <Breadcrumb.Section key="breadcrumb-questionnaire">
          <Link to={`/manager/questionnaire/${questionnaire.id}`}>
            Questionnaire : {questionnaire.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-field" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-field">
          <Link
            to={`/manager/questionnaire/${questionnaire.id}/champ/${questionnaireField.id}`}
          >
            Champ : {questionnaireField.text}
          </Link>
        </Breadcrumb.Section>,
      ]);
    }

    breadcrumbItems.push([
      <Breadcrumb.Divider key="bc-divider-label" icon="right angle" />,
      <Breadcrumb.Section key="breadcrumb-label">
        Label : {questionnaireFieldLabel.text}
      </Breadcrumb.Section>,
    ]);

    return (
      <ManagerLayout breadcrumbItems={breadcrumbItems}>
        <Header as="h5" block>
          Label : {questionnaireFieldLabel.text}
        </Header>
        <QuestionnaireFieldLabelInfos
          questionnaire={questionnaire}
          questionnaireField={questionnaireField}
          questionnaireFieldLabel={questionnaireFieldLabel}
          editMode={this.state.editMode}
          toEditMode={this.toEditMode}
          toReadMode={this.toReadMode}
          createNewLabel={false}
        />
      </ManagerLayout>
    );
  }
}

const QuestionnaireFieldLabelQuery = ({ match, layer }) => (
  <EQuery
    query={ADMIN_QUESTIONNAIRE}
    variables={{ questionnaireId: match.params.questionnaireId }}
  >
    {(data) => {
      const questionnaireField =
        data.adminQuestionnaire.questionnairefieldSet.find(
          (field) => field.id === match.params.questionnaireFieldId
        );

      const questionnaireFieldLabel =
        questionnaireField.questionnairefieldlabelSet.find(
          (label) => label.id === match.params.questionnaireFieldLabelId
        );

      return (
        <QuestionnaireFieldLabel
          questionnaireFieldLabel={questionnaireFieldLabel}
          questionnaireField={questionnaireField}
          questionnaire={data.adminQuestionnaire}
          layer={layer}
        />
      );
    }}
  </EQuery>
);

export { QuestionnaireFieldLabelQuery };

const QuestionnaireFieldLabelEntry = ({ match }) => {
  if (match.params.layerId) {
    return (
      <EQuery query={GET_LAYER} variables={{ layerId: match.params.layerId }}>
        {(data) => (
          <QuestionnaireFieldLabelQuery match={match} layer={data.layer} />
        )}
      </EQuery>
    );
  }

  return <QuestionnaireFieldLabelQuery match={match} />;
};

export default QuestionnaireFieldLabelEntry;
