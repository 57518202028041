// GraphQL
import { gql } from "@apollo/client";

export const STYLE_FRAG = gql`
  fragment StyleFrag on StyleType {
    id
    pathOptions
    geoTypeCode
    usePattern
    patternTypeCode
    patternOptions
    shapeOptions
    usingLayerSet {
      id
      name
      task {
        id
        name
        project {
          id
          name
        }
      }
    }
    usingQuestionnaireFieldLabelSet {
      id
      text
      field {
        id
        text
        questionnaire {
          id
          name
        }
      }
    }
  }
`;

export const LABEL_FRAG = gql`
  fragment LabelFrag on LabelType {
    id
    text
    code
  }
`;

export const USER_FRAG = gql`
  fragment UserFrag on UserType {
    id
    email
    firstName
    lastName
    isActive
    profile {
      avatar
      avatarPath
      name
      nature {
        id
        code
      }
    }
  }
`;

export const DRAWING_FRAG = gql`
  fragment DrawingFrag on DrawingType {
    id
    name
    background
    backgroundPath
    paperjsProject
    currentUserCanEdit
  }
`;

export const ROOT_FOLDER_FRAG = gql`
  fragment RootFolderFrag on FolderType {
    id
    name
  }
`;

export const PROJECT_FRAG = gql`
  fragment ProjectFrag on ProjectType {
    id
    name
    description
    slug
    rootFolder {
      ...RootFolderFrag
    }
    site {
      id
      name
      domain
      moduleSet {
        id
        name
      }
    }
    planning
    planningPath
    illustration
    illustrationPath
    active
    notificationsActive
    notificationsSenderGroups
    tileLayer {
      id
      name
      description
      mapId
      latitude
      longitude
      zoomInit
      zoomMin
      zoomMax
    }
  }
  ${ROOT_FOLDER_FRAG}
`;

export const QUESTIONNAIRE_FIELD_LABEL_FRAG = gql`
  fragment QuestionnaireFieldLabelFrag on QuestionnaireFieldLabelType {
    id
    text
    marker
    markerColor
    lineColor
    lineStyle {
      id
      pathOptions
      geoTypeCode
      usePattern
      patternTypeCode
      patternOptions
      shapeOptions
    }
    polyColor
    polyStyle {
      id
      pathOptions
      geoTypeCode
      usePattern
      patternTypeCode
      patternOptions
      shapeOptions
    }
  }
`;

export const QUESTIONNAIRE_FIELD_FRAG = gql`
  fragment QuestionnaireFieldFrag on QuestionnaireFieldType {
    id
    text
    shpField
    placeHolder
    required
    readOnly
    order
    helptext
    minimum
    maximum
    multiline
    multiselect
    decimal
    decimalDigits
    parentQuestionnaireField {
      id
      text
      fieldType {
        id
        text
        code
      }
      questionnairefieldlabelSet {
        ...QuestionnaireFieldLabelFrag
      }
    }
    parentQuestionnaireFieldAnswer
    parentQuestionnaireFieldLabel {
      ...QuestionnaireFieldLabelFrag
    }
    questionnairefieldlabelSet {
      ...QuestionnaireFieldLabelFrag
    }
    fieldType {
      id
      text
      code
    }
    isDecorator
  }
  ${QUESTIONNAIRE_FIELD_LABEL_FRAG}
`;

export const DEFAULT_FOLDER_GROUP_PERMISSION_FRAG = gql`
  fragment DefaultFolderGroupPermissionFrag on DefaultFolderGroupPermissionType {
    project {
      id
      name
    }
    group {
      id
      name
    }
    permission
  }
`;

export const GROUP_PERMISSION_FRAG = gql`
  fragment GroupPermissionFrag on GroupPermissionObjectType {
    groupId
    permission
  }
`;

export const DEFAULT_DOCUMENT_GROUP_PERMISSION_FRAG = gql`
  fragment DefaultDocumentGroupPermissionFrag on DefaultDocumentGroupPermissionType {
    project {
      id
      name
    }
    group {
      id
      name
    }
    permission
  }
`;
