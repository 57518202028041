// React
import React from "react";

// Semantic ui
import { Checkbox, Table } from "semantic-ui-react";

class DrawingPermissionsFormRow extends React.Component {
  handleClick = (e, { value, checked }) => {
    this.props.handleCheckChange(this.props.permission.group, value, checked);
  };

  render() {
    return (
      <Table.Row>
        <Table.Cell>{this.props.permission.group.name}</Table.Cell>
        <Table.Cell textAlign="center">
          <Checkbox
            value="view_drawing"
            checked={this.props.permission.canView}
            onChange={this.handleClick}
          />
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Checkbox
            value="change_drawing"
            checked={this.props.permission.canChange}
            onChange={this.handleClick}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default DrawingPermissionsFormRow;
