// React
import React from "react";

// Semantic ui
import { Button, Form, Grid, Table, Loader } from "semantic-ui-react";

// Components
import FolderGroupPermissionsFormRow from "./FolderGroupPermissionsFormRow";
import DocumentGroupPermissionsFormRow from "./DocumentGroupPermissionsFormRow";
import ErrorMessage from "../ErrorMessage";

const GroupPermissionsForm = (props) => (
  <Form onSubmit={props.handleSubmit} error>
    {props.error ? <ErrorMessage error={props.error} /> : null}
    <Form.Field disabled={!props.mutation}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Groupe</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Accès en lecture
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Peut déplacer
            </Table.HeaderCell>
            {props.fileType === "folder" ? (
              <Table.HeaderCell textAlign="center">
                Peut éditer
              </Table.HeaderCell>
            ) : null}
            <Table.HeaderCell textAlign="center">
              Peut supprimer
            </Table.HeaderCell>
            {props.fileType === "folder" ? (
              <Table.HeaderCell textAlign="center">
                Peut ajouter des dossiers
              </Table.HeaderCell>
            ) : null}
            {props.fileType === "folder" ? (
              <Table.HeaderCell textAlign="center">
                Peut ajouter des documents
              </Table.HeaderCell>
            ) : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.groupPermissionsRows
            .sort(function (a, b) {
              var textA = a.group.name.toUpperCase();
              var textB = b.group.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .map((groupPermissionsRow) =>
              props.fileType === "folder" ? (
                <FolderGroupPermissionsFormRow
                  key={`table-row-group-${groupPermissionsRow.group.name}`}
                  groupPermissionsRow={groupPermissionsRow}
                  handleCheckChange={props.handleCheckChange}
                />
              ) : (
                <DocumentGroupPermissionsFormRow
                  key={`table-row-group-${groupPermissionsRow.group.name}`}
                  groupPermissionsRow={groupPermissionsRow}
                  handleCheckChange={props.handleCheckChange}
                />
              )
            )}
        </Table.Body>
      </Table>
    </Form.Field>
    {props.mutation ? (
      <Grid columns={1}>
        <Grid.Row>
          {props.loading ? (
            <Loader />
          ) : (
            <Grid.Column textAlign="right">
              <Button positive type="submit">
                Enregistrer
              </Button>
              <Button
                negative
                onClick={(e) => {
                  e.preventDefault();
                  props.handleCancel();
                }}
              >
                Annuler
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    ) : null}
  </Form>
);

export default GroupPermissionsForm;
