// React
import React from "react";

// React Router
import { withRouter } from "react-router-dom";

// GraphQL
import EMutation from "../../../../utils/EMutation";
import { DELETE_PROJECT } from "../../_GraphQL/mutations";
import { ADMIN_PROJECT_SET } from "../../_GraphQL/queries";

// Semantic ui
import { Button, Grid, Header, Segment, Confirm } from "semantic-ui-react";

class ProjectDelete extends React.Component {
  state = { open: false };

  render() {
    const { project } = this.props;

    return (
      <Segment secondary>
        <EMutation
          mutation={DELETE_PROJECT}
          update={(cache, { data: { adminDeleteProject } }) => {
            const data = cache.readQuery({
              query: ADMIN_PROJECT_SET,
              variables: { siteId: project.site.id },
            });
            if (data) {
              cache.writeQuery({
                query: ADMIN_PROJECT_SET,
                variables: { siteId: project.site.id },
                data: {
                  adminProjectSet: data.adminProjectSet.filter(
                    (p) => p.id !== adminDeleteProject.project.id
                  ),
                },
              });
            }
          }}
          onCompleted={() => {
            this.props.history.push(`/manager/site/${project.site.id}`);
          }}
        >
          {(adminDeleteProject) => {
            return (
              <Header as="h5">
                <Grid columns={1}>
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      <Button
                        disabled={this.props.editMode}
                        primary
                        floated="right"
                        onClick={() => this.setState({ open: true })}
                      >
                        Supprimer le projet
                      </Button>
                      <Confirm
                        open={this.state.open}
                        onCancel={() => this.setState({ open: false })}
                        onConfirm={() =>
                          adminDeleteProject({
                            variables: { projectId: project.id },
                          })
                        }
                        content="Etes-vous sûr(e) de vouloir supprimer ce projet ?"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Header>
            );
          }}
        </EMutation>
      </Segment>
    );
  }
}

export default withRouter(ProjectDelete);
