// React
import React from "react";

// React Router
import { withRouter } from "react-router";

// Semantic ui
import { Form } from "semantic-ui-react";

// GraphQL
import {
  ADMIN_CREATE_QUESTIONNAIRE,
  ADMIN_UPDATE_QUESTIONNAIRE,
} from "../../_GraphQL/mutations";
import { ADMIN_QUESTIONNAIRE_SET } from "../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../Common/components/EditSegment";

// Message
import { addSuccessToastMessage } from "../../../../_GraphQL/message";
import { QUESTIONNAIRE_FILTER } from "../../../../utils/Constants";

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <Form.Field width={6}>
        <label>Nom</label>
        <input
          placeholder="Nom"
          required
          value={this.props.name}
          onChange={this.props.onChangeName}
          disabled={!this.props.enabled}
        />
      </Form.Field>
    );
  }
}

class QuestionnaireInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    if (!this.props.create) {
      this.setState({ name: this.props.questionnaire.name });
    }
  };

  onChangeName = (e) => this.setState({ name: e.target.value });

  handleSubmit = (mutate) => {
    let variables = {
      name: this.state.name,
    };

    if (this.props.layerId) {
      variables.layerId = this.props.layerId;
    }

    if (!this.props.create) {
      variables.questionnaireId = this.props.questionnaire.id;
    }

    mutate({ variables });
  };

  update = (cache, { data: { adminUpdateQuestionnaire, adminCreateQuestionnaire } }) => {
    // S'il s'agit d'un nouveau questionnaire, on ne met pas à jour le cache :
    // - si on vient de la liste des questionnaire, le cache sera rafraichi de toutes
    // façons puisque que la requête et de type network-only
    // - si on vient d'une couche, on est redirrigé vers la page du questionnaire de la couche,
    // qui sera donc chargée pour la première fois.
    if (!this.props.create) {
      cache.modify({
        id: `QuestionnaireType:${this.props.questionnaire.id}`,
        fields: {
          name() {
            return adminUpdateQuestionnaire.questionnaire.name;
          },
        },
      });
    } else {
      const { adminQuestionnaireSet } = cache.readQuery({
        query: ADMIN_QUESTIONNAIRE_SET,
        variables: { orphelin: QUESTIONNAIRE_FILTER.ALL },
      });
      cache.writeQuery({ 
        query: ADMIN_QUESTIONNAIRE_SET,
        variables: { orphelin: QUESTIONNAIRE_FILTER.ALL },
        data: { adminQuestionnaireSet: [...adminQuestionnaireSet, adminCreateQuestionnaire.questionnaire ]} })
    }
  };

  onCompleted = (data) => {
    if (this.props.create) {
      if (this.props.layerId) {
        this.props.history.push(
          `/manager/couche/${this.props.layerId}/questionnaire/${data.adminCreateQuestionnaire.questionnaire.id}`
        );
      } else {
        this.props.history.push(
          `/manager/questionnaire/${data.adminCreateQuestionnaire.questionnaire.id}`
        );
      }
    } else {
      addSuccessToastMessage({
        header: "Confirmation enregistrement",
        content: "Le nom a été enregistré.",
      });
      this.toReadMode();
    }
  };

  render() {
    const mutate = this.props.create
      ? ADMIN_CREATE_QUESTIONNAIRE
      : ADMIN_UPDATE_QUESTIONNAIRE;
    return (
      <EditSegment
        title="Informations"
        buttonText="Modifier"
        mutation={mutate}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
        openInEditMode={this.props.create}
      >
        {(enabled) => (
          <Fields
            name={this.state.name}
            enabled={enabled}
            initFields={this.initFields}
            onChangeName={this.onChangeName}
          />
        )}
      </EditSegment>
    );
  }
}

export default withRouter(QuestionnaireInfos);
