// React
import React from "react";

// Semantic ui
import { Form, Checkbox, Segment, Dropdown } from "semantic-ui-react";

// GraphQL
import { ADD_REMOVE_LAYER_GEOMETRY } from "../../../_GraphQL/mutations";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";
import { IconField } from "./LayerInfos";

// Constants
import {
  MESSAGE_TYPE,
  COLLABORATIVE_MAP_PERMISSIONS,
  QUESTIONNAIRE_FIELD_TYPES,
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
} from "../../../../../utils/Constants";

// Message
import {
  addErrorToastMessage,
  addSuccessToastMessage,
} from "../../../../../_GraphQL/message";

const possibleDecorators = [
  QUESTIONNAIRE_FIELD_TYPES.RADIO,
  QUESTIONNAIRE_FIELD_TYPES.CHECKBOX,
  QUESTIONNAIRE_FIELD_TYPES.SELECT,
];

function GeometryFieldDeco({ enabled, decoratorId, layer, onDecoratorChange }) {
  return (
    <Form.Field width={6}>
      <label>Décorateur</label>
      <Dropdown
        disabled={!enabled}
        selection
        value={decoratorId}
        options={[{ key: -1, value: -1, text: "Aucun décorateur" }].concat(
          layer.questionnaire.questionnairefieldSet
            .filter((qfs) =>
              possibleDecorators.find((pd) => pd === qfs.fieldType.code)
            )
            .filter((qfs) => !qfs.multiselect)
            .map((qfs) => ({
              key: qfs.id,
              value: qfs.id,
              text: qfs.text,
            }))
        )}
        onChange={onDecoratorChange}
      ></Dropdown>
    </Form.Field>
  );
}

function GeometryField({
  enabled,
  icon,
  pickIcon,
  resetIcon,
  text,
  onTextChange,
  layer,
  decoratorId,
  onDecoratorChange,
  ici,
}) {
  if (layer.editable) {
    return (
      <Segment>
        {ici ? null : (
          <>
            <IconField
              label="Icone du bouton"
              icon={icon}
              enabled={enabled}
              reset={resetIcon}
              pickIcon={pickIcon}
            />

            <Form.Field width={6}>
              <label>Label du bouton</label>
              <input
                placeholder="Texte par défaut"
                value={text}
                onChange={onTextChange}
                disabled={!enabled}
              />
            </Form.Field>
          </>
        )}

        {layer.questionnaire ? (
          <GeometryFieldDeco
            enabled={enabled}
            decoratorId={decoratorId}
            layer={layer}
            onDecoratorChange={onDecoratorChange}
          />
        ) : null}
      </Segment>
    );
  } else {
    if (layer.questionnaire) {
      return (
        <GeometryFieldDeco
          enabled={enabled}
          decoratorId={decoratorId}
          layer={layer}
          onDecoratorChange={onDecoratorChange}
        />
      );
    }
    return null;
  }
}

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <>
        <Form.Field>
          <Checkbox
            label="Point"
            checked={this.props.point}
            onChange={this.props.onPointChange}
            disabled={!this.props.enabled}
          />
          {this.props.point ? (
            <GeometryField
              enabled={this.props.enabled}
              icon={this.props.pointIcon}
              pickIcon={this.props.pickPointIcon}
              resetIcon={this.props.resetPointIcon}
              text={this.props.pointText}
              onTextChange={this.props.onPointTextChange}
              layer={this.props.layer}
              decoratorId={this.props.selectPointDecorator}
              onDecoratorChange={this.props.onSelectPointDecoratorChange}
              ici={this.props.ici}
            />
          ) : null}
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="Ligne"
            checked={this.props.line}
            onChange={this.props.onLineChange}
            disabled={!this.props.enabled}
          />
          {this.props.line ? (
            <GeometryField
              enabled={this.props.enabled}
              icon={this.props.lineIcon}
              pickIcon={this.props.pickLineIcon}
              resetIcon={this.props.resetLineIcon}
              text={this.props.lineText}
              onTextChange={this.props.onLineTextChange}
              layer={this.props.layer}
              decoratorId={this.props.selectLineDecorator}
              onDecoratorChange={this.props.onSelectLineDecoratorChange}
              ici={this.props.ici}
            />
          ) : null}
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="Polygone"
            checked={this.props.poly}
            onChange={this.props.onPolyChange}
            disabled={!this.props.enabled}
          />
          {this.props.poly ? (
            <GeometryField
              enabled={this.props.enabled}
              icon={this.props.polyIcon}
              pickIcon={this.props.pickPolyIcon}
              resetIcon={this.props.resetPolyIcon}
              text={this.props.polyText}
              onTextChange={this.props.onPolyTextChange}
              layer={this.props.layer}
              decoratorId={this.props.selectPolyDecorator}
              onDecoratorChange={this.props.onSelectPolyDecoratorChange}
              ici={this.props.ici}
            />
          ) : null}
        </Form.Field>
      </>
    );
  }
}

class LayerGeometries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      point: false,
      pointIcon: "",
      pointText: "",
      selectPointDecorator: -1,

      line: false,
      lineIcon: "",
      lineText: "",
      selectLineDecorator: -1,

      poly: false,
      polyIcon: "",
      polyText: "",
      selectPolyDecorator: -1,
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    let newState = {
      point: false,
      pointIcon: "",
      pointText: "",
      selectPointDecorator: -1,

      line: false,
      lineIcon: "",
      lineText: "",
      selectLineDecorator: -1,

      poly: false,
      polyIcon: "",
      polyText: "",
      selectPolyDecorator: -1,
    };

    const layergeometrySet = this.props.layer.layergeometrySet;

    const point = layergeometrySet.find(
      (lfs) => lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT
    );

    if (point) {
      newState.point = true;
      newState.pointIcon = point.icon || "";
      newState.pointText = point.text || "";
      newState.selectPointDecorator = point.decorator ? point.decorator.id : -1;
    }

    const line = layergeometrySet.find(
      (lfs) => lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE
    );

    if (line) {
      newState.line = true;
      newState.lineIcon = line.icon || "";
      newState.lineText = line.text || "";
      newState.selectLineDecorator = line.decorator ? line.decorator.id : -1;
    }

    const poly = layergeometrySet.find(
      (lfs) =>
        lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
    );

    if (poly) {
      newState.poly = true;
      newState.polyIcon = poly.icon || "";
      newState.polyText = poly.text || "";
      newState.selectPolyDecorator = poly.decorator ? poly.decorator.id : -1;
    }

    this.setState(newState);
  };

  onPointChange = (e, { checked }) => this.setState({ point: checked });

  pickPointIcon = (icon) => this.setState({ pointIcon: icon });

  resetPointIcon = () => this.setState({ pointIcon: "" });

  onPointTextChange = (e) => this.setState({ pointText: e.target.value });

  onSelectPointDecoratorChange = (e, data) =>
    this.setState({ selectPointDecorator: data.value });

  onLineChange = (e, { checked }) => this.setState({ line: checked });

  pickLineIcon = (icon) => this.setState({ lineIcon: icon });

  resetLineIcon = () => this.setState({ lineIcon: "" });

  onLineTextChange = (e) => this.setState({ lineText: e.target.value });

  onSelectLineDecoratorChange = (e, data) =>
    this.setState({ selectLineDecorator: data.value });

  onPolyChange = (e, { checked }) => this.setState({ poly: checked });

  pickPolyIcon = (icon) => this.setState({ polyIcon: icon });

  resetPolyIcon = () => this.setState({ polyIcon: "" });

  onPolyTextChange = (e) => this.setState({ polyText: e.target.value });

  onSelectPolyDecoratorChange = (e, data) =>
    this.setState({ selectPolyDecorator: data.value });

  handleSubmit = (mutate) => {
    if (
      !this.state.point &&
      this.props.layerGroupToolSet.find((lgt) =>
        lgt.assignedToolPermissionSet.find(
          (p) =>
            p.codename ===
            COLLABORATIVE_MAP_PERMISSIONS.ADD_POINT_TO_LAYER +
              this.props.layer.id
        )
      )
    ) {
      addErrorToastMessage({
        header: "Formulaire invalide",
        type: MESSAGE_TYPE.NEGATIVE,
        autoClose: true,
        closeTime: 10000,
        content: `Vous ne pouvez pas supprimer la géométrie "Point" car elle est affectée comme
                        outil à au moins un groupe. Vous pouvez décocher l'outil dans la section "outils".`,
      });
      return;
    }

    if (
      !this.state.line &&
      this.props.layerGroupToolSet.find((lgt) =>
        lgt.assignedToolPermissionSet.find(
          (p) =>
            p.codename ===
            COLLABORATIVE_MAP_PERMISSIONS.ADD_LINE_TO_LAYER +
              this.props.layer.id
        )
      )
    ) {
      addErrorToastMessage({
        header: "Formulaire invalide",
        autoClose: true,
        closeTime: 10000,
        content: `Vous ne pouvez pas supprimer la géométrie "Ligne" car elle est affectée comme
                        outil à au moins un groupe. Vous pouvez décocher l'outil dans la section "outils".`,
      });
      return;
    }

    if (
      !this.state.poly &&
      this.props.layerGroupToolSet.find((lgt) =>
        lgt.assignedToolPermissionSet.find(
          (p) =>
            p.codename ===
            COLLABORATIVE_MAP_PERMISSIONS.ADD_POLYGON_TO_LAYER +
              this.props.layer.id
        )
      )
    ) {
      addErrorToastMessage({
        header: "Formulaire invalide",
        autoClose: true,
        closeTime: 10000,
        content: `Vous ne pouvez pas supprimer la géométrie "Polygone" car elle est affectée comme
                        outil à au moins un groupe. Vous pouvez décocher l'outil dans la section "outils".`,
      });
      return;
    }

    let layerGeometryToRemove = [];
    let layerGeometryToAdd = [];

    if (this.state.point) {
      layerGeometryToAdd.push({
        geometry: "Point",
        decoratorId:
          this.state.selectPointDecorator === -1
            ? null
            : this.state.selectPointDecorator,
        icon: this.state.pointIcon,
        text: this.state.pointText,
      });
    } else {
      layerGeometryToRemove.push("Point");
    }

    if (this.state.line) {
      layerGeometryToAdd.push({
        geometry: "Ligne",
        decoratorId:
          this.state.selectLineDecorator === -1
            ? null
            : this.state.selectLineDecorator,
        icon: this.state.lineIcon,
        text: this.state.lineText,
      });
    } else {
      layerGeometryToRemove.push("Ligne");
    }

    if (this.state.poly) {
      layerGeometryToAdd.push({
        geometry: "Polygone",
        decoratorId:
          this.state.selectPolyDecorator === -1
            ? null
            : this.state.selectPolyDecorator,
        icon: this.state.polyIcon,
        text: this.state.polyText,
      });
    } else {
      layerGeometryToRemove.push("Polygone");
    }

    let variables = {
      layerId: this.props.layer.id,
      geometryToRemoveSet: layerGeometryToRemove,
      geometryToAddSet: layerGeometryToAdd,
    };

    mutate({
      variables: variables,
    });
  };

  update = (cache, { data: { addRemoveLayerGeometry } }) => {
    cache.modify({
      id: `LayerType:${this.props.layer.id}`,
      fields: {
        layergeometrySet() {
          return addRemoveLayerGeometry.layer.layergeometrySet;
        },
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      type: MESSAGE_TYPE.POSITIVE,
      content: "Les géométries ont été mises à jour.",
    });
    this.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="Géométries"
        buttonText="Modifier"
        mutation={ADD_REMOVE_LAYER_GEOMETRY}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            initFields={this.initFields}
            enabled={enabled}
            layer={this.props.layer}
            point={this.state.point}
            onPointChange={this.onPointChange}
            pointIcon={this.state.pointIcon}
            pickPointIcon={this.pickPointIcon}
            resetPointIcon={this.resetPointIcon}
            pointText={this.state.pointText}
            onPointTextChange={this.onPointTextChange}
            selectPointDecorator={this.state.selectPointDecorator}
            onSelectPointDecoratorChange={this.onSelectPointDecoratorChange}
            line={this.state.line}
            onLineChange={this.onLineChange}
            lineIcon={this.state.lineIcon}
            pickLineIcon={this.pickLineIcon}
            resetLineIcon={this.resetLineIcon}
            lineText={this.state.lineText}
            onLineTextChange={this.onLineTextChange}
            selectLineDecorator={this.state.selectLineDecorator}
            onSelectLineDecoratorChange={this.onSelectLineDecoratorChange}
            poly={this.state.poly}
            onPolyChange={this.onPolyChange}
            polyIcon={this.state.polyIcon}
            pickPolyIcon={this.pickPolyIcon}
            resetPolyIcon={this.resetPolyIcon}
            polyText={this.state.polyText}
            onPolyTextChange={this.onPolyTextChange}
            selectPolyDecorator={this.state.selectPolyDecorator}
            onSelectPolyDecoratorChange={this.onSelectPolyDecoratorChange}
            ici={this.props.ici}
          />
        )}
      </EditSegment>
    );
  }
}

export default LayerGeometries;
