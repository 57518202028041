// React
import React from "react";

// Semantic ui
import { Grid, Header, Segment, List, Popup } from "semantic-ui-react";

// GraphQL
import { ASSIGN_UNASSIGN_GROUP_TO_TASK } from "../../../_GraphQL/mutations";
import { GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_TASK } from "../../../_GraphQL/queries";

// Components
import EQuery from "../../../../../utils/EQuery";
import { EditSegment } from "../../../../../Common/components/EditSegment";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

const groupStates = {
  noChange: 0,
  add: 1,
  remove: 2,
};

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <Grid columns={2}>
        {this.props.enabled ? (
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Groupes non autorisés
              </Header>
              <Segment attached>
                <List selection>
                  {this.props.groupOut.map((g) => (
                    <Popup
                      key={`popup-${g.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.props.addGroup(g)}
                        >
                          {g.name}
                        </List.Item>
                      }
                      content="Ajouter"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" attached="top">
                Groupes autorisés
              </Header>
              <Segment attached>
                <List selection>
                  {this.props.groupIn.map((g) => (
                    <Popup
                      key={`popup-${g.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.props.removeGroup(g)}
                        >
                          {g.name}
                        </List.Item>
                      }
                      content="Supprimer"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Groupes autorisés
              </Header>
              <Segment attached>
                <List>
                  {this.props.groupIn.map((g) => (
                    <List.Item key={`grp-${g.id}`}>{g.name}</List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

class TaskGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupIn: [],
      groupOut: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    this.setState({
      groupIn: this.props.data.adminAssignedGroupForTaskSet.map((g) => ({
        id: g.id,
        name: g.name,
        groupState: groupStates.noChange,
      })),
      groupOut: this.props.data.adminAvailableGroupForTaskSet.map((g) => ({
        id: g.id,
        name: g.name,
        groupState: groupStates.noChange,
      })),
    });
  };

  addGroup = (group) => {
    if (group.groupState === groupStates.noChange) {
      group.groupState = groupStates.add;
    }
    if (group.groupState === groupStates.remove) {
      group.groupState = groupStates.noChange;
    }
    this.setState({
      groupIn: this.state.groupIn.concat([group]),
      groupOut: this.state.groupOut.filter((g) => g.id !== group.id),
    });
  };

  removeGroup = (group) => {
    if (group.groupState === groupStates.noChange) {
      group.groupState = groupStates.remove;
    }
    if (group.groupState === groupStates.add) {
      group.groupState = groupStates.noChange;
    }
    this.setState({
      groupOut: this.state.groupOut.concat([group]),
      groupIn: this.state.groupIn.filter((g) => g.id !== group.id),
    });
  };

  handleSubmit = (mutate) =>
    mutate({
      variables: {
        taskId: this.props.task.id,
        groupsToAssign: this.state.groupIn
          .filter((g) => g.groupState === groupStates.add)
          .map((g) => g.id),
        groupsToUnassign: this.state.groupOut
          .filter((g) => g.groupState === groupStates.remove)
          .map((g) => g.id),
      },
    });

  update = (cache, { data: { adminAssignUnassignGroupToTask } }) => {
    cache.writeQuery({
      query: GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_TASK,
      variables: { taskId: this.props.task.id },
      data: {
        adminAssignedGroupForTaskSet:
          adminAssignUnassignGroupToTask.adminAssignedGroupSet,
        adminAvailableGroupForTaskSet:
          adminAssignUnassignGroupToTask.adminAvailableGroupSet,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Le nom a été enregistré.",
    });
    this.toReadMode();
  };

  render() {
    return (
      <EditSegment
        title="Autorisation des Groupes"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_GROUP_TO_TASK}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            groupIn={this.state.groupIn}
            groupOut={this.state.groupOut}
            enabled={enabled}
            initFields={this.initFields}
            onChangeName={this.onChangeName}
            addGroup={this.addGroup}
            removeGroup={this.removeGroup}
          />
        )}
      </EditSegment>
    );
  }
}

const TaskGroupsQuery = (props) => {
  return (
    <EQuery
      query={GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_TASK}
      variables={{ taskId: props.task.id }}
    >
      {(data) => (
        <TaskGroups
          data={data}
          task={props.task}
          editMode={props.editMode}
          toEditMode={props.toEditMode}
          toReadMode={props.toReadMode}
        />
      )}
    </EQuery>
  );
};

export { TaskGroupsQuery as TaskGroups };
