// React
import React from "react";

// Semantic ui
import {
  Form,
  Radio,
  Header,
  Select,
  Input,
  FormField,
} from "semantic-ui-react";

// Constants
import { QUESTIONNAIRE_FILTER } from "../../../../utils/Constants";

function ItemFilter({
  itemSet,
  itemId,
  handleItemChange,
  label,
  placeholder,
  noItem,
}) {
  let options = [];

  if (itemSet.length === 0) {
    return (
      <Form.Field width={4}>
        <label>{label}</label>
        <input readOnly disabled value={noItem} />
      </Form.Field>
    );
  } else if (itemSet.length === 1) {
    const p = itemSet[0];
    options = [{ key: p.id, value: p.id, text: p.name }];
  } else {
    options = [{ key: null, value: null, text: placeholder }].concat(
      itemSet.map((p) => ({ key: p.id, value: p.id, text: p.name }))
    );
  }

  return (
    <Form.Field
      placeholder={placeholder}
      disabled={itemSet.length < 2}
      control={Select}
      label={label}
      options={options}
      value={itemId}
      onChange={handleItemChange}
      width={4}
    />
  );
}

const QuestionnaireFilter = ({
  orphan,
  handleOrphanChange,
  siteId,
  handleSiteChange,
  sites,
  projectId,
  handleProjectChange,
  projectSet,
  taskId,
  handleTaskChange,
  taskSet,
  handleSearchChange,
}) => {
  return (
    <Form>
      <Header as="h4">Filtres</Header>
      <Form.Group inline>
        <FormField
          control={Radio}
          label="Questionnaires affectés"
          name="radioGroup"
          value={QUESTIONNAIRE_FILTER.NON_ORPHELIN}
          checked={orphan === QUESTIONNAIRE_FILTER.NON_ORPHELIN}
          onChange={handleOrphanChange}
        />

        <FormField
          control={Radio}
          label="Questionnaires orphelins"
          name="radioGroup"
          value={QUESTIONNAIRE_FILTER.ORPHELIN}
          checked={orphan === QUESTIONNAIRE_FILTER.ORPHELIN}
          onChange={handleOrphanChange}
        />

        <FormField
          control={Radio}
          label="Tous"
          name="radioGroup"
          value={QUESTIONNAIRE_FILTER.ALL}
          checked={orphan === QUESTIONNAIRE_FILTER.ALL}
          onChange={handleOrphanChange}
        />
      </Form.Group>

      <Form.Group>
        {orphan === QUESTIONNAIRE_FILTER.NON_ORPHELIN ? (
          <>
            <Form.Field
              control={Select}
              label="Site"
              options={sites}
              placeholder="Tous les sites"
              value={siteId}
              onChange={handleSiteChange}
              width={4}
            />
            {siteId ? (
              <ItemFilter
                itemId={projectId}
                itemSet={projectSet}
                handleItemChange={handleProjectChange}
                label="Projets"
                placeholder="Tous les projets"
                noItem="Aucun projet pour ce site"
              />
            ) : (
              <Form.Field width={4} />
            )}
            {projectId ? (
              <ItemFilter
                itemId={taskId}
                itemSet={taskSet}
                handleItemChange={handleTaskChange}
                label="Tâches"
                placeholder="Toutes les tâches"
                noItem="Aucune tâche pour ce projet"
              />
            ) : (
              <Form.Field width={4} />
            )}
          </>
        ) : null}
        <Form.Field
          control={Input}
          label="Rechercher"
          id="requete"
          placeholder="Un questionnaire"
          onChange={handleSearchChange}
          icon="search"
          width={4}
        />
      </Form.Group>
    </Form>
  );
};

export { QuestionnaireFilter, ItemFilter };
