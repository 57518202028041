import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { Viewer } from "../../components/Styles/Viewer";
import { CreateStyle } from "../../components/Styles/CreateStyle";
import {
  COLLABORATIVE_MAP_GEOMETRY_TYPES,
  PATTERN_TYPES,
} from "../../../../utils/Constants";
import { useLocation } from "react-router-dom";

function Create() {
  const query = new URLSearchParams(useLocation().search);
  const initGeoTypeCode = query.get("type");

  const [geoTypeCode, setGeoTypeCode] = useState(
    initGeoTypeCode ?? COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
  );

  const [usePattern, setUsePattern] = useState(false);

  const [patternTypeCode, setPatternTypeCode] = useState(PATTERN_TYPES.STRIPES);

  const [pathOptions, setPathOptions] = useState({});
  const [patternOptions, setPatternOptions] = useState({});
  const [shapeOptions, setShapeOptions] = useState({});

  return (
    <Grid>
      <Grid.Row columns={3}>
        <CreateStyle
          pathOptions={pathOptions}
          setPathOptions={setPathOptions}
          patternOptions={patternOptions}
          setPatternOptions={setPatternOptions}
          shapeOptions={shapeOptions}
          setShapeOptions={setShapeOptions}
          geoTypeCode={geoTypeCode}
          setGeoTypeCode={setGeoTypeCode}
          usePattern={usePattern}
          setUsePattern={setUsePattern}
          patternTypeCode={patternTypeCode}
          setPatternTypeCode={setPatternTypeCode}
        />
        <Grid.Column>
          <Viewer
            geoTypeCode={geoTypeCode}
            pathOptions={pathOptions}
            usePattern={usePattern}
            patternType={patternTypeCode}
            patternOptions={patternOptions}
            shapeOptions={shapeOptions}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export { Create };
