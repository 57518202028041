import React from "react";

function SvgLine({ color, weight, dashArray }) {
  return (
    <svg height="30" width="100">
      <line
        x1="10"
        y1="15"
        x2="90"
        y2="15"
        style={{ stroke: color, strokeWidth: weight }}
        strokeDasharray={dashArray ?? null}
        strokeLinecap="round"
      />
    </svg>
  );
}

export { SvgLine };
