// React
import React, { useState } from "react";

// GraphQL
import { UPDATE_USER_PASSWORD } from "../../../_GraphQL/mutations";

// Semantic ui
import { Form } from "semantic-ui-react";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";
import MessageDisplayer from "../../../../../Common/components/MessageDisplayer";

// Constants
import { COMPONENTS } from "../../../../../utils/Constants";

// Message
import {
  addErrorMessage,
  addSuccessMessage,
} from "../../../../../_GraphQL/message";

function Fields({
  password,
  handlePasswordChange,
  passwordConfirm,
  handlePasswordConfirmChange,
  enabled,
}) {
  return (
    <>
      <MessageDisplayer location={COMPONENTS.MANAGER.PWD} />
      <Form.Field>
        <label>Mot de passe</label>
        <input
          type="password"
          required
          value={password}
          onChange={handlePasswordChange}
          disabled={!enabled}
        />
      </Form.Field>
      <Form.Field>
        <label>Confirmation du mot de passe</label>
        <input
          type="password"
          required
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
          disabled={!enabled}
        />
      </Form.Field>
    </>
  );
}

function UserPassword({ editMode, toggleMode, user }) {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  function handleSubmit(mutate) {
    if (password !== passwordConfirm) {
      addErrorMessage({
        location: COMPONENTS.MANAGER.PWD,
        header: "Les mots de passes ne correspondent pas",
        content: "Les deux mots de passes doivent correspondre strictement.",
      });
    } else {
      mutate({
        variables: {
          userId: user.id,
          password: password,
        },
      });
    }
  }

  function onCompleted() {
    addSuccessMessage({
      location: COMPONENTS.MANAGER.PWD,
      header: "Mot de passe mis à jour",
      content: "Le mot de passe a été mis à jour avec succès.",
    });
    setPassword("");
    setPasswordConfirm("");
    toggleMode();
  }

  function toReadMode() {
    setPassword("");
    setPasswordConfirm("");
    if (editMode) {
      toggleMode();
    }
  }

  function toEditMode() {
    if (!editMode) {
      toggleMode();
    }
  }

  return (
    <EditSegment
      title="Mot de passe"
      buttonText="Modifier"
      mutation={UPDATE_USER_PASSWORD}
      onCompleted={onCompleted}
      handleSubmit={handleSubmit}
      toEditMode={toEditMode}
      handleCancel={toReadMode}
      editMode={editMode}
    >
      {(enabled) => (
        <Fields
          password={password}
          handlePasswordChange={(e) => setPassword(e.target.value)}
          passwordConfirm={passwordConfirm}
          handlePasswordConfirmChange={(e) =>
            setPasswordConfirm(e.target.value)
          }
          enabled={enabled}
        />
      )}
    </EditSegment>
  );
}

export { UserPassword };
