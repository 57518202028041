// React
import React from "react";

// Semantic ui
import { Form, Checkbox, Table } from "semantic-ui-react";

// GraphQL
import { ASSIGN_UNASSIGN_RESTR_BTW_USERS_PERM_TO_USER } from "../../../_GraphQL/mutations";
import {
  GET_USER_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET,
  GET_LAYER,
} from "../../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";
import EQuery from "../../../../../utils/EQuery";

// Constants
import {
  COLLABORATIVE_MAP_PERMISSIONS,
  USER_TYPES,
} from "../../../../../utils/Constants";

// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";

const UserRestrBtwUsersPermRow = ({
  enabled,
  userRestrBtwUsersPermRow,
  handleCheckChange,
  layerId,
}) => {
  const user = userRestrBtwUsersPermRow.user;
  let name =
    user.profile && user.profile.nature.code === USER_TYPES.LEGAL_PERSON
      ? user.profile.name
      : `${user.firstName} ${user.lastName}`;
  if (name === " ") {
    name = user.email;
  } else {
    name = `${name} (${user.email})`;
  }
  return (
    <Table.Row>
      <Table.Cell>{name}</Table.Cell>

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId}
          checked={
            userRestrBtwUsersPermRow[
              COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userRestrBtwUsersPermRow.user, value, checked)
          }
        />
      </Table.Cell>

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId}
          checked={
            userRestrBtwUsersPermRow[
              COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userRestrBtwUsersPermRow.user, value, checked)
          }
        />
      </Table.Cell>

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId}
          checked={
            userRestrBtwUsersPermRow[
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userRestrBtwUsersPermRow.user, value, checked)
          }
        />
      </Table.Cell>

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES + layerId}
          checked={
            userRestrBtwUsersPermRow[
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userRestrBtwUsersPermRow.user, value, checked)
          }
        />
      </Table.Cell>

      <Table.Cell textAlign="center">
        <Checkbox
          disabled={!enabled}
          value={
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES + layerId
          }
          checked={
            userRestrBtwUsersPermRow[
              COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES + layerId
            ]
          }
          onChange={(e, { value, checked }) =>
            handleCheckChange(userRestrBtwUsersPermRow.user, value, checked)
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields(this.props.enabled);
  }

  componentDidUpdate() {
    if (this.props.enabled) {
      this.props.fieldsDidUpdate();
    }
  }

  render() {
    return (
      <Form.Field>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Utilisateur</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Voir les géométries des autres utilisateurs
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Voir les infos des géométries des autres utilisateurs
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Editer les infos des géométries des autres utilisateurs
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Editer les géométries des autres utilisateurs
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Supprimer les géométries des autres utilisateurs
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.userRestrBtwUsersPermRowSet
              .sort((a, b) => {
                let aName =
                  a.user.profile &&
                  a.user.profile.nature.code === USER_TYPES.LEGAL_PERSON
                    ? a.user.profile.name
                    : a.user.firstName;
                if (!aName) {
                  aName = a.user.email;
                }
                let bName =
                  b.user.profile &&
                  b.user.profile.nature.code === USER_TYPES.LEGAL_PERSON
                    ? b.user.profile.name
                    : b.user.firstName;
                if (!bName) {
                  bName = b.user.email;
                }
                if (aName === bName) {
                  return 0;
                } else if (aName === null) {
                  return 1;
                } else if (bName === null) {
                  return -1;
                } else {
                  return aName.toLowerCase().localeCompare(bName.toLowerCase());
                }
              })
              .map((userRestrBtwUsersPermRow) => (
                <UserRestrBtwUsersPermRow
                  enabled={this.props.enabled}
                  key={`table-row-user-${userRestrBtwUsersPermRow.user.id}`}
                  userRestrBtwUsersPermRow={userRestrBtwUsersPermRow}
                  handleCheckChange={this.props.handleCheckChange}
                  layerId={this.props.layerId}
                />
              ))}
          </Table.Body>
        </Table>
      </Form.Field>
    );
  }
}

class LayerUserRestrBtwUsersPerm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialUserRestrBtwUsersPermRowSet: [],
      userRestrBtwUsersPermRowSet: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = (edit) => {
    let userRestrBtwUsersPermRowSet = [];

    if (edit) {
      this.props.data.adminUserAllowedToViewLayerSet.forEach((g) => {
        let userRestrBtwUsersPermRow = {
          user: g,
        };

        userRestrBtwUsersPermRow[
          COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES +
            this.props.layer.id
        ] = false;
        userRestrBtwUsersPermRow[
          COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + this.props.layer.id
        ] = false;
        userRestrBtwUsersPermRow[
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + this.props.layer.id
        ] = false;
        userRestrBtwUsersPermRow[
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES +
            this.props.layer.id
        ] = false;
        userRestrBtwUsersPermRow[
          COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES +
            this.props.layer.id
        ] = false;

        let restrBtwUsersPerm =
          this.props.data.adminUserWithAssignedRestrBtwUsersPermSetSet.find(
            (gt) => gt.id === g.id
          );

        if (restrBtwUsersPerm) {
          restrBtwUsersPerm.assignedRestrBtwUsersPermSet.forEach(
            (t) => (userRestrBtwUsersPermRow[t.codename] = true)
          );
        }

        userRestrBtwUsersPermRowSet.push(userRestrBtwUsersPermRow);
      });
    } else {
      this.props.data.adminUserWithAssignedRestrBtwUsersPermSetSet.forEach(
        (userRestrBtwUsersPerm) => {
          let userRestrBtwUsersPermRow = {
            user: {
              id: userRestrBtwUsersPerm.id,
              firstName: userRestrBtwUsersPerm.firstName,
              lastName: userRestrBtwUsersPerm.lastName,
              email: userRestrBtwUsersPerm.email,
            },
          };
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES +
              this.props.layer.id
          ] = userRestrBtwUsersPerm.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES +
                this.props.layer.id
          );
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS +
              this.props.layer.id
          ] = userRestrBtwUsersPerm.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS +
                this.props.layer.id
          );
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS +
              this.props.layer.id
          ] = userRestrBtwUsersPerm.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS +
                this.props.layer.id
          );
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES +
              this.props.layer.id
          ] = userRestrBtwUsersPerm.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES +
                this.props.layer.id
          );
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES +
              this.props.layer.id
          ] = userRestrBtwUsersPerm.assignedRestrBtwUsersPermSet.some(
            (perm) =>
              perm.codename ===
              COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES +
                this.props.layer.id
          );

          userRestrBtwUsersPermRowSet.push(userRestrBtwUsersPermRow);
        }
      );
    }

    this.setState({
      userRestrBtwUsersPermRowSet: JSON.parse(
        JSON.stringify(userRestrBtwUsersPermRowSet)
      ),
      initialUserRestrBtwUsersPermRowSet: JSON.parse(
        JSON.stringify(userRestrBtwUsersPermRowSet)
      ),
    });
  };

  handleSubmit = (mutate) => {
    let users = [];

    const restrBtwUsersPerms = [
      COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES +
        this.props.layer.id,
      COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES +
        this.props.layer.id,
    ];

    this.state.initialUserRestrBtwUsersPermRowSet.forEach((initRow) => {
      let user = {
        userId: initRow.user.id,
        permissionToAssignSet: [],
        permissionToUnassignSet: [],
      };

      const currentRow = this.state.userRestrBtwUsersPermRowSet.find(
        (row) => row.user.id === initRow.user.id
      );
      for (let t of restrBtwUsersPerms) {
        // ceux qui étaint checked au départ et qui maintenant sont unchecked
        if (initRow[t] && !currentRow[t]) {
          user.permissionToUnassignSet.push(t);
        }

        // ceux qui étaient unchecked au départ et qui maintenant sont checked
        if (!initRow[t] && currentRow[t]) {
          user.permissionToAssignSet.push(t);
        }
      }

      users.push(user);
    });

    mutate({
      variables: {
        layerId: this.props.layer.id,
        userToAssignUnassignSet: users,
      },
    });
  };

  update = (
    cache,
    { data: { adminAssignUnassignRestrBtwUsersPermToUser } }
  ) => {
    let data = cache.readQuery({
      query: GET_USER_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET,
      variables: { layerId: this.props.layer.id },
    });

    cache.writeQuery({
      query: GET_USER_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET,
      variables: { layerId: this.props.layer.id },
      data: {
        adminUserWithAssignedRestrBtwUsersPermSetSet:
          adminAssignUnassignRestrBtwUsersPermToUser.userWithAssignedRestrBtwUsersPermSetSet,
        adminUserAllowedToViewLayerSet: data.adminUserAllowedToViewLayerSet,
      },
    });

    data = cache.readQuery({
      query: GET_LAYER,
      variables: { layerId: this.props.layer.id },
    });

    cache.writeQuery({
      query: GET_LAYER,
      variables: { layerId: this.props.layer.id },
      data: {
        ...data,
        adminUserWithAssignedRestrBtwUsersPermSetSet:
          adminAssignUnassignRestrBtwUsersPermToUser.userWithAssignedRestrBtwUsersPermSetSet,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content:
        "L'affectation des restrictions entre utilisateurs a été mise à jour.",
    });
    this.toReadMode();
  };

  handleCheckChange = (user, restrBtwUsersPerm, checked) => {
    this.setState((prevState) => {
      let userRestrBtwUsersPermRow = prevState.userRestrBtwUsersPermRowSet.find(
        (al) => al.user.id === user.id
      );

      userRestrBtwUsersPermRow[restrBtwUsersPerm] = checked;

      let layerId = this.props.layer.id;

      if (checked) {
        if (
          restrBtwUsersPerm ===
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId ||
          restrBtwUsersPerm ===
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES + layerId ||
          restrBtwUsersPerm ===
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES + layerId
        ) {
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId
          ] = checked;
        } else if (
          restrBtwUsersPerm ===
          COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId
        ) {
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId
          ] = checked;
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId
          ] = checked;
        }
      } else {
        if (
          restrBtwUsersPerm ===
          COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_FEATURES + layerId
        ) {
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId
          ] = checked;
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId
          ] = checked;
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_GEOMETRIES + layerId
          ] = checked;
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.DELETE_OTHERS_GEOMETRIES + layerId
          ] = checked;
        } else if (
          restrBtwUsersPerm ===
          COLLABORATIVE_MAP_PERMISSIONS.VIEW_OTHERS_INFOS + layerId
        ) {
          userRestrBtwUsersPermRow[
            COLLABORATIVE_MAP_PERMISSIONS.EDIT_OTHERS_INFOS + layerId
          ] = checked;
        }
      }

      return {
        userRestrBtwUsersPermRowSet: prevState.userRestrBtwUsersPermRowSet,
      };
    });
  };

  render() {
    return (
      <EditSegment
        title="Restrictions entre les utilisateurs"
        buttonText="Modifier"
        mutation={ASSIGN_UNASSIGN_RESTR_BTW_USERS_PERM_TO_USER}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled, fieldsDidUpdate) => (
          <Fields
            fieldsDidUpdate={fieldsDidUpdate}
            editable={this.state.editable}
            enabled={enabled}
            initFields={this.initFields}
            userRestrBtwUsersPermRowSet={this.state.userRestrBtwUsersPermRowSet}
            handleCheckChange={this.handleCheckChange}
            layerId={this.props.layer.id}
          />
        )}
      </EditSegment>
    );
  }
}

const LayerUserRestrBtwUsersPermQuery = (props) => {
  return (
    <EQuery
      query={GET_USER_WITH_ASSIGNED_RESTR_BTW_USERS_PERM_SET_SET}
      variables={{ layerId: props.layer.id }}
    >
      {(data) => (
        <LayerUserRestrBtwUsersPerm
          data={data}
          layer={props.layer}
          editMode={props.editMode}
          toEditMode={props.toEditMode}
          toReadMode={props.toReadMode}
        />
      )}
    </EQuery>
  );
};

export default LayerUserRestrBtwUsersPermQuery;
