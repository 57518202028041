import React from "react";
import HomeRedirect from "./HomeRedirect";
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { isConnectedVar } from "../../_GraphQL/graphQLClient";
import {
  Segment,
  Header,
  Container,
  List,
  Grid,
  Reveal,
  Image,
} from "semantic-ui-react";
import poignee from "../../medias/poignee.svg";
import fleche from "../../medias/fleche.svg";
import { Login } from "../../Common/components/Login";

function Welcome() {
  return (
    <Container>
      <Segment>
        <Header as="h1">Accueil</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <Header as="h2" textAlign="left">
                Bienvenue sur l&apos;outil de gestion des paramètres des outils
                collaboratifs !
              </Header>
              Il vous permet de :
              <List as="ol">
                <List.Item as="li" value="-">
                  créer un nouveau projet et lui affecter des outils
                </List.Item>
                <List.Item as="li" value="-">
                  gérer les outils d&apos;un projet existant
                </List.Item>
                <List.Item as="li" value="-">
                  préciser qui a accès aux outils
                </List.Item>
                <List.Item as="li" value="-">
                  éditer ou créer des questionnaires
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3} textAlign="right" verticalAlign="bottom">
              <Reveal animated="move" instant>
                <Reveal.Content visible>
                  <Link to={"manager"}>
                    <Image src={poignee} size="tiny" />
                  </Link>
                </Reveal.Content>
                <Reveal.Content hidden>
                  <Link to={"manager"}>
                    <Image src={fleche} size="tiny" />
                  </Link>
                </Reveal.Content>
              </Reveal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
}

function Home({ location }) {
  const isAuthenticated = useReactiveVar(isConnectedVar);
  return (
    <>
      {location && location.state && location.state.from ? (
        <HomeRedirect from={location.state.from} />
      ) : null}
      {isAuthenticated ? <Welcome /> : <Login />}
    </>
  );
}

export { Home };
