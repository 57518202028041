export enum ListItemState {
  NoChange = 0,
  Add = 1,
  Remove = 2,
}

export enum CollaborativeMapGeometryTypes {
  Point = "COLLABORATIVE_MAP_GEOMETRY_TYPE_POINT",
  Line = "COLLABORATIVE_MAP_GEOMETRY_TYPE_LINE",
  Polygon = "COLLABORATIVE_MAP_GEOMETRY_TYPE_POLYGON",
}

export enum CollaborativeMapTool {
  None = "COLLABORATIVE_MAP_TOOL_NONE",
  AddPoint = "COLLABORATIVE_MAP_TOOL_ADD_POINT",
  AddLine = "COLLABORATIVE_MAP_TOOL_ADD_LINE",
  AddPolygon = "COLLABORATIVE_MAP_TOOL_ADD_POLYGON",
  EditGeometry = "COLLABORATIVE_MAP_TOOL_EDIT_GEOMETRY",
  EditQuestionnaire = "COLLABORATIVE_MAP_TOOL_EDIT_QUESTIONNAIRE",
  DeleteFeature = "COLLABORATIVE_MAP_TOOL_DELETE_FEATURE",
  ExportShp = "COLLABORATIVE_MAP_TOOL_EXPORT_SHP",
}

export enum QuestionnaireMode {
  Create = "QUESTIONNAIRE_MODE_CREATE",
  Edit = "QUESTIONNAIRE_MODE_EDIT",
}

export enum Components {
  CommonIndex = "COMPONENT_COMMON_INDEX",
  CommonProfile = "COMPONENT_COMMON_PROFILE",
  CollaborativeMapController = "COMPONENT_COLLABORATIVE_MAP_CONTROLLER",
  ManagerManager = "COMPONENT_MANAGER_MANAGER",
  ManagerSite = "COMPONENT_MANAGER_SITE",
  ManagerSites = "COMPONENT_MANAGER_SITES",
  ManagerPwd = "COMPONENT_MANAGER_PWD",
  ManagerCreateUser = "COMPONENT_MANAGER_CREATE_USER",
  ManagerDeleteQuestionnaire = "COMPONENT_MANAGER_DELETE_QUESTIONNAIRE",
  ManagerDeleteQuestionnaireField = "COMPONENT_MANAGER_DELETE_QUESTIONNAIRE_FIELD",
  ManagerDeleteQuestionnaireFieldLabel = "COMPONENT_MANAGER_DELETE_QUESTIONNAIRE_FIELD_LABEL",
  FileManagerManagePermissions = "COMPONENT_FILEMANAGER_MANAGE_PERMISSIONS",
  ForumAddPost = "COMPONENT_FORUM_ADD_POST",
}

// Messages
export enum MessageType {
  Positive = "MESSAGE_TYPE_POSITIVE",
  Warning = "MESSAGE_TYPE_WARNING",
  Negative = "MESSAGE_TYPE_NEGATIVE",
}
