// React
import React from "react";

// Semantic ui
import { Grid, Header, Segment, List, Popup } from "semantic-ui-react";

// GraphQL
import { ADMIN_ASSIGN_UNASSIGN_MODULE_SITE } from "../../_GraphQL/mutations";
import { ADMIN_MODULE_SITE } from "../../_GraphQL/queries";

// Components
import EQuery from "../../../../utils/EQuery";
import { EditSegment } from "../../../../Common/components/EditSegment";

// Utils
import { ListItemType } from "../../../../Common/types/commonTypes";
import { ListItemState } from "../../../../utils/enums";
import { listItemSorter } from "../../../../utils/sorter";
import { Scalars, ModuleType } from "../../../../_GraphQL/typesDefinition";
import { AdminAssignUnassignModuleToSite } from "../../../../_GraphQL/mutationsDefinition";

// Message
import { addSuccessToastMessage } from "../../../../_GraphQL/message";

type FieldsProps = {
  enabled: boolean;
  moduleIn: ListItemType[];
  moduleOut: ListItemType[];
  initFields: () => void;
  addModule: (module: ListItemType) => void;
  removeModule: (module: ListItemType) => void;
};

class Fields extends React.Component<FieldsProps, {}> {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <Grid columns={2}>
        {this.props.enabled ? (
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Modules disponibles
              </Header>
              <Segment attached>
                <List selection>
                  {this.props.moduleOut.sort(listItemSorter).map((m) => (
                    <Popup
                      key={`popup-${m.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.props.addModule(m)}
                        >
                          {m.name}
                        </List.Item>
                      }
                      content="Ajouter"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" attached="top">
                Modules activés
              </Header>
              <Segment attached>
                <List selection>
                  {this.props.moduleIn.sort(listItemSorter).map((m) => (
                    <Popup
                      key={`popup-${m.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.props.removeModule(m)}
                        >
                          {m.name}
                        </List.Item>
                      }
                      content="Supprimer"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Modules activés
              </Header>
              <Segment attached>
                <List>
                  {this.props.moduleIn.sort(listItemSorter).map((m) => (
                    <List.Item key={`grp-${m.id}`}>{m.name}</List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

type SiteModulesProps = {
  toEditMode: () => void;
  toReadMode: () => void;
  data: Data;
  siteId: Scalars["ID"];
  editMode: boolean;
};

type SiteModulesState = {
  moduleIn: ListItemType[];
  moduleOut: ListItemType[];
};

class SiteModules extends React.Component<SiteModulesProps, SiteModulesState> {
  constructor(props: SiteModulesProps) {
    super(props);
    this.state = {
      moduleIn: [],
      moduleOut: [],
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    this.setState({
      moduleIn: this.props.data.adminAssignedModuleSet.map((m) => ({
        id: m.id,
        name: m.name,
        itemState: ListItemState.NoChange,
      })),
      moduleOut: this.props.data.adminAvailableModuleSet.map((m) => ({
        id: m.id,
        name: m.name,
        itemState: ListItemState.NoChange,
      })),
    });
  };

  addModule = (module: ListItemType) => {
    if (module.itemState === ListItemState.NoChange) {
      module.itemState = ListItemState.Add;
    }
    if (module.itemState === ListItemState.Remove) {
      module.itemState = ListItemState.NoChange;
    }
    this.setState({
      moduleIn: this.state.moduleIn.concat([module]),
      moduleOut: this.state.moduleOut.filter((m) => m.id !== module.id),
    });
  };

  removeModule = (module: ListItemType) => {
    if (module.itemState === ListItemState.NoChange) {
      module.itemState = ListItemState.Remove;
    }
    if (module.itemState === ListItemState.Add) {
      module.itemState = ListItemState.NoChange;
    }
    this.setState({
      moduleOut: this.state.moduleOut.concat([module]),
      moduleIn: this.state.moduleIn.filter((m) => m.id !== module.id),
    });
  };

  handleSubmit = (mutate: any) => {
    mutate({
      variables: {
        siteId: this.props.siteId,
        moduleToAssignSet: this.state.moduleIn
          .filter((m) => m.itemState === ListItemState.Add)
          .map((m) => m.id),
        moduleToUnassignSet: this.state.moduleOut
          .filter((m) => m.itemState === ListItemState.Remove)
          .map((m) => m.id),
      },
    });
  };

  update = (
    cache: any,
    {
      data: { adminAssignUnassignModuleToSite },
    }: {
      data: {
        adminAssignUnassignModuleToSite: AdminAssignUnassignModuleToSite;
      };
    }
  ) => {
    cache.writeQuery({
      query: ADMIN_MODULE_SITE,
      variables: { siteId: this.props.siteId },
      data: {
        adminAvailableModuleSet:
          adminAssignUnassignModuleToSite.availableModuleSet,
        adminAssignedModuleSet:
          adminAssignUnassignModuleToSite.assignedModuleSet,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Le modifications ont été enregistrées.",
    });
    this.toReadMode();
  };

  handleCancel = () => {
    this.props.toReadMode();
    this.initFields();
  };

  render() {
    return (
      <EditSegment
        title="Activation des Modules"
        buttonText="Modifier"
        mutation={ADMIN_ASSIGN_UNASSIGN_MODULE_SITE}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        handleCancel={this.handleCancel}
        toEditMode={this.props.toEditMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            moduleIn={this.state.moduleIn}
            moduleOut={this.state.moduleOut}
            enabled={enabled}
            initFields={this.initFields}
            addModule={this.addModule}
            removeModule={this.removeModule}
          />
        )}
      </EditSegment>
    );
  }
}

type SiteModulesQueryProps = {
  siteId: Scalars["ID"];
  editMode: boolean;
  toEditMode: () => void;
  toReadMode: () => void;
};

type Data = {
  adminAvailableModuleSet: [ModuleType];
  adminAssignedModuleSet: [ModuleType];
};

const SiteModulesQuery = (props: SiteModulesQueryProps) => {
  return (
    <EQuery query={ADMIN_MODULE_SITE} variables={{ siteId: props.siteId }}>
      {(data: Data) => (
        <SiteModules
          data={data}
          siteId={props.siteId}
          editMode={props.editMode}
          toEditMode={props.toEditMode}
          toReadMode={props.toReadMode}
        />
      )}
    </EQuery>
  );
};

export default SiteModulesQuery;
