// React
import React from "react";

// Semantic ui
import {
  Form,
  Checkbox,
  Grid,
  Button,
  Segment,
  Header,
} from "semantic-ui-react";

// GraphQL
import { UPDATE_LAYER } from "../../../_GraphQL/mutations";
import { GET_LAYER } from "../../../_GraphQL/queries";

// Components
import { EditSegment } from "../../../../../Common/components/EditSegment";
import IconPicker from "./IconPicker";
import ColorPicker, { ColorDisplayer } from "./ColorPicker";
import { StyleSelector } from "../../../../../Common/components/StyleSelector";

import { COLLABORATIVE_MAP_GEOMETRY_TYPES } from "../../../../../utils/Constants";
// Message
import { addSuccessToastMessage } from "../../../../../_GraphQL/message";
import { Preview } from "../../Styles/Preview/Preview";

function StyleField({ label, style, setStyle, enabled, geoTypeCode }) {
  return (
    <Form.Field>
      <label>{label}</label>
      <Grid>
        <Grid.Column width={2} verticalAlign="middle" textAlign="center">
          {style ? <Preview style={style} geoTypeCode={geoTypeCode} /> : null}
        </Grid.Column>
        <Grid.Column width={1} verticalAlign="middle" textAlign="right">
          {style && enabled ? (
            <Button
              icon="delete"
              type="button"
              onClick={() => setStyle(null)}
              disabled={!enabled}
            />
          ) : null}
        </Grid.Column>
        <Grid.Column width={6} verticalAlign="middle">
          {enabled ? (
            <StyleSelector
              initStyle={style}
              setStyle={setStyle}
              geoTypeCode={geoTypeCode}
            />
          ) : null}
        </Grid.Column>
      </Grid>
    </Form.Field>
  );
}

const ColorField = ({ label, color, reset, onChange, enabled }) => (
  <Form.Field>
    <label>{label}</label>
    <Grid>
      <Grid.Column width={2} verticalAlign="middle" textAlign="center">
        {color ? (
          <ColorDisplayer
            color={color}
            marginTop={0}
            padding={10}
            height={10}
            width={100}
          />
        ) : (
          "Couleur par défaut"
        )}
      </Grid.Column>
      <Grid.Column width={1} verticalAlign="middle" textAlign="right">
        {color && enabled ? (
          <Button
            icon="delete"
            type="button"
            onClick={reset}
            disabled={!enabled}
          />
        ) : null}
      </Grid.Column>
      <Grid.Column width={6} verticalAlign="middle">
        {enabled ? (
          <ColorPicker type="color" color={color} onChange={onChange} />
        ) : null}
      </Grid.Column>
    </Grid>
  </Form.Field>
);

const IconField = ({ label, icon, reset, pickIcon, enabled }) => (
  <Form.Field>
    <label>{label}</label>
    <Grid>
      <Grid.Column width={2} verticalAlign="middle" textAlign="center">
        {icon ? (
          <img
            width="20"
            height="20"
            src={`/static/back/resources/media/markers/${icon}`}
            alt={`${icon}`}
          />
        ) : (
          "Icône par défaut"
        )}
      </Grid.Column>
      <Grid.Column width={1} verticalAlign="middle" textAlign="right">
        {icon && enabled ? (
          <Button icon="delete" type="button" onClick={reset} />
        ) : null}
      </Grid.Column>
      <Grid.Column width={6} verticalAlign="middle">
        {enabled ? <IconPicker pickIcon={pickIcon} /> : null}
      </Grid.Column>
    </Grid>
  </Form.Field>
);

export { IconField, ColorField, StyleField };

class Fields extends React.Component {
  componentDidMount() {
    this.props.initFields();
  }

  render() {
    return (
      <>
        <Form.Field width={6} required>
          <label>Numéro (pour ordre d'affichage)</label>
          <input
            type="number"
            required
            value={this.props.position}
            onChange={this.props.onChangePosition}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field width={6}>
          <label>Nom</label>
          <input
            placeholder="Nom"
            required
            value={this.props.name}
            onChange={this.props.onChangeName}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="Visible par défaut"
            checked={this.props.visibleByDefault}
            onChange={this.props.onVisibleByDefaultChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="Créateur visible"
            checked={this.props.creatorVisible}
            onChange={this.props.onCreatorVisibleChange}
            disabled={!this.props.enabled}
          />
        </Form.Field>
        {this.props.editable ? (
          <>
            <Form.Field>
              <Checkbox
                label="Restrictions entre utilisateurs"
                checked={this.props.restrictionsBetweenUsers}
                onChange={this.props.onRestrictionsBetweenUsersChange}
                disabled={!this.props.enabled}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Autoriser les contributions multiples à une même géométrie"
                checked={this.props.multipleFilled}
                onChange={this.props.onMultipleFilledChange}
                disabled={!this.props.enabled}
              />
            </Form.Field>
          </>
        ) : null}

        {this.props.point ? (
          <>
            <Header as="h5" attached="top">
              Aspect des marqueurs (points)
            </Header>
            <Segment attached="bottom">
              <IconField
                label="Picto"
                icon={this.props.marker}
                enabled={this.props.enabled}
                reset={this.props.resetIcon}
                pickIcon={this.props.pickIcon}
              />
              <ColorField
                label="Couleur"
                color={this.props.markerColor}
                enabled={this.props.enabled}
                reset={this.props.resetMarkerColor}
                onChange={this.props.onMarkerColorChange}
              />
            </Segment>
          </>
        ) : null}
        {this.props.line ? (
          <>
            <Header as="h5" attached="top">
              Aspect des lignes
            </Header>
            <Segment attached="bottom">
              <ColorField
                label="Couleur"
                color={this.props.lineColor}
                enabled={this.props.enabled}
                reset={this.props.resetLineColor}
                onChange={this.props.onLineColorChange}
              />
              <StyleField
                label="Style"
                style={this.props.lineStyle}
                enabled={this.props.enabled}
                setStyle={this.props.setLineStyle}
                geoTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE}
              />
            </Segment>
          </>
        ) : null}
        {this.props.poly ? (
          <>
            <Header as="h5" attached="top">
              Aspect des polygones
            </Header>
            <Segment attached="bottom">
              <ColorField
                label="Couleur"
                color={this.props.polyColor}
                enabled={this.props.enabled}
                reset={this.props.resetPolyColor}
                onChange={this.props.onPolyColorChange}
              />
              <StyleField
                label="Style"
                style={this.props.polyStyle}
                enabled={this.props.enabled}
                setStyle={this.props.setPolyStyle}
                geoTypeCode={COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON}
              />
            </Segment>
          </>
        ) : null}
      </>
    );
  }
}

class LayerInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      visibleByDefault: false,
      creatorVisible: true,
      restrictionsBetweenUsers: false,
      multipleFilled: false,
      marker: "",
      markerColor: "",
      lineColor: "",
      lineStyle: null,
      polyColor: "",
      polyStyle: null,
      position: 1,
    };
  }

  toReadMode = () => this.props.toReadMode();

  initFields = () => {
    this.setState({
      name: this.props.layer.name,
      visibleByDefault: this.props.layer.visibleByDefault,
      creatorVisible: this.props.layer.creatorVisible,
      restrictionsBetweenUsers: this.props.layer.restrictionsBetweenUsers,
      multipleFilled: this.props.layer.multipleFilled,
      marker: this.props.layer.marker,
      markerColor: this.props.layer.markerColor || "",
      lineColor: this.props.layer.lineColor || "",
      lineStyle: this.props.layer.lineStyle,
      polyColor: this.props.layer.polyColor || "",
      polyStyle: this.props.layer.polyStyle,
      position: this.props.layer.position || 1,
    });
  };

  onChangePosition = (e) => this.setState({ position: e.target.value });

  onChangeName = (e) => this.setState({ name: e.target.value });

  onVisibleByDefaultChange = (e, { checked }) =>
    this.setState({ visibleByDefault: checked });

  onCreatorVisibleChange = (e, { checked }) =>
    this.setState({ creatorVisible: checked });

  onRestrictionsBetweenUsersChange = (e, { checked }) =>
    this.setState({ restrictionsBetweenUsers: checked });

  onMultipleFilledChange = (e, { checked }) =>
    this.setState({ multipleFilled: checked });

  setLineStyle = (style) => this.setState({ lineStyle: style });

  setPolyStyle = (style) => this.setState({ polyStyle: style });

  handleSubmit = (mutate) => {
    let variables = {
      position:
        this.state.position === undefined || this.state.position === null
          ? null
          : parseInt(this.state.position, 10),
      layerId: this.props.layer.id,
      name: this.state.name,
      visibleByDefault: this.state.visibleByDefault,
      creatorVisible: this.state.creatorVisible,
      restrictionsBetweenUsers: this.state.restrictionsBetweenUsers,
      multipleFilled: this.state.multipleFilled,
    };

    if (this.state.marker !== "") {
      variables.marker = this.state.marker;
    }
    if (this.state.markerColor !== "") {
      variables.markerColor = this.state.markerColor;
    }
    if (this.state.lineColor !== "") {
      variables.lineColor = this.state.lineColor;
    }
    if (this.state.lineStyle) {
      variables.lineStyleId = this.state.lineStyle.id;
    }
    if (this.state.polyColor !== "") {
      variables.polyColor = this.state.polyColor;
    }
    if (this.state.polyStyle) {
      variables.polyStyleId = this.state.polyStyle.id;
    }

    mutate({
      variables: variables,
    });
  };

  update = (cache, { data: { updateLayer } }) => {
    let data = cache.readQuery({
      query: GET_LAYER,
      variables: { layerId: this.props.layer.id },
    });

    cache.writeQuery({
      query: GET_LAYER,
      variables: { layerId: this.props.layer.id },
      data: {
        ...data,
        layer: updateLayer.layer,
      },
    });
  };

  onCompleted = () => {
    addSuccessToastMessage({
      header: "Confirmation enregistrement",
      content: "Les informations ont été mises à jour.",
    });
    this.toReadMode();
  };

  pickIcon = (icon) => this.setState({ marker: icon });

  resetIcon = () => this.setState({ marker: "" });

  onMarkerColorChange = (color) => this.setState({ markerColor: color.hex });

  resetMarkerColor = () => this.setState({ markerColor: "" });

  onLineColorChange = (color) => this.setState({ lineColor: color.hex });

  resetLineColor = () => this.setState({ lineColor: "" });

  onPolyColorChange = (color) => this.setState({ polyColor: color.hex });

  resetPolyColor = () => this.setState({ polyColor: "" });

  render() {
    return (
      <EditSegment
        title="Informations"
        buttonText="Modifier"
        mutation={UPDATE_LAYER}
        onCompleted={this.onCompleted}
        update={this.update}
        handleSubmit={this.handleSubmit}
        toEditMode={this.props.toEditMode}
        handleCancel={this.props.toReadMode}
        editMode={this.props.editMode}
      >
        {(enabled) => (
          <Fields
            position={this.state.position}
            onChangePosition={this.onChangePosition}
            name={this.state.name}
            editable={this.props.layer.editable}
            enabled={enabled}
            initFields={this.initFields}
            onChangeName={this.onChangeName}
            marker={this.state.marker}
            pickIcon={this.pickIcon}
            resetIcon={this.resetIcon}
            markerColor={this.state.markerColor}
            onMarkerColorChange={this.onMarkerColorChange}
            resetMarkerColor={this.resetMarkerColor}
            lineColor={this.state.lineColor}
            onLineColorChange={this.onLineColorChange}
            resetLineColor={this.resetLineColor}
            polyColor={this.state.polyColor}
            onPolyColorChange={this.onPolyColorChange}
            resetPolyColor={this.resetPolyColor}
            visibleByDefault={this.state.visibleByDefault}
            onVisibleByDefaultChange={this.onVisibleByDefaultChange}
            creatorVisible={this.state.creatorVisible}
            onCreatorVisibleChange={this.onCreatorVisibleChange}
            restrictionsBetweenUsers={this.state.restrictionsBetweenUsers}
            multipleFilled={this.state.multipleFilled}
            onRestrictionsBetweenUsersChange={
              this.onRestrictionsBetweenUsersChange
            }
            onMultipleFilledChange={this.onMultipleFilledChange}
            lineStyle={this.state.lineStyle}
            setLineStyle={this.setLineStyle}
            polyStyle={this.state.polyStyle}
            setPolyStyle={this.setPolyStyle}
            point={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.POINT
            )}
            line={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code === COLLABORATIVE_MAP_GEOMETRY_TYPES.LINE
            )}
            poly={this.props.layer.layergeometrySet.find(
              (lfs) =>
                lfs.geometryType.code ===
                COLLABORATIVE_MAP_GEOMETRY_TYPES.POLYGON
            )}
          />
        )}
      </EditSegment>
    );
  }
}

export default LayerInfos;
