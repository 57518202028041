// React
import React from "react";

// React Router
import { Link } from "react-router-dom";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_LAYER } from "../../_GraphQL/queries";

// Semantic ui
import {
  Breadcrumb,
  Header,
  Grid,
  Segment,
  Button,
  Loader,
} from "semantic-ui-react";

// Components
import ManagerLayout from "../../components/ManagerLayout";
import LayerInfos from "../../components/CollaborativeMap/Layer/LayerInfos";
import LayerGeometries from "../../components/CollaborativeMap/Layer/LayerGeometries";
import LayerGroupsQuery from "../../components/CollaborativeMap/Layer/LayerGroups";
import LayerUsersQuery from "../../components/CollaborativeMap/Layer/LayerUsers";
import LayerGroupToolsQuery from "../../components/CollaborativeMap/Layer/LayerGroupTools";
import LayerUserToolsQuery from "../../components/CollaborativeMap/Layer/LayerUserTools";
import LayerQuestionnaireQuery from "../../components/CollaborativeMap/Layer/LayerQuestionnaire";
import LayerGroupRestrBtwUsersPerm from "../../components/CollaborativeMap/Layer/LayerGroupRestrBtwUsersPerm";
import LayerUserRestrBtwUsersPerm from "../../components/CollaborativeMap/Layer/LayerUserRestrBtwUsersPerm";
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import { SITE_NAMES } from "../../../../utils/Constants";
class Layer extends React.Component {
  state = {
    editMode: false,
  };

  toReadMode = () => this.setState({ editMode: false });

  toEditMode = () => this.setState({ editMode: true });

  render() {
    const { layer, layerGroupToolSet } = this.props;
    return (
      <ManagerLayout
        breadcrumbItems={[
          <Breadcrumb.Section key="breadcrumb-home">
            <Link to="/manager">Manager</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-sites" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-sites">
            <Link to="/manager/sites">Sites</Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-site" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-site">
            <Link to={`/manager/site/${layer.task.project.site.id}`}>
              Site : {layer.task.project.site.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-project" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-project">
            <Link to={`/manager/projet/${layer.task.project.id}`}>
              Projet : {layer.task.project.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-module" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-module">
            <Link to={`/manager/carte/?projectId=${layer.task.project.id}`}>
              Module : Carte participative
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-task" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-task">
            <Link to={`/manager/tache/${layer.task.id}`}>
              Tâche : {layer.task.name}
            </Link>
          </Breadcrumb.Section>,
          <Breadcrumb.Divider key="bc-divider-layer" icon="right angle" />,
          <Breadcrumb.Section key="breadcrumb-layer">
            Couche : {layer.name}
          </Breadcrumb.Section>,
        ]}
      >
        <Header as="h5" block>
          {`Couche ${layer.editable ? "participative" : "informative"} : ${
            layer.name
          }`}
        </Header>
        {layer.editable ? (
          <>
            <LayerInfos
              layer={layer}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <LayerGeometries
              layer={layer}
              layerGroupToolSet={layerGroupToolSet}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
              ici={layer.task.project.site.name === SITE_NAMES.ICI}
            />

            <LayerGroupsQuery
              layer={layer}
              layerGroupToolSet={layerGroupToolSet}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <LayerUsersQuery
              layer={layer}
              projectId={layer.task.project.id}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <LayerGroupToolsQuery
              layer={layer}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <LayerUserToolsQuery
              layer={layer}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <LayerQuestionnaireQuery
              layer={layer}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <Segment>
              <Header as="h5">
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column verticalAlign="middle" width={4}>
                      Import
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="right">
                      {!this.state.editMode ? (
                        <Button
                          primary
                          floated="right"
                          as={Link}
                          to={`/manager/couche/${layer.id}/import`}
                        >
                          Importer une couche
                        </Button>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Header>
            </Segment>

            {layer.restrictionsBetweenUsers ? (
              <>
                <LayerGroupRestrBtwUsersPerm
                  layer={layer}
                  editMode={this.state.editMode}
                  toEditMode={this.toEditMode}
                  toReadMode={this.toReadMode}
                />
                <LayerUserRestrBtwUsersPerm
                  layer={layer}
                  editMode={this.state.editMode}
                  toEditMode={this.toEditMode}
                  toReadMode={this.toReadMode}
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            <LayerInfos
              layer={layer}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <LayerGeometries
              layer={layer}
              layerGroupToolSet={layerGroupToolSet}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <LayerGroupsQuery
              layer={layer}
              layerGroupToolSet={layerGroupToolSet}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />

            <Segment>
              <Header as="h5">
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column verticalAlign="middle" width={4}>
                      Import
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="right">
                      {!this.state.editMode ? (
                        <Button
                          primary
                          floated="right"
                          as={Link}
                          to={`/manager/couche/${layer.id}/import`}
                        >
                          Importer une couche
                        </Button>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Header>
            </Segment>

            <LayerUsersQuery
              layer={layer}
              projectId={layer.task.project.id}
              editMode={this.state.editMode}
              toEditMode={this.toEditMode}
              toReadMode={this.toReadMode}
            />
          </>
        )}
      </ManagerLayout>
    );
  }
}

function LayerQuery({ match }) {
  const { loading, error, data } = useQuery(GET_LAYER, {
    variables: { layerId: match.params.layerId },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return (
    <Layer
      layer={data.layer}
      layerGroupToolSet={data.adminGroupWithAssignedToolPermissionSetSet}
    />
  );
}

export { LayerQuery as Layer };
