/// React
import React, { useState } from "react";

// Semantic ui
import { Button, Modal, Icon, Dimmer, Loader } from "semantic-ui-react";

// GraphQL
import { useMutation } from "@apollo/client";
import { ADMIN_DELETE_QUESTIONNAIRE_FIELD } from "../../_GraphQL/mutations";

// Message
import { ErrorMessage } from "../../../../Common/components/ErrorMessage";

// Types
import { Scalars } from "../../../../_GraphQL/typesDefinition";

type RefType = {
  __ref: Scalars["ID"];
};

type DeleteQuestionnaireFieldProps = {
  questionnaireId: Scalars["ID"];
  questionnaireFieldId: Scalars["ID"];
  questionnaireFieldName: string;
};

function DeleteQuestionnaireField({
  questionnaireId,
  questionnaireFieldId,
  questionnaireFieldName,
}: DeleteQuestionnaireFieldProps) {
  const [modalOpen, setModalOpen] = useState(false);

  function handleCancel() {
    setModalOpen(false);
  }

  const [deleteQuestionnaire, { loading, error }] = useMutation(
    ADMIN_DELETE_QUESTIONNAIRE_FIELD,
    {
      update(cache) {
        cache.modify({
          id: `QuestionnaireType:${questionnaireId}`,
          fields: {
            questionnairefieldSet(
              existingQuestionnaireFieldRefs,
              { readField }
            ) {
              return existingQuestionnaireFieldRefs.filter(
                (questionnaireFieldRef: RefType) =>
                  questionnaireFieldId !==
                  readField("id", questionnaireFieldRef)
              );
            },
          },
        });
      },
      onCompleted() {
        handleCancel();
      },
      onError(_) {
        // Nécessaire pour évite les crash de type unhandle rejection, mais
        // On ne fait rien car on utilise l'état "error" fournit par useMutation
        // Pour afficher les erreurs.
      },
    }
  );

  return (
    <>
      <Icon
        name={"delete"}
        className="clickable"
        onClick={() => setModalOpen(true)}
      />
      <Modal open={modalOpen} onCancel={handleCancel}>
        <Modal.Content>
          {loading ? (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          ) : null}
          {error ? <ErrorMessage error={error} /> : null}
          {error ? null : (
            <p>
              Confirmez-vous la suppression du champ {questionnaireFieldName} ?
            </p>
          )}
          <Button content="Annuler" onClick={handleCancel} />
          {error ? null : (
            <Button
              positive
              content="Supprimer"
              onClick={() =>
                deleteQuestionnaire({
                  variables: {
                    questionnaireId: questionnaireId,
                    questionnaireFieldId: questionnaireFieldId,
                  },
                })
              }
            />
          )}
        </Modal.Content>
      </Modal>
    </>
  );
}

export { DeleteQuestionnaireField };
