import React, { useState, useEffect } from "react";
import { Form, Segment } from "semantic-ui-react";

function PathShapeForm({ setShapePathOptions, initShapeOptions }) {
  const [shapePathOptionD, setShapePathOptionD] = useState(null); //: {path} (default: null) - The SVG path definition.

  // Initialisation
  useEffect(() => {
    if (initShapeOptions) {
      if (initShapeOptions.hasOwnProperty("d")) {
        setShapePathOptionD(initShapeOptions.d);
      }
    }
  }, [initShapeOptions]);

  useEffect(() => {
    setShapePathOptions({
      d: shapePathOptionD,
    });
  }, [shapePathOptionD, setShapePathOptions]);

  return (
    <Segment secondary attached="bottom">
      <Form.Field>
        <label htmlFor="shape-path-opt-d">Chemin SVG</label>
        <input
          id="shape-path-opt-d"
          type="text"
          value={shapePathOptionD}
          onChange={(e) => setShapePathOptionD(e.target.value)}
        />
      </Form.Field>
    </Segment>
  );
}

export { PathShapeForm };
