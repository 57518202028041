// React
import React from "react";

// Semantic ui
import { Container } from "semantic-ui-react";

// React Router
import { Switch } from "react-router";
import PrivateRoute from "../../../utils/Privateroute";

// Component
import { Home } from "../pages/Dashboard";

// Users
import { Users } from "../pages/Users/Users";
import { User } from "../pages/Users/User";
import { CreateUser } from "../pages/Users/CreateUser";

// File Manager
import { FileManager } from "../pages/FileManager";

// Draw
import { Draw } from "../pages/Draw/Draw";
import { Drawing } from "../pages/Draw/Drawing";
import { CreateDrawing } from "../pages/Draw/CreateDrawing";

// Collaborative Map
import { CollaborativeMap } from "../pages/CollaborativeMap/CollaborativeMap";
import { CreateTask } from "../pages/CollaborativeMap/CreateTask";
import { Task } from "../pages/CollaborativeMap/Task";
import { Layer } from "../pages/CollaborativeMap/Layer";
import { CreateLayer } from "../pages/CollaborativeMap/CreateLayer";
import { ImportLayer } from "../pages/CollaborativeMap/ImportLayer";
import { Project } from "../pages/Project/Project";
import { CreateProject } from "../pages/Project/CreateProject";
import ProjectQuery from "../components/Project/ProjectQuery";

import { SiteList } from "../pages/Site/SiteList";
import { Site } from "../pages/Site/Site";
import SiteQuery from "../components/Site/SiteQuery";

import { QuestionnaireSet } from "../pages/Questionnaire/QuestionnaireSet";
import { Questionnaire } from "../pages/Questionnaire/Questionnaire";
import { CreateQuestionnaire } from "../pages/Questionnaire/CreateQuestionnaire";
import QuestionnaireFieldEntry, {
  QuestionnaireFieldQuery,
} from "../pages/Questionnaire/QuestionnaireField";
import CreateQuestionnaireFieldEntry, {
  CreateQuestionnaireFieldQuery,
} from "../pages/Questionnaire/CreateQuestionnaireField";
import QuestionnaireFieldLabelEntry, {
  QuestionnaireFieldLabelQuery,
} from "../pages/Questionnaire/QuestionnaireFieldLabel";
import CreateQuestionnaireFieldLabelEntry, {
  CreateQuestionnaireFieldLabelQuery,
} from "../pages/Questionnaire/CreateQuestionnaireFieldLabel";
import { Styles } from "../routes/Styles/Styles";

const Manager = () => (
  <>
    <Container>
      <Switch>
        {/* Accueil */}
        <PrivateRoute exact path="/manager" component={Home} />

        {/* Utilisateurs */}
        <PrivateRoute path="/manager/utilisateurs" component={Users} />

        <PrivateRoute
          path="/manager/utilisateur/:userId(\d+)"
          component={User}
        />

        <PrivateRoute
          path="/manager/utilisateur/creation/"
          component={CreateUser}
        />

        {/* Sites */}
        <PrivateRoute path="/manager/sites" component={SiteList} />

        {/* Site */}
        <PrivateRoute
          path="/manager/site/:siteId"
          component={(props) => (
            <SiteQuery
              {...props}
              render={(nextProps) => <Site {...nextProps} />}
            />
          )}
        />

        {/* Project */}
        <PrivateRoute
          path="/manager/projet/:projectId(\d+)"
          component={(props) => (
            <ProjectQuery
              {...props}
              render={(nextProps) => <Project {...nextProps} />}
            />
          )}
        />

        <PrivateRoute
          path="/manager/projet/creation/:siteId"
          component={(props) => (
            <SiteQuery
              {...props}
              render={(nextProps) => <CreateProject {...nextProps} />}
            />
          )}
        />

        {/* Tâche */}
        <PrivateRoute path="/manager/tache/:taskId(\d+)" component={Task} />

        <PrivateRoute
          path="/manager/tache/creation/:projectId"
          component={(props) => (
            <ProjectQuery
              {...props}
              render={(nextProps) => <CreateTask {...nextProps} />}
            />
          )}
        />

        {/* Couche */}

        <PrivateRoute
          path="/manager/couche/creation/:taskId(\d+)"
          component={CreateLayer}
        />

        <PrivateRoute
          path="/manager/couche/:layerId(\d+)"
          exact
          component={Layer}
        />

        <PrivateRoute
          path="/manager/couche/:layerId(\d+)/import"
          exact
          component={ImportLayer}
        />

        <PrivateRoute
          path="/manager/couche/:layerId(\d+)/questionnaire/nouveau"
          component={CreateQuestionnaire}
        />

        <PrivateRoute
          path="/manager/couche/:layerId(\d+)/questionnaire/:questionnaireId(\d+)"
          exact
          component={Questionnaire}
        />

        <PrivateRoute
          path="/manager/couche/:layerId(\d+)/questionnaire/:questionnaireId(\d+)/champ/nouveau"
          exact
          component={CreateQuestionnaireFieldEntry}
        />

        <PrivateRoute
          path="/manager/couche/:layerId(\d+)/questionnaire/:questionnaireId(\d+)/champ/:questionnaireFieldId(\d+)"
          exact
          component={QuestionnaireFieldEntry}
        />

        {/* Création d'un label pour une question depuis la gestion d'une couche */}
        <PrivateRoute
          path="/manager/couche/:layerId(\d+)/questionnaire/:questionnaireId(\d+)/champ/:questionnaireFieldId(\d+)/label/nouveau"
          exact
          component={CreateQuestionnaireFieldLabelEntry}
        />

        <PrivateRoute
          path="/manager/couche/:layerId(\d+)/questionnaire/:questionnaireId(\d+)/champ/:questionnaireFieldId(\d+)/label/:questionnaireFieldLabelId(\d+)"
          exact
          component={QuestionnaireFieldLabelEntry}
        />

        {/* Dessin */}
        <PrivateRoute path="/manager/dessin/" exact component={Draw} />

        <PrivateRoute
          path="/manager/dessin/dessin/:drawingId(\d+)"
          component={Drawing}
        />

        <PrivateRoute
          path="/manager/dessin/creation/:projectId"
          component={(props) => (
            <ProjectQuery
              {...props}
              render={(nextProps) => <CreateDrawing {...nextProps} />}
            />
          )}
        />

        {/* Gestionnaire de fichiers */}
        <PrivateRoute path="/manager/fichiers/" component={FileManager} />

        {/* Carte Participative */}
        <PrivateRoute path="/manager/carte/" component={CollaborativeMap} />

        {/* Questionnaire */}
        <PrivateRoute
          path="/manager/questionnaires/"
          component={QuestionnaireSet}
        />

        <PrivateRoute
          path="/manager/questionnaire/nouveau"
          exact
          component={CreateQuestionnaire}
        />

        <PrivateRoute
          path="/manager/questionnaire/:questionnaireId(\d+)"
          exact
          component={Questionnaire}
        />

        <PrivateRoute
          path="/manager/questionnaire/:questionnaireId(\d+)/champ/nouveau"
          exact
          component={CreateQuestionnaireFieldQuery}
        />

        <PrivateRoute
          path="/manager/questionnaire/:questionnaireId(\d+)/champ/:questionnaireFieldId(\d+)"
          exact
          component={QuestionnaireFieldQuery}
        />

        {/* Création d'un label pour une question depuis la gestion d'un questionnaire */}
        <PrivateRoute
          path="/manager/questionnaire/:questionnaireId(\d+)/champ/:questionnaireFieldId(\d+)/label/nouveau"
          exact
          component={CreateQuestionnaireFieldLabelQuery}
        />

        <PrivateRoute
          path="/manager/questionnaire/:questionnaireId(\d+)/champ/:questionnaireFieldId(\d+)/label/:questionnaireFieldLabelId(\d+)"
          exact
          component={QuestionnaireFieldLabelQuery}
        />
      </Switch>
    </Container>
    <Styles />
  </>
);

export default Manager;
