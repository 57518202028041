// React
import React from "react";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_PROJECT } from "../../../../Common/_GraphQL/queries";

// Semantic ui
import { Loader } from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";

function ProjectQuery({ match, render, ...rest }) {
  const { loading, error, data, refetch } = useQuery(GET_PROJECT, {
    variables: { projectId: match.params.projectId },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return render({
    ...rest,
    project: data.project,
    refetch: refetch,
  });
}

export default ProjectQuery;
