// React
import React, { useState, useEffect } from "react";

// React Router
import { Link } from "react-router-dom";
import {
  useQueryParam,
  StringParam,
  BooleanParam,
  NumberParam,
} from "use-query-params";

// GraphQL
import { useQuery } from "@apollo/client";
import { GET_LISTS_FOR_FILTERS } from "../../_GraphQL/queries";

// Semantic ui
import {
  Loader,
  Button,
  Divider,
  Breadcrumb,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";

// Components
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import ManagerLayout from "../../components/ManagerLayout";
import { UserList, NAV_BUTTON } from "../../components/Users/UserList";
import { UsersFilter } from "../../components/Users/UsersFilter";

function Users({ listForFilters }) {
  const [active, setActive] = useQueryParam("active", BooleanParam);
  const [sortColumn, setSortColumn] = useQueryParam("sort_column", StringParam);
  const [sortDirection, setSortDirection] = useQueryParam(
    "sort_direction",
    StringParam
  );
  const [page, setPage] = useQueryParam("page", NumberParam);
  const [siteId, setSiteId] = useQueryParam("site_id", StringParam);
  const [projectId, setProjectId] = useQueryParam("project_id", StringParam);
  const [groupId, setGroupId] = useQueryParam("group_id", StringParam);
  const [nbPage, setNbPage] = useQueryParam("nb_page", NumberParam);
  const [search, setSearch] = useState("");
  const [projectSet, setProjectSet] = useState([]);

  useEffect(() => {
    if (active === undefined || active === null) {
      setActive(true);
    }
    if (sortColumn === undefined || sortColumn === null) {
      setSortColumn("first_name");
    }
    if (sortDirection === undefined || sortDirection === null) {
      setSortDirection("asc");
    }
    if (page === undefined || page === null) {
      setPage(1);
    }
    if (nbPage === undefined || nbPage === null) {
      setNbPage(1);
    }
  }, [
    active,
    setActive,
    sortColumn,
    setSortColumn,
    sortDirection,
    setSortDirection,
    page,
    setPage,
    nbPage,
    setNbPage,
  ]);

  useEffect(() => {
    if (
      nbPage !== null &&
      page !== null &&
      nbPage !== undefined &&
      page !== undefined
    ) {
      if (nbPage < 2) {
        setPage(1);
      } else if (page > nbPage) {
        setPage(nbPage);
      }
    }
  }, [nbPage, page, setPage]);

  useEffect(() => {
    if (!siteId) {
      setProjectId(null);
      setProjectSet([]);
    } else {
      const site = listForFilters.siteSet.find((s) => s.id === siteId);
      setProjectSet(site.projectSet);
      if (site.projectSet.length === 1) {
        setProjectId(site.projectSet[0].id);
      }
    }
  }, [siteId, setProjectId, listForFilters.siteSet]);

  function firstNameClicked() {
    if (sortColumn === "first_name") {
      const dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(dir);
    } else {
      setSortColumn("first_name");
    }
  }

  function emailClicked() {
    if (sortColumn === "email") {
      const dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(dir);
    } else {
      setSortColumn("email");
    }
  }

  function handleNavClick(button) {
    if (button === NAV_BUTTON.FIRST) {
      setPage(1);
    } else if (button === NAV_BUTTON.PREV && page > 1) {
      setPage(page - 1);
    } else if (button === NAV_BUTTON.LAST) {
      setPage(nbPage);
    } else if (button === NAV_BUTTON.NEXT && page < nbPage) {
      setPage(page + 1);
    }
  }

  return (
    <ManagerLayout
      breadcrumbItems={[
        <Breadcrumb.Section key="breadcrumb-home">
          <Link to="/manager">Manager</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key={`bc-divider-sites`} icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-sites">
          Utilisateurs
        </Breadcrumb.Section>,
      ]}
    >
      <Segment secondary>
        <Header as="h5">
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column verticalAlign="middle">
                Liste des utilisateurs
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Link to="/manager/utilisateur/creation">
                  <Button primary>Ajouter un utilisateur</Button>
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <Divider />

        <UsersFilter
          active={active}
          toggleActive={(_, { checked }) => setActive(checked)}
          listForFilters={listForFilters}
          groupId={groupId}
          handleGroupChange={(_, { value }) => setGroupId(value)}
          siteId={siteId}
          handleSiteChange={(_, { value }) => {
            setSiteId(value);
            setProjectId(null);
          }}
          projectId={projectId}
          projectSet={projectSet}
          handleProjectChange={(_, { value }) => setProjectId(value)}
          handleSearchChange={(e) => setSearch(e.target.value)}
        />

        {active === null || active === undefined ? null : (
          <UserList
            active={active}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            firstNameClicked={firstNameClicked}
            emailClicked={emailClicked}
            groupId={groupId}
            siteId={siteId}
            projectId={projectId}
            search={search}
            nbPage={nbPage}
            page={page}
            setNbPage={setNbPage}
            handleNavClick={handleNavClick}
            setPage={setPage}
          />
        )}
      </Segment>
    </ManagerLayout>
  );
}

function UsersQuery() {
  const { loading, error, data } = useQuery(GET_LISTS_FOR_FILTERS);

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  return <Users listForFilters={data} />;
}

export { UsersQuery as Users };
