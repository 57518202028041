// React
import React from "react";

// React Router
import { withRouter } from "react-router-dom";

// GraphQL
import EMutation from "../../../../../utils/EMutation";
import EQuery from "../../../../../utils/EQuery";
import { GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_TASK } from "../../../_GraphQL/queries";
import { ASSIGN_UNASSIGN_GROUP_TO_TASK } from "../../../_GraphQL/mutations";

// Semantic ui
import {
  Button,
  List,
  Header,
  Popup,
  Form,
  Icon,
  Grid,
  Segment,
} from "semantic-ui-react";

const groupStates = {
  noChange: 0,
  add: 1,
  remove: 2,
};

class AddRemoveAutorisations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupIn: this.props.data.adminAssignedGroupForTaskSet.map((g) => ({
        id: g.id,
        name: g.name,
        groupState: groupStates.noChange,
      })),
      groupOut: this.props.data.adminAvailableGroupForTaskSet.map((g) => ({
        id: g.id,
        name: g.name,
        groupState: groupStates.noChange,
      })),
    };
  }

  handleSubmit = (mutate) => {
    let variables = {
      taskId: this.props.task.id,
      groupsToAssign: this.state.groupIn
        .filter((g) => g.groupState === groupStates.add)
        .map((g) => g.id),
      groupsToUnassign: this.state.groupOut
        .filter((g) => g.groupState === groupStates.remove)
        .map((g) => g.id),
    };

    mutate.call(this, { variables });
  };

  addGroup = (group) => {
    if (group.groupState === groupStates.noChange) {
      group.groupState = groupStates.add;
    }

    if (group.groupState === groupStates.remove) {
      group.groupState = groupStates.noChange;
    }

    this.setState({
      groupIn: this.state.groupIn.concat([group]),
      groupOut: this.state.groupOut.filter((g) => g.id !== group.id),
    });
  };

  removeGroup = (group) => {
    if (group.groupState === groupStates.noChange) {
      group.groupState = groupStates.remove;
    }

    if (group.groupState === groupStates.add) {
      group.groupState = groupStates.noChange;
    }

    this.setState({
      groupOut: this.state.groupOut.concat([group]),
      groupIn: this.state.groupIn.filter((g) => g.id !== group.id),
    });
  };

  render() {
    return (
      <div>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header as="h5" attached="top">
                Groupes non autorisés
              </Header>
              <Segment attached>
                <List selection>
                  {this.state.groupOut.map((g) => (
                    <Popup
                      key={`popup-${g.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.addGroup(g)}
                        >
                          {g.name}
                        </List.Item>
                      }
                      content="Ajouter"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header as="h5" attached="top">
                Groupes autorisés
              </Header>
              <Segment attached>
                <List selection>
                  {this.state.groupIn.map((g) => (
                    <Popup
                      key={`popup-${g.id}`}
                      trigger={
                        <List.Item
                          className="clickable"
                          onClick={() => this.removeGroup(g)}
                        >
                          {g.name}
                        </List.Item>
                      }
                      content="Supprimer"
                    />
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <EMutation
          mutation={ASSIGN_UNASSIGN_GROUP_TO_TASK}
          update={(cache, { data: { adminAssignUnassignGroupToTask } }) => {
            cache.writeQuery({
              query: GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_TASK,
              variables: { taskId: this.props.task.id },
              data: {
                adminAssignedGroupForTaskSet:
                  adminAssignUnassignGroupToTask.adminAssignedGroupSet,
                adminAvailableGroupForTaskSet:
                  adminAssignUnassignGroupToTask.adminAvailableGroupSet,
              },
            });
          }}
          onCompleted={() =>
            this.props.history.push(`/manager/tache/${this.props.task.id}`)
          }
        >
          {(mutate) => (
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      positive
                      onClick={this.handleSubmit.bind(this, mutate)}
                    >
                      <Icon name="checkmark" /> Enregistrer
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </EMutation>
      </div>
    );
  }
}

const AddRemoveAutorisationsWithRouter = withRouter(AddRemoveAutorisations);

const SetAutorisations = ({ task }) => (
  <Segment attached>
    <Header as="h5">
      Autoriser l&apos;accès de groupes à la tâche {task.name}
    </Header>

    <EQuery
      query={GET_ASSIGNED_AND_AVAILABLE_GROUPS_FOR_TASK}
      variables={{ taskId: task.id }}
    >
      {(data) => <AddRemoveAutorisationsWithRouter task={task} data={data} />}
    </EQuery>
  </Segment>
);

export { SetAutorisations };
