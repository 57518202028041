// React
import React from "react";
import { useState, useEffect, useCallback } from "react";

// React Router
import { Link } from "react-router-dom";

// Semantic ui
import {
  Breadcrumb,
  Header,
  Form,
  Input,
  Loader,
  Message,
  Segment,
  List,
  Button,
  Dropdown,
  Grid,
  Table,
  Divider,
} from "semantic-ui-react";

// GraphQL
import { useQuery, useMutation, gql } from "@apollo/client";
import ErrorMessage from "../../../../Common/components/ErrorMessage";
import LoaderDisplayer from "../../../../Common/components/LoaderDisplayer";
import { GET_LAYER, ADMIN_QUESTIONNAIRE } from "../../_GraphQL/queries";
import {
  ADMIN_CREATE_QUESTIONNAIRE,
  CREATE_QUESTIONNAIRE_FIELD_WITH_LABEL_SET,
  DELETE_QUESTIONNAIRE_FIELD_WITH_LABEL_SET,
  IMPORT_GEOJSON,
} from "../../_GraphQL/mutations";

// Components
import ManagerLayout from "../../components/ManagerLayout";

// Utils
import { tryParseJSON } from "../../../../utils/utils";

import gjv from "geojson-validation";

import { QUESTIONNAIRE_FIELD_TYPES } from "../../../../utils/Constants";

// MEMO :
// QGis -> Attributes
// GeoJSON -> Properties

function CreateQuestionForAttribute({
  layer,
  attribute,
  scanFileAndFindValuesForAttribute,
  setQuestionCreation,
  attributeValues,
}) {
  const [name, setName] = useState("");
  const [fieldTypeCode, setFieldTypeCode] = useState("");

  useEffect(() => {
    if (
      fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.RADIO ||
      fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.SELECT
    ) {
      scanFileAndFindValuesForAttribute();
    }
  }, [fieldTypeCode, scanFileAndFindValuesForAttribute]);

  const { loading, error, data, refetch } = useQuery(ADMIN_QUESTIONNAIRE, {
    variables: { questionnaireId: layer.questionnaire.id },
  });

  const [
    createQuestionnaireFieldWithLabelSet,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_QUESTIONNAIRE_FIELD_WITH_LABEL_SET, {
    update(cache, { data: { createQuestionnaireFieldWithLabelSet } }) {
      cache.modify({
        id: cache.identify(layer.questionnaire),
        fields: {
          questionnairefieldSet(existingQuestionnaireFieldRefs = []) {
            const newQuestionnaireFieldRef = cache.writeFragment({
              data: createQuestionnaireFieldWithLabelSet.questionnaireField,
              fragment: gql`
                fragment QuestionnaireField on QuestionnaireFieldType {
                  id
                  text
                  shpField
                  fieldType {
                    id
                    code
                    text
                  }
                }
              `,
            });

            return [
              ...existingQuestionnaireFieldRefs,
              newQuestionnaireFieldRef,
            ];
          },
        },
      });
    },
    onCompleted() {
      setQuestionCreation({
        show: false,
        attribute: null,
      });
    },
    onError(error) {
      console.log(error);
    },
  });

  if (loading || mutationLoading) return <Loader />;

  if (error) {
    return (
      <>
        <ErrorMessage error={error} />
        <Button onClick={() => refetch()}>Réessayer</Button>
        <Button
          negative
          onClick={() =>
            setQuestionCreation({
              show: false,
              attribute: null,
            })
          }
        >
          Annuler
        </Button>
      </>
    );
  }

  const fieldTypeSet = data.adminFieldTypeSet.map((type) => ({
    key: type.id,
    value: type.code,
    text: type.text,
  }));

  return (
    <Segment>
      <Header>Création d'une question pour l'attribut « {attribute} »</Header>
      <Form
        error
        onSubmit={() =>
          createQuestionnaireFieldWithLabelSet({
            variables: {
              questionnaireId: layer.questionnaire.id,
              text: name,
              fieldTypeId: fieldTypeSet.find((ft) => ft.value === fieldTypeCode)
                .key,
              shpField: attribute,
              labelSet: attributeValues,
            },
          }).catch((e) => {
            /* Do nothing */
          })
        }
      >
        {mutationError ? <ErrorMessage error={mutationError} /> : null}
        <Form.Field width={8}>
          <label>Nom de l'attribut</label>
          <input readOnly={true} value={attribute} />
        </Form.Field>
        <Form.Field width={6}>
          <label>Intitulé</label>
          <input
            placeholder="Intitulé"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Field>
        <Form.Field width={6}>
          <label>Type</label>
          <Dropdown
            required
            selection
            value={fieldTypeCode}
            options={fieldTypeSet}
            onChange={(e, { value }) => setFieldTypeCode(value)}
          />
        </Form.Field>
        {fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.RADIO ||
        fieldTypeCode === QUESTIONNAIRE_FIELD_TYPES.SELECT ? (
          <>
            <p>Valeurs possibles détectées</p>
            <List>
              {attributeValues.map((v) => (
                <List.Item key={`key-${v}`}>{v}</List.Item>
              ))}
            </List>
          </>
        ) : null}
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column textAlign="right">
              <Button positive type="submit">
                Créer
              </Button>
              <Button
                negative
                onClick={() =>
                  setQuestionCreation({
                    show: false,
                    attribute: null,
                  })
                }
              >
                Annuler
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Segment>
  );
}

function ImportPage({ layer }) {
  const [message, setMessage] = useState({
    error: false,
    message: "",
  });
  const [importCompleted, setImportCompleted] = useState(false);
  const [geoProps, setGeoProps] = useState({ attributes: [], json: null });
  const [questionCreation, setQuestionCreation] = useState({
    show: false,
    attribute: null,
  });
  const [attributeValues, setAttributeValues] = useState([]);
  const [
    createQuestionnaire,
    { loading: createMutationLoading, error: createMutationError },
  ] = useMutation(ADMIN_CREATE_QUESTIONNAIRE, {
    update(cache, { data: { layer } }) {
      cache.writeQuery({
        query: GET_LAYER,
        data: { layer },
      });
    },
  });

  const [
    deleteQuestionnaireFieldWithLabelSet,
    { loading: deleteMutationLoading, error: deleteMutationError },
  ] = useMutation(DELETE_QUESTIONNAIRE_FIELD_WITH_LABEL_SET, {
    update(cache, { data: { deleteQuestionnaireFieldWithLabelSet } }) {
      const questionnaireFieldId =
        deleteQuestionnaireFieldWithLabelSet.questionnaireFieldId.toString();
      layer.questionnaire.questionnairefieldSet =
        layer.questionnaire.questionnairefieldSet.filter(
          (qf) => qf.id !== questionnaireFieldId
        );
      cache.writeQuery({
        query: GET_LAYER,
        variables: { layerId: layer.id },
        data: { layer },
      });
    },
  });

  const [
    importGeojson,
    { loading: importMutationLoading, error: importMutationError },
  ] = useMutation(IMPORT_GEOJSON, {
    update(cache, { data: { layer } }) {
      cache.writeQuery({
        query: GET_LAYER,
        data: { layer },
      });
    },
    onCompleted(data) {
      setGeoProps({ variables: { attributes: [], json: null, file: null } });
      setImportCompleted(true);
    },
  });

  function readGeoJson({
    target: {
      files: [file],
    },
  }) {
    const fileReader = new FileReader();

    fileReader.onload = function () {
      const json = tryParseJSON(fileReader.result);
      if (json) {
        if (gjv.valid(json)) {
          if (json.type === "FeatureCollection") {
            setGeoProps({
              attributes: Object.keys(json.features[0].properties).map((p) => ({
                name: p,
              })),
              json: json,
              file: file,
            });
          }
        } else {
          setMessage({ error: true, message: "Le GeoJSON n'est pas valide." });
        }
      } else {
        setMessage({
          error: true,
          message: "Le fichier renseigné n'est pas au format JSON.",
        });
      }
    };

    fileReader.readAsText(file);
  }

  function handleCreateQuestionClick(attribute) {
    setQuestionCreation({
      show: true,
      attribute: attribute,
    });
  }

  const scanFileAndFindValuesForAttribute = useCallback(() => {
    let values = new Set();
    geoProps.json.features.forEach((f) => {
      values.add(f.properties[questionCreation.attribute]);
    });
    setAttributeValues(Array.from(values));
  }, [geoProps, questionCreation.attribute]);

  if (createMutationLoading || importMutationLoading || deleteMutationLoading) {
    return <LoaderDisplayer />;
  }

  let questionWithShpFields = [];
  let questionWithoutShpFields = [];
  let shpFields = [];
  if (layer.questionnaire) {
    questionWithShpFields = layer.questionnaire.questionnairefieldSet.filter(
      (qf) => qf.shpField !== null
    );
    questionWithoutShpFields = layer.questionnaire.questionnairefieldSet.filter(
      (qf) => qf.shpField === null
    );
    shpFields = questionWithShpFields.map((qf) => qf.shpField);
  }

  return (
    <ManagerLayout
      breadcrumbItems={[
        <Breadcrumb.Section key="breadcrumb-home">
          <Link to="/manager">Manager</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-sites" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-sites">
          <Link to="/manager/sites">Sites</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-site" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-site">
          <Link to={`/manager/site/${layer.task.project.site.id}`}>
            Site : {layer.task.project.site.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-project" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-project">
          <Link to={`/manager/projet/${layer.task.project.id}`}>
            Projet : {layer.task.project.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-module" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-module">
          <Link to={`/manager/carte/?projectId=${layer.task.project.id}`}>
            Module : Carte participative
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-task" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-task">
          <Link to={`/manager/tache/${layer.task.id}`}>
            Tâche : {layer.task.name}
          </Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-layer" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-layer">
          <Link to={`/manager/couche/${layer.id}`}>Couche : {layer.name}</Link>
        </Breadcrumb.Section>,
        <Breadcrumb.Divider key="bc-divider-import" icon="right angle" />,
        <Breadcrumb.Section key="breadcrumb-import">Import</Breadcrumb.Section>,
      ]}
    >
      <Header as="h5" block>
        {`Import GeoJSON pour la couche ${
          layer.editable ? "participative" : "informative"
        } « ${layer.name} »`}
      </Header>

      <Segment secondary>
        <Form error warning>
          {layer.hasFeature ? (
            <Message warning>
              Cette couche possède déjà des données. Si vous importez un nouveau
              fichier, les données précédentes seront écrasées.
            </Message>
          ) : null}
          <Form.Field>
            <label>
              Veuillez entrer un fichier au format GeoJSON (Système géodésique
              EPSG:4326 WGS84 - non projeté)
            </label>
            <Input type="file" onChange={readGeoJson} />
          </Form.Field>
          {message.error ? (
            <Message
              error
              header="Le fichier renseigné n'est pas valide"
              content={message.message}
            />
          ) : null}
        </Form>
      </Segment>
      {importCompleted ? (
        <Message positive>
          <Message.Header>Import terminé</Message.Header>
          <Message.Content>
            L'import s'est correctement déroulé.{" "}
            <Link to={`/manager/couche/${layer.id}`}>Revenir à la couche</Link>
          </Message.Content>
        </Message>
      ) : null}
      {geoProps.attributes && geoProps.attributes.length ? (
        <>
          {!layer.questionnaire ? (
            <Segment secondary>
              <Form error>
                {createMutationError ? (
                  <ErrorMessage error={createMutationError} />
                ) : null}
                <Button
                  onClick={() =>
                    createQuestionnaire({
                      variables: {
                        name: `${layer.task.project.name} - ${layer.task.name} - ${layer.name}`,
                        layerId: layer.id,
                      },
                    }).catch((e) => console.log(e))
                  }
                >
                  Créer le questionnaire
                </Button>
              </Form>
            </Segment>
          ) : (
            <Segment secondary>
              <Header as="h5">
                Gestion de la correspondance attributs/questions pour le
                questionnaire « {layer.questionnaire.name} »
              </Header>

              {!questionCreation.show ? (
                <Form error>
                  {importMutationError ? (
                    <ErrorMessage error={importMutationError} />
                  ) : null}
                  {deleteMutationError ? (
                    <ErrorMessage error={deleteMutationError} />
                  ) : null}
                  <Table celled structured attached="top">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan={3}>
                          Questions pour les attributs détectés dans le fichier
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell>Attribut</Table.HeaderCell>
                        <Table.HeaderCell>Question</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {geoProps.attributes
                        .filter((p) => shpFields.indexOf(p.name) < 0)
                        .map((p) => (
                          <Table.Row key={`attr-${p.name}`}>
                            <Table.Cell>{p.name}</Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>
                              <Button
                                onClick={() =>
                                  handleCreateQuestionClick(p.name)
                                }
                              >
                                Créer une question pour cet attribut
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      {geoProps.attributes
                        .filter((p) => shpFields.indexOf(p.name) >= 0)
                        .map((p) => {
                          const question = questionWithShpFields.find(
                            (q) => q.shpField === p.name
                          );
                          return (
                            <Table.Row key={`attr-${p.name}`}>
                              <Table.Cell>{p.name}</Table.Cell>
                              <Table.Cell>{question.text}</Table.Cell>
                              <Table.Cell>
                                <Button
                                  icon="delete"
                                  onClick={() =>
                                    deleteQuestionnaireFieldWithLabelSet({
                                      variables: {
                                        questionnaireFieldId: question.id,
                                      },
                                    }).catch((e) => {
                                      /* Do nothing */
                                    })
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                  {questionWithoutShpFields.length > 0 ? (
                    <Table celled attached="bottom">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Autres questions</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {questionWithoutShpFields.map((q) => (
                          <Table.Row key={`attr-${q.id}`}>
                            <Table.Cell>{q.text}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  ) : null}
                  <Divider />

                  <Form.Field>
                    <Grid columns={1}>
                      <Grid.Row>
                        <Grid.Column textAlign="right">
                          <Button
                            positive
                            type="submit"
                            onClick={() =>
                              importGeojson({
                                variables: {
                                  layerId: layer.id,
                                  properties: shpFields,
                                  data: geoProps.file,
                                },
                              }).catch((e) => {
                                /* Do nothing */
                              })
                            }
                          >
                            Importer
                          </Button>
                          <Button
                            onClick={() =>
                              setGeoProps({ attributes: [], json: null })
                            }
                            negative
                          >
                            Annuler
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                </Form>
              ) : (
                <CreateQuestionForAttribute
                  geoProps={geoProps}
                  attribute={questionCreation.attribute}
                  layer={layer}
                  scanFileAndFindValuesForAttribute={
                    scanFileAndFindValuesForAttribute
                  }
                  setQuestionCreation={setQuestionCreation}
                  attributeValues={attributeValues}
                />
              )}
            </Segment>
          )}
        </>
      ) : null}
    </ManagerLayout>
  );
}

function GetLayer({ match }) {
  const { loading, error, data } = useQuery(GET_LAYER, {
    variables: { layerId: match.params.layerId },
  });
  if (loading) return <Loader />;
  if (error) return <Message negative>{error.message}</Message>;
  return <ImportPage layer={data.layer} />;
}

export { GetLayer as ImportLayer };
